import OSS from 'ali-oss';
import { preUpload } from './../api/service';
import { IPreUploadRes } from './../api/types';

export async function createOSSClient(data: IPreUploadRes) {
  const {
    // region,
    accessKeyId,
    accessKeySecret,
    securityToken,
    bucketName,
    endpoint,
  } = data;

  const client = new OSS({
    // region,
    accessKeyId: accessKeyId,
    accessKeySecret: accessKeySecret,
    stsToken: securityToken,
    bucket: bucketName,
    endpoint: endpoint,
  });

  return client;
}

export async function upload(scene: string, filename: string, file: File | Blob | Object) {
  const { data } = await preUpload(scene, filename);
  const client = await createOSSClient(data);
  const uploadResult = await client.put(data.objectName, file);
  return {
    mediaId: data.mediaId,
    uploadResult,
  };
}

// export async function getPreviewUrl(path: string, type: string) {
//   const { data } = await preUpload(type);
//   const client = await createOSSClient(data);
//   return client.signatureUrl(path, { expires: 3600 });
// }

// export async function getDownloadUrl(path: string, type: string, fileName: string) {
//   const { data } = await preUpload(type);
//   const client = await createOSSClient(data);

//   const response = {
//     'content-disposition': `attachment; filename=${encodeURIComponent(fileName)}`,
//   };
//   return client.signatureUrl(path, { response });
// }
