















import { Vue, Component, Prop } from 'vue-property-decorator';
import Router from 'vue-router';

Vue.use(Router);

const ref = {
  innerChange: false,
};

@Component
export default class Anchor extends Vue {
  @Prop({ required: true }) anchorData!: { title: string, hash: string }[]
  @Prop() pos?: { left?: string, right?: string, top?: string, bottom?: string }
  @Prop() offset?: number
  @Prop({ default: () => window }) container!: string | HTMLElement | (() => (HTMLElement | Window)) | Window;

  currentHash = this.$route.hash;

  scrollToHash(hash: string) {
    const title = document.querySelector(hash) as HTMLElement | null;
    if (!title) { return; }
    ref.innerChange = true;
    this.realContainer.scrollTo({
      top: title.offsetTop + (this.offset || 0),
      behavior: 'smooth',
    });
    setTimeout(() => {
      history.replaceState(null, '', document.URL.replace(/#([\w\W]+)/, hash));
      this.currentHash = hash;
      ref.innerChange = false;
    }, 300);
  }

  get realContainer() {
    return (
    // eslint-disable-next-line no-nested-ternary
      typeof this.container === 'function'
        ? this.container()
        : typeof this.container === 'string'
          ? document.querySelector(this.container) as HTMLElement
          : this.container
    ) || window;
  }

  listenScroll() {
    this.realContainer.addEventListener('scroll', () => {
      if (ref.innerChange) {
        return;
      }
      const currentNav = this.anchorData.find(nav => {
        const title = document.querySelector(nav.hash) as HTMLElement | null;
        if (!title) {
          return false;
        }
        const offsetTop = (this.realContainer as HTMLElement).scrollTop || document.documentElement.offsetTop;
        return Math.abs(offsetTop - title.offsetTop) <= 300;
      });
      if (!currentNav) {
        return;
      }
      history.replaceState(null, '', document.URL.replace(/#([\w\W]+)/, currentNav.hash));
      this.currentHash = currentNav.hash;
    });
  }

  created() {
    if (!window.location.hash && this.anchorData[0]?.hash) {
      this.currentHash = this.anchorData[0].hash;
      history.replaceState(null, '', `${document.URL}${this.anchorData[0].hash}`);
    }
    this.listenScroll();
  }
}
