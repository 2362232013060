<template>
  <div>
    <el-col class="search-col">
      <el-input
        placeholder="请输入内容"
        v-model="searchValue"
        class="search"
        clearable
        @keydown.enter.native="submitSearch"
        @clear="clearSearch"
        size="mini"
      >
        <el-select v-model="searchOptionValue" slot="prepend" placeholder="请选择" class="search-options">
          <el-option v-for="item in selectOptions" :key="item.code" :label="item.title" :value="item.code"></el-option>
        </el-select>
        <el-button
          :slot="isShowSearchIcon ? 'append' : ''"
          :icon="isShowSearchIcon ? 'el-icon-search' : ''"
          size="mini"
          :disabled="!searchOptionValue || !searchValue.trim()"
          @click="submitSearch"
        ></el-button>
      </el-input>
    </el-col>
  </div>
</template>

<script>
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component()
export default class Search extends Vue {
  searchValue = '';
  searchOptionValue = 'name';

  @Prop({ default: [] }) selectOptions;
  @Prop({ default: true }) isShowSearchIcon;

  @Watch('searchOptionValue')
  onSearchOptionValue() {
    this.searchValue = '';
  }

  @Watch('searchValue')
  onSearchValue() {
    this.submitSearch();
  }

  @Emit('submit')
  submitSearch() {
    return {
      type: this.searchOptionValue,
      value: this.searchValue,
      isShowSearchIcon: this.isShowSearchIcon,
    };
  }

  @Emit('clear')
  clearSearch() {
    return this.searchOptionValue;
  }
}
</script>

<style scoped lang="less">
/deep/.el-input__inner {
  height: 40px;
}
.search-col {
  width: 300px;
}
.search-options {
  width: 100px;
}
</style>
