























































/* eslint-disable no-unused-vars */

import { Vue, Component, Prop } from 'vue-property-decorator';
import { AjaxResponse } from '../../api/types';
import { uploadConfig, ImageAccept, defaultImg } from './config';
import { fileSize, isPromise, safeCallback } from './../../utils/global';
import { upload } from './service';
import { IFile, TFile, IPostUplaodData, IUploadObjRes, IUploadVideoRes, IUploadFileOptions, TPreuploadScene, IUploadOptions } from './types';
import VideoUpload from './components/video-upload.vue';

@Component({
  components: {
    VideoUpload,
  },
})
export default class CommonUpload extends Vue {
  @Prop({ required: true }) uploadOptions!: IUploadFileOptions<IUploadObjRes | IUploadVideoRes>;
  @Prop({ required: true, type: String }) scene!: TPreuploadScene;
  @Prop() disabled?: boolean;

  imgLoadError(e: Event) {
    const target = e.target as HTMLImageElement;
    target.parentElement!.style.cssText = 'display: flex;justify-content: center; align-items: center;';
    target.style.cssText = 'width: 20px; height: auto; object-fit: cover;';
    // eslint-disable-next-line max-len

    target.src = defaultImg;
  }

  currentFile: IFile | null = null;
  fileList: TFile[] = [];
  dialogVisible = false;
  dialogImageUrl?: string = '';

  created() {
    this.fileList = this.options.fileList || [];
  }

  get hasSlots() {
    return !!(Object.keys(this.$slots).length || Object.keys(this.$scopedSlots).filter(k => !!this.$scopedSlots[k]).length);
  }

  get coverOverLength() {
    return this.fileList.length >= (this.options.limit || 1);
  }

  handlePictureCardPreview(file: IFile) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  handleRemove(file: IFile) {
    const res = safeCallback(this.options.beforeRemove, [file, this.fileList]);
    type TRes = Extract<typeof res, Promise<any>>;
    if (isPromise(res)) {
      return (res as TRes)
        .then(() => {
          const index = this.fileList.findIndex(item => item.url === file.url || item.uid === file.uid);
          this.fileList.splice(index, 1);
          safeCallback(this.options.onRemove, [file, this.fileList]);
        })
        .catch(() => {});
    }

    if (res === true) {
      const index = this.fileList.findIndex(item => item.url === file.url || item.uid === file.uid);
      this.fileList.splice(index, 1);
      return safeCallback(this.options.onRemove, [file, this.fileList]);
    }

    return false;
  }

  handleDownload(file: IFile) {
    window.open(file.url);
  }

  uploadError(error: string | Error, file: IFile, fileList: TFile[]) {
    safeCallback(this.uploadOptions.onError, [error, file, fileList]);
    safeCallback(this.uploadOptions.onChange, [file, fileList]);
    this.$notify.error({
      title: this.uploadOptions.errorMsg || '上传文件失败',
      message: typeof error === 'string' ? error : error.message,
    });
    console.error(`【上传文件失败】:\n${file.name}`);
    console.info('【文件信息】:\n', file, fileList);
    console.info('【错误信息】:\n', error);
  }

  uploadSuccess(res: AjaxResponse<IUploadObjRes | IUploadVideoRes>, file: IFile, fileList: TFile[]) {
    this.fileList = fileList.map(e => (e.uid === file.uid ? { ...file, ...res.data } : e));
    safeCallback(this.uploadOptions.onSuccess, [res, file, this.fileList]);
    safeCallback(this.uploadOptions.onChange, [file, this.fileList]);
    this.uploadOptions.successMsg && this.$notify.success(this.uploadOptions.successMsg);
  }

  afterUploadObj(rs: AjaxResponse<IUploadObjRes | IPostUplaodData>, file: IFile) {
    if (!rs.success || !rs.data) {
      this.uploadError(rs.message, file, this.fileList);
      return;
    }
    this.uploadSuccess(rs, file, this.fileList);
    return;
  }

  beforeUpload(file: IFile) {
    const { onlyImage = false, singleFileSizeLimit = uploadConfig.singleSizeLimit, fileTypeLimit } = this.options;

    if (onlyImage && !ImageAccept.includes(file.type)) {
      this.$notify.error('只能上传图片格式的文件!');
      return false;
    }

    if (fileTypeLimit && fileTypeLimit.length > 0 && !fileTypeLimit.includes(file.type)) {
      this.$notify.error({
        title: '上传文件格式不正确!',
        message: `只能上传 ${fileTypeLimit.join(',')} 类型的文件`,
      });
      return false;
    }

    if (file.size > singleFileSizeLimit) {
      this.$notify.error(`上传文件不能超过${fileSize(singleFileSizeLimit)}`);
      return false;
    }

    return true;
  }

  upload(options: IUploadOptions<IUploadObjRes | IUploadVideoRes>) {
    if (!this.currentFile || !options.file) {
      this.$notify.warning('请先选择一个文件');
      return;
    }
    const file = options.file || this.currentFile;
    upload(file, this.scene)
      .then(rs => {
        this.afterUploadObj(rs, file);
      })
      .catch(err => this.uploadError(err, file, this.fileList));
  }

  beforeRemove(file: IFile) {
    return new Promise<boolean>((resolve, reject) => {
      this.$confirm(`确定要删除文件${file.name ? ` ${file.name} ` : ''}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'before-remove-msgbox',
      })
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  }

  get options(): IUploadFileOptions<IUploadObjRes | IUploadVideoRes> {
    return {
      ...this.uploadOptions,
      url: this.uploadOptions.url || '',
      /**
       * 视频上传使用 阿里云SDK，关闭自动上传
       */
      autoUpload: this.uploadOptions.video ? false : this.uploadOptions.autoUpload,
      httpRequest: this.uploadOptions.httpRequest || this.upload,
      onChange: (file: IFile, fileList: TFile[]) => {
        this.currentFile = file;
        this.fileList = fileList;
        safeCallback(this.uploadOptions.onChange, [file, fileList]);
      },
      beforeRemove: (file: IFile, fileList: TFile[]) => {
        const res = safeCallback(this.uploadOptions.beforeRemove, [file, fileList]);
        if (isPromise(res)) {
          return (res as Promise<any>).then(() => {
            return this.beforeRemove(file);
          });
        }

        if (res === false) {
          return false;
        }

        return this.beforeRemove(file);
      },
      beforeUpload: (file: IFile) => {
        const res = safeCallback(this.uploadOptions.beforeUpload, [file]);
        if (isPromise(res)) {
          return (res as Promise<any>).then(() => {
            this.beforeUpload(file);
          });
        }

        if (res === false) {
          return false;
        }

        return this.beforeUpload(file);
      },
      onSuccess: (res, file, fileList) => {
        this.uploadSuccess(res, file, fileList);
      },
      onError: (error, file, fileList) => {
        this.fileList = fileList;
        this.uploadError(error as string | Error, file, fileList);
      },
      onRemove: (file: IFile, fileList: TFile[]) => {
        this.fileList = fileList;
        safeCallback(this.uploadOptions.onRemove, [file, fileList]);
        safeCallback(this.uploadOptions.onChange, [file, fileList]);
      },
      onExceed: (file: IFile, fileList: IFile[]) => {
        this.$notify.warning(`文件个数最多不能超过${this.options.limit || 1}个`);
        safeCallback(this.uploadOptions.onExceed, [file, fileList]);
      },
      headers: {
        ...this.uploadOptions.headers,
        token: this.$QJUtils.getInfoFromAllStorage<string>(this.$QJConfig.tokenKey),
      },
    };
  }
}
