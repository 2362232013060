






































































import { IJob } from '../../model/job-create';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JobInfoPreview extends Vue {
  show = false;
  job: Partial<IJob> = {};
}
