






























































import { getFilterList } from '@/common/apis/list/list';
import { getJobList } from '@/common/service';
import { Component, Vue, Watch } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import Search from '@/pages/home/views/list-of-operations/components/search.vue';
import { jobColumns } from '@/pages/home/views/list-of-operations/contants';
import ResumeClueDrawer from './resume-clue-drawer.vue';
import { Bind, Debounce } from 'lodash-decorators';

interface selectOption {
  label: string;
  value: string | null;
}

@Component({
  components: {
    List,
    Search,
    ResumeClueDrawer,
  },
})
export default class ListOfOperations extends Vue {
  activeName = 'job-list';
  pageNo = 1;
  pageSize = 10;
  totalCount = 0;
  searchValue = '';
  loading = false;
  searchOption = '';
  columns = jobColumns;
  tableData: any = [];
  companyOptions: any = [];
  query: Partial<{
    title: string;
    strCompanyIds: string;
    jobIds: string;
    city: string;
    opened: string | null;
    status: string | null;
  }> = {
    title: '',
    strCompanyIds: '',
    jobIds: '',
    city: '',
    opened: '',
    status: '',
  };
  interviewRoomStatusOptions: selectOption[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '已开启',
      value: '1',
    },
    {
      label: '待开启',
      value: '-1',
    },
    {
      label: '已关闭',
      value: '0',
    },
  ];
  recruitmentStatusOptions: selectOption[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '招聘中',
      value: 'PUBLISHED',
    },
    {
      label: '已关闭',
      value: 'CLOSED',
    },
  ];

  $refs!: {
    ResumeClueDrawer: ResumeClueDrawer;
  };

  // search
  companySearch = '';
  jobSearch = '';
  city = '';

  get isShowBack() {
    return !!this.$route?.query?.path;
  }

  async created() {
    const data = await this.getFilterList('COMPANY');
    this.companyOptions = this.companyOptions.concat(
      data.map(el => ({
        label: el.name,
        value: el.id,
      })),
    );
  }

  @Debounce(1000)
  @Bind
  @Watch('companySearch')
  async onCompanySearch(value: string[]) {
    this.pageNo = 1;
    this.pageSize = 10;
    this.query.strCompanyIds = Array.isArray(value) ? value.join(',') : '';
    this.getTableList();
  }

  @Debounce(1000)
  @Bind
  @Watch('query.status')
  async onStatus() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getTableList();
  }

  @Debounce(1000)
  @Bind
  @Watch('query.opened')
  async onOpened() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getTableList();
  }

  async handlerClue(row) {
    this.$refs.ResumeClueDrawer.open(row.jobId);
  }

  @Debounce(1000)
  @Bind
  async onJobSearch(value: string, type: string) {
    this.pageNo = 1;
    this.pageSize = 10;
    this.query[type] = value;
    this.getTableList();
  }

  // 附身
  handlerLook(item) {
    let id = null;
    let name = null;
    let path = '';
    switch (this.activeName) {
      case 'job-list':
        id = item.publishedBy.id;
        name = item.publishedBy.name;
        path = `/jd/${item.jobId}/job`;
        break;
    }
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: id,
      targetName: name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(
      `${this.$QJConfig.BEndPath}${path}?${Object.entries(query)
        .map(e => e.join('='))
        .join('&')}`,
    );
  }

  // 导入简历
  handleExportCv(row) {
    this.$router.push({
      name: 'resume-review',
      query: {
        jobId: row.jobId,
        isExport: '1',
        isImport: '0',
      },
    });
  }

  // 获取所有列表（职位、公司等
  async getFilterList(type: string, companyIds: string = '') {
    const params = {
      type: type,
      companyIds: companyIds,
      city: '',
    };
    const { data } = await getFilterList(params);
    return data;
  }

  handlerBack() {
    const path = this.$route?.query?.path || '';
    const tabName = this.$route?.query?.tabName || '';
    if (path) {
      this.$router.replace({
        path: String(path),
        query: { tabName: String(tabName) },
      });
    }
  }

  async getTableList() {
    this.loading = true;
    const query = Object.assign({}, this.query);
    Object.keys(query).forEach(key => {
      if (!query[key] || query[key] === '') {
        delete query[key];
      }
    });
    const params = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      ...query,
    };
    try {
      const { data } = await getJobList(params);
      this.totalCount = data.totalCount!;
      this.tableData = data.list.map(el => ({
        ...el,
        companyName: el.company.name,
        name: el.publishedBy.name,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  clearQuery() {
    this.query = {
      title: '',
      strCompanyIds: '',
      jobIds: '',
      city: '',
    };
  }

  handleClick() {
    this.clearQuery();
    const { activeName } = this;
    this.pageNo = 1;
    this.pageSize = 10;
    switch (activeName) {
      case 'job-list':
        this.companySearch = '';
        this.jobSearch = '';
        this.columns = jobColumns;
        break;
    }
  }

  handlerSizeChange(value: number) {
    this.pageSize = value;
    this.getTableList();
  }

  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getTableList();
  }
}
