

















import { IContentAudit, IContentAuditListData } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const ContentAuditModule = namespace('audit');

@Component
export default class ContentAuditListCard extends Vue {
  @Prop({ required: true }) item!: IContentAuditListData;

  @ContentAuditModule.Action('setCurrentAuditInterview')
  setCurrentAuditInterview!: (interviewId: number) => Promise<IContentAudit>;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  handleDuration(duration?: string | number) {
    if (duration && ['number', 'string'].includes(typeof duration)) {
      return 1000 > Number(duration)
        ? '小于1秒'
        : this.$QJUtils.formatSeconds(Number(duration) / 1000);
    }
    return '未知';
  }

  bodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  };
}
