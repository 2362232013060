













































import { Vue, Component } from 'vue-property-decorator';
import {
  IAddInterviewerData,
  IInterviewer,
  IPagination,
} from '@w-admin/common/src/api/types';
import { addInterviewer, getAddedInterviewerList, getInterviewerList } from '@w-admin/common/src/api/service';
import AddInterviewerForm from './add-interviewer-form.vue';
import { IAddInterviewerDrawerData, TAPIClent } from './types';

@Component({
  components: {
    AddInterviewerForm,
  },
})
export default class AddInterviewerDrawer extends Vue {
  subjectText = '职位协作人';
  client: TAPIClent = 'a';
  jobTitle = '';
  footerStyle: string | Record<string, any> = '';
  extraButton: IAddInterviewerDrawerData['extraButton'] = {
    type: 'default',
    text: '取消',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    click: (_jobId: number, _e: MouseEvent) => {
      this.closeDrawer();
    },
  }

  extraButtonClick(e: MouseEvent) {
    if (this.extraButton && typeof this.extraButton.click === 'function') {
      this.extraButton.click(this.jobId!, e);
    }
  }

  listData: IPagination<IInterviewer> = {
    pageNo: 1,
    pageSize: 1000,
    totalCount: 0,
    list: [],
  }

  loading = false;
  btnLoading = false;
  subtitle = '';

  show = false;

  onShowChange(show: boolean) {
    if (!show) {
      this.closeDrawer();
    }
  }

  jobId: number | null = null;

  bids: number[] = [];

  closeDrawer() {
    this.show = false;
    this.$destroy();
  }

  cancel() {
    this.closeDrawer();
  }

  success() {}

  async addInterviewerAction(addInterviewerData: IAddInterviewerData) {
    return await addInterviewer(this.client, addInterviewerData.jobId, addInterviewerData.bids);
  }

  async getInterviewersAction(options: { jobId: number, pageNo: number, pageSize: number }) {
    const res = await getInterviewerList(this.client, options);
    const { data: checkedInterviewers } = await getAddedInterviewerList(this.client, options.jobId);
    const listData = res?.data?.list?.map(e => ({ ...e, checked: (checkedInterviewers || []).includes(e.id) }));
    const resData: IPagination<IInterviewer> = { ...res?.data, list: listData };
    return resData;
  }

  async getInterviewerList(pageNo = 1, pageSize = 1000) {
    this.loading = true;
    const clientNotifyMethods = this.$QJConfig.clientNotifyMethods[this.client] as 'message' | 'notify';
    try {
      const data = await this.getInterviewersAction({
        jobId: this.jobId!,
        pageNo,
        pageSize,
      });
      this.listData = data;
      this.bids = this.listData.list.filter(e => e.checked).map(e => e.id);
      if (data.totalCount <= 0) {
        this[`$${clientNotifyMethods}`].warning(`没有可添加的${this.subjectText}！`);
      }
      this.loading = false;
    } catch (error) {
      console.log(`[获取${this.subjectText}列表错误]`, error);
      this.loading = false;
    }
  }

  async submit() {
    const clientNotifyMethods = this.$QJConfig.clientNotifyMethods[this.client] as 'message' | 'notify';
    try {
      this.btnLoading = true;
      const data = await this.addInterviewerAction({
        bids: this.bids,
        jobId: this.jobId!,
      });
      if (data.success) {
        this[`$${clientNotifyMethods}`].success(`设置${this.subjectText}成功!`);
        this.$QJUtils.safeCallback(this.success);
        this.closeDrawer();
      } else {
        this[`$${clientNotifyMethods}`].error(data.message);
      }
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      console.log(`[设置${this.subjectText}出错]`, error);
      this[`$${clientNotifyMethods}`].error(`[设置${this.subjectText}出错]`);
    }
  }

  handleSizeChange(pageSize: number) {
    this.getInterviewerList(this.listData.pageNo, pageSize);
  }

  handleCurrentChange(pageNo: number) {
    this.getInterviewerList(pageNo, this.listData.pageSize);
  }

  mounted() {
    if (this.jobId) {
      this.getInterviewerList();
    }
  }
}
