


































import { IEnterpriseVideoListData } from '@/model/enterprise-video';
import { IVideoBaseInfo } from '@/model/resource';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class VideoItem extends Vue {
  @Prop({ required: true }) video!: IEnterpriseVideoListData<IVideoBaseInfo> & { data: IVideoBaseInfo };
  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  preview(item: IEnterpriseVideoListData<IVideoBaseInfo>, e: Event) {
    this.$emit('preview', item, e);
  }
  remove(item: IEnterpriseVideoListData<IVideoBaseInfo>, e: Event) {
    this.$emit('remove', item, e);
  }
}
