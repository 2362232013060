import { PluginObject } from 'vue';
import CommonUpload from './index.vue';
import NcCommonUpload from './components/nc-common-upload.vue';
import * as options from './config';
import * as services from './service';
export * from './types';
export { NcCommonUpload };

export const $QJUploader = {
  options,
  services,
  scene: options.preUploadSceneMap,
  imgTypes: options.ImageAccept,
};

const tagRef = {
  installed: false,
};

const QJUploader: PluginObject<undefined> = {
  install: Vue => {
    if (tagRef.installed) {
      return;
    }
    tagRef.installed = true;
    const OSS = require('ali-oss/lib/browser.js');
    Object.defineProperty(window, 'OSS', { value: OSS });
    require('@babel/polyfill');
    require('es6-promise/auto');
    require('./lib/fileReader.polyfill.js');
    require('./lib/aliyun-upload-sdk/aliyun-upload-sdk-1.5.2.min.js');
    Vue.component('common-upload', CommonUpload);
    Object.defineProperty(Vue.prototype, '$QJUploader', {
      value: $QJUploader,
      writable: false,
    });
  },
};

export default QJUploader;
