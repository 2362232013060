




























































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { TResume } from '@/model/resume';
import { getResumeDetail, getResumeDetail1, postResumeSuccess } from '@/common/apis/resume/resume';
import UserInfoDrawer from '../../resume-annotation/components/userinfo-drawer.vue';
import PersonalAdvantage from '../../resume-annotation/components/personal-advantage.vue';
import WorkEdit from '../../resume-annotation/components/work-edit.vue';
import ProjectExperience from '../../resume-annotation/components/project-experience.vue';
import EducationalExperience from '../../resume-annotation/components/educational-experience.vue';
import TrainingExperience from '../../resume-annotation/components/training-experience.vue';
import LanguageSkills from '../../resume-annotation/components/language-skills.vue';
import QualificationCertificate from '../../resume-annotation/components/qualification-certificate.vue';

@Component({
  components: {
    Resume,
    UserInfoDrawer,
    PersonalAdvantage,
    WorkEdit,
    ProjectExperience,
    EducationalExperience,
    TrainingExperience,
    LanguageSkills,
    QualificationCertificate,
  },
})
export default class CvDetail extends Vue {
  drawerVisible = false;
  status = 1;
  isPass = false;
  edit = '0';
  data: TResume = {
    applyCount: 0,
    createdAt: '',
    forwards: [],
    edus: [],
    works: [],
    trainings: [],
    advantage: {
      resumeId: 0,
      summary: '',
    },
    latestEdu: {},
    latestWork: {},
    resumeId: 0,
    photoUrl: '',
    pdfUrl: '',
    age: 0,
    birth: '',
    email: '',
    jobRole: '',
    jobState: '',
    location: '',
    mobile: '',
    name: '',
    photoMediaId: 0,
    politicalStatus: '',
    sex: '',
    vocation: '',
    workStart: '',
    editStatus: 0,
    certificates: [],
    projects: [],
    summary: '',
    education: '',
    eduId: null,
    end: '',
    school: '',
    speciality: '',
    start: '',
    experience: '',
    channel: '',
    userId: '',
  };
  resumeIds = [];
  resumeIndex = 0;
  isDirtyData = false;

  $refs!: {
    UserInfoDrawer: UserInfoDrawer;
    PersonalAdvantage: PersonalAdvantage;
    WorkEdit: WorkEdit;
    ProjectExperience: ProjectExperience;
    EducationalExperience: EducationalExperience;
    TrainingExperience: TrainingExperience;
    LanguageSkills: LanguageSkills;
    QualificationCertificate: QualificationCertificate;
  };

  created() {
    this.edit = String(this.$route.query.edit);
    this.getResumeDetail();
  }

  // 标记为脏数据
  async handlerDirtyData() {
    try {
      const { success } = await postResumeSuccess({
        resumeId: Number(this.$route.query.cvId),
        editStatus: this.isDirtyData ? 0 : -1,
      });
      if (success) {
        if (this.isDirtyData) {
          this.$message({
            type: 'success',
            message: '标记成功!',
          });
        }
        this.getResumeDetail();
      }
    } catch (error) {
      console.error('🙅 标记简历', error);
    }
  }

  async getResumeDetail() {
    let result!: TResume;
    if (this.edit === '0') {
      const resumeId = this.$route.query.cvId;
      // this.resumeIds = JSON.parse(JSON.stringify(this.$route.params.ids));
      // this.resumeIndex = this.resumeIds.findIndex(el => el === resumeId);
      const { data } = await getResumeDetail({
        resumeId: Number(resumeId),
      });
      result = data;
      this.isDirtyData = result.editStatus === -1;
    } else {
      const { data } = await getResumeDetail1({
        resumeId: Number(this.$route.query.cvId),
      });
      result = data;
    }
    this.data = result;
  }

  // 完善完成
  async handlerSuccess() {
    if (this.data.editStatus === 1) {
      this.$message({
        type: 'success',
        message: '已完善状态，不可更改！',
      });
      return;
    }
    try {
      const { success } = await postResumeSuccess({ resumeId: Number(this.$route.query.cvId), editStatus: 1 });
      if (success) {
        this.$message({
          type: 'success',
          message: '提交成功!',
        });
        this.getResumeDetail();
      }
    } catch (error) {
      console.error('🙅 完善简历', error);
    }
    this.handlerBack();
  }
  handlerBack() {
    this.$router.go(-1);
  }
  // 个人信息编辑
  handlerUserInfoEditCv() {
    this.$refs.UserInfoDrawer.open(this.data.pdfUrl, this.data, Number(this.$route.query.cvId));
  }
  // 个人优势编辑
  handlerAdvantageEditCv() {
    const data = this.data?.advantage?.summary || '';
    this.$refs.PersonalAdvantage.open(this.data.pdfUrl, data, Number(this.$route.query.cvId));
  }
  // 工作经历
  handlerWorkEditCv() {
    this.$refs.WorkEdit.open(this.data.pdfUrl, this.data.works, Number(this.$route.query.cvId));
  }
  // 项目经历
  handlerProjectExperienceEditCv() {
    this.$refs.ProjectExperience.open(this.data.pdfUrl, this.data.projects, Number(this.$route.query.cvId));
  }
  // 教育经历
  handlerEdusEditCv() {
    this.$refs.EducationalExperience.open(this.data.pdfUrl, this.data.edus, Number(this.$route.query.cvId));
  }
  // 培训经历
  handlerTrainEditCv() {
    this.$refs.TrainingExperience.open(this.data.pdfUrl, this.data.trainings, Number(this.$route.query.cvId));
  }
  // 语言能力
  handlerLanguageEditCv() {
    this.$refs.LanguageSkills.open(this.data.pdfUrl);
  }
  // 资质证书
  handlerCertificateEditCv() {
    this.$refs.QualificationCertificate.open(this.data.pdfUrl, this.data.certificates, Number(this.$route.query.cvId));
  }
  // 刷新数据
  handlerSubmit() {
    this.getResumeDetail();
  }

  handleClose() {
    this.drawerVisible = false;
  }
}
