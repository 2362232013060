














/* eslint-disable no-unused-vars */
import { TContentAuditTabName } from '@/common/types';
import showGetAuditTaskModal from '@/components/get-audit-task-modal';
import { IContentAuditModuleTabs } from '@/pages/home/store/interview-content-audit-module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import showRejectAuditModal from '@/components/reject-audit-modal';
import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';

const ContentAuditModule = namespace('audit');

@Component
export default class ContentAuditNavHeader extends Vue {
  @Prop() currentMenuTitle!: string;

  @ContentAuditModule.Action('setCurrentTabByName')
  setCurrentTabByNameAction!: (name: TContentAuditTabName) => Promise<void>;

  @ContentAuditModule.Getter('tabs')
  tabs!: IContentAuditModuleTabs[];

  @ContentAuditModule.Getter('currentTabName')
  currentTabName!: TContentAuditTabName;

  @ContentAuditModule.State('currentTabIndex')
  currentTabIndex!: number;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  rejectableTabs: TContentAuditTabName[] = ['wait-audit', 'dangerless', 'dangerous'];

  onTabChange(name: TContentAuditTabName) {
    if (name !== this.currentTabName) {
      this.setCurrentTabByNameAction(name);
    }
  }

  iWantToGetTask() {
    showGetAuditTaskModal('RISK');
  }

  rejectInterviewVideo() {
    showRejectAuditModal(this.currentAuditInterview);
  }
}
