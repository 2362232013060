
















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { interviewStatus, questionStyleMap, libraryTypeMap, levelMap } from '../contants';

@Component
export default class List extends Vue {
  searchValue = '';
  interviewStatus = interviewStatus;
  questionStyleMap = questionStyleMap;
  libraryTypeMap = libraryTypeMap;
  levelMap = levelMap;

  @Prop({ default: [] }) tableData: any;
  @Prop({ default: [] }) columns: any;
  @Prop({ default: '100%' }) operateWidth!: string;
  @Prop({ default: 'right' }) fixedRight!: string | boolean;
  @Prop({ default: 'job-list' }) activeName!: string;
  @Prop({ default: 1 }) pageNo!: number;
  @Prop({ default: 10 }) pageSize!: number;
  @Prop({ default: 0 }) totalCount!: number;

  @Prop({ default: false }) isShowDelete!: boolean;
  @Prop({ default: false }) isShowLookDetail!: boolean;
  @Prop({ default: false }) isShowEdit!: boolean;
  @Prop({ default: false }) isShowResumeClue!: boolean;
  @Prop({ default: false }) isShowImportResume!: boolean;

  @Emit('look')
  handleLookClick(row) {
    return row;
  }

  // 编辑
  @Emit('edit')
  handleEdit(row) {
    return row;
  }

  // 删除
  @Emit('delete')
  handleDelete(row) {
    return row;
  }

  // 导入简历
  @Emit('exportCv')
  handleExportCv(row) {
    return row;
  }

  // 使用匹配线索
  @Emit('clue')
  handlerClue(row) {
    return row;
  }

  @Emit('sizeChange')
  handleSizeChange(pageSize: number) {
    return pageSize;
  }

  @Emit('currentChange')
  handleCurrentChange(value: number) {
    return value;
  }

  @Emit('cumulativeDeliveries')
  handlerCumulativeDeliveries(row) {
    return row;
  }

  @Emit('recruitmentChannel')
  handlerChannel(row) {
    return row.jobId;
  }

  @Emit('fee')
  handlerSetFee(row) {
    return row;
  }
}
