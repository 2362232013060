











































































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { IEdus } from '@/common/apis/resume/type';
import { getSchoolSuggest, postEditCv } from '@/common/apis/resume/resume';
import { ElForm } from 'element-ui/types/form';

const eduoptions = [
  {
    label: '不限',
    value: '不限',
  },
  {
    label: '初中及以下',
    value: '初中及以下',
  },
  {
    label: '高中',
    value: '高中',
  },
  {
    label: '中专/中技',
    value: '中专/中技',
  },
  {
    label: '大专',
    value: '大专',
  },
  {
    label: '本科',
    value: '本科',
  },
  {
    label: '硕士',
    value: '硕士',
  },
  {
    label: '博士',
    value: '博士',
  },
];

@Component({
  components: {
    Resume,
  },
})
export default class EducationalExperience extends Vue {
  showDrawer = false
  previewResumeUrl = ''
  eduoptions = eduoptions
  resumeId = 0
  schoolLoading = false

  edus: Partial<IEdus>[] = [{
    start: '',
    end: '',
    education: '',
    school: '',
    speciality: '',
    summary: '',
  }]
  schoolOptions: Record<string, string>[] = []

  $refs!:{
    form: ElForm[]
  }

  rules = {
    // start: { required: true, message: '在校起始时间不能为空', trigger: 'blur' },
    // end: { required: true, message: '在校结束时间不能为空', trigger: 'blur' },
    // education: { required: true, message: '学历不能为空', trigger: 'blur' },
    // school: { required: true, message: '学历不能为空', trigger: 'blur' },
    // speciality: { required: true, message: '专业不能为空', trigger: 'blur' },
  }

  open(previewResumeUrl: string, data: IEdus[], resumeId:number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.resumeId = resumeId;
    this.edus = data.map(el => ({
      ...el,
      end: el.end === '至今' ? '' : el.end,
    }));
  }
  async remoteMethod(value: string) {
    this.schoolLoading = true;
    try {
      const { data } = await getSchoolSuggest({
        name: value,
      });
      this.schoolOptions = data.map(el => ({
        label: el.chName,
        value: el.chName,
      }));
    } catch (error) {
      console.error('🙅  学校联想搜索', error);
    } finally {
      this.schoolLoading = false;
    }
  }
  async handlerSave() {
    let valid = true;
    for (const item of this.$refs.form) {
      // eslint-disable-next-line no-loop-func
      item.validate(async value => {
        if (!value) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = false;
        }
      });
    }
    if (valid) {
      try {
        const { success } = await postEditCv({
          resumeId: this.resumeId,
          form: {
            edus: this.edus.map(item => ({
              eduId: item.eduId,
              education: item.education || '',
              end: item.end || '',
              school: item.school || '',
              speciality: item.speciality || '',
              start: item.start || '',
              summary: item.summary || '',
            })),
          },
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '提交成功!',
          });
          this.showDrawer = false;
          this.$emit('submit');
        }
      } catch (error) {
        console.error('🙅 保存教育经历', error);
      }
    }
  }
  remove(index: number) {
    this.edus.splice(index, 1);
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  // 添加项目经历
  handlerEduProject() {
    this.edus.push({
      start: '',
      end: '',
      education: '',
      school: '',
      speciality: '',
      summary: '',
    });
  }
}
