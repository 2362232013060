



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAuditList } from '@/model/audit';
import List from './list.vue';

@Component({
  components: {
    List,
  },
})
export default class EnterpriseAccountAudit extends Vue {
  @Prop(String)
  activeTab!: string;

  @Prop(Array)
  dataList!: IAuditList[];

  handleView(id) {
    this.$router.push({
      path: `/enterprise-management/account-audit/detail/${id}`,
      query: {
        activeTab: this.activeTab,
      },
    });
  }
}
