import { ajax } from '@w-admin/common';
import {
  TSaveJobFollowerParams,
  TFollower,
  IQuery,
  IClueListResponse,
  IClueParams,
  ICuleJob,
  IConfirmTheJob,
  IJobListFilter,
  IRecruitmentJobList,
  IRecruitmentChannelParams,
  ISaveRecruitmentChannel,
  ICandidates,
  IRecruitmentJobA,
} from './type';

// 保存职位跟进人
export async function saveJobFollower(params: TSaveJobFollowerParams) {
  return await ajax.post('/admin/v1/job-follower/save', params);
}

// 获取职位跟进人列表
export async function getJobFollower(params: Partial<IQuery>) {
  return await ajax.get<{
    list: TFollower[];
    totalCount: number;
  }>('/admin/v1/job-follower/list', params);
}

// 删除职位跟进人
export async function deleteJobFollower(params: { id: number }) {
  return await ajax.post('/admin/v1/rpo-channel/delete', params);
}

// 职位跟进人配置
// export async function getFollowerConfig() {
//   return await ajax.get<{
//     accounts: { account: string }[];
//     followers: {
//       followerId: number;
//       followerName: string;
//     }[];
//   }>('/admin/v1/job-follower/config');
// }

// 导入公司线索
export async function importClue(params: { mediaId: number }) {
  return await ajax.post('/admin/v1/company-clue/import', params);
}

// 线索列表
export async function getClueList(params: Partial<IClueParams>) {
  return await ajax.get<IClueListResponse>('/admin/v1/company-clue/list', params);
}

// 保存公司线索
export async function saveClue(params: {
  companyName: string;
  contactsEmail: string;
  contactsMobile: string;
  contactsName: string;
  id: number | null;
  followerName: string;
}) {
  return await ajax.post('/admin/v1/company-clue/save', params);
}

// 更新职位
export async function updateJob(params: { id: number }) {
  return await ajax.post('/admin/v1/company-clue/sync-jobs', params);
}

// 发送代招邮箱
export async function sendRecruitmentEmail(params: { id: number }) {
  return await ajax.post('/admin/v1/company-clue/send-rpo-email', params);
}

// 获取代招职位列表
export async function getRecruitmentJobList(params: { sign: string; companyName: string; contactsMobile: string; batch: number }) {
  return await ajax.get<IRecruitmentJobA[]>('/wt/v1/rpo-job/customer-jobs', params);
}

// 获取 B 端线索职位列表 
export async function getClueJobList(params: { sign: string; companyName: string; contactsMobile: string }) {
  return await ajax.get<ICuleJob[]>('/wt/v1/company-clue/synced-jobs', params);
}

// 获取验证码
export async function getAuditCode(params: { mobile: string | null }) {
  return await ajax.post('/qj/v1/auth/token/send', params);
}

// 确认领取代招职位
export async function confirmTheJob(params: IConfirmTheJob) {
  return await ajax.post('/wt/v1/company-clue/confirm-rpo-jobs', params);
}

// A 端代招职位列表(管理)
export async function getRecruitmentJobListA(params: Partial<IJobListFilter>) {
  return await ajax.get<IRecruitmentJobList>('/admin/v1/rpo-job/list', params);
}

// 获取代招渠道列表
export async function getRecruitmentChannelList(params: Partial<ISaveRecruitmentChannel>) {
  return await ajax.get<IRecruitmentChannelParams>('/admin/v1/rpo-channel/list', params);
}

// 保存代招渠道列表
export async function saveRecruitmentChannelList(params: ISaveRecruitmentChannel) {
  return await ajax.post('/admin/v1/rpo-channel/save', params);
}

// 代招渠道绑定职位
export async function bindJob(params: { jobId: number; channel: string; account: string; jobTitle: string }) {
  return await ajax.post('/admin/v1/rpo-channel/bind-job', params);
}

//  获取代招渠道配置（经理） 
export async function getRecruitmentChannelConfig() {
  return await ajax.get<{
    followers: {
      followerId: number;
      followerName: string;
    }[]
  }>('/admin/v1/rpo-channel/config');
}

// 从代招渠道解绑职位
export async function deleteUnbindJob(params: { jobId: number; channel: string; account: string; jobTitle: string }) {
  return await ajax.post('/admin/v1/rpo-channel/unbind-job', params);
}

// 删除代招渠道
export async function deleteRecruitmentChannel(params: { id: number }) {
  return await ajax.post('/admin/v1/rpo-channel/delete', params);
}

// A 端客户代招职位列表 
export async function getCustomerRecruitmentJobList(params: { companyName: string; contactsMobile: string }) {
  return await ajax.get<IRecruitmentJobA[]>('/admin/v1/rpo-job/customer-jobs', params);
}

// A 端客户代招职位候选人列表 
export async function getRecruitmentCandidatesList(params: { jobId: number }) {
  return await ajax.get<ICandidates>('/admin/v1/rpo-job/candidates', params);
}
// A 端设置价格
export async function setJobFree(params: { jobId: number; fee: number }) {
  return await ajax.post('/admin/v1/rpo-job/set-fee', params);
}

// 保存代招职位候选人
export async function saveCandidates(params: {
  jobId: number;
  candidates: {
    leadsId: number;
    cvId: number;
    candidateType: number;
  }[];
}) {
  return await ajax.post('/admin/v1/rpo-job/save-candidates', params);
}

// 简历同步客户
export async function resumeSyncClient(params: { companyName: string; contactsMobile: string }) {
  return await ajax.post('/admin/v1/rpo-job/sync-candidates-to-customer', params);
}

// B 端获取客户候选人
export async function getCandidatesList(params: { sign: string; companyName: string; contactsMobile: string; batch: number; jobId: number }) {
  return await ajax.get<ICandidates>('/wt/v1/rpo-job/candidates', params);
}

// 免费解锁简历 
export async function freeUnlockResume(params: {
  sign: string;
  companyName: string;
  contactsMobile: string;
  batch: number;
  jobId: number;
  vCode: string
}) {
  return await ajax.post('/wt/v1/rpo-job/free-unlock', params);
}

// 付费解锁简历
export async function payToUnlockResume(params: {
  sign: string;
  companyName: string;
  contactsMobile: string;
  batch: number;
  jobId: number
  vCode: string;
}) {
  return await ajax.post('/wt/v1/rpo-job/pay-unlock', params);
}