

















































/* eslint-disable no-unused-vars */
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IInterviewer, IPagination } from '@w-admin/common/src/api/types';

@Component
export default class AddInterviewerForm extends Vue {
  @Prop({ required: true }) value!: number[];
  @Prop({ required: true }) jobId!: number;
  @Prop({ type: Boolean }) loading?: boolean;
  @Prop({ required: true }) listData!: IPagination<IInterviewer>;

  get bids() {
    return this.value;
  }

  set bids(bids: number[]) {
    this.$emit('input', bids);
  }
}
