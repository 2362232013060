import './index.less';
import { parseDom } from '../utils';
import locationImg from './assets/location-point.svg';

/**
 * 进度条打点自定义展示
 */
export default class ProgressMarkerComponent {
  html: HTMLElement;

  /**
   * @constructor 倍速播放组件构造函数
   */
  constructor() {
    const html = `<div class='progress-marker-container'>
      <img class='marker-img' src="${locationImg}"></img>
      <div class='marker-text'></div>
    </div>`;
    this.html = parseDom(html) as HTMLElement;
  }

  createEl(el: HTMLElement) {
    el.appendChild(this.html);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markerDotOver(player: any, data: { progressMarker: any; left: number; }) {
    const progressMarker = data.progressMarker;
    const img = this.html.querySelector('.marker-img') as HTMLImageElement | null;
    if (img && progressMarker.imgUrl) {
      img.setAttribute('src', progressMarker.imgUrl);
    } else if (img) {
      !img.classList.contains('marker-img-icon') && img.classList.add('marker-img-icon');
      !this.html.classList.contains('progress-marker-container-noImg') && this.html.classList.add('progress-marker-container-noImg');
    }

    if (progressMarker.text) {
      const text = this.html.querySelector('.marker-text') as HTMLElement | null;
      text && (text.innerText = progressMarker.text);
    }

    this.html.style.left = data.left * 100 + '%';
    this.html.style.display = 'flex';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markerDotOut(player: any, data: any) {
    this.html.style.display = 'none';
  }
}
