








































/* eslint-disable no-unused-vars */
import { AjaxResponse } from '@/common/types';
import { IUploadFileOptions, IUploadObjRes } from '@w-admin/common/src/components/common-upload';
import { IEnterprise } from '@/model/enterprise';
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { addEnterpriseImg } from '@/common/service';
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component
export default class EnterpriseInformation extends Vue {

  licenseUploading = false;

  get currentEnterpriseId() {
    return this.$route.params.id;
  }

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseInfoModule.Action('setCurrentEnterprise')
  setCurrentEnterprise!: (enterpriseId: number) => Promise<AjaxResponse<IEnterprise>>;

  previewImage(url: string, e: Event) {
    e.stopImmediatePropagation();
    this.$alert(`
      <div style="
      width: 800px;
      height: 600px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center center;
      background-image: url(${url});
      margin: 0 auto;
      "></div>`,
    '营业执照预览',
    {
      confirmButtonText: '关闭预览',
      center: true,
      dangerouslyUseHTMLString: true,
      customClass: 'license-preview-dialog',
    }).catch(() => {});
  }

  get uploadOptions(): IUploadFileOptions<IUploadObjRes> {
    return {
      accept: this.$QJUploader.imgTypes.join(','),
      limit: 1,
      multiple: false,
      onlyImage: true,
      showFileList: false,
      singleFileSizeLimit: 20 * 1024 ** 2,
      beforeUpload: () => {
        this.licenseUploading = true;
        return true;
      },
      onSuccess: async res => {
        try {
          /**
         * 添加 营业执照
         */
          await addEnterpriseImg(this.currentEnterprise.id, 'LICENSE', String(res.data.mediaId));
          /**
         * 刷新当前企业信息
         */
          const { success } = await this.setCurrentEnterprise(Number(this.currentEnterpriseId));
          success && this.$notify.success('企业营业执照上传成功');
          this.licenseUploading = false;
        } catch (error) {
          this.licenseUploading = false;
        }
      },
      onError: () => {
        this.licenseUploading = false;
      },
    };
  }
}
