
























import { Component, Vue } from 'vue-property-decorator';
import { addWhiteList } from '@/common/service';

@Component
export default class WhiteList extends Vue {
  mobile = ''

  async submit() {
    if (!this.mobile.trim() || !/^\d{11}$/.test(this.mobile)) {
      this.$notify.warning('请输入合法的手机号码');
      return;
    }
    const { success } = await addWhiteList(this.mobile);
    if (success) {
      this.$notify.success('添加成功');
      this.mobile = '';
    }
  }
}
