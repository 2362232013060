import { ajax } from '@w-admin/common';
import type { IListRequest, IListResponse, IQuestionListRequest, IAddress } from './type';

export async function getList(params: IListRequest) {
  return await ajax.get<IListResponse>('/admin/v1/op/platform/list', params);
}

// 过滤筛选
export async function getFilterList(params: { type: string; companyIds: string }) {
  return await ajax.get<{ id: number; name: string }[]>('/admin/v1/op/platform/filter', params);
}

// 获取试题列表
export async function getQuestionList(params: IQuestionListRequest) {
  return await ajax.get<IListResponse>('/admin/v1/question/questions', params);
}

// 获取省市列表 
export async function getAddresses() {
  return await ajax.get<{addresses: IAddress[]}>('/zm/v1/job/used-dicts');
}
