import './index.less';
import { parseDom } from '../utils';

/**
 * 倍速播放组件
 */
export default class RateComponent {
  html: HTMLElement
  rate = '1.0'
  rates = ['2.0', '1.5', '1.2', '1.0', '0.5']
  // eslint-disable-next-line no-unused-vars
  onRateChange = (arg0?: string) => {}

  /**
   * @constructor 倍速播放组件构造函数
   */
  constructor(params: { rate: string, rates: string[], onRateChange: () => void }) {
    if (params) {
      this.rate = params.rate || this.rate;
      this.rates = params.rates || this.rates;
      this.onRateChange = params.onRateChange || this.onRateChange;
    }
    const ratesContentStr
      = this.rates.map(
        rate => `<li data-rate="${rate}" ${rate === this.rate ? 'class="current"' : ''}>${rate}x</li>`,
      ).join('\n');
    const rateHtml = `<div class="rate-components">
      <div class="current-rate">${this.rate}x</div>
      <ul class="rate-list">
        ${ratesContentStr}
      </ul>
    </div>`;
    this.html = parseDom(rateHtml) as HTMLElement;
  }

  createEl(el: HTMLElement) {
    const eleControlbar = el.querySelector('.prism-controlbar');
    eleControlbar!.appendChild(this.html);
  }

  ready(player: any) {
    if (Number(this.rate) !== 1) {
      player.setSpeed(this.rate);
    }

    const currentRateEle = this.html.querySelector('.current-rate')! as HTMLElement;
    const rateListEle = this.html.querySelector('.rate-list')! as HTMLElement;
    let timeId: number | null = null;

    // 隐藏设置里面的倍速播放
    const settingRate = document.querySelector('.prism-setting-item.prism-setting-speed');
    if (settingRate) {
      settingRate.classList.add('player-hidden');
    }

    currentRateEle.onclick = () => {
      rateListEle.style.display = 'block';
    };
    currentRateEle.onmouseleave = () => {
      timeId = window.setTimeout(() => {
        rateListEle.style.display = 'none';
      }, 100);
    };

    rateListEle.onmouseenter = () => {
      clearTimeout(timeId as number);
    };
    rateListEle.onmouseleave = () => {
      rateListEle.style.display = 'none';
    };

    rateListEle.onclick = ({ target }) => {
      const rate = (target as HTMLElement)?.dataset?.rate;
      if (rate) {
        this.rate = rate;
        this.onRateChange(this.rate);
        player.setSpeed(rate);
        if ((target as HTMLElement).className !== 'current') {
          const currentEle = rateListEle.querySelector('.current');
          if (currentEle) {
            currentEle.className = '';
          }
          (target as HTMLElement).className = 'current';
        }
        rateListEle.style.display = 'none';
        currentRateEle.innerText = rate + 'x';
      }
    };
  }
}
