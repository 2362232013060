









































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VideoItem extends Vue {
  @Prop({ type: Boolean }) checked?: boolean;
  @Prop({ type: String, required: true }) coverUrl!: string;
  @Prop({ type: String, required: true }) duration!: string;
  @Prop({ type: Boolean }) read?: boolean;
  @Prop({ type: Number }) videoStatus?: number
  @Prop({ type: Boolean, default: false }) possibleNoVoice!: boolean;

  get videoIsNotReady() {
    return this.videoStatus !== this.$QJDict.videoStatus.OK.value;
  }
}
