import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Notification } from 'element-ui';
import { nanoid } from 'nanoid';
import { AjaxResponse } from './types';
import {
  fromShare,
  getInfoFromAllStorage,
  getPlatformAndSite,
  removeAllStorageBykey,
  removeEmptyField,
} from '../utils/global';
import { globalConfig } from '../config';
import {
  isDing,
  getDingLoginResponseIntercepter,
} from '@w-admin/b-site/src/common/ding/axios-intercepter';

export interface CustomAxiosRequestConfig extends AxiosRequestConfig, Record<string, any> {
  customErrorMsg?: string;
  unShowErrorMsg?: boolean;
}

const LOGIN_STATUS_ERROR_CODE = [4002, 4006];
const DEFAULT_ERROR_MESSAGE = '请求出错了，请稍后再试';
const CANCEL_ERROR_MESSAGE = '请求被取消了';
const LOGIN_STATUS_EXCEPTION_ERROR_MESSAGE = '登录后即可查看相关信息，请在首页进行登录';

const CancelToken = axios.CancelToken;
const cancelTask: Array<any> = [];

const config: AxiosRequestConfig = {
  // 最长 30 秒
  timeout: 30 * 1e3,
  baseURL: process.env.VUE_APP_BASE_URL || globalConfig.current.baseUrl,
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

function shareInvalid() {
  Notification.error('分享链接失效，请联系分享人获取新的分享链接');
  (top || window).postMessage({ type: 'invalid' }, '*');
}

export function canceAllTasks() {
  cancelTask.forEach(e => e());
}

function rejectHandle(errData: any) {
  return Promise.reject(errData);
}

function loginResponseIntercepter<T>(res: AxiosResponse<AjaxResponse<T>>) {
  if (LOGIN_STATUS_ERROR_CODE.includes(res.data.code)) {
    if (fromShare()) {
      shareInvalid();
    } else {
      Notification.error('未登录或登录过期, 请重新登录');
    }
    removeAllStorageBykey(globalConfig.current.tokenKey);
    removeAllStorageBykey(globalConfig.current.tempTokenKey);
    removeAllStorageBykey(globalConfig.current.userKey);
    globalConfig.current.authFailCallBack();
    canceAllTasks();
    return rejectHandle({
      message: LOGIN_STATUS_EXCEPTION_ERROR_MESSAGE,
      config: res.config,
      code: String(res.data.code),
      response: res,
      isKnownError: true,
    });
  }

  if (res.data.code === 5006 && fromShare()) {
    shareInvalid();
    removeAllStorageBykey(globalConfig.current.tokenKey);
    removeAllStorageBykey(globalConfig.current.userKey);
  }

  return res;
}

function getAxiosInstance<T>(configParams: CustomAxiosRequestConfig): AxiosInstance {
  const { unShowErrorMsg = false, customErrorMsg } = configParams;

  const instance: AxiosInstance = axios.create({
    ...configParams,
    ...config,
    cancelToken: new CancelToken(cancel => {
      cancelTask.push(cancel);
    }),
  });

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const runAsInfo = globalConfig.current.runAsInfo;
    const token = getInfoFromAllStorage<string>(globalConfig.current.tokenKey);
    const isBServer = location.hostname.includes('b.reta-inc.com');
    const runAs = isBServer && runAsInfo?.targetUid ? { 'run-as': runAsInfo.targetUid } : {};
    const tokenKey = fromShare() ? 's-token' : 'token';

    config.headers = removeEmptyField({
      ...config.headers,
      ...(token ? { [tokenKey]: token } : {}),
      ...runAs,
      platform: getPlatformAndSite(),
      'trace-id': nanoid(),
    });
    return config;
  });

  // login intercepter
  if (isDing) {
    instance.interceptors.response.use(getDingLoginResponseIntercepter(canceAllTasks));
  } else {
    instance.interceptors.response.use(loginResponseIntercepter);
  }

  instance.interceptors.response.use(
    (res: AxiosResponse<AjaxResponse<T>>) => {
      // res.data.success 不为 true 统一为 后端请求错误
      if (!res.data.success) {
        let msg = res.data.message;
        if (res.data.message === '系统异常') {
          msg = '网络异常';
        }
        const errorTitle = customErrorMsg || msg || '啊哦，网络开了小差 (つ﹏<。) ～';
        const errorMsg
          = res?.data?.errors?.reduce(
            (t, cv) => `
          ${t} <span style="color: red;">${cv.message}<span><br/>
        `,
            '',
          ) || '';
        !unShowErrorMsg
          && Notification.error({
            title: errorTitle,
            message: errorMsg,
            dangerouslyUseHTMLString: true,
            customClass: 'qj-global-notification',
          });
        return rejectHandle({
          message: res.data.message,
          config: res.config,
          code: String(res.data.code),
          response: res,
        } as AxiosError);
      }

      return res;
    },
    error => {
      if (String(error?.response?.status).startsWith('5')) {
        // 只要以 5 开头的状态码，都视为 服务器异常
        Notification.error('啊哦，服务器出差去了 (つ﹏<。) ～');
      }

      // 主动cancel
      if (axios.isCancel(error)) {
        return rejectHandle(CANCEL_ERROR_MESSAGE);
      }

      if (!error.isKnownError) {
        Notification.error('啊哦，网络开了小差 (つ﹏<。) ～');
      }
      return rejectHandle(error || DEFAULT_ERROR_MESSAGE);
    },
  );
  return instance;
}

export function getWithAuthRequest(configParams?: AxiosRequestConfig): AxiosInstance {
  const instance: AxiosInstance = axios.create({
    ...configParams,
    ...config,
  });
  instance.interceptors.request.use(config => {
    const runAsInfo = globalConfig.current.runAsInfo;
    const token = getInfoFromAllStorage<string>(globalConfig.current.tokenKey);
    const isBServer = location.hostname.includes('b.reta-inc.com');
    const runAs = isBServer && runAsInfo?.targetUid ? { 'run-as': runAsInfo.targetUid } : {};
    const tokenKey = fromShare() ? 's-token' : 'token';

    config.headers = {
      ...config.headers,
      ...(token ? { [tokenKey]: token } : {}),
      ...runAs,
      platform: getPlatformAndSite(),
      'trace-id': nanoid(),
    };
    return config;
  });
  return instance;
}

export default {
  // eslint-disable-next-line space-before-function-paren
  post: function <T>(
    url: string,
    data: object | string = {},
    config: CustomAxiosRequestConfig = {},
  ): Promise<AjaxResponse<T>> {
    const instance: AxiosInstance = getAxiosInstance<T>(config);

    return new Promise((resolve, reject) => {
      instance
        .request<AjaxResponse<T>>({
          ...config,
          method: 'POST',
          url,
          data,
        })
        .then((data: AxiosResponse<AjaxResponse<T>>) => {
          resolve(data.data);
        }, reject);
    });
  },
  // eslint-disable-next-line space-before-function-paren
  get: function <T>(
    url: string,
    params: object | string = {},
    config: CustomAxiosRequestConfig = {},
  ): Promise<AjaxResponse<T>> {
    const instance: AxiosInstance = getAxiosInstance<T>(config);

    return new Promise((resolve, reject) => {
      instance
        .request<AjaxResponse<T>>({
          ...config,
          method: 'GET',
          url,
          params,
        })
        .then((data: AxiosResponse<AjaxResponse<T>>) => {
          resolve(data.data);
        }, reject);
    });
  },
};

export * from 'axios';
