





















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class IsOpenRoomDialog extends Vue {
  dialogVisible = false

  @Prop({ default: '是否直接开启面试间？' }) title!:string;
  @Prop({ default: '极速开启面试间' }) btnText!:string;
  @Prop() list!:{
    content: string,
    id: number
  }[];

  open() {
    this.dialogVisible = true;
  }
  handleClose() {
    this.dialogVisible = false;
  }
  @Emit('confirm')
  handlerOpenRoom() {
    this.dialogVisible = false;
  }
}
