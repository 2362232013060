















































































/* eslint-disable no-unused-vars */
import { Vue, Component, Prop } from 'vue-property-decorator';
import menus from '@/pages/home/router/menus';
import { namespace } from 'vuex-class';
import { RouteConfig } from 'vue-router/types/router';

const LayoutStore = namespace('layout');

@Component
export default class AsideBar extends Vue {
  @Prop({ type: Boolean, default: false }) collapse!: boolean;

  menus = menus.filter(e => !e.meta?.hide);

  activeMenuPath = '/enterprise-management';

  @LayoutStore.State('openedsMenus')
  openedsMenus!: string[];

  @LayoutStore.State('asideIsCollapse')
  isCollapse!: boolean;

  @LayoutStore.Action('setAsideCollapse')
  setAsideCollapse!: (collapse: boolean) => Promise<void>;

  @LayoutStore.Action('setOpenedMenus')
  setOpenedMenus!: (option: { type: 'add' | 'remove'; value: string }) => Promise<void>;

  filterHideMenus() {
    const { menus } = this;
    for (const item of menus as RouteConfig[]) {
      if (Array.isArray(item.children) && item.children.length > 0 ) {
        item.children = item.children.filter(el => !el?.meta?.hide);
      }
    }
  }

  getActiveMenuFromPath() {
    this.activeMenuPath = this.$route.path;
  }

  toggleCollapse() {
    this.setAsideCollapse(!this.isCollapse);
  }

  handleOpen(key: string) {
    this.setOpenedMenus({ type: 'add', value: key });
  }

  handleClose(key: string) {
    this.setOpenedMenus({ type: 'remove', value: key });
  }

  init() {
    this.getActiveMenuFromPath();
    this.filterHideMenus();
  }

  created() {
    this.init();
  }
}
