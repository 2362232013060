

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ReviewList extends Vue {
  dialogVisible = false
  url = ''
  index = 0

  @Prop() images!:string;

  open(url: string, index: number) {
    this.dialogVisible = true;
    this.url = url;
    this.index = index;
  }

  handlerPre() {
    if (this.index === 0) {
      alert('已经是第一张了～');
      return;
    }
    this.index--;
    this.url = this.images[this.index];
  }

  handlerNext() {
    if (this.index === this.images.length - 1) {
      alert('已经是最后第一张了～');
      return;
    }
    this.index++;
    this.url = this.images[this.index];
  }

  handlerViewImage(img: string ) {
    const image = new Image();
    image.src = img;
    const imgWindow = window.open(img)!;
    imgWindow.document.write(image.outerHTML);
  }
}
