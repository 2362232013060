<template>
  <select-back-base
    btnText="选择"
    :labelValue="labelValue"
    :placeholder="placeholder"
    :dialogTitle="mergeConfig.dialogTitle"
    :disabled="disabled"
    :readonly="readonly"
    :hidden="hidden"
    :remove="mergeConfig.remove"
    :multiple="mergeConfig.multiple"
    :submit="submit"
    @onRemove="onRemove"
    @onShow="onShow"
  >
    <template v-if="$scopedSlots.reference" v-slot:reference="{ showDialog }">
      <slot name="reference" :showDialog="showDialog"></slot>
    </template>

    <el-row
      :gutter="24"
      type="flex"
      align="center"
      class="header"
    >
      <el-col class="label">
        <span>类别类型</span>
      </el-col>
      <el-col class="flex-auto">
        <el-select
          v-model="innerTypeCode"
          placeholder="请选择标签类型"
          @change="getTagList"
          style="width: 100%"
          :disabled="!!mergeConfig.typeCode"
        >
          <el-option
            v-for="item in innerTypeCodeList"
            :key="item.code"
            :label="item.title"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col class="add-tag" v-if="mergeConfig.addable && mergeConfig.addTypeCode">
        <el-tooltip content="添加自定义标签">
          <el-button @click="addTag" icon="el-icon-plus"></el-button>
        </el-tooltip>
      </el-col>
      <el-col class="refresh">
        <el-tooltip content="刷新数据">
          <i @click="refreshData" :class="loading ? 'el-icon-loading' : 'el-icon-refresh'"></i>
        </el-tooltip>
      </el-col>
    </el-row>
    <div v-loading="loading" element-loading-text="稍等一下，数据马上加载完成哦ヽ(✿ﾟ▽ﾟ)ノ..." class="transfer">
      <el-transfer
        filterable
        :filter-method="filterMethod"
        :titles="['待选标签', '已选标签']"
        :button-texts="['到左边', '到右边']"
        :props="defaultProps"
        filter-placeholder="请输入标签名称"
        v-model="modelVal"
        @change="onChange"
        :data="listData"
      >
      </el-transfer>
      <div class="all-res">
        <span>所选全部标签:</span>
        <el-tag v-for="tag in labelValue" :key="tag">
          {{ tag }}
        </el-tag>
      </div>
    </div>
  </select-back-base>
</template>

<script>
import ncformCommon from '@ncform/ncform-common';
import { Component, Mixins } from 'vue-property-decorator';
import SelectBackBase from './select-back-base.vue';
import { getTagList, addNewTag } from '../../api/service';

const localBaseKey = '_category_tag_';

@Component({
  components: {
    SelectBackBase,
  },
})
export default class SelectBackTag extends Mixins(ncformCommon.mixins.vue.controlMixin) {
  innerTypeCode = 'JOB';

  innerTypeCodeList = this.$QJDict.tagsType;

  loading = false;

  labelValue = [];

  listData = [];

  defaultProps = {
    key: 'id',
    label: 'name',
  };

  getTagInfoFormAllCache(id) {
    const res = { id };
    if (typeof id !== 'number' || isNaN(id)) {
      return res;
    }
    for (const item in this.innerTypeCodeList) {
      const current = this.innerTypeCodeList[item];
      const currentKey = this.genLocalTreekey(current.code);
      const currentList = this.$QJStorage.getStorage(currentKey);
      if (Array.isArray(currentList) && currentList.length > 0) {
        const findRes = currentList.find(e => e.id === id);
        if (findRes && findRes.name) {
          res.name = findRes.name;
          break;
        }
      }
    }
    return res;
  }

  filterMethod(query, item) {
    return item.name.indexOf(query) > -1;
  }

  filterText = '';

  defaultConfig = {
    dialogTitle: '请选择职位标签',
    remove: false,
    multiple: true,
    addable: false,
  };

  genLocalTreekey(typeCode) {
    return `${localBaseKey}_tree_${typeCode}`;
  }

  get localTreekey() {
    return this.genLocalTreekey(this.innerTypeCode);
  }

  setLabelValue(value) {
    if (!value) {
      return;
    }
    const items = (Array.isArray(value) ? value : [value])
      .filter(e => e !== false)
      .map(e => {
        if (typeof e === 'object' && e.id && e.name) {
          return `${e.id}-${e.name}`;
        }

        const current = this.listData.find(item => item.id === e);

        if (current) {
          return `${current.id}-${current.name}`;
        }

        const tagInfo = this.getTagInfoFormAllCache(e);

        if (tagInfo && tagInfo.name) {
          return `${tagInfo.id}-${tagInfo.name}`;
        }

        return `${e}-${e}`;
      });
    this.labelValue = this.mergeConfig.multiple ? items : items[0];
  }

  onChange() {
    this.setLabelValue(this.modelVal);
  }

  onRemove(text) {
    const [id] = text.split('-');
    const index = this.modelVal.findIndex(e => Number(e) === Number(id));
    if (index < 0) {
      return;
    }
    this.modelVal.splice(index, 1);
    this.setLabelValue(this.modelVal);
  }

  submit() {
    this.setLabelValue(this.modelVal);

    if (!this.mergeConfig.multiple && Array.isArray(this.modelVal) && this.modelVal.length > 1) {
      this.$notify.warning('只能选择一个哦 (っ´▽`)っ');
      return false;
    }

    if (typeof this.mergeConfig.maxlength === 'number' && Array.isArray(this.modelVal) && this.modelVal.length > this.mergeConfig.maxlength) {
      this.$notify.warning(`最多只能选择${this.mergeConfig.maxlength}哦 (っ´▽\`)っ`);
      return false;
    }

    return true;
  }

  addTag() {
    const addTypeCode = this.mergeConfig.addTypeCode === '*' ? this.innerTypeCode : this.mergeConfig.addTypeCode;
    this.$prompt(`标签类型: <b>${this.$QJDict.tagsType[addTypeCode].title}</b>`, '添加自定义标签', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /[\w\W]+/,
      inputErrorMessage: '标签内容不能为空',
      inputPlaceholder: '请输入自定义标签内容',
      dangerouslyUseHTMLString: true,
    })
      .then(({ value }) => {
        const addTagOptions = {
          typeCode: addTypeCode,
          name: value,
        };
        return addNewTag(addTagOptions);
      })
      .then(rs => {
        if (rs && rs.success) {
          this.refreshData();
          this.$notify.success('自定义标签添加成功');
        } else {
          this.$notify.error({ title: '自定义标签添加失败', message: rs.message });
        }
      })
      .catch(() => {});
  }

  async getTagList(typeCode) {
    const { data } = await getTagList({ typeCode });
    this.listData = data;
    if (Array.isArray(data) && data.length > 0) {
      this.$QJStorage.setStorage(this.localTreekey, this.listData);
    }
  }

  init() {
    if (this.mergeConfig.typeCode) {
      this.innerTypeCode = this.mergeConfig.typeCode;
    }
    if (this.mergeConfig.typeCodeList && Array.isArray(this.mergeConfig.typeCodeList) && this.mergeConfig.typeCodeList.length > 0) {
      this.innerTypeCode = this.mergeConfig.typeCodeList[0];
      this.innerTypeCodeList = this.mergeConfig.typeCodeList.map(code => this.$QJDict.tagsType[code]);
    }
    this.setLabelValue(this.modelVal);
    this.modelVal = (Array.isArray(this.modelVal) ? this.modelVal : [this.modelVal]).map(e => e.id || e);
  }

  getData() {
    const localTreeData = this.$QJStorage.getStorage(this.localTreekey);
    if (localTreeData) {
      this.listData = localTreeData;
    } else {
      this.refreshData();
    }
  }

  refreshData() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    Promise.all([this.getTagList(this.innerTypeCode)])
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  mounted() {
    this.init();
  }

  onShow() {
    this.getData();
  }
}
</script>

<style lang="less" scoped>
.add-tag,
.refresh {
  display: flex;
  align-items: center;
  flex: 1;
  i {
    cursor: pointer;
  }
}

.header {
  position: sticky;
  top: -30px;
  z-index: 10;
  background: #fff;
  padding-bottom: 20px;
}

.label {
  width: max-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.flex-auto {
  flex: auto;
}

.transfer {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /deep/ .el-transfer-panel {
    min-width: 260px;
    .el-transfer-panel__filter {
      width: 90%;
    }
  }
}

.all-res {
  width: 82%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 20px;
  align-items: center;
}
</style>
