












import { Vue, Component, Prop } from 'vue-property-decorator';
import ContentAuditNavHeader from './content-audit.vue';
import VideoQualityAuditNavHeader from './video-quality-audit.vue';


@Component({
  components: {
    ContentAuditNavHeader,
    VideoQualityAuditNavHeader,
  },
})
export default class NavHeader extends Vue {
  @Prop() currentMenuTitle!: string;

  get currentRouteName() {
    return this.$route.name;
  }
}
