












































import { getAuditListCount, getContentAuditTask } from '@/common/service';
import { TAuditType } from '@/common/types';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class GetAuditTaskModal extends Vue {
  show = false;
  type: TAuditType = 'RISK';
  totalCount = 0;
  count = 5;
  loading = false;
  get title() {
    return `${this.$QJDict.auditType[this.type].title}任务池`;
  }

  async getAuditListCount() {
    try {
      this.loading = true;
      const { data } = await getAuditListCount(this.type);
      this.totalCount = data.totalCount;
      this.count = this.totalCount > 5 ? 5 : this.totalCount;
      this.loading = false;
      this.$router.replace({
        path: this.$route.path,
        hash: `${Date.now()}`,
      });
    } catch (error) {
      console.info('【获取任务池数量错误】', this.type);
      this.loading = false;
    }
  }

  onInput(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    if (Number(value) > this.totalCount) {
      this.$notify.warning(`最多只能领取${this.totalCount}个任务`);
      this.count = this.totalCount;
    }
  }

  async onSubmit() {
    try {
      this.loading = true;
      const { success, data } = await getContentAuditTask(this.type, this.count);
      if (success) {
        this.$notify.success(`成功领取${data.count}审核个任务!`);
      }
      this.loading = false;
      this.$router.replace({
        path: this.$route.path,
        hash: `${Date.now()}`,
        query: {
          status: '0',
        },
      });
      this.close();
    } catch (error) {
      console.info('【任务领取错误】', this.type, this.count);
      this.loading = false;
    }
  }

  close() {
    this.show = false;
  }

  mounted() {
    this.getAuditListCount();
  }
}
