// 职位列表
export const jobColumns = [
  {
    label: '公司',
    prop: 'companyName',
    width: 220,
  },
  {
    label: '职位',
    prop: 'title',
    width: 100,
  },
  {
    label: '职位编码',
    prop: 'code',
    width: 80,
  },
  {
    label: '地区',
    prop: 'officeLocation',
    width: 150,
  },
  {
    label: '当前状态',
    prop: 'statusName',
    width: 80,
  },
  {
    label: '面试间状态',
    prop: 'opened',
    width: 100,
  },
  {
    label: '职位在线天数',
    prop: 'onlineDays',
    width: 110,
  },
  {
    label: '累计投递人数',
    prop: 'totalCandidateCount',
    width: 110,
  },
  {
    label: '发布人',
    prop: 'name',
    width: 80,
  },
  {
    label: '发布时间',
    prop: 'publishedAt',
    width: 170,
  },
];
// 面试列表
export const interviewColumns = [
  {
    label: '面试间ID',
    prop: 'interviewId',
    width: 100,
  },
  {
    label: '面试者ID',
    prop: 'cid',
    width: 100,
  },
  {
    label: '姓名',
    prop: 'cname',
    width: 100,
  },
  {
    label: '手机号',
    prop: 'mobile',
    width: 150,
  },
  {
    label: '邮箱',
    prop: 'interviewEmail',
    width: 150,
  },
  {
    label: '邀请码',
    prop: 'invitationCode',
    width: 100,
  },
  {
    label: '面试状态',
    prop: 'status',
    width: 100,
  },
  {
    label: '职位',
    prop: 'jobTitle',
    width: 100,
  },
  {
    label: '公司',
    prop: 'companyName',
    width: 250,
  },
  {
    label: '面邀时间',
    prop: 'startAt',
    width: 150,
  },
  {
    label: '到期时间',
    prop: 'expiredAt',
    width: 150,
  },
];
// 候选人
export const candidateColumns = [
  {
    label: '用户ID',
    prop: 'id',
    width: 100,
  },
  {
    label: '姓名',
    prop: 'name',
    width: 100,
  },
  {
    label: '手机号',
    prop: 'mobile',
    width: 150,
  },
  {
    label: '邮箱',
    prop: 'email',
    width: 200,
  },
  {
    label: '性别',
    prop: 'isMale',
    width: 100,
  },
  {
    label: '年龄',
    prop: 'age',
    width: 100,
  },
  {
    label: '最后学历',
    prop: 'education',
    width: 100,
  },
  {
    label: '最后工作经历',
    prop: 'work',
    width: 150,
  },
];
// 面试题列表
export const questionsColumns = [
  { label: '试题ID', prop: 'id', width: 100 },
  { label: '试题类型', prop: 'questionStyle', width: 100 },
  { label: '题目名称', prop: 'title', width: 150 },
  { label: '试题类别', prop: 'categoriesName', width: 300 },
  { label: '题目来源', prop: 'libraryType', width: 100 },
  { label: '考查点', prop: 'tagsName', width: 150 },
  { label: '难度', prop: 'level', width: 100 },
  { label: '答题时常', prop: 'duration', width: 150 },
  { label: '创建时间', prop: 'createdAt', width: 150 },
];

export const tabTypeMap = {
  'job-list': 'JOB',
  'intervie-list': 'INTERVIEW',
  'role-list': 'CANDIDATE',
};

// 姓名/电话/面试ID/邮箱/邀请码
export const interviewSearchOptions = [
  {
    code: 'name',
    title: '姓名',
  },
  {
    code: 'mobile',
    title: '电话',
  },
  {
    code: 'uid',
    title: '面试者ID',
  },
  {
    code: 'interviewId',
    title: '面试间ID',
  },
  {
    code: 'email',
    title: '邮箱',
  },
  {
    code: 'invitationCode',
    title: '邀请码',
  },
];

export const roleSelectOptions = [
  {
    code: 'name',
    title: '姓名',
  },
  {
    code: 'mobile',
    title: '手机号',
  },
  {
    code: 'email',
    title: '邮箱',
  },
];

export const topicTypeOptions = [
  {
    label: '文本题',
    value: 4,
  },
  {
    label: '视频题',
    value: 2,
  },
  {
    label: '代码分析题',
    value: 3,
  },
  {
    label: '编码题',
    value: 8,
  },
];

// 试题类别映射
export const questionStyleMap = {
  4: '文本题',
  2: '视频题',
  3: '代码分析题',
  8: '编码题',
};

// 试题来源映射
export const libraryTypeMap = {
  1: '公司',
  0: '公共',
};

// 难度映射
export const levelMap = {
  1: '简单',
  2: '中等',
  3: '困难',
};

// 面试状态映射
export const interviewStatus = {
  WAIT_ACCEPT: '待接受',
  WAIT_ANSWER: '待面试',
  AUDITING: '审核中',
  WAIT_FEEDBACK: ' 待反馈',
  PASSED: '面试通过',
  FAILED: '面试失败',
  CANCEL: '取消',
  HOLD: '待定',
  AUDIT_FAILED: '审核失败',
};
