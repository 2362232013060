



































import { Vue, Component } from 'vue-property-decorator';
import EditQuestion from './components/edit-question.vue';
import QuestionListItem from './components/question-list-item.vue';
import { namespace } from 'vuex-class';
import Anchor from '@/components/anchor.vue';
import { IEnterprise } from '@/model/enterprise';
import { IEnterpriseQuestionCategory } from '@/model/enterprise-question';
const EnterpriseQuestionBankModule = namespace('enterprise/enterpriseQuestionBank');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component({
  components: {
    EditQuestion,
    Anchor,
    QuestionListItem,
  },
})
export default class EnterpriseQuestionBank extends Vue {
  questionTypes = this.$QJConfig.enterpriseQuestionCategoriesTypes;
  pageNo: number = 1;
  pageSize: number = 20;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseQuestionBankModule.State('enterpriseQuestionsList')
  enterpriseQuestionsList!: any;

  @EnterpriseQuestionBankModule.Action('getEnterpriseQuestionsList')
  getEnterpriseQuestionsListAction!: any;

  getQuestionsListByQuestionType(questionType: string) {
    return this.enterpriseQuestionsList[this.getQuestionsListMapping(questionType)];
  }

  get currentCompanyId() {
    return this.currentEnterprise.id;
  }

  reloadQuestionList(questionType: string) {
    const options = {
      companyId: this.currentCompanyId,
      libraryType: 1,
      questionType: questionType,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

  getQuestionsListMapping(questionType: string) {
    if (questionType === '通用能力') {
      return 'commonSkillsList';
    }
    if (questionType === '专业能力') {
      return 'professionalSkillsList';
    }
    return 'othersList';
  }

  getQuestionTypeCode(questionType: string) {
    if (questionType === '通用能力') {
      return 'common';
    }
    if (questionType === '专业能力') {
      return 'professional';
    }
    return 'other';
  }

  get getAnchorData() {
    const anchorData: { title: any; hash: string; }[] = [];
    this.questionTypes.forEach((element: any) => {
      anchorData.push({
        title: element.name,
        hash: `#${element.name}`,
      });
    });
    return anchorData;
  }

  handleSizeChange(questionType: string, val: number) {
    this.pageSize = val;
    const options = {
      companyId: this.currentCompanyId,
      libraryType: 1,
      questionType: this.getQuestionTypeCode(questionType),
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

  handleCurrentChange(questionType: IEnterpriseQuestionCategory, val: number) {
    this.pageNo = val;
    const options = {
      companyId: this.currentCompanyId,
      libraryType: 1,
      questionType: questionType.code,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

  mounted() {
    this.questionTypes.forEach(questionType => {
      const options = {
        companyId: this.currentCompanyId,
        libraryType: 1,
        questionType: questionType.code,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      this.getEnterpriseQuestionsListAction(options);
    });
  }
}
