import { render, staticRenderFns } from "./jd-details.vue?vue&type=template&id=4bf933bc&scoped=true&"
import script from "./jd-details.vue?vue&type=script&lang=ts&"
export * from "./jd-details.vue?vue&type=script&lang=ts&"
import style0 from "./jd-details.vue?vue&type=style&index=0&id=4bf933bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf933bc",
  null
  
)

export default component.exports