<template>
  <el-dialog title="请告知用户审核有风险原因：" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-input type="textarea" class="textarea" :rows="4" maxlength="20" placeholder="请输入内容" show-word-limit v-model="textarea"> </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handlerConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Vue, Component, Emit } from 'vue-property-decorator';

@Component({})
export default class ReviewFaildDialog extends Vue {
  dialogVisible = false;
  textarea = '';
  row = null;

  handleClose() {}

  open(row) {
    this.row = row;
    this.textarea = '';
    this.dialogVisible = true;
  }

  @Emit('confirm')
  handlerConfirm() {
    // if (this.textarea.trim() === '') {
    //   this.$message({
    //     type: 'warning',
    //     message: '驳回原因不能为空！',
    //   });
    //   return;
    // }
    this.dialogVisible = false;
    return {
      row: this.row,
      value: this.textarea,
    };
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog__body {
  padding: 20px;
}
.textarea {
  height: 100px;
}
</style>
