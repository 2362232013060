import {
  IContentAuditListDataWithPagination,
  IGetContentAuditListOptions,
  IGetVideoQualityAuditListOptions,
  IVideoQualityAuditListDataWithPagination,
} from '@/common/types';
import User from '@/model/user';
import { IContentAuditModuleTabs } from './interview-content-audit-module';
import { IVideoQualityAuditModuleTabs } from './video-quality-audit-module';

export const initLoginUser = (defaultUser?: User) => ({
  nickname: '',
  email: '',
  authToken: '',
  ...defaultUser,
});

export const initContentAuditTabs = (tabs?: IContentAuditModuleTabs[]): IContentAuditModuleTabs[] => [
  {
    name: 'wait-audit',
    title: '待审核',
    status: 0,
  },
  {
    name: 'dangerless',
    title: '无风险',
    status: 1,
  },
  {
    name: 'dangerous',
    title: '有风险',
    status: 3,
  },
  ...(tabs ? tabs : []),
];

export const initVideoQualityAuditTabs = (tabs?: IVideoQualityAuditModuleTabs[]): IVideoQualityAuditModuleTabs[] => [
  {
    name: 'wait-audit',
    title: '待审核',
    status: 0,
  },
  {
    name: 'completed',
    title: '已完成',
    status: 1,
  },
  {
    name: 'reject',
    title: '已驳回',
    status: 2,
  },
  ...(tabs ? tabs : []),
];

export const initCurrentContentAuditListData = (listData?: Partial<IContentAuditListDataWithPagination>): IContentAuditListDataWithPagination => ({
  pageNo: 1,
  pageSize: 20,
  totalCount: 0,
  list: [],
  filters: [],
  ...listData,
});

export const initContentAuditSearchParams = (params?: Partial<IGetContentAuditListOptions>): IGetContentAuditListOptions => ({
  status: 0,
  pageNo: 1,
  pageSize: 20,
  createdBy: '',
  ...params,
});


// eslint-disable-next-line max-len
export const initCurrentVideoQualityAuditListData = (listData?: Partial<IVideoQualityAuditListDataWithPagination>): IVideoQualityAuditListDataWithPagination => ({
  pageNo: 1,
  pageSize: 20,
  totalCount: 0,
  list: [],
  filters: [],
  ...listData,
});

export const initVideoQualityAuditSearchParams = (params?: Partial<IGetVideoQualityAuditListOptions>): IGetVideoQualityAuditListOptions => ({
  status: 0,
  pageNo: 1,
  pageSize: 20,
  marker: '',
  ...params,
});
