









































































import { Component, Vue, Watch } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import { columns } from './contants';
import EditRecruitementDialog from './dialog/edit-recruitement-dialog.vue';
import { deleteJobFollower, getRecruitmentChannelList, getRecruitmentChannelConfig } from '@/common/apis/recruitment';
import { IQuery } from '@/common/apis/recruitment/type';
import { Bind, Throttle } from 'lodash-decorators';

interface IOptions {
  label: string;
  value: string | number;
}
type ITable = {
  title: string;
  followerName: string;
  recruitmentChannel: string;
  account: string;
  email: string;
  channel: string;
  followerId: number | null | string;
  accountType: number;
};

@Component({
  components: {
    List,
    EditRecruitementDialog,
  },
})
export default class RecruitmentManagement extends Vue {
  title = '';
  query: IQuery = {
    followerId: '', // 客户经理 id
    channel: '', // 招聘渠道
    account: '', // 招聘账号
    accountType: '', // 账号属性
    jobTitle: '', // 职位标题
    email: '', // 招聘邮箱
  };
  loading = false;
  columns = columns;
  tableData: ITable[] = [];
  recruitmentChannelOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: 'BOSS直聘',
      value: 'BOSS',
    },
    {
      label: '拉钩',
      value: 'LAGOU',
    },
    {
      label: '58同城',
      value: 'C_58',
    },
    {
      label: '猎聘',
      value: 'LIEPIN',
    },
    {
      label: '24365',
      value: 'C_24365',
    },
    {
      label: '牛客',
      value: 'NIUKE',
    },
    {
      label: '前程无忧',
      value: 'JOB51',
    },
    {
      label: '智联招聘',
      value: 'ZHILIAN',
    },
    {
      label: '实习僧',
      value: 'SHIXISENG',
    },
    {
      label: '合作客户',
      value: 'PARTNER',
    },
  ];
  accountTypeOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '机器人',
      value: 0,
    },
    {
      label: '真人',
      value: 1,
    },
  ];
  hiringManagerOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
  ];
  accountoptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
  ];

  pageNo = 1;
  pageSize = 10;
  totalCount = 0;

  $refs!: {
    EditRecruitementDialog: EditRecruitementDialog;
  };

  @Throttle(2000)
  @Bind
  @Watch('query', { deep: true })
  onFollowerId() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getTableList();
  }

  created() {
    this.getTableList();
    this.gerFollowerConfig();
    this.getAccount();
  }

  handlerBack() {
    this.$router.go(-1);
  }

  onSubmit() {
    this.getTableList();
  }

  // 获取跟进人配置
  async gerFollowerConfig() {
    try {
      const { data } = await getRecruitmentChannelConfig();
      this.hiringManagerOptions.push(
        ...data.followers.map(el => ({
          label: el.followerName,
          value: el.followerId,
        })),
      );
    } catch (error) {
      console.error('🙅 获取跟进人配置', error);
    }
  }

  // 增加
  handlerAdd() {
    this.title = '新增代招岗位';
    this.$refs.EditRecruitementDialog.open({ email: 'cv_receive@reta-inc.com' });
  }

  // 编辑
  handlerEdit(row) {
    this.title = '编辑代招岗位';
    this.$refs.EditRecruitementDialog.open(row);
  }

  // 获取招聘账号
  async getAccount() {
    try {
      const { data } = await getRecruitmentChannelList({
        pageNo: 1,
        pageSize: 999,
      });
      this.accountoptions.push(
        ...Array.from(new Set(data.list.map(el => el.account))).map(el => ({
          label: el,
          value: el,
        })),
      );
    } catch (error) {
      console.error('🙅 获取账号列表', error);
    }
  }

  // 删除
  async handlerDelete(row) {
    try {
      this.$confirm('确定要删除此条数据么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { success } = await deleteJobFollower({ id: row.id });
        if (success) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getTableList();
        }
      });
    } catch (error) {
      console.error('🙅 删除跟进人', error);
    }
  }

  // 获取列表
  async getTableList() {
    this.loading = true;
    const { query, pageNo, pageSize } = this;
    const params = {
      pageNo,
      pageSize,
    };
    Object.keys(query).forEach(el => {
      if (query[el] !== '') {
        params[el] = query[el];
      }
    });
    try {
      const { data } = await getRecruitmentChannelList(params);
      this.tableData = data.list.map(el => ({
        id: el.id,
        title: el?.jobTitle,
        followerName: el?.followerName || '',
        recruitmentChannel: el?.channelName || '',
        account: el?.account || '',
        email: el?.email || '',
        followerId: el.followerId || null,
        channel: el.channel || '',
        accountType: el.accountType,
      }));
      this.totalCount = data.totalCount;
    } catch (error) {
      console.error('🙅 获取跟进人', error);
    } finally {
      this.loading = false;
    }
  }
  // 搜索
  handlerSearch() {
    this.getTableList();
  }
  handlerSizeChange(value: number) {
    this.pageSize = value;
    this.getTableList();
  }
  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getTableList();
  }
}
