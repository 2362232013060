




































































import { getAccountList, modifyContact } from '@/common/service';
import { IPagination } from '@/common/types';
import { IAccount, IAccountListData } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EnterpriseContactsListForm extends Vue {
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() contacts?: IAccount;

  contactsBid: number | null = null;

  listData: IPagination<IAccountListData> = {
    pageSize: 20,
    pageNo: 1,
    totalCount: 0,
    list: [],
  };

  async getList(pageSize: number, pageNo: number) {
    const { data } = await getAccountList({
      companyId: this.enterprise.id,
      pageSize,
      pageNo,
    });
    this.listData = data;
  }

  get pageSize() {
    return this.listData.pageSize;
  }
  get pageNo() {
    return this.listData.pageNo;
  }

  set pageSize(pageSize: number) {
    this.contactsBid = null;
    this.getList(pageSize, this.pageNo);
  }

  set pageNo(pageNo: number) {
    this.contactsBid = null;
    this.getList(this.pageSize, pageNo);
  }

  handleSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  handleCurrentChange(pageNo: number) {
    this.pageNo = pageNo;
  }

  async submit() {
    try {
      const res = await modifyContact(
        [this.contactsBid].filter(e => !!e),
        [this.contacts?.bid].filter(e => !!e),
        this.enterprise.id,
      );
      if (res.success) {
        this.$notify.success('企业联系人修改成功');
        this.$emit('onSuccessModifyContact');
        this.$emit('close');
      }
    } catch (error) {
      console.log('修改企业联系人出错', error);
    }
  }

  created() {
    this.getList(20, 1);
  }
}
