













import { IRootSchema } from '../../model/form-schema';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NcformRender extends Vue {
  @Prop({ type: Object, required: true }) formSchema!: IRootSchema;
  @Prop({ type: String, required: true }) formName!: string;
  @Prop({ type: String, default: 'e => e' }) inFunc!: string;
  @Prop({ type: String, default: 'e => e' }) outFunc!: string;
  @Prop({ required: true }) value!: any;

  get transValue() {
    if (this.$options.updatedValueFromInner) {
      return this.value;
    }
    // eslint-disable-next-line no-eval
    return eval(`(${this.inFunc})(${JSON.stringify(this.value)})`);
  }
  get globalConfig() {
    return this.formSchema.globalConfig;
  }

  handleChange(form: any) {
    const { formValue } = form;
    this.$options.updatedValueFromInner = true;
    // eslint-disable-next-line no-eval
    this.$emit('input', eval(`(${this.outFunc})(${JSON.stringify(formValue)})`));
    this.$emit('change', form);
  }

  handleSubmit() {
    this.$emit('submit', arguments);
  }

  insertStyle(styleString: string) {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = `ncform-maker-style-${this.formName}`;
    style.appendChild(document.createTextNode(styleString));
    document.querySelector('head')!.appendChild(style);
  }

  removeStyle() {
    const style = document.querySelector(`style#ncform-maker-style-${this.formName}`);
    if (style) {
      style.remove();
    }
  }

  initStyle() {
    this.removeStyle();
    const { css } = this.globalConfig;
    if (css && css.code) {
      this.insertStyle(css.code);
    }
  }

  initForm() {
    this.initStyle();
  }

  mounted() {
    this.initForm();
  }
}
