import Dict from './dict';

export const aside = {
  width: '176px',
  collapseWidth: '38px',
};

export const enterpriseQuestionCategoriesTypes = [
  { name: '通用能力', code: 'common' },
  { name: '专业能力', code: 'professional' },
  { name: '其它', code: 'other' },
];

export const enterpriseVideoParentCategoriesTypes = [
  { name: '职位类', code: '0' },
  { name: '公司类', code: '1' },
  { name: '面试官类', code: '2' },
  { name: '其它', code: '9999' },
];

export const enterpriseVideoParentCategoriesTree = [
  {
    name: '职位类',
    code: '0',
    secondCategory: [
      { name: '团队介绍', code: '0' },
      { name: '工作内容', code: '1' },
      { name: '职位类业务介绍', code: '2' },
      { name: '员工培训', code: '3' },
      { name: '其它', code: '9999' },
    ],
  },
  {
    name: '公司类',
    code: '1',
    secondCategory: [
      { name: '公司环境', code: '100' },
      { name: '公司发展', code: '101' },
      { name: '公司类业务介绍', code: '102' },
      { name: '公司其他类', code: '99999' },
    ],
  },
  {
    name: '面试官类',
    code: '2',
    secondCategory: [
      { name: '开场自我介绍', code: '1000' },
      { name: '面试结束语', code: '1001' },
      { name: '转场视频', code: '1002' },
    ],
  },
  {
    name: '其它',
    code: '9999',
    secondCategory: [
      { name: '其它', code: '' },
    ],
  },
];

export const enterpriseJobManagerVideoDisplayPart: (keyof typeof Dict.videoDisplayType)[] = [
  // 'WarmVideo',
  'JobDetail',
  'CompanyIntro',
  'BizIntro',
  'TeamIntro',
];

export const enterpriseJobManagerQuestionsPart: (keyof typeof Dict.jobExamType)[] = [
  'InterviewerIntro',
  'QuestionSet',
  'Conclusion',
];

export const eventName = {
  MARKED_DANGEROUS_POINT: 'markedDangerousPoint',
  SEEK_VIDEO_TIME: 'seekVideoTime',
  ONVIDEOPLAYERREADY: 'onVideoPlayerReady',
  ONVIDEOPLAYING: 'onVideoPlaying',
  ONVIDEOPUASE: 'onVideoPause',
  ONVIDEOCOMPLETESEEK: 'onVideoCompleteSeek',
  ONVIDEORATECHANGE: 'onVideoRateChange',
};

export const LDKey = {
  STORAGE_READ_VIDEO_NAME: '__audit_video_read__',
};
