












































































import { saveJob } from '@/common/service';
import { transformJobToSave } from '@/common/utils';
import { IEnterprise } from '@/model/enterprise';
import { IJob, IJobExamsForSave, IJobForSave, IJobForSaveExt, ISnippetsForSave } from '@/model/job';
import { Vue, Component, Prop } from 'vue-property-decorator';
import JobFormStepBaseInfo from './job-form-step-base-info.vue';
import JobFormStepQuestion from './job-form-step-question.vue';
import JobFormStepVideoShow from './job-form-step-video-show.vue';
import previewJob from '@w-admin/common/src/components/job-preview';

@Component({
  components: {
    JobFormStepBaseInfo,
    JobFormStepVideoShow,
    JobFormStepQuestion,
  },
})
export default class JobForm extends Vue {
  @Prop() title?: string;
  @Prop() job?: IJob;
  @Prop() defaultStep?: number;
  @Prop({ required: true }) enterprise!: IEnterprise;

  ext: IJobForSaveExt = {};

  loading = false;

  innerJob: Partial<IJobForSave> | null = null

  get isEdit() {
    return !!(this.job && this.job.id);
  }

  innerActiveStep = 0

  $refs!: {
    instance: (Vue & { onBeforeSave: () => Promise<string> })[]
  }

  get steps() {
    return [
      {
        title: '基本信息',
        step: 0,
        icon: 'el-icon-edit',
        component: 'job-form-step-base-info',
      },
      {
        title: '视频展示',
        step: 1,
        icon: 'el-icon-upload',
        component: 'job-form-step-video-show',
      },
      {
        title: '职位试题',
        step: 2,
        icon: 'el-icon-picture',
        component: 'job-form-step-question',
      },
    ];
  }

  get currentSteps() {
    return this.steps.filter(step => step.step === this.innerActiveStep);
  }

  snippets: ISnippetsForSave[] = [];
  exams: IJobExamsForSave[] = []

  tempSaveJob(notiy = false) {
    this.loading = true;
    return saveJob({ ...this.innerJob, ext: this.ext, status: 'DRAFT' }).then(rs => {
      if (rs && rs.success) {
        this.$emit('reloadJob');
        notiy && this.$notify.success('职位暂存成功');
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  async nextStep() {
    if (this.innerActiveStep >= this.steps.length) {
      return;
    }
    if (this.innerActiveStep === 0) {
      const instance = this.$refs.instance?.[0];
      if (instance) {
        this.loading = true;
        const pic = await instance.onBeforeSave();
        this.ext = { jobWeiXin: { jobSharePic: pic } };
        this.loading = false;
      }
    }

    this.tempSaveJob().finally(() => {
      this.innerActiveStep += 1;
    });
  }

  prevStep() {
    if (this.innerActiveStep <= 0) {
      return;
    }
    this.innerActiveStep -= 1;
  }

  snippetsChange(snippets: ISnippetsForSave[]) {
    this.snippets = snippets;
    if (this.innerJob) {
      this.innerJob = transformJobToSave(this.job, { snippets, status: 'PUBLISHED' });
    }
  }

  examsChange(exams: IJobExamsForSave[]) {
    this.exams = exams;
    if (this.innerJob) {
      this.innerJob = transformJobToSave(this.job, { exams, status: 'PUBLISHED' });
    }
  }

  preview() {
    if (this.job && this.job.id) {
      previewJob(this.job, 'jd-details-job', this.$router);
    } else {
      this.$notify.error('😭 呜呜呜，职位不存在');
    }
  }

  pubOrEdit() {
    if (!this.innerJob) {
      return;
    }
    this.innerJob = {
      ...this.innerJob,
      status: 'PUBLISHED',
    };

    // if (this.innerJob?.exams?.some(e => !e?.interviewer?.interviewerId)) {
    //   this.$notify.warning('请选择面试官');
    //   Promise.reject('请选择面试官');
    //   return;
    // }
    this.loading = true;
    console.log('pubOrEdit', this.innerJob);
    saveJob(this.innerJob).then(rs => {
      if (rs && rs.success) {
        this.$notify.success('职位保存成功');
        this.$emit('close');
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  created() {
    if (this.defaultStep) {
      this.innerActiveStep = this.defaultStep;
    }

    if (this.job) {
      this.innerJob = transformJobToSave(this.job);
    }
  }

  hideBodyScrollBar() {
    document.body.classList.add('hide-overflow');
  }

  showBodyScrollBar() {
    document.body.classList.remove('hide-overflow');
  }

  mounted() {
    this.hideBodyScrollBar();
  }

  destroyed() {
    this.showBodyScrollBar();
  }
}
