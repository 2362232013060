























































import { IUploadFileOptions, IUploadObjRes } from '@w-admin/common/src/components/common-upload';
import { IAccount } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EnterpriseContactsListForm from './enterprise-contacts-list-form.vue';
import ESetting from './enterprise-setting/index.vue';

@Component({
  components: {
    EnterpriseContactsListForm,
    ESetting,
  },
})
export default class EnterpriseHeader extends Vue {
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() currentEnterpriseContacts?: IAccount;

  show = false;
  drawer = false;

  get uploadOptions(): IUploadFileOptions<IUploadObjRes> {
    return {
      accept: this.$QJUploader.imgTypes.join(','),
      limit: 1,
      multiple: false,
      onlyImage: true,
      listType: 'picture-card',
      singleFileSizeLimit: 2 * 1024 ** 2,
      fileList: this.enterprise.logoUrl ? [{ url: this.enterprise.logoUrl, name: `${this.enterprise.shortName}Logo` }] : [],
      onSuccess: res => {
        this.$emit('logoUploadSuccess', res.data.mediaId);
      },
    };
  }
  onSuccessModifyContact() {
    this.$emit('onSuccessModifyContact');
  }
}
