






































import { Component, Vue } from 'vue-property-decorator';
import { getTagList, saveRelation } from '@/common/service';
import { getCategoryTree } from '@/common/apis/resume/resume';
import { ICategoryNode } from '@/model/category';

interface ITag {
  id: number;
  name: string;
}

@Component({
  directives: {
    /** 下拉框懒加载 */
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        if (!SELECTWRAP_DOM) {
          throw new Error('获取不到"el-select-dropdown__wrap"节点');
        }
        SELECTWRAP_DOM.addEventListener('scroll', () => {
          const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
})
export default class EntityAssociation extends Vue {
  sequence = ''; // 职能
  sequenceSkills = []; // 职能对应的技能包
  skill = ''; // 技能包
  tags = []; // 技能包对应的考察点
  filterTagKeywords = '';
  sequenceList: ICategoryNode[] = [];
  skillList: ITag[] = [];
  tagList: ITag[] = [];

  pageData = {
    // 懒加载相关参数，这里代表从第一条数据开始加载，一次加载二十项
    pageIndex: 1,
    pageSize: 20,
  };

  get cTagList() {
    const num = ~~this.pageData.pageIndex * ~~this.pageData.pageSize;
    return this.tagList
      .filter(t => {
        return t.name.includes(this.filterTagKeywords.toLowerCase());
      })
      .filter((item, index) => {
        return index < num;
      });
  }

  async getOptions() {
    const [{ data: sequenceList }, { data: skillList }, { data: tagList }] = await Promise.all([
      getCategoryTree({ typeCode: 'JOB_SEQUENCE' }),
      getTagList('SKILL'),
      getTagList('QUESTION'),
    ]);
    this.sequenceList = sequenceList;
    this.skillList = skillList;
    this.tagList = tagList;
  }

  filterTags(val) {
    this.filterTagKeywords = val;
  }

  loadMore() {
    this.pageData.pageIndex++;
  }

  handleSave(type) {
    let parent = '';
    let children = [];
    if (type === 'SEQUENCE_SKILL') {
      parent = this.sequence;
      children = this.sequenceSkills;
    } else {
      parent = this.skill;
      children = this.tags;
    }
    const params = {
      type,
      parent,
      children,
    };
    this.save(params);
  }

  async save(params) {
    try {
      await saveRelation(params);
      this.$message.success('保存成功');
      if (params.type === 'SEQUENCE_SKILL') {
        this.sequence = '';
        this.sequenceSkills = [];
      } else {
        this.skill = '';
        this.tags = [];
      }
    } catch (error) {
      console.info('http error', error);
    }
  }

  mounted() {
    this.getOptions();
  }
}
