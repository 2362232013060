














































import { Vue, Component, Prop } from 'vue-property-decorator';
import accountSchema from '@/common/schema/account.json';
import { IEnterprise } from '@/model/enterprise';
import { injectGlobalConst } from '@/common/utils';
import { IRootSchema } from '@/common/schema/types';
import { addUserRole, bindAccount, saveAccount, searchAccountByMobile } from '@/common/service';
import {
  IAccountForBind,
  IAccountFormData,
  IAccountForSave,
  IAccountListData,
} from '@/model/account';

const initFormData = (initData?: Partial<IAccountListData | IAccountFormData>): IAccountFormData => ({
  name: '',
  avatar: undefined,
  mobile: '',
  email: '',
  title: '',
  employeeRole: '',
  ...initData,
});

@Component
export default class AccountForm extends Vue {
  @Prop({ required: true }) currentEnterprise!: IEnterprise;
  @Prop() editeItem?: IAccountListData;

  bid?: number;
  searched = false;
  hasAccount = false;
  related = false;
  formName = 'accountForm';

  userRole: string[] = [];

  get isEdit() {
    return !!this.editeItem;
  }

  get formSchema() {
    return injectGlobalConst(accountSchema as unknown as IRootSchema, {
      hasAccount: this.hasAccount,
      searched: this.searched,
    });
  }

  get titleText() {
    if (this.isEdit) {
      return '编辑';
    }
    if (this.hasAccount) {
      return '关联';
    }
    return '新建';
  }

  formData = initFormData();

  created() {
    if (this.isEdit) {
      this.formData = initFormData(this.editeItem);
      this.hasAccount = true;
      this.searched = true;
      this.related = true;
      this.bid = this.editeItem!.bid;
    }
  }

  search() {
    this.$ncformValidate(this.formName).then(async data => {
      if (!data.result) {
        return;
      }

      const {
        success,
        data: {
          exist,
          related,
          name,
          bid,
          avatar,
        },
      } = await searchAccountByMobile(this.formData.mobile, this.currentEnterprise.id);

      if (!success) {
        return;
      }

      this.searched = true;
      this.hasAccount = exist;
      this.related = related;

      if (!exist) {
        return;
      }

      this.bid = bid;
      this.formData = initFormData({
        ...this.formData,
        name,
        avatar,
      });
    });
  }

  handleDataToBind(data: IAccountFormData): IAccountForBind {
    return {
      ...data, // @ts-ignore
      avatar: data.avatar?.mediaId || data.avatar,
      bid: this.bid!,
      companyId: this.currentEnterprise.id,
      status: 1,
    };
  }

  handleDataToSave(data: IAccountFormData): IAccountForSave {
    return {
      ...data, // @ts-ignore
      avatar: data.avatar?.mediaId || data.avatar,
      bid: this.bid!,
      isUpdate: this.isEdit,
      companyId: this.currentEnterprise.id,
      status: 1,
    };
  }

  submit() {
    this.$ncformValidate(this.formName).then(async data => {

      if (!data.result) {
        return;
      }

      let res = { success: false };

      if (!this.hasAccount || this.isEdit) {
        /**
         * 账号不存在 -> 注册账号并添加到企业下
         * 编辑模式 -> 更新用户信息
         */
        res = await saveAccount(this.handleDataToSave(this.formData));
      }

      if (this.hasAccount && !this.related) {
        /**
         * 存在账号但是不在企业下 -> 绑定关联企业
         */
        res = await bindAccount(this.handleDataToBind(this.formData));
      }

      if (this.hasAccount && this.related && !this.isEdit) {
        /**
         * 存在账号并且已经关联到企业下且是非编辑模式 -> 提示
         */
        this.$alert(`该用户已经关联贵公司的招聘账号，姓名为 ${this.formData.name}`,
          '提示',
          {
            confirmButtonText: '确定',
          });
        return;
      }


      if (!res.success) {
        return;
      }

      this.$notify.success(`招聘者账号${this.titleText}成功`);
      this.$emit('success');
      this.$emit('close');
    });
  }

  async submitRole() {
    if (this.userRole.filter(e => !!e).length === 0) {
      this.$notify.warning('请至少选择一个角色');
      return;
    }
    try {
      const { success } = await addUserRole(this.formData.mobile, this.userRole);
      if (success) {
        this.$notify.success('用户角色添加成功');
      }
    } catch (error) {
      console.log('[添加用户角色失败]', error);
    }
  }
}
