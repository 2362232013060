







































import { IAuditList } from '@/model/content-audit';
import { Component, Vue, Prop } from 'vue-property-decorator';
import dangerous from '@/assets/images/dangerous.svg';

@Component
export default class ContentAuditPreviewDangerous extends Vue {
  @Prop() audit?: IAuditList;
  @Prop({ type: Boolean }) expand?: boolean;
  dangerousIcon = dangerous;

  seekVideo(index: number, point: number) {
    this.$QJEventBus.$emit(this.$QJConfig.eventName.SEEK_VIDEO_TIME, {
      index,
      point,
    });
  }

  modify() {
    this.$emit('modified', true);
  }
}
