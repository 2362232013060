


































































import { getAccountList } from '@/common/service';
import { IPagination } from '@/common/types';
import { IAccountListData } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EnterpriseInterviewerListForm extends Vue {
  @Prop({ required: true }) enterprise!: IEnterprise;

  interviewerBid: number | null = null;

  listData: IPagination<IAccountListData> = {
    pageSize: 20,
    pageNo: 1,
    totalCount: 0,
    list: [],
  };

  async getList(pageSize: number, pageNo: number) {
    const { data } = await getAccountList({
      companyId: this.enterprise.id,
      pageSize,
      pageNo,
    });
    this.listData = data;
  }

  get pageSize() {
    return this.listData.pageSize;
  }
  get pageNo() {
    return this.listData.pageNo;
  }

  set pageSize(pageSize: number) {
    this.interviewerBid = null;
    this.getList(pageSize, this.pageNo);
  }

  set pageNo(pageNo: number) {
    this.interviewerBid = null;
    this.getList(this.pageSize, pageNo);
  }

  handleSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  handleCurrentChange(pageNo: number) {
    this.pageNo = pageNo;
  }

  submit() {
    const user = this.listData.list.find(u => u.bid === this.interviewerBid);
    this.$emit('submit', this.interviewerBid, user);
  }

  created() {
    this.getList(20, 1);
  }
}
