




































import { Vue, Component } from 'vue-property-decorator';
import registerSchema from '@/common/schema/register.json';
import { IRegisterOptions } from '@/common/types';
import { IRootSchema } from '@/common/schema/types';
import { injectGlobalConst } from '@/common/utils';
import { register } from '@/common/service';

type TRegisterOptions = IRegisterOptions & { repassword?: string };

const initFormData = (initData?: TRegisterOptions): TRegisterOptions => ({
  email: '',
  nickname: '',
  password: '',
  repassword: '',
  verificationCode: '',
  ...initData,
});

@Component
export default class RegisterForm extends Vue {

  formData = initFormData();

  formName = 'registerForm';

  outFunc = 'e => Object.assign({}, e, { email: e.email + \'@reta-inc.com\' })';

  get allowSubmit() {
    return !!(
      this.formData.email
      && this.formData.nickname
      && this.formData.password
      && this.formData.repassword
      && this.formData.password === this.formData.repassword
      && this.formData.verificationCode
      && String(this.formData.verificationCode).length === 8
    );
  }

  get formSchema() {
    return injectGlobalConst(registerSchema as unknown as IRootSchema, {});
  }

  submit() {
    this.$ncformValidate(this.formName).then(async data => {

      if (!data.result) {
        return;
      }
      const res = await register(this.formData);
      if (!res.success) {
        return;
      }
      this.$notify.success('账号注册成功');
      this.$emit('success');
      this.$emit('close');
    });
  }
}
