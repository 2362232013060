import { PluginFunction, VueConstructor } from 'vue';
import { AxiosInstance } from 'axios';
import installAuthBgImgDirective from './auth-bgImg';
import installAuthSrcDirective from './auth-src';

export interface IAuthMediaOptions {
  request: AxiosInstance
}

export const installAuthMediaDirective: PluginFunction<IAuthMediaOptions> = (Vue, options) => {
  if (!options) {
    console.error('[AuthMediaDirective Error]: request is not defined in AuthMediaDirective');
    return;
  }
  installAuthBgImgDirective(Vue, options.request);
  installAuthSrcDirective(Vue, options.request);
};

export class AuthMediaDirective {
  installed = false;
  install(Vue: VueConstructor, options?: IAuthMediaOptions) {
    if (this.installed) {
      return;
    }
    this.installed = true;
    installAuthMediaDirective(Vue, options);
  }
}

const AuthMediaPlugin = new AuthMediaDirective();

export default AuthMediaPlugin;
