import { Module } from 'vuex';
import { IRootState } from '../root';

export interface IInterviewRecordsModule {
}

const interviewRecordsModule: Module<IInterviewRecordsModule, IRootState> = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default interviewRecordsModule;
