































import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { interviewReject } from '@/common/service';
import { Vue, Component } from 'vue-property-decorator';
import VideoItemCheckBox from '@/pages/home/views/interview-audit/interview-content-audit/components/video-item-check-box.vue';
import { namespace } from 'vuex-class';
import { ICurrentAuditVideo } from '@/common/types';

const ContentAuditModule = namespace('audit');

@Component({
  components: {
    VideoItemCheckBox,
  },
})
export default class RejectAuditModal extends Vue {
  @ContentAuditModule.State('currentVideo')
  currentVideo?: ICurrentAuditVideo;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  @ContentAuditModule.Action('setCurrentVideo')
  setCurrentVideo!: (options: { video?: ICurrentAuditVideo; index?: number }) => Promise<void>;

  @ContentAuditModule.Action('setCurrentAuditInterview')
  setCurrentAuditInterview!: (interviewId: number) => Promise<IContentAudit>;

  show = false;
  loading = false;
  title = '请选择要驳回重录的视频';

  contentAuditInterview: IContentAudit<IVideoBaseInfo> | null = null;

  get hasVideo() {
    return Array.isArray(this.contentAuditInterview?.mediaList) && this.contentAuditInterview!.mediaList.length > 0;
  }

  get checkedMediaIds() {
    return (this.contentAuditInterview?.mediaList || []).filter(e => e.checked).map(e => e.mediaId);
  }

  async rejectVideo() {
    if (this.loading || !this.contentAuditInterview) {
      return;
    }
    try {
      this.loading = true;
      const { success } = await interviewReject(this.contentAuditInterview!.interviewId, [...this.checkedMediaIds]);
      if (success) {
        this.$notify.success(`成功驳回${this.checkedMediaIds.length}个视频!`);
        if (this.checkedMediaIds.includes(this.currentVideo?.mediaId || -1)) {
          this.setCurrentVideo({
            video: undefined,
            index: 0,
          });
          this.setCurrentAuditInterview(this.currentAuditInterview?.interviewId);
        }
      }
      this.loading = false;
      this.$router.replace({
        path: this.$route.path,
        hash: `${Date.now()}`,
        query: {
          status: '0',
        },
      });
      this.close();
    } catch (error) {
      console.info('【任务驳回错误】', this.checkedMediaIds, this.contentAuditInterview!.interviewId);
      this.loading = false;
    }
  }

  async onSubmit() {
    this.$confirm(`确定要驳回这${this.checkedMediaIds.length}个视频吗? `, '温馨提示', {
      title: '温馨提示',
    })
      .then(() => {
        this.rejectVideo();
      })
      .catch(() => {});
  }

  close() {
    this.show = false;
    this.$destroy();
  }
}
