

































































































import { TResume } from '@/model/resume';
import { Component, Vue } from 'vue-property-decorator';
import { postExportCv, getJobToResumes } from '@/common/apis/resume/resume';
import SendResultDialog from '../candidate-pool/resume-review/components/send-result-dialog.vue';

interface ICvList extends TResume{
  isSelect: boolean;
}

@Component({
  components: {
    SendResultDialog,
  },
})
export default class ResumeClueDrawer extends Vue {
  drawer = false
  loading = false
  isIndeterminate = false
  allSelect:boolean = true
  cvList:ICvList[] = []
  jobId: number | null = null
  faildCvList: { name: string; reason: string }[] = []

  $refs!:{
    SendResultDialog: SendResultDialog
  }

  get importCount() {
    return this.cvList.filter(el => el.isSelect).length;
  }

  async open(jobId: number) {
    this.drawer = true;
    this.loading = true;
    try {
      const { data } = await getJobToResumes({ jobId });
      this.jobId = jobId;
      this.cvList = data.map(el => ({
        ...el,
        isSelect: true,
      }));
    } catch (error) {
      console.error('🙅 获取简历匹配线索', error);
    } finally {
      this.loading = false;
    }
  }

  // 导入简历线索
  async handlerExport() {
    try {
      const { data } = await postExportCv({
        jobId: this.jobId!,
        cvCenterIdList: this.cvList.filter(el => el.isSelect).map(el => el.resumeId),
      });
      if (data.failedCount === 0) {
        this.$message({
          message: '投递成功！',
          type: 'success',
        });
        this.drawer = false;
      } else {
        this.$refs.SendResultDialog.open(data.succeedCount, data.failedCount);
        this.faildCvList = data.failedData.map(el => ({
          name: el.name,
          reason: el.reason,
        }));
      }
      this.onAllSelectChange(false);
    } catch (error) {
      console.error('🙅 导入简历线索', error);
    }
  }

  // 全选
  onAllSelectChange(value:boolean) {
    this.isIndeterminate = false;
    this.cvList.forEach(el => {
      el.isSelect = value;
    });
  }

  // 选择一份简历
  handlerChange(item:ICvList) {
    item.isSelect = !item.isSelect;
    const allSelectStatus = this.cvList.every(el => el.isSelect);
    if (allSelectStatus) {
      this.allSelect = true;
      this.isIndeterminate = false;
    }
    const noSelectStatus = this.cvList.every(el => !el.isSelect);
    if (noSelectStatus) {
      this.allSelect = true;
      this.isIndeterminate = false;
      return;
    }
    const someSlect = this.cvList.some(el => el.isSelect)
    && this.cvList.filter(el => el.isSelect).length < this.cvList.length;
    if (someSlect) {
      this.isIndeterminate = true;
      return;
    }
  }

  // 打开简历详情
  async handlerCvDetail(item: Partial<TResume>) {
    this.$router.push({
      name: 'resume-detail',
      query: {
        cvId: String(item.resumeId),
        edit: '1',
      },
    });
  }

  stopDefault(e:Event) {
    e.stopPropagation();
  }

  // 数组截取
  sliceArr< T >(long: number, arr: T[]) : T[] {
    if (!Array.isArray(arr)) {
      return [];
    }
    return arr.length < long ? arr : arr.slice(0, long);
  }
}
