









































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import { managementColumns } from './contants';
import SetRecruitementChannelDrawer from './dialog/set-recruitement-channel-drawer.vue';
// import CandidateList from './dialog/candidate-list-drawer.vue';
import { getRecruitmentChannelConfig, getRecruitmentChannelList, getRecruitmentJobListA, setJobFree } from '@/common/apis/recruitment/index';
import { IRecruitmentJobTable } from '@/common/apis/recruitment/type';
import { Bind, Throttle } from 'lodash-decorators';

interface IOptions {
  label: string;
  value: string | number;
}

@Component({
  components: {
    List,
    // CandidateList,
    SetRecruitementChannelDrawer,
  },
})
export default class RecruitmentManagement extends Vue {
  loading = false;
  pageNo = 1;
  pageSize = 10;
  totalCount = 0;
  columns = managementColumns;
  tableData: IRecruitmentJobTable[] = [];
  statusOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '待发布',
      value: 'TO_PUBLISH',
    },
    {
      label: '招聘中',
      value: 'PUBLISHED',
    },
  ];
  interviewRoomOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '待开启',
      value: -1,
    },
    {
      label: '已开启',
      value: 1,
    },
    // {
    //   label: '已关闭',
    //   value: 0,
    // },
  ];
  daysOnlineOptions: IOptions[] = [
    {
      label: '不限',
      value: '',
    },
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '6',
      value: 6,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '大于7天',
      value: '大于7天',
    },
  ];
  recruitmentStatusOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '已开启',
      value: 1,
    },
    {
      label: '已关闭',
      value: 0,
    },
  ];
  hiringManagerOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
  ];
  accountoptions: IOptions[] = [];
  query = {
    companyName: '',
    title: '',
    code: '',
    companyFollowerName: '',
    status: '',
    opened: '',
    onlineDays: '',
    time: '',
    rpoFollowerName: '',
    rpoStatus: '',
    publishedAtFrom: '',
    publishedAtTo: '',
    onlineDaysGt: 0,
  };
  $refs!: {
    SetRecruitementChannelDrawer: SetRecruitementChannelDrawer;
    // CandidateList: CandidateList;
  };

  @Throttle(1000)
  @Bind
  @Watch('query.status')
  @Watch('query.opened')
  @Watch('query.onlineDays')
  @Watch('query.rpoStatus')
  @Watch('query.time')
  @Watch('query.rpoFollowerName')
  onSearch() {
    this.getRecruitmentJobListA();
  }

  created() {
    this.getRecruitmentJobListA();
    this.gerFollowerConfig();
    this.getAccount();
  }

  // 获取跟进人配置
  async gerFollowerConfig() {
    try {
      const { data } = await getRecruitmentChannelConfig();
      this.hiringManagerOptions.push(
        ...data.followers.map(el => ({
          label: el.followerName,
          value: el.followerId,
        })),
      );
    } catch (error) {
      console.error('🙅 获取跟进人配置', error);
    }
  }

  // 设置代招费用
  handlerFee(item: { jobId: number; fee: number }) {
    this.$prompt('请输入价格', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[0-9]*$/,
      inputValue: String(item.fee || ''),
      inputErrorMessage: '格式不正确',
    })
      .then(async (res: any) => {
        try {
          const { success } = await setJobFree({
            jobId: item.jobId,
            fee: res.value,
          });
          if (success) {
            this.$message({
              type: 'success',
              message: `设置成功，价格为 ¥${res.value}！`,
            });
            this.getRecruitmentJobListA();
          }
        } catch (error) {
          console.error('🙅 设置职位价格', error);
        }
      })
      .catch(() => {});
  }

  // 获取招聘账号
  async getAccount() {
    try {
      const { data } = await getRecruitmentChannelList({
        pageNo: 1,
        pageSize: 999,
      });
      this.accountoptions.push(
        ...Array.from(new Set(data.list.map(el => el.account))).map(el => ({
          label: el,
          value: el,
        })),
      );
    } catch (error) {
      console.error('🙅 获取账号列表', error);
    }
  }

  // 获取 A 端职位列表
  async getRecruitmentJobListA() {
    this.query.onlineDaysGt = 0;
    const { query, pageNo, pageSize } = this;
    const params = {
      pageNo,
      pageSize,
    };
    // 导入时间处理
    if (query.time !== '') {
      query.publishedAtFrom = query.time?.[0] || '';
      query.publishedAtTo = query.time?.[1] || '';
    } else {
      query.publishedAtFrom = '';
      query.publishedAtTo = '';
    }
    // 招聘天数
    if (query.onlineDays === '大于7天') {
      query.onlineDaysGt = 7;
    }
    Object.keys(query).forEach(el => {
      if (
        // eslint-disable-next-line operator-linebreak
        (el !== 'onlineDaysGt' && el !== 'onlineDays' && query[el] !== '') ||
        // eslint-disable-next-line operator-linebreak
        (el === 'onlineDaysGt' && query.onlineDaysGt !== 0) ||
        (el === 'onlineDays' && query.onlineDays !== '大于7天' && query.onlineDays !== '')
      ) {
        params[el] = query[el];
      }
    });
    try {
      const { data } = await getRecruitmentJobListA(params);
      this.tableData = data.list.map(el => ({
        id: el.id,
        contactsMobile: el.contactsMobile,
        companyName: el.companyName,
        title: el.job.title,
        code: el.job.code,
        officeLocation: el.job.officeLocation,
        followerName: el.job.company.followerName,
        rpoStatusName: el.statusName,
        statusName: el.job.statusName,
        openedDesc: el.job.openedDesc,
        onlineDays: el.job.onlineDays,
        totalCandidateCount: el.job.totalCandidateCount,
        publishedAt: el.job.publishedAt,
        rpoStatus: el.status,
        rpoFollowerName: el.rpoFollowerName,
        rpoChannelCount: el.rpoChannelCount,
        publishedById: el.job.publishedBy.id,
        publishedByName: el.job.publishedBy.name,
        jobId: el.job.jobId,
        fee: el.fee,
      }));
      this.totalCount = data.totalCount;
    } catch (error) {
      console.error('🙅 获取代招列表', error);
    }
  }

  // 过滤搜索
  handlerSearch() {
    this.getRecruitmentJobListA();
  }

  handlerImport() {}
  handlerChannel() {
    this.$router.push({
      path: '/channel',
    });
  }
  handlerSizeChange(value: number) {
    this.pageNo = 1;
    this.pageSize = value;
    this.getRecruitmentJobListA();
  }
  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getRecruitmentJobListA();
  }
  // 查看详情
  handlerLookJobInfo(row: any) {
    console.log(row);
    const id = row.publishedById;
    const name = row.publishedByName;
    const path = `/jd/${row.jobId}/job`;
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: id,
      targetName: name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(
      `${this.$QJConfig.BEndPath}${path}?${Object.entries(query)
        .map(e => e.join('='))
        .join('&')}`,
    );
  }
  handlerSetChannel(jobId: number) {
    this.$refs.SetRecruitementChannelDrawer.open(jobId);
  }

  // 累计投递人数
  handlerCumulativeDeliveries(row: IRecruitmentJobTable) {
    console.error(row);
    this.$router.push({
      name: 'import-cv',
      query: {
        companyName: row.companyName,
        contactsMobile: row.contactsMobile,
      },
    });
  }
  // 导入简历
  handleImport() {
    this.$router.push({
      path: '/all-job-management',
      query: {
        path: '/clue',
        tabName: 'recruitment',
      },
    });
  }
  // 导入线索
  handlerClue() {
    this.$router.push({
      path: '/all-job-management',
      query: {
        path: '/clue',
        tabName: 'recruitment',
      },
    });
  }
}
