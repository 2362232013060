




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class sendResultdialog extends Vue {
  centerDialogVisible = false;
  successTotal = 0;
  faildTotal = 0;

  // cv list
  @Prop(Array) faildCvList;
  @Prop({
    type: String,
    default: '发送面试邀请结果',
  }) title;
  @Prop({
    type: Boolean,
    default: true,
  }) isShowHint;
  open(successTotal, faildTotal) {
    this.centerDialogVisible = true;
    this.successTotal = successTotal;
    this.faildTotal = faildTotal;
  }
  confirm() {
    this.centerDialogVisible = false;
  }
}
