import { AxiosInstance } from 'axios';
import { VueConstructor } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { commonRequest } from './common';

export function setImgSrc(el: HTMLElement, binding: DirectiveBinding, request: AxiosInstance) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const url = binding.value;
    commonRequest(url, request).then(base64Url => {
      el.setAttribute('src', base64Url);
    }).catch(() => {
      el.setAttribute('src', url);
    });
  }
}

const installAuthSrcDirective = (Vue: VueConstructor, request: AxiosInstance ) => {
  Vue.directive('auth-src', {
    bind(el: HTMLElement, binding: DirectiveBinding) {
      setImgSrc(el, binding, request);
    },
    componentUpdated(el, binding) {
      setImgSrc(el, binding, request);
    },
  });
};

export default installAuthSrcDirective;
