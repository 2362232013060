import { Dict } from '@w-admin/common';
import { IAccountListData } from '@/model/account';
import moment from 'moment';
import { ITableColumnsConfig } from '../config';

export const accountColumnsConfig: ITableColumnsConfig<IAccountListData>[] = [
  {
    prop: 'id',
    label: '编号',
    width: '200',
    align: 'center',
  },
  {
    prop: 'name',
    label: '姓名',
    width: '120',
    align: 'center',
  },
  {
    prop: 'mobile',
    label: '手机号',
    width: '120',
    align: 'center',
  },
  {
    prop: 'email',
    label: '邮箱',
    width: '200',
    align: 'center',
  },
  {
    prop: 'title',
    label: '岗位名称',
    width: '120',
    align: 'center',
  },
  {
    prop: 'permissionRole',
    label: '权限角色',
    width: '120',
    align: 'center',
  },
  {
    prop: 'status',
    label: '状态',
    width: '120',
    align: 'center',
    formatter: row => Dict.accountStatus[row.status],
  },
  {
    prop: 'createAt',
    label: '注册时间',
    width: '120',
    align: 'center',
    formatter: row => moment(new Date(row.createAt)).format('YYYY-MM-DD'),
  },
  /**
   * 该列 width: auto, 自动撑开，用于兼容解决大屏空白问题
   */
  {
    prop: '',
    label: '',
  },
];

export default accountColumnsConfig;
