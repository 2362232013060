



















































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { IProjects } from '@/common/apis/resume/type';
import { ElForm } from 'element-ui/types/form';
import { postEditCv } from '@/common/apis/resume/resume';

@Component({
  components: {
    Resume,
  },
})
export default class ProjectExperience extends Vue {
  showDrawer = false
  previewResumeUrl = ''

  projects: IProjects[] = [{
    company: '',
    end: '',
    projectName: '',
    responsibilities: '',
    start: '',
    summary: '',
    title: '',
    projectUrl: '',
  }]
  resumeId = 0

  $refs!:{
    form: ElForm[]
  }

  rules = {
    projectName: [
      { required: true, message: '项目名称不能为空', trigger: 'blur' },
      { max: 20, message: '最多支持20个字符', trigger: 'blur' },
    ],
    start: { required: true, message: '起始时间不能为空', trigger: 'blur' },
    end: { required: true, message: '结束时间不能为空', trigger: 'blur' },
    responsibilities: [
      { required: true, message: '担任角色不能为空', trigger: 'blur' },
      { max: 10, message: '最多支持10个字符', trigger: 'blur' },
    ],
    summary: { required: true, message: '项目描述不能为空', trigger: 'blur' },
  }

  open(previewResumeUrl: string, data:IProjects[], resumeId: number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.projects = data;
    this.resumeId = resumeId;
  }
  async handlerSave() {
    let valid = true;
    for (const item of this.$refs.form) {
      // eslint-disable-next-line no-loop-func
      item.validate(async value => {
        if (!value) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = false;
        }
      });
    }
    if (valid) {
      try {
        const { success } = await postEditCv({
          resumeId: this.resumeId,
          form: {
            projects: this.projects,
          },
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '提交成功!',
          });
          this.showDrawer = false;
          this.$emit('submit');
        }
      } catch (error) {
        console.error('🙅 保存项目经历', error);
      }
    }
  }
  remove(index: number) {
    this.projects.splice(index, 1);
  }
  handlerAddProject() {
    this.projects.push({
      company: '',
      end: '',
      projectName: '',
      responsibilities: '',
      start: '',
      summary: '',
      title: '',
      projectUrl: '',
    });
  }
  handlerCancel() {
    this.showDrawer = false;
  }
}
