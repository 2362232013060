
























































































/* eslint-disable no-unused-vars */
import { exitSu, su } from '@/common/service';
import { IPagination, ISearchAccount } from '@/common/types';
import { IAccountListData } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import User from '@/model/user';
import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import AccountForm from './account-form.vue';
import { accountColumnsConfig } from './config';
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');
const AccountModule = namespace('enterprise/account');
const LoginModule = namespace('login');

@Component({
  components: {
    AccountForm,
  },
})
export default class AccountManagement extends Vue {
  accountColumnsConfig = accountColumnsConfig;

  showDrawer = false;

  editeItem: IAccountListData | null = null;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @AccountModule.Action('setSearchParams')
  setSearchParams!: (options: { searchParams: Partial<ISearchAccount>; unRefresh?: boolean }) => Promise<void>;

  @AccountModule.Action('getList')
  getList!: () => Promise<IPagination<IAccountListData>>;

  @AccountModule.State('listData')
  listData!: IPagination<IAccountListData>;

  @LoginModule.Action('getUserInfobyStorage')
  getUserInfobyStorage!: () => Promise<void>;

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  onSuccess() {
    /**
     * 成功后刷新列表
     */
    this.getList();
  }

  get pageSize() {
    return this.listData.pageSize;
  }

  set pageSize(pageSize: number) {
    this.setSearchParams({ searchParams: { pageSize } });
  }

  get pageNo() {
    return this.listData.pageNo;
  }

  set pageNo(pageNo: number) {
    this.setSearchParams({ searchParams: { pageNo } });
  }

  handleSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  handleCurrentChange(pageNo: number) {
    this.pageNo = pageNo;
  }

  openEditeAccount(item: IAccountListData) {
    this.editeItem = item;
    this.showDrawer = true;
  }

  attachmentBUser(item: IAccountListData) {

    const gotoBend = () => {
      const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
      const user = (this.$QJStorage.getStorage<User>(this.$QJConfig.userKey) || {}) as User;
      // A端 标记附身
      this.$QJStorage.setStorage(this.$QJConfig.userKey, { ...user, suId: -1 });
      this.$QJStorage.setStorage(this.$QJConfig.targetKey, item);
      this.getUserInfobyStorage();
      const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
      const sign = `${Date.now()}`;
      const query = {
        [attachmentTokenKey]: encodeURIComponent(token!),
        sign,
        targetUid: item.bid,
        targetName: item.name,
      };
      window.open(`${this.$QJConfig.BEndPath}?${Object.entries(query).map(e => e.join('=')).join('&')}`);
    };

    const doSu = () => {
      const loading = this.$loading({ text: '正在附身中...' });
      su(item.bid, this.currentEnterprise.id).then(rs => {
        loading.close();
        if (!rs || !rs.success) {
          this.$notify.error('呜呜呜，附身失败，请再试一次～');
          return;
        }
        gotoBend();
      }).catch(() => {
        loading.close();
      });
    };

    const exitPreSuAndSuNext = () => {
      exitSu().then(() => doSu());
    };

    const preTarget = this.$QJStorage.getStorage<IAccountListData>(this.$QJConfig.targetKey);
    const user = (this.$QJStorage.getStorage<User>(this.$QJConfig.userKey) || {}) as User;
    if (preTarget && typeof preTarget === 'object' && preTarget.name && preTarget.bid) {
      this.$confirm(`您已附身<b style="color: deepskyblue;">${preTarget.name}</b>，继续操作会退出该附身，是否继续?`, '温馨提示', {
        dangerouslyUseHTMLString: true,
      })
        .then(() => exitPreSuAndSuNext())
        .catch(() => {});
    } else if (user.suId) {
      exitPreSuAndSuNext();
    } else {
      doSu();
    }
  }

  enterAccount(item: IAccountListData) {
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: item.bid,
      targetName: item.name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(`${this.$QJConfig.BEndPath}?${Object.entries(query).map(e => e.join('=')).join('&')}`);
  }

  closeDrawer() {
    this.showDrawer = false;
    this.editeItem = null;
  }

  async init() {
    await this.setSearchParams({ searchParams: { companyId: this.currentEnterprise.id, pageNo: 1 } });
  }

  created() {
    this.init();
  }
}
