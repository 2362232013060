import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import router from '@/pages/home/router';
import store from '@/pages/home/store';
import RejectAuditModal from './index.vue';

export interface IRejectAuditModalData {
  show: boolean;
  contentAuditInterview: IContentAudit<IVideoBaseInfo> | null;
}

export default function showRejectAuditModal(contentAuditInterview: IContentAudit<IVideoBaseInfo>) {
  const instance = new RejectAuditModal({ router, store }) as RejectAuditModal & IRejectAuditModalData;
  instance.contentAuditInterview = {
    ...contentAuditInterview,
    mediaList: contentAuditInterview?.mediaList?.map(e => {
      return {
        ...e,
        checked: false,
      };
    }),
  };
  instance.$mount();
  instance.show = true;
}
