


























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { fileSize, safeCallback } from '../../../utils/global';
import {
  createUploadVideoAuth,
  refreshUploadVideoAuth,
  updateCover,
} from '../service';
import {
  AjaxResponse,
  IAliVideoUploader,
  IFile,
  TFile,
  IUploadFileOptions,
  IUploadInfo,
  IUploadObjRes,
  IUploadVideoCallbacks,
  IUploadVideoRes,
  TPreuploadScene,
} from '../types';
import { uploadConfig } from '../config';

// @ts-ignore
const _DEV_: boolean = process.env.NODE_ENV === 'development';

@Component
export default class VideoUpload extends Vue {
  @Prop({ required: true, type: String }) scene!: TPreuploadScene;
  @Prop({ required: true }) uploadOptions!: IUploadFileOptions<IUploadVideoRes>;

  fileSize = fileSize

  dialogVisible = false;
  file: IFile | null = null;
  timeout = '';
  partSize = '';
  parallel = '';
  retryCount = '';
  retryDuration = '';
  region = uploadConfig.aliyunConfig.region;
  userId = uploadConfig.aliyunConfig.userId;
  authProgress = 0;
  uploadDisabled = true;
  resumeDisabled = true;
  pauseDisabled = true;
  uploader: null | IAliVideoUploader = null;
  statusText = '';
  mediaId: number | null = null;
  videoId: string | null = null;
  res: AjaxResponse<IUploadVideoRes> | null = null;

  useDefaultCover: boolean = true;

  coverList: TFile[] = []
  coverMediaId: number | null = null;
  coverUrl = ''

  get isEdit() {
    return !!(this.mediaId && this.uploadOptions.fileList && this.uploadOptions.fileList.length);
  }

  get coverUploadOptions(): IUploadFileOptions<IUploadObjRes> {
    return {
      coverWidth: this.uploadOptions.coverWidth || '100px',
      coverHeight: this.uploadOptions.coverHeight || '100px',
      accept: this.$QJUploader.imgTypes.join(','),
      showFileList: true,
      listType: 'picture-card',
      limit: 1,
      multiple: false,
      onlyImage: true,
      fileList: this.coverList,
      autoUpload: true,
      onChange: (file: IFile, fileList: TFile[]) => {
        _DEV_ && console.info('【封面文件发生变化】', file, fileList);
        this.coverList = fileList;
      },
      onSuccess: (res, file, fileList) => {
        _DEV_ && console.info('【封面上传成功】', res);
        const { mediaId, url } = res.data;
        this.coverMediaId = mediaId;
        this.coverUrl = url;
        this.coverList = fileList;
        if (this.isEdit && this.mediaId) {
          updateCover(this.mediaId, mediaId);
        }
        this.$notify.success(`视频封面${this.isEdit ? '修改' : '上传'}成功`);
      },
    };
  }

  get callbacks(): IUploadVideoCallbacks {
    return {
      addFileSuccess: (uploadInfo: IUploadInfo) => {
        safeCallback(this.uploadOptions.onChange, [uploadInfo.file, [uploadInfo.file]]);
        _DEV_ && console.info('【文件添加成功】' + uploadInfo.file.name);
      },
      onUploadstarted: (uploadInfo: IUploadInfo) => {
        _DEV_ && console.info(
          '【开始上传文件】'
            + uploadInfo.file.name
            + ', endpoint:'
            + uploadInfo.endpoint
            + ', bucket:'
            + uploadInfo.bucket
            + ', object:'
            + uploadInfo.object,
        );
      },
      onUploadSucceed: (uploadInfo: IUploadInfo) => {
        this.uploadOptions.successMsg && this.$notify.success(this.uploadOptions.successMsg);
        safeCallback(this.uploadOptions.onSuccess, [this.res!, uploadInfo.file, [uploadInfo.file]]);
      },
      onUploadFailed: (uploadInfo: IUploadInfo, code: number, message: string) => {
        safeCallback(this.uploadOptions.onError, [new Error(JSON.stringify({ code, message })), uploadInfo.file, [uploadInfo.file]]);
        this.$notify.error({
          title: this.uploadOptions.errorMsg || '上传视频失败',
          message: message,
        });
        _DEV_ && console.error(`【上传视频失败】:\n${uploadInfo.file.name}`);
        _DEV_ && console.info('【视频信息】:\n', uploadInfo.file);
        _DEV_ && console.info('【错误编码】:\n', code);
        _DEV_ && console.info('【错误信息】:\n', message);
      },
      onUploadCanceled: (uploadInfo: IUploadInfo, code: number, message: string) => {
        _DEV_ && console.info('【取消视频上传】' + uploadInfo.file.name + ', code: ' + code + ', message:' + message);
      },
      onUploadProgress: (uploadInfo: IUploadInfo, totalSize: number, progress: number) => {
        _DEV_ && console.info(
          '【视频上传中】'
          + '文件名:'
          + uploadInfo.file.name
          + '\t文件大小:'
          + fileSize(totalSize)
          + '\t进度:'
          + Math.ceil(progress * 100)
          + '%',
        );
        safeCallback(this.uploadOptions.onProgress, [{ totalSize, progress } as any, uploadInfo.file, [uploadInfo.file]]);
      },
      onUploadTokenExpired: (uploadInfo: IUploadInfo) => {
        _DEV_ && console.info('【视频上传凭证过期】:\n', uploadInfo);
      },
      onUploadEnd: (uploadInfo: IUploadInfo) => {
        _DEV_ && console.info('【视频上传结束】', uploadInfo);
        this.pauseDisabled = true;
        this.resumeDisabled = true;
        this.uploadDisabled = true;
        this.dialogVisible = false;
      },
      onAPIError: (uploadInfo: IUploadInfo, error?: unknown) => {
        _DEV_ && console.error(`【预上传视频接口出错】:\n${uploadInfo.file.name}`);
        _DEV_ && console.info('【视频信息】:\n', uploadInfo.file);
        _DEV_ && console.info('【错误信息】:\n', error);
      },
    };
  }

  initFormData() {
    this.coverList = this.uploadOptions.coverList || [];
    this.useDefaultCover = this.coverList.length === 0;
    if (this.coverList.length) {
      this.coverUrl = this.coverList[0].url || '';
    }
    if (this.uploadOptions.fileList && this.uploadOptions.fileList.length) {
      this.file = this.uploadOptions.fileList[0] as IFile;
      this.mediaId = this.file.mediaId as number;
    }
  }

  created() {
    this.initFormData();
  }

  handleClose(done: Function) {
    this.pauseUpload();
    done();
  }

  chooseFile() {
    (this.$refs.uploadInput as HTMLInputElement).click();
  }

  removeFile() {
    this.$confirm(`确定要删除文件${this.file?.name ? ` ${this.file.name} ` : ''}吗？`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'before-remove-msgbox',
    }).then(() => {
      this.file = null;
      this.authProgress = 0;
      this.uploadDisabled = true;
      this.resumeDisabled = true;
      this.pauseDisabled = true;
      this.uploader = null;
      this.mediaId = null;
      this.videoId = null;
      this.statusText = '';
    }).catch(() => {});
  }

  createVideoUploadAuth() {
    if (!this.file) {
      return Promise.reject('请先选择一个文件');
    }

    if (!this.useDefaultCover && !this.isEdit && this.coverMediaId) {
      return createUploadVideoAuth(this.file!, this.scene, this.coverMediaId);
    }

    return createUploadVideoAuth(this.file!, this.scene);
  }

  refreshVideoUploadAuth() {
    if (!this.videoId) {
      return Promise.reject('文件未找到');
    }
    return refreshUploadVideoAuth(this.videoId);
  }

  fileChange(event: Event) {
    const { singleFileSizeLimit = uploadConfig.videoSizeLimit } = this.uploadOptions;
    const file = (event.target! as any).files[0];

    if (file.size > singleFileSizeLimit) {
      this.$notify.error(`上传文件不能超过${fileSize(singleFileSizeLimit)}`);
      return;
    }

    this.file = file;
    const userData = null;
    if (!this.file) {
      this.$notify.warning('请先选择需要上传的文件!');
      return;
    }

    if (this.uploader) {
      this.uploader.stopUpload();
      this.authProgress = 0;
      this.statusText = '';
    }

    this.uploader = this.createUploader(this.callbacks);
    this.uploader!.addFile(this.file, null, null, null, userData);
    this.uploadDisabled = false;
    this.pauseDisabled = true;
    this.resumeDisabled = true;
  }

  authUpload() {
    // 然后调用 startUpload 方法, 开始上传
    if (this.uploader !== null) {
      this.uploader.startUpload();
      this.uploadDisabled = true;
      this.pauseDisabled = false;
    }
  }

  // 暂停上传
  pauseUpload() {
    if (this.uploader !== null) {
      this.uploader.stopUpload();
      this.resumeDisabled = false;
      this.pauseDisabled = true;
    }
  }

  // 恢复上传
  resumeUpload() {
    if (this.uploader !== null) {
      this.uploader.startUpload();
      this.resumeDisabled = true;
      this.pauseDisabled = false;
    }
  }

  createUploader(callbacks: IUploadVideoCallbacks) {
    const self = this;
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const uploader = new AliyunUpload.Vod({
      timeout: self.timeout || 60000,
      partSize: self.partSize || 1048576,
      parallel: self.parallel || 5,
      retryCount: self.retryCount || 3,
      retryDuration: self.retryDuration || 2,
      region: self.region,
      userId: self.userId,
      // 添加文件成功
      addFileSuccess: function (uploadInfo: IUploadInfo) {
        self.uploadDisabled = false;
        self.resumeDisabled = false;
        self.statusText = '添加文件成功, 等待上传...';
        safeCallback(callbacks.addFileSuccess, [uploadInfo]);
      },
      // 开始上传
      onUploadstarted: function (uploadInfo: IUploadInfo) {
        if (!uploadInfo.videoId) {
          self.createVideoUploadAuth().then(({ data }) => {
            self.res = data;
            const { uploadAuth, uploadAddress, videoId, mediaId } = data.data;
            self.mediaId = mediaId;
            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
          }).catch(e => {
            safeCallback(callbacks.onAPIError, [uploadInfo, e]);
          });
          self.statusText = '文件开始上传...';
        } else {
          self.videoId = uploadInfo.videoId;
          self.refreshVideoUploadAuth().then(({ data }) => {
            self.res = data;
            const { uploadAuth, uploadAddress, videoId } = data.data;
            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
          }).catch(e => {
            safeCallback(callbacks.onAPIError, [uploadInfo, e]);
          });
        }
        safeCallback(callbacks.onUploadstarted, [uploadInfo]);
      },
      // 文件上传成功
      onUploadSucceed: function (uploadInfo: IUploadInfo) {
        self.statusText = '文件上传成功!';
        safeCallback(callbacks.onUploadSucceed, [uploadInfo]);
      },
      // 文件上传失败
      onUploadFailed: function (uploadInfo: IUploadInfo, code: number, message: string) {
        self.statusText = '文件上传失败!';
        safeCallback(callbacks.onUploadFailed, [uploadInfo, code, message]);
      },
      // 取消文件上传
      onUploadCanceled: function (uploadInfo: IUploadInfo, code: number, message: string) {
        self.statusText = '文件已暂停上传';
        safeCallback(callbacks.onUploadCanceled, [uploadInfo, code, message]);
      },
      // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
      onUploadProgress: function (uploadInfo: IUploadInfo, totalSize: number, progress: number) {
        const progressPercent = Math.ceil(progress * 100);
        self.authProgress = progressPercent;
        self.statusText = '文件上传中...';
        safeCallback(callbacks.onUploadProgress, [uploadInfo, totalSize, progress]);
      },
      // 上传凭证超时
      onUploadTokenExpired: function (uploadInfo: IUploadInfo) {
        self.videoId = uploadInfo.videoId;
        self.refreshVideoUploadAuth().then(({ data }) => {
          self.res = data;
          const { uploadAuth } = data.data;
          uploader.resumeUploadWithAuth(uploadAuth);
          console.log('upload expired and resume upload with uploadauth ' + uploadAuth);
        }).catch(e => {
          safeCallback(callbacks.onAPIError, [uploadInfo, e]);
        });
        self.statusText = '文件超时...';
        safeCallback(callbacks.onUploadTokenExpired, [uploadInfo]);
      },
      // 全部文件上传结束
      onUploadEnd: function (uploadInfo: IUploadInfo) {
        self.statusText = '文件上传完毕';
        safeCallback(callbacks.onUploadEnd, [uploadInfo]);
      },
    });
    return uploader;
  }
}
