


































































import { Component, Vue } from 'vue-property-decorator';
import { Bind, Debounce } from 'lodash-decorators';
import CandidateCard from '@/pages/recruitment/views/components/candidate-card.vue';
import {
  getRecruitmentCandidatesList,
  getCustomerRecruitmentJobList,
  setJobFree,
  saveCandidates,
  resumeSyncClient,
} from '@/common/apis/recruitment/index';

interface IJob {
  isSelect: boolean;
  jobId: number;
  title: string;
  salary: string;
  fee: number;
  experience: string;
  education: string;
  localtion: string;
  publisher: string;
  candidateCount: number;
  currBatchCandidateCount: number;
}
interface ICandidate {
  isSelect: boolean;
  cvId: number;
  candidateType: number;
  leadsId: number;
  cvUrl: string;
  name: string;
  mobile: string;
  company: string;
  school: string;
  deliveryTime: string;
  synced: boolean;
}

export interface ICandidates {
  onCampusCandidates: ICandidate[];
  recommendCandidates: ICandidate[];
  socialCandidates: ICandidate[];
}

@Component({
  components: {
    CandidateCard,
  },
})
export default class ImportCv extends Vue {
  activeName = 'social-recruitment-candidates';
  isShowAuthenticationDialog = false;
  loading = false;
  jobListLoading = false;
  lastSelectJob!: IJob;
  jobList: IJob[] = [];
  candidates: ICandidates = {
    onCampusCandidates: [],
    recommendCandidates: [],
    socialCandidates: [],
  };

  get selectCount() {
    const { candidates } = this;
    return (
      // eslint-disable-next-line operator-linebreak
      candidates.onCampusCandidates.filter(el => el.isSelect).length +
      // eslint-disable-next-line operator-linebreak
      candidates.recommendCandidates.filter(el => el.isSelect).length +
      candidates.socialCandidates.filter(el => el.isSelect).length
    );
  }

  created() {
    this.getRecruitmentJobList();
  }

  get companyName() {
    return this.$route.query.companyName ? String(this.$route.query.companyName) : '';
  }

  // 简历同步客户
  @Debounce(500)
  @Bind
  async handlerResumeSyncClient() {
    // if (this.jobList.some(el => !el.fee || String(el.fee) === '')) {
    //   this.$message({
    //     type: 'warning',
    //     message: '请先设置职位价格！',
    //   });
    //   return;
    // }
    const contactsMobile = this.$route.query.contactsMobile ? String(this.$route.query.contactsMobile) : null;
    try {
      const { success } = await resumeSyncClient({
        companyName: this.companyName,
        contactsMobile: contactsMobile!,
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '同步成功！',
        });
      }
    } catch (error) {
      console.error('🙅 简历同步客户', error);
    }
  }

  // 获取代招列表
  async getRecruitmentJobList(update: Boolean = false) {
    this.jobListLoading = true;
    const companyName = this.$route.query.companyName ? String(this.$route.query.companyName) : null;
    const contactsMobile = this.$route.query.contactsMobile ? String(this.$route.query.contactsMobile) : null;
    try {
      const { data, success } = await getCustomerRecruitmentJobList({
        companyName: companyName!,
        contactsMobile: contactsMobile!,
      });
      if (success) {
        this.jobList = data.map((el, index) => {
          return {
            isSelect: update ? this.lastSelectJob.jobId === el.job.jobId : index === 0,
            jobId: el.job.jobId,
            title: el.job.title,
            fee: el.fee,
            salary: el.job.salaryDesc,
            experience: el.job.experienceDesc,
            education: el.job.educationFrom,
            localtion: el.job.officeLocation,
            publisher: el.job.publishedBy.name,
            currBatchCandidateCount: el.currBatchCandidateCount,
            candidateCount: el.candidateCount,
          };
        });
        if (!update) {
          this.lastSelectJob = this.jobList[0];
        }
        const jobId = this.lastSelectJob.jobId;
        this.getRecruitmentCandidatesList(jobId);
      }
    } catch (error) {
      console.error('🙅 获取代招职位列表', error);
    } finally {
      this.jobListLoading = false;
    }
  }
  // 设置价格
  async handlerSetFree(item: IJob) {
    this.$prompt('请输入价格', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[0-9]*$/,
      inputValue: String(item.fee || ''),
      inputErrorMessage: '格式不正确',
    })
      .then(async (res: any) => {
        try {
          const { success } = await setJobFree({
            jobId: item.jobId,
            fee: res.value,
          });
          if (success) {
            this.$message({
              type: 'success',
              message: `设置成功，价格为 ¥${res.value}！`,
            });
            this.getRecruitmentJobList(true);
          }
        } catch (error) {
          console.error('🙅 设置职位价格', error);
        }
      })
      .catch(() => {});
  }
  // 获取代招候选人
  async getRecruitmentCandidatesList(jobId: number) {
    this.loading = true;
    try {
      const { data, success } = await getRecruitmentCandidatesList({
        jobId: jobId,
      });
      if (success) {
        this.candidates.socialCandidates = data.socialCandidates.map(el => {
          const university = el.cv.university && el.cv.university !== '' ? el.cv.university : '';
          const major = university && el.cv.major && el.cv.major !== '' ? `· ${el.cv.major}` : '';
          const education = (university || major) && el.cv.education && el.cv.education !== '' ? `· ${el.cv.education}` : '';
          return {
            isSelect: true,
            cvId: el.cv.id,
            cvUrl: el.cv.url,
            candidateType: el.candidateType,
            leadsId: el.leads.id,
            name: el.leads.name,
            mobile: el.leads.mobile,
            company: el?.cv?.latestCompany && el.cv.latestCompany !== '' ? el.cv.latestCompany : '未知',
            synced: el.synced,
            school: university || major || education ? `${university} ${major} ${education}` : '未知',
            deliveryTime: el.leads.createdAt,
          };
        });
        this.candidates.recommendCandidates = data.recommendCandidates.map(el => {
          const university = el.cv.university && el.cv.university !== '' ? el.cv.university : '';
          const major = university && el.cv.major && el.cv.major !== '' ? `· ${el.cv.major}` : '';
          const education = (university || major) && el.cv.education && el.cv.education !== '' ? `· ${el.cv.education}` : '';
          return {
            isSelect: true,
            cvId: el.cv.id,
            candidateType: el.candidateType,
            cvUrl: el.cv.url,
            leadsId: el.leads.id,
            name: el.leads.name,
            mobile: el.leads.mobile,
            company: el?.cv?.latestCompany && el.cv.latestCompany !== '' ? el.cv.latestCompany : '未知',
            synced: el.synced,
            school: university || major || education ? `${university} ${major} ${education}` : '未知',
            deliveryTime: el.leads.createdAt,
          };
        });
        this.candidates.onCampusCandidates = data.onCampusCandidates.map(el => {
          const university = el.cv.university && el.cv.university !== '' ? el.cv.university : '';
          const major = university && el.cv.major && el.cv.major !== '' ? `· ${el.cv.major}` : '';
          const education = (university || major) && el.cv.education && el.cv.education !== '' ? `· ${el.cv.education}` : '';
          return {
            isSelect: true,
            cvId: el.cv.id,
            cvUrl: el.cv.url,
            candidateType: el.candidateType,
            leadsId: el.leads.id,
            name: el.leads.name,
            mobile: el.leads.mobile,
            company: el?.cv?.latestCompany && el.cv.latestCompany !== '' ? el.cv.latestCompany : '未知',
            synced: el.synced,
            school: university || major || education ? `${university} ${major} ${education}` : '未知',
            deliveryTime: el.leads.createdAt,
          };
        });
      }
    } catch (error) {
      console.error('🙅 获取候选人列表', error);
    } finally {
      this.loading = false;
    }
  }
  // 选择职位
  handlerSelectJob(item: IJob) {
    if (this.lastSelectJob && this.lastSelectJob.title !== item.title) {
      this.lastSelectJob.isSelect = false;
    }
    item.isSelect = true;
    this.lastSelectJob = item;
    this.getRecruitmentCandidatesList(item.jobId);
  }
  // 保存选择候选人
  @Debounce(500)
  @Bind
  async handlerSave() {
    const { lastSelectJob, candidates } = this;
    this.loading = true;
    try {
      const { success } = await saveCandidates({
        jobId: lastSelectJob.jobId,
        candidates: [
          ...candidates.socialCandidates.filter(el => el.isSelect),
          ...candidates.onCampusCandidates.filter(el => el.isSelect),
          ...candidates.recommendCandidates.filter(el => el.isSelect),
        ].map(el => ({
          leadsId: el.leadsId,
          cvId: el.cvId,
          candidateType: el.candidateType,
        })),
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '保存成功！',
        });
        this.getRecruitmentJobList(true);
      }
    } catch (error) {
      console.error('🙅 保存选择候选人', error);
    } finally {
      this.loading = false;
    }
  }
  // 返回
  handlerBack() {
    this.$router.push({
      name: 'clue',
    });
  }
  handleTabClick() {}
}
