
















































































































































































































import { getResumeAuditList, postExportCv, getResumeDeliveryCount } from '@/common/apis/resume/resume';
import { Component, Vue } from 'vue-property-decorator';
import BulkUploadDrawer from './components/bulk-upload-drawer.vue';
import CvDetail from './components/cv-detail.vue';
import { TResume } from '@/model/resume';
import SendResultDialog from './components/send-result-dialog.vue';
import { getRecruitmentChannelConfig } from '@/common/apis/recruitment';
import { channelMap, educationOptions, sourceOptions, channelOptions } from '../contants';
import { IResumeAuditListRequest } from '@/common/apis/resume/type';

interface IOptions {
  label: string;
  value: string | number;
}

@Component({
  components: {
    CvDetail,
    BulkUploadDrawer,
    SendResultDialog,
  },
})
export default class ResumeReview extends Vue {
  pageSize = 10;
  pageNo = 1;
  total = 0;
  query = {
    email: '',
    name: '',
    mobile: '',
    educationFrom: '',
    ageFrom: null,
    ageTo: null,
    experienceFrom: '',
    experienceTo: '',
    other: '',
    forwardTitle: '',
    forwardLocation: '',
    period: [],
    source: '',
    userId: '',
    channel: '',
    createdAtFrom: '',
    createdAtTo: '',
    type: '',
  };
  loading = false;
  allSelect: boolean = false;
  faildCvList: { name: string; reason: string }[] = [];
  channelMap = channelMap;
  userIdMap = {};
  $refs!: {
    cvDetail: CvDetail;
    BulkUploadDrawer: BulkUploadDrawer;
    SendResultDialog: SendResultDialog;
  };
  educationOptions = educationOptions;
  recruitingSourceOptions: IOptions[] = sourceOptions;
  recruitmentChannelOptions: IOptions[] = channelOptions;
  hiringManagerOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
  ];
  resumeTypeOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '在线简历',
      value: 'FORM',
    },
    {
      label: '附件简历',
      value: 'FILE',
    },
  ];

  isIndeterminate = false;
  cvList: (TResume & { isSelect: boolean })[] = [];
  deliveryData: { date: string; company: string }[] = [];

  isExport = '0';
  isImport = '1';

  deliveryLoading = false;

  // 是否显示简历其他信息（职位导入）
  get isShowOtherCVInfo() {
    return this.isExport === '1';
  }

  get importCount() {
    return this.cvList.filter(el => el.isSelect).length;
  }

  created() {
    this.isExport = this.$route.query.isExport ? String(this.$route.query.isExport) : '0';
    this.isImport = this.$route.query.isImport ? String(this.$route.query.isImport) : '1';
    this.getResumeAduitList();
    this.gerFollowerConfig();
  }

  async gerFollowerConfig() {
    try {
      const { data } = await getRecruitmentChannelConfig();
      this.hiringManagerOptions.push(
        ...data.followers.map(el => {
          this.userIdMap[el.followerId] = el.followerName;
          return {
            label: el.followerName,
            value: el.followerId,
          };
        }),
      );
    } catch (error) {
      console.error('🙅 获取跟进人配置', error);
    }
  }

  stopDefault(e: Event) {
    e.stopPropagation();
  }

  // 获取投递记录
  async handlerGetDeliverData(item: TResume & { isSelect: boolean }) {
    this.deliveryLoading = true;
    try {
      const { data } = await getResumeDeliveryCount({
        resumeId: item.resumeId,
        pageSize: 3,
      });
      this.deliveryData = data.map(el => ({
        date: el.deliverTime,
        company: el.companyName,
      }));
    } catch (error) {
      console.error('🙅 获取投递次数', error);
    } finally {
      this.deliveryLoading = false;
    }
  }

  // 批量下载
  async handlerDownLoad() {
    const pdfs = this.cvList.filter(el => el.isSelect);
    if (pdfs.length === 0) {
      this.$message({
        message: '请至少选择一个简历进行下载！',
        type: 'warning',
      });
      return;
    }
    const urls = pdfs.map(el => ({
      url: `${el.pdfUrl}&targetFilename=${el.name}.pdf`,
      name: el.name,
    }));
    for await (const item of urls) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.style.height = '0px';
      iframe.src = item.url;
      document.body.appendChild(iframe);
      setTimeout(() => {
        iframe.remove();
      }, 1000);
    }
  }

  // 批量导入职位
  async handlerExport() {
    const { jobId } = this.$route.query;
    const params = {
      jobId: Number(jobId),
      cvCenterIdList: this.cvList.filter(el => el.isSelect).map(el => el.resumeId),
    };
    try {
      const { data } = await postExportCv(params);
      if (data.failedCount === 0) {
        this.$message({
          message: '投递成功！',
          type: 'success',
        });
      } else {
        this.$refs.SendResultDialog.open(data.succeedCount, data.failedCount);
        this.faildCvList = data.failedData.map(el => ({
          name: el.name,
          reason: el.reason,
        }));
      }
      this.onAllSelectChange(false);
    } catch (error) {
      console.error('🙅 导入简历', error);
    }
  }

  // 全选
  onAllSelectChange(value: boolean) {
    this.isIndeterminate = false;
    this.cvList.forEach(el => {
      el.isSelect = value;
    });
  }

  handlerChange(item: Partial<TResume> & { isSelect: boolean }) {
    item.isSelect = !item.isSelect;
    const allSelectStatus = this.cvList.every(el => el.isSelect);
    if (allSelectStatus) {
      this.allSelect = true;
      this.isIndeterminate = false;
    }
    const noSelectStatus = this.cvList.every(el => !el.isSelect);
    if (noSelectStatus) {
      this.allSelect = false;
      this.isIndeterminate = false;
      return;
    }
    const someSlect = this.cvList.some(el => el.isSelect) && this.cvList.filter(el => el.isSelect).length < this.cvList.length;
    if (someSlect) {
      this.isIndeterminate = true;
      return;
    }
  }

  // 批量上传简历
  handlerOpenDrawer() {
    this.$refs.BulkUploadDrawer.open();
  }

  // 打开简历详情
  async handlerCvDetail(item: TResume & { isSelect: boolean }) {
    this.$router.push({
      name: 'resume-detail',
      query: {
        cvId: String(item.resumeId),
        edit: '1',
      },
    });
  }

  // 数组截取
  sliceArr<T>(long: number, arr: T[]): T[] {
    if (!Array.isArray(arr)) {
      return [];
    }
    return arr.length < long ? arr : arr.slice(0, long);
  }

  // 获取简历审核列表
  async getResumeAduitList() {
    this.loading = true;
    const { query } = this;
    const params: Partial<IResumeAuditListRequest> = {
      pageSize: this.pageSize,
      pageNo: this.pageNo,
    };
    Object.keys(query).forEach(el => {
      if (query[el] !== '' && el !== 'period') {
        params[el] = query[el];
      }
    });
    if (query?.period?.length > 0) {
      params.createdAtFrom = new Date(query.period[0]).getTime();
      params.createdAtTo = new Date(query.period[1]).getTime();
    }
    this.cvList.length = 0;
    try {
      const { data } = await getResumeAuditList(params);
      data.list.forEach(el => {
        this.cvList.push({
          isSelect: false,
          ...el,
        });
      });
      this.total = data.totalCount;
    } catch (error) {
      console.error('🙅 获取简历列表', error);
    } finally {
      this.loading = false;
    }
  }

  // 搜索简历
  handlerSearch() {
    this.pageNo = 1;
    this.getResumeAduitList();
  }

  handleCurrentChange(value: number) {
    this.pageNo = value;
    this.getResumeAduitList();
  }

  handleSizeChange(value: number) {
    this.pageSize = value;
    this.getResumeAduitList();
  }
}
