
























































import { Component, Vue, Watch } from 'vue-property-decorator';
import VueAliplayerV2 from 'vue-aliplayer-v2';
import { Getter, namespace } from 'vuex-class';
import VideoItem from './video-item.vue';
// @ts-ignore
import RateComponent from '@w-admin/common/src/components/aliplayer-components/RateComponent/';
// @ts-ignore
import ProgressMarkerComponent from '@w-admin/common/src/components/aliplayer-components/ProgressMarkerComponent/index';
import { IContentAudit, IContentAuditMedia } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { ICurrentAuditVideo } from '@/common/types';
import Core from '@any-touch/core';
import doubletap from '@any-touch/doubletap';

const ContentAuditModule = namespace('audit');

const PLAYER_CONFIG = {
  width: '100%',
  height: '100%',
  autoplay: true,
  videoSrc: '',
  playsinline: true,
  x5_type: 'h5',
  controlBarVisibility: 'always',
  progressMarkers: [{}] as { offset?: number, text?: string, isCustomized?: boolean }[],
  skinLayout: [
    { name: 'bigPlayButton', align: 'cc' },
    { name: 'H5Loading', align: 'cc' },
    { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
    { name: 'infoDisplay' },
    { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
    { name: 'thumbnail' },
    {
      name: 'controlBar',
      align: 'blabs',
      x: 0,
      y: 16,
      children: [
        { name: 'progress', align: 'blabs', x: 0, y: 44 },
        { name: 'playButton', align: 'tl', x: 15, y: 12 },
        { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
        { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
        // { name: 'subtitle', align: 'tr', x: 15, y: 12 },
        // { name: 'setting', align: 'tr', x: 15, y: 12 },
        { name: 'volume', align: 'tr', x: 5, y: 10 },
      ],
    },
  ],
};

@Component({
  components: {
    VideoItem,
  },
})
export default class VideoPlayer extends Vue {
  $refs!: {
    player: typeof VueAliplayerV2;
    videoWrap: any;
  };

  videoRate = '1.0';

  onRateChange(videoRate: string) {
    this.videoRate = videoRate;
  }

  options = {
    ...PLAYER_CONFIG,
    components: [
      {
        name: 'ProgressMarkerComponent',
        type: ProgressMarkerComponent,
      },
      {
        name: 'RateComponent',
        type: RateComponent,
        args: [
          {
            rate: this.videoRate,
            onRateChange: this.onRateChange,
          },
        ],
      },
    ],
  };

  @ContentAuditModule.State('currentVideo')
  currentVideo?: ICurrentAuditVideo;

  @ContentAuditModule.State('currentVideoIndex')
  currentVideoIndex?: number;

  @ContentAuditModule.Action('setCurrentVideo')
  setCurrentVideo!: (options: { video?: ICurrentAuditVideo; index?: number }) => Promise<void>;

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  videoHeight = '100%';

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  get currentVideoPlayUrl() {
    return this?.currentVideo?.playAddressList && this?.currentVideo?.playAddressList[0]
      ? this?.currentVideo?.playAddressList[0].playUrl
      : null;
  }

  get effectiveAudit() {
    if (this.currentAuditInterview && this.currentAuditInterview.auditList) {
      const [effectiveAudit] = this.currentAuditInterview.auditList.filter(e => e.effective);
      return effectiveAudit ? effectiveAudit : undefined;
    }
    return undefined;
  }

  get effectiveAuditPoint() {
    return typeof this.currentVideoIndex === 'number'
    && this.currentVideoIndex >= 0
    && this.effectiveAudit?.evidences[this.currentVideoIndex]?.points
      ? this.effectiveAudit?.evidences[this.currentVideoIndex]?.points.map(p => ({
        offset: Math.floor(p / 1000),
        text: `关键点: ${p < 1000 ? `${p}毫秒` : this.$QJUtils.formatSeconds(p / 1000)}`,
        isCustomized: true,
      }))
      : [];
  }

  get currentVideoIsNotReady() {
    return this.currentVideo?.playAddressList?.[0]?.status !== this.$QJDict.videoStatus.OK.value;
  }

  getItemStatus(item: IContentAuditMedia<IVideoBaseInfo>) {
    return item?.data?.playAddressList?.[0]?.status;
  }

  setVideoOption() {
    this.options = {
      ...PLAYER_CONFIG,
      progressMarkers: this.effectiveAuditPoint,
      components: [
        {
          name: 'ProgressMarkerComponent',
          type: ProgressMarkerComponent,
        },
        {
          name: 'RateComponent',
          type: RateComponent,
          args: [
            {
              rate: this.videoRate,
              onRateChange: this.onRateChange,
            },
          ],
        },
      ],
    };
  }

  isRead(mediaId: number) {
    return !!this.$QJStorage.getStorage(this.$QJConfig.LDKey.STORAGE_READ_VIDEO_NAME)?.includes(mediaId);
  }

  readVideo(mediaId: number) {
    const hasReaded = this.$QJStorage.getStorage(this.$QJConfig.LDKey.STORAGE_READ_VIDEO_NAME) || [];
    this.$QJStorage.setStorage(this.$QJConfig.LDKey.STORAGE_READ_VIDEO_NAME, [...(Array.isArray(hasReaded) ? hasReaded : []), mediaId]);
  }

  @Watch('currentAuditInterview')
  currentAuditInterviewChange(auditInterview: IContentAudit<IVideoBaseInfo>) {
    if (auditInterview?.mediaList && auditInterview.mediaList[0]) {
      this.readVideo(auditInterview.mediaList[0].mediaId);
      this.setCurrentVideo({
        video: {
          ...auditInterview.mediaList[0].data,
          mediaId: auditInterview.mediaList[0].mediaId,
          blackWords: auditInterview.mediaList[0].blackWords,
          possibleNoVoice: auditInterview.mediaList[0].possibleNoVoice,
        },
        index: 0,
      });
    } else {
      this.setCurrentVideo({
        video: undefined,
        index: 0,
      });
    }
    this.setVideoOption();
  }

  videoTarget: HTMLDivElement | null = null;

  at: Core | null = null;

  bindMobileAddPoints() {
    if (this.$QJUtils.isMobile() && this.videoTarget) {
      if (this.at) {
        this.at.destroy();
      }
      this.at = new Core(this.videoTarget);
      this.at.use(doubletap);
      this.at.on('doubletap', () => {
        this.getCurrentTime();
      });
    }
  }

  unBindMobileAddPoints() {
    if (this.at) {
      this.at.destroy();
    }
  }

  onVideoPlayerReady({ target }: { target: HTMLDivElement }) {
    this.videoTarget = target;
    this.$QJEventBus.$emit(this.$QJConfig.eventName.ONVIDEOPLAYERREADY, this.$refs.player);
    this.bindMobileAddPoints();
  }

  onPlaying() {
    this.$QJEventBus.$emit(this.$QJConfig.eventName.ONVIDEOPLAYING, this.$refs.player);
  }

  onPause() {
    this.$QJEventBus.$emit(this.$QJConfig.eventName.ONVIDEOPUASE, this.$refs.player);
  }

  selected(item: IContentAuditMedia<IVideoBaseInfo>, index: number) {
    this.readVideo(item.mediaId);
    this.setCurrentVideo({
      video: {
        ...item.data,
        mediaId: item.mediaId,
        blackWords: item.blackWords,
        possibleNoVoice: item.possibleNoVoice,
      },
      index,
    }).then(() => {
      this.setVideoOption();
    });
    if (this.$QJUtils.isDingTalk()) {
      this.$alert(`
      <video
        controls
        height="100%"
        width="100%"
        autoplay
        muted
        src="${item?.data?.playAddressList?.[0].playUrl}"
      ></video>`, `视频预览: ${item?.data?.title || ''}`, {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'dingTalk-video-preview-dialog',
        // eslint-disable-next-line no-sequences
        beforeClose: (action, instance, done) => (instance.$el.remove(), done()),
      }).catch(() => {});
      return;
    }
  }

  getCurrentTime() {
    if (this.$refs?.player && this.currentVideo?.mediaId) {
      const currentTime: number = this.$refs?.player?.getCurrentTime();
      const point = parseInt(String(currentTime * 1000), 10);
      this.$QJEventBus.$emit(this.$QJConfig.eventName.MARKED_DANGEROUS_POINT, {
        videoRef: this.$refs.player,
        index: this.currentVideoIndex,
        mediaId: this.currentVideo?.mediaId,
        point,
        checked: true,
      });
    }
  }

  bindKeyBoardEvent = (e: KeyboardEvent) => {
    if (e.altKey && e.code.toLocaleLowerCase() === 'space') {
      e.preventDefault();
      this.getCurrentTime();
    }
  };

  seekVideo(option: { index: number; point: number }) {
    if (this.currentVideoIndex !== option.index) {
      this.selected(this.currentAuditInterview.mediaList[option.index], option.index);
    }
    if (this.$refs?.player) {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.player.seek(option.point / 1000);
    }
  }

  mounted() {
    if (this.$refs?.videoWrap && this.currentAuditInterview?.mediaList?.length > 4) {
      this.videoHeight = getComputedStyle(this.$refs?.videoWrap)?.height;
    }
  }

  created() {
    window.addEventListener('keydown', this.bindKeyBoardEvent);
    this.$QJEventBus.$on(this.$QJConfig.eventName.SEEK_VIDEO_TIME, this.seekVideo);
  }

  destroyed() {
    window.removeEventListener('keydown', this.bindKeyBoardEvent);
    this.$QJEventBus.$off(this.$QJConfig.eventName.SEEK_VIDEO_TIME, this.seekVideo);
    this.unBindMobileAddPoints();
  }
}
