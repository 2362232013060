


























































import { deleteUnbindJob, getRecruitmentChannelList, bindJob } from '@/common/apis/recruitment';
import { IRecruitmentChannel, IQuery } from '@/common/apis/recruitment/type';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

interface IOptions {
  label: string;
  value: string | number;
}

type IChannel = Partial<IRecruitmentChannel> & { id?: number; isEdit?: boolean } & {
  hiringManagerOptions?: IOptions[];
  recruitmentChannelOptions?: IOptions[];
  accountoptions?: IOptions[];
  jobsOptions?: IOptions[];
};

@Component
export default class SetRecruitementChannelDrawer extends Vue {
  drawer = false;
  loading = false;
  jobId = -1;
  jobsOptions: IOptions[] = [];

  @Prop(Array)
  hiringManagerOptions!: IOptions[];
  @Prop(Array)
  accountoptions!: IOptions[];

  recruitmentChannelOptions: IOptions[] = [
    {
      label: '全部',
      value: '',
    },
    {
      label: 'BOSS直聘',
      value: 'BOSS',
    },
    {
      label: '58同城',
      value: 'C_58',
    },
    {
      label: '猎聘',
      value: 'LIEPIN',
    },
    {
      label: '拉钩',
      value: 'LAGOU',
    },
    {
      label: '牛客',
      value: 'NIUKE',
    },
    {
      label: '前程无忧',
      value: 'JOB51',
    },
    {
      label: '智联招聘',
      value: 'ZHILIAN',
    },
    {
      label: '实习僧',
      value: 'SHIXISENG',
    },
    {
      label: '合作客户',
      value: 'PARTNER',
    },
  ];
  channels: IChannel[] = [];

  @Watch('')
  onFollowerId() {}

  // 对象数组去重
  objDeduplication(arr: IOptions[]) {
    const map = new Map();
    for (const item of arr) {
      if (!map.has(item.value)) {
        map.set(item.value, item);
      }
    }
    return [...map.values()];
  }

  // 选择招聘经理
  async handlerHiringManagerClick(value: number | string | undefined, item: IChannel) {
    console.error(value, item);
    const list = await this.getChannelOptions({
      followerId: String(value!),
    });
    item.channel = '';
    item.account = '';
    item.jobTitle = '';
    this.$set(
      item,
      'recruitmentChannelOptions',
      this.objDeduplication(
        list.map(el => ({
          label: el.channelName,
          value: el.channel,
        })),
      ),
    );
  }
  // 选择招聘渠道
  async handlerChannelClick(value: number | string | undefined, item: IChannel) {
    console.error(value, item);
    const list = await this.getChannelOptions({
      followerId: String(item.followerId!),
      channel: String(value!),
    });
    item.account = '';
    item.jobTitle = '';
    this.$set(
      item,
      'accountoptions',
      this.objDeduplication(
        list.map(el => ({
          label: el.account,
          value: el.account,
        })),
      ),
    );
  }
  // 选择招聘账号
  async handlerAccountlClick(value: number | string | undefined, item: IChannel) {
    console.error(value, item);
    const list = await this.getChannelOptions({
      followerId: String(item.followerId!),
      channel: String(item.channel!),
      account: String(value!),
    });
    this.$set(
      item,
      'jobsOptions',
      this.objDeduplication(
        list.map(el => ({
          label: el.jobTitle,
          value: el.jobTitle,
        })),
      ),
    );
  }

  handlerEditChannel(item: IChannel) {
    item.isEdit = false;
    item.hiringManagerOptions = this.hiringManagerOptions;
    this.handlerAccountlClick(item.account, item);
  }

  // 过滤搜索筛选项
  async getChannelOptions(query: Partial<IQuery>) {
    const params = {
      pageNo: 1,
      pageSize: 9999,
    };
    Object.keys(query).forEach(el => {
      if (query[el] !== '') {
        params[el] = query[el];
      }
    });
    try {
      const { data } = await getRecruitmentChannelList(params);
      return data?.list || [];
    } catch (error) {
      console.error('🙅 获取下拉筛选项', error);
      return [];
    }
  }

  // 增加招聘渠道
  handlerAddChannel() {
    this.channels.push({
      isEdit: false,
      jobId: this.jobId,
      followerId: '',
      followerName: '',
      channelName: '',
      account: '',
      jobTitle: '',

      hiringManagerOptions: this.hiringManagerOptions,
      recruitmentChannelOptions: [],
      accountoptions: [],
      jobsOptions: [],
    });
  }

  // 解绑关系
  async deleteUnbindJob(item: IChannel, index: number) {
    if (!item.id) {
      this.channels.splice(index, 1);
      return;
    }
    try {
      const { success } = await deleteUnbindJob({
        jobId: item.id!,
        channel: item.channel!,
        account: item.account!,
        jobTitle: item.jobTitle!,
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '解绑成功！',
        });
        this.getRecruitmentList(this.jobId);
        // this.channels.splice(index, 1);
      }
    } catch (error) {
      console.error('🙅 解绑代招渠道职位', error);
    }
  }

  // 保存代招渠道绑定职位
  async handlerBindJob(item: IChannel) {
    this.loading = true;
    try {
      const { success } = await bindJob({
        jobId: item.jobId!,
        channel: item.channel!,
        account: item.account!,
        jobTitle: item.jobTitle!,
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '绑定成功！',
        });
        item.isEdit = true;
        this.getRecruitmentList(this.jobId);
      }
    } catch (error) {
      console.error('🙅 绑定代招渠道职位', error);
    } finally {
      this.loading = false;
    }
  }

  // 获取代招渠道列表
  async getRecruitmentList(jobId: number) {
    this.loading = true;
    try {
      const { data } = await getRecruitmentChannelList({ jobId: jobId, pageSize: 999, pageNo: 1 });
      this.channels = data.list.map(el => ({
        ...el,
        isEdit: true,
        hiringManagerOptions: this.hiringManagerOptions,
        recruitmentChannelOptions: this.recruitmentChannelOptions,
      }));
    } catch (error) {
      console.error('🙅 获取代招渠道列表', error);
    } finally {
      this.loading = false;
    }
  }

  open(jobId: number) {
    this.drawer = true;
    this.jobId = jobId;
    this.getRecruitmentList(jobId);
  }

  handlerCancle() {
    this.drawer = false;
  }
}
