



















































































import { IEnterpriseQuestionListData } from '@/model/enterprise-question';
import { IMedia, IVideoBaseInfo } from '@/model/resource';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SelectBackCategory } from '@w-admin/common/src/components/select-back';
import textIcon from '@/assets/images/text.svg';

interface IQuestionActionOptions {
  question: IEnterpriseQuestionListData<IVideoBaseInfo>,
  item: IMedia<IVideoBaseInfo> | { data: { coverUrl: string; url: string } },
  index: number
}

const defaultAnnexRequirements = 'FORBIDDEN';

@Component({
  components: {
    SelectBackCategory,
  },
})
export default class QuestionItem extends Vue {
  @Prop({ required: true }) question!: IEnterpriseQuestionListData<IVideoBaseInfo>;
  @Prop({ default: false, type: Boolean }) disabled?: boolean;
  @Prop({ required: true }) index!: number;

  minDuration = 0
  maxDuration = 180
  annexRequirements = ''

  annexRequirementsConfig = {
    multiple: false,
    limit: 1,
    typeCode: this.$QJDict.categoryType.QUESTION_ATTACHMENT_REQUIREMENT.code,
    referenceSize: 'mini',
  }

  get questionMedias() {
    return Array.isArray(this.question.medias) && this.question.medias.length > 0
      ? this.question.medias
      : [
        {
          data: {
            url: textIcon,
            coverUrl: textIcon,
          },
        },
      ];
  }

  created() {
    this.minDuration = this.question.minDuration;
    this.maxDuration = this.question.maxDuration || 180;
    this.annexRequirements = this.question.attachmentRequirement || defaultAnnexRequirements;
  }

  onMinChange(minDuration: number) {
    if (minDuration > this.maxDuration) {
      return;
    }
    this.minDuration = minDuration;
    this.$emit('onMinDurationChange', Number(minDuration), this.index);
  }

  onMaxChange(maxDuration: number) {
    if (maxDuration < this.minDuration) {
      return;
    }
    this.maxDuration = maxDuration;
    this.$emit('onMaxDurationChange', Number(maxDuration), this.index);
  }

  annexRequirementsChange(e: ({ code: string }[]) | string) {
    const code = (
      Array.isArray(e) && e.length > 0 && e[0] && e[0].code
        ? e[0].code
        : e
      ) as string;
    this.annexRequirements = code;
    this.$emit('onAnnexRequirementsChange', code, this.index);
  }

  preview(options: IQuestionActionOptions, e: Event) {
    this.$emit('preview', options, e);
  }
  remove(options: IQuestionActionOptions, e: Event) {
    this.$emit('remove', this.index, options, e);
  }
}
