














import { Component, Vue, Prop } from 'vue-property-decorator';
import showGetAuditTaskModal from '@/components/get-audit-task-modal';

@Component
export default class ContentAuditEmpty extends Vue {
  @Prop({ type: Boolean }) loading?: boolean;
  @Prop({ type: String, required: true }) title!: string;
  getAuditTask() {
    showGetAuditTaskModal('RISK');
  }
}
