




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IMyPaper, IPaperList } from '../../../model/job-create';
import JobPaperPreview from './job-paper-preview.vue';
import IsOpenRoomDialog from './is-open-room-dialog.vue';

@Component({
  components: {
    JobPaperPreview,
    IsOpenRoomDialog,
  },
})
export default class JobPaper extends Vue {

  // 试题数据
  @Prop(Number)
  jobId!: number;

  // 试题数据
  @Prop(Object)
  myPaper!: IMyPaper;

  // 是否展示顶部的信息
  @Prop(Boolean)
  showTopDesc?: boolean;

  // 标题是否不折行
  @Prop(Boolean)
  isTitleLineFeed?: boolean;

  // 是否为预览
  @Prop(Boolean)
  preview?: boolean;

  // 当前播放的高亮
  @Prop(Number)
  currentPlayItem?: number;

  @Prop({ type: Boolean, default: false })
  unShowEmptyBtn?: boolean;

  typeSort = ['VIDEO', 'PICTURE', 'CODE'];
  num = 1;

  $refs!: {
    IsOpenRoomDialog: any;
  };

  // 计算试卷时间
  get paperTime() {
    const duration: number = this.myPaper.list.reduce((t, v) => t + v.duration, 0);
    return Math.ceil(duration / 60);
  }
  // 试卷列表
  get paperList() {
    const paperLevelMap: any = {};
    this.myPaper.list.forEach(paper => {
      if (!paperLevelMap[paper.questionStyle]) {
        paperLevelMap[paper.questionStyle] = [];
      }
      paperLevelMap[paper.questionStyle].push(paper);
    });
    return paperLevelMap;
  }

  // svgicon的name
  iconName(type) {
    const nameMap = {
      TEXT: '',
      VIDEO: 'video-topic',
      CODE: 'code-analys.icon',
      PICTURE: 'program-problem-icon',
    };
    return nameMap[type];
  }

  // 处理分钟
  formatTime(time) {
    return Math.ceil(time / 60);
  }

  // 处理难度的标签
  getLevelClass(level) {
    const levelClassMap = {
      EASY: 'success',
      MEDIUM: 'warning',
      HARD: 'danger',
    };
    return levelClassMap[level] || 'warning';
  }

  // 极速配题
  @Emit('setSpeedQuestion')
  async handlerSpeedSettingQuestion() {
  }

  // 跳转到试题列表
  handleToQuestion() {
    const jobId
      = this.$route.params.jobId || (this.$route.query.jobId as string) || String(this.jobId);
    const step = this.$route.name === 'jd-create' ? '1' : '';
    this.$router.push({
      name: 'jd-question-bank',
      params: {
        jobId,
      },
      query: {
        from: this.$route.name,
        ...this.$route.query,
        ...(step ? { step } : {}),
        jobId,
      },
    });
  }
  // 点击题目
  handleClickQuestion(q: IPaperList) {
    if (this.preview) {
      this.$emit('questionClick', q);
    }
  }
}
