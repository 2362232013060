


















/* eslint-disable no-unused-vars */
import { addEnterpriseImg } from '@/common/service';
import { AjaxResponse } from '@/common/types';
import { IAccount } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { enterpriseMenu } from '@/pages/home/router/menus';
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EnterpriseHeader from './components/enterprise-header.vue';
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component({
  components: {
    EnterpriseHeader,
  },
})
export default class EnterpriseDetails extends Vue {
  enterpriseMenu = enterpriseMenu;

  @EnterpriseInfoModule.State('activeMenu')
  activeMenu!: { name: string; index: number };

  @EnterpriseInfoModule.Action('setActiveMenu')
  setActiveMenu!: (menu: { name: string; index: number }) => Promise<void>;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseInfoModule.State('currentEnterpriseContacts')
  currentEnterpriseContacts?: IAccount;

  @EnterpriseInfoModule.Action('setCurrentEnterprise')
  setCurrentEnterprise!: (enterpriseId: number) => Promise<AjaxResponse<IEnterprise>>;

  async logoUploadSuccess(mediaId: number) {
    /**
     * 添加 logo
     */
    await addEnterpriseImg(this.currentEnterprise.id, 'LOGO', String(mediaId));
    /**
     * 刷新当前企业信息
     */
    const { success } = await this.setCurrentEnterprise(Number(this.currentEnterpriseId));
    success && this.$notify.success('企业 logo 上传成功');
  }

  onSuccessModifyContact() {
    /**
     * 企业联系人修改成功后 - 刷新当前企业信息
     */
    this.setCurrentEnterprise(Number(this.currentEnterpriseId));
  }

  get currentEnterpriseId() {
    return this.$route.params.id;
  }

  get currentMenu() {
    return this.activeMenu.name;
  }

  set currentMenu(name: string) {
    const index = this.enterpriseMenu.findIndex(m => m.name === name);
    this.setActiveMenu({ name, index });
    this.$router.replace({ name });
  }

  backList() {
    this.$router.replace({ name: 'enterprise-management' });
  }

  init() {
    if (!this.currentEnterpriseId) {
      this.$notify.error('企业信息不存在');
      return;
    }
    this.setCurrentEnterprise(Number(this.currentEnterpriseId));
    if (this.$route.name && this.$route.name !== this.currentMenu) {
      this.currentMenu = this.$route.name;
    }
  }

  created() {
    this.init();
  }
}
