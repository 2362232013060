import * as QJUtils from './global';
import { PluginObject } from 'vue';
import EventBus from '../event-bus';
import m from 'moment';

const QJUtilsPlugin: PluginObject<undefined> & { hasInstalled: boolean } = {
  hasInstalled: false,
  install(Vue) {
    if (this.hasInstalled) {
      return;
    }

    this.hasInstalled = true;

    Object.defineProperty(Vue.prototype, '$QJEventBus', {
      value: EventBus,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$QJFullname', {
      value: QJUtils.fullname,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$QJStorage', {
      value: QJUtils.QJStorage,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$JSONParse', {
      value: QJUtils.JSONParse,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$QJUtils', {
      value: QJUtils,
      writable: false,
    });
  },
};

export default QJUtilsPlugin;

export function getNearestWeekDay(weekDay: number = 6, date?: string) {
  let today = m();
  if (date) {
    today = m(date);
  }
  let result = today;
  while (result.weekday() !== weekDay) {
    result = result.add(1, 'd');
  }
  return result.format('YYYY-MM-DD');
}

export enum GlobalProperty {
  conf = '_conf',
}

export function loadExternalJsFile(CDNUrl: string, name?: GlobalProperty) {
  return new Promise(resolve => {
    if (name && typeof (window as { [key: string]: any })[name] !== 'undefined') {
      resolve(name);
      return;
    }
    const dom = document.createElement('script');
    dom.src = `${CDNUrl}?${new Date().getTime()}`;
    dom.type = 'text/javascript';
    dom.onload = () => {
      if (name) {
        resolve((window as { [key: string]: any })[name]);
      } else {
        resolve(1);
      }
    };
    document.head.appendChild(dom);
  });
}

export function getByteLength(str: string) {
  if (!str) {
    return 0;
  }
  return str.split('').reduce((acc, cur) => {
    if (cur.charCodeAt(0) > 255) {
      return acc + 2;
    }
    return acc + 1;
  }, 0);
}
