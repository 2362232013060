












































import { Component, Vue } from 'vue-property-decorator';
import { getAuditDetail, saveAuditResult } from '@/common/service';
import { IAuditDetail } from '@/model/audit';
import FailReason from './fail-reason';

@Component
export default class EnterpriseAccountAuditDetail extends Vue {
  detailData: IAuditDetail = {
    id: 0, // ID
    companyId: 0,
    companyName: '', // 公司全称
    companyShortName: '', // 公司简称
    name: '', // 声请人的姓名
    mobile: '', // 申请人的手机号
    email: '', // 邮箱
    auditName: '',
    applyTime: '',
    auditWay: 0,
    auditStatus: 0,
    auditStatusName: '', // 返回审核结果（审核通过，或者审核失败）
    auditTime: '',
    reason: '', // 审核失败的原因
    auditMedias: [], // 返回的图片
  };
  get auditDetailId() {
    return Number(this.$route.params.id);
  }

  get isPass() {
    return this.detailData.reason;
  }

  async loadDetailData() {
    try {
      const { data } = await getAuditDetail(this.auditDetailId);
      this.detailData = data;
    } catch (error) {
      console.error('[获取审核详情]', error);
    }
  }

  handleBack() {
    this.$router.push({
      name: 'enterprise-management-account-audit',
      query: {
        activeTab: this.$route.query.activeTab,
      },
    });
  }

  viewImg() {
    const img = this.detailData.auditMedias?.[0];
    if (!img) {
      return;
    }
    this.$alert(
      `
    <div style="width:560px;height: 500px;overflow-y: auto;">
      <img src="${img}" width="540px" alt="img" />
    </div>`,
      {
        dangerouslyUseHTMLString: true,
        closeOnClickModal: true,
        confirmButtonText: '关闭预览',
        customClass: 'global-img-preview-dialog',
      },
    );
  }

  handleSuccess() {
    this.$confirm('确定通过该条审核?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      try {
        const params = {
          id: this.detailData.id,
          auditStatus: 3,
        };
        await saveAuditResult(params);
        this.loadDetailData();
        this.$message.success('操作成功');
      } catch (error) {
        console.error('[审核操作]', error);
      }
    });
  }
  handleFail() {
    FailReason(this.detailData.id).then(() => {
      this.loadDetailData();
    });
  }

  mounted() {
    this.loadDetailData();
  }
}
