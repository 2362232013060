




















import { Component, Vue } from 'vue-property-decorator';
import { saveAuditResult } from '@/common/service';

@Component
export default class FailReason extends Vue {
  show = false;
  id = 0;
  checkList: string[] = [];

  onCancel() {
    this.show = false;
  }

  async onConfirm() {
    if (!this.checkList.length) {
      this.$message.warning('请选择原因');
      return;
    }
    try {
      const params = {
        id: this.id,
        auditStatus: 4,
        reason: this.checkList.join('、'),
      };
      await saveAuditResult(params);
      this.$emit('loadDetailData');
      this.$message.success('操作成功');
      this.show = false;
    } catch (error) {
      console.error('[审核操作]', error);
    }
  }
}
