


















import { IJob } from '@/model/job';
import { Vue, Component, Prop } from 'vue-property-decorator';
// import { namespace } from 'vuex-class';
// import Config from './config';
import { toBlob } from 'html-to-image';

@Component
export default class JobFormStepBaseInfo extends Vue {
  @Prop() job?: IJob;

  innerJobString = '';

  $refs!: {
    poster: HTMLElement
  }

  change(e: Event) {
    const target = e.target as HTMLElement;
    this.innerJobString = target.innerText;
  }

  created() {
    this.innerJobString = JSON.stringify(this.job, null, 2);
  }

  async toBlob() {
    return new Promise<Blob>((resolve, reject) => {
      toBlob(this.$refs.poster, { pixelRatio: 3 }).then(function (blob) {
        resolve(blob!);
      }, reject);
    });
  }

  async onBeforeSave() {
    const blob = await this.toBlob();

    const client = new this.$QJUtils.UploadClient({
      scene: 'COMPANY_PUBLIC',
      filename: 'jd_pic.jpg',
      file: new File([blob], 'jd_pic.jpg', { type: 'image/jpg' }),
    });
    const { mediaId } = await client.upload();
    return `${mediaId}`;
  }
}
