import { enterpriseDetails, getAccountDetails } from '@/common/service';
import { IAccount } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { Module } from 'vuex';
import { IRootState } from '../root';
import { initActiveMenu } from './init';

export interface IEnterpriseInformationState {
  currentEnterprise?: IEnterprise;
  currentEnterpriseContacts?: IAccount;
  activeMenu: { name: string; index: number };
}

const types = {
  UPDATE_CURRENT_ENTERPRISE: 'updateCurrentEnterPrise',
  UPDATE_CURRENT_ENTERPRISE_CONTACTS: 'updateCurrentEnterPriseContacts',
  UPDATE_ACTIVE_MENU: 'updateActiveMenu',
};

const enterpriseInformationModule: Module<IEnterpriseInformationState, IRootState> = {
  namespaced: true,
  state: {
    currentEnterprise: undefined,
    currentEnterpriseContacts: undefined,
    activeMenu: initActiveMenu(),
  },
  getters: {},
  mutations: {
    updateActiveMenu(state, options: { name: string; index: number }) {
      state.activeMenu = options;
    },
    updateCurrentEnterPrise(state, enterprise?: IEnterprise) {
      state.currentEnterprise = enterprise;
    },
    updateCurrentEnterPriseContacts(state, currentEnterpriseContacts?: IAccount) {
      state.currentEnterpriseContacts = currentEnterpriseContacts;
    },
  },
  actions: {
    setActiveMenu({ commit }, options: { name: string; index: number }) {
      commit(types.UPDATE_ACTIVE_MENU, options);
    },
    clearCurrentEnterprise({ commit }) {
      commit(types.UPDATE_CURRENT_ENTERPRISE, undefined);
    },
    async setCurrentEnterprise({ commit }, enterpriseId: number) {
      const res = await enterpriseDetails(enterpriseId);
      if (res.data && res.data.contactsBid) {
        const { data: enterpriseContacts } = await getAccountDetails(res.data.contactsBid, enterpriseId);
        commit(types.UPDATE_CURRENT_ENTERPRISE_CONTACTS, enterpriseContacts);
      }
      commit(types.UPDATE_CURRENT_ENTERPRISE, res.data);
      return res;
    },
  },
};

export default enterpriseInformationModule;
