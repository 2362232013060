


































import { Vue, Component } from 'vue-property-decorator';
import AsideBar from './components/aside-bar.vue';
import HeaderBar from './components/header-bar.vue';
import { namespace } from 'vuex-class';
import NavHeader from './components/nav-header/index.vue';

const LayoutStore = namespace('layout');

@Component({
  components: {
    AsideBar,
    HeaderBar,
    NavHeader,
  },
})
export default class Layout extends Vue {
  @LayoutStore.State('asideIsCollapse')
  isCollapse!: boolean;

  get asideBarWidth() {
    return this.isCollapse ? this.$QJConfig.aside.collapseWidth : this.$QJConfig.aside.width;
  }

  get containerStyle() {
    return {
      paddingLeft: this.asideBarWidth,
    };
  }

  mainCardStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  }
}
