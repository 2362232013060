/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
import { IEnterpriseListData } from '@/model/enterprise';
import moment from 'moment';

export interface ITableColumnsConfig<T> {
  prop: keyof T | ''
  label: string
  width?: string | number
  fixed?: boolean
  custom?: boolean
  resizable?: boolean
  formatter?: (row: T, column: object, cellValue: string, index: number) => string
  align?: 'left' | 'center' | 'right'
}


export const enterpriseColumnsConfig: ITableColumnsConfig<IEnterpriseListData>[] = [
  {
    prop: 'docNo',
    label: '编号',
    width: '100',
    align: 'center',
  },
  {
    prop: 'name',
    label: '公司全称',
    align: 'center',
    width: '300',
  },
  {
    prop: 'shortName',
    label: '公司简称',
    width: '150',
    align: 'center',
  },
  {
    prop: 'followerName',
    label: '客户经理',
    width: '150',
    align: 'center',
  },
  {
    prop: 'enterStage',
    label: '入驻阶段',
    width: '150',
    align: 'center',
  },
  {
    prop: 'registerLocation',
    label: '所在地',
    width: '200',
    align: 'center',
  },
  {
    prop: 'industryName',
    label: '所属行业',
    width: '120',
    align: 'center',
  },
  {
    prop: 'contacts',
    label: '联系人',
    width: '120',
    align: 'center',
  },
  {
    prop: 'accountCount',
    label: '招聘账号',
    width: '100',
    align: 'center',
  },
  {
    prop: 'jobCount',
    label: '在招职位',
    width: '100',
    align: 'center',
  },
  {
    prop: 'status',
    label: '公司状态',
    width: '120',
    align: 'center',
  },
  {
    prop: 'registerDate',
    label: '注册时间',
    width: '120',
    align: 'center',
    formatter: function (row) {
      return row.registerDate ? moment(new Date(row.registerDate)).format('YYYY-MM-DD') : '-';
    },
  },
];

export default enterpriseColumnsConfig;
