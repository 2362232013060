

















































import ViewOriginalImage from '@/components/view-original-image.vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ViewOriginalImage,
  },
})
export default class ReviewList extends Vue {
  currentPage = 1;
  pageSize = 100;

  $refs!:{
    viewOriginalImage: any
  }

  @Prop({ required: true }) tableData: any;
  @Prop({ required: true }) total!: number;
  @Prop({ required: true }) activeName!: string;

  get isHiddenAvatarReview() {
    return !['manual-review-passed', 'manual-review-rejected'].includes(this.activeName);
  }

  get isShowPass() {
    return ['pending-manual-review', 'machine-review-rejected', 'manual-review-rejected'].includes(this.activeName);
  }

  get isShowFailPass() {
    return ['pending-manual-review', 'approved-by-the-machine', 'manual-review-passed'].includes(this.activeName);
  }

  get isShowReasonRejection() {
    return ['manual-review-rejected'].includes(this.activeName);
  }

  viewImg(url: string) {
    this.$refs.viewOriginalImage.open(url);
  }

  @Emit('sizeChange')
  handleSizeChange(val: number) {
    this.pageSize = val;
    return {
      curPage: this.currentPage,
      pageSize: val,
    };
  }
  @Emit('curChange')
  handleCurrentChange(val: number) {
    this.currentPage = val;
    return {
      curPage: val,
      pageSize: this.pageSize,
    };
  }
  // 通过
  @Emit('passed')
  handlerPassed(row: any) {
    return row;
  }
  // 不通过
  @Emit('faildPassed')
  handlerNoPassed(row: any) {
    return row;
  }
}
