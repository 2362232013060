



















import { Component, Vue } from 'vue-property-decorator';
import { SelectBackTag, SelectBackCategory } from '@w-admin/common/src/components/select-back';
import EntityAssociation from './entity-association.vue';

@Component({
  components: {
    SelectBackTag,
    SelectBackCategory,
    EntityAssociation,
  },
})
export default class SettingPage extends Vue {
  tagsConfig = {
    addable: true,
    addTypeCode: '*',
  };

  categoryConfig = {
    multiple: true,
    editable: true,
    typeEditable: false,
    rootEditable: true,
  };
}
