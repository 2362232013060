














































import { IContentAuditListDataWithPagination, IGetContentAuditListOptions, TContentAuditTabName } from '@/common/types';
import { IContentAuditModuleTabs } from '@/pages/home/store/interview-content-audit-module';
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getAuditListCount, getContentAuditTask } from '@/common/service';
import ContentAuditEmpty from './components/content-audit-empty.vue';
import ContentAuditFooter from './components/content-audit-footer.vue';
import ContentAuditList from './components/content-audit-list.vue';
import ContentAuditSubtitle from './components/content-audit-subtitle.vue';
import VideoPlayer from './components/video-player.vue';

const ContentAuditModule = namespace('audit');

@Component({
  components: {
    ContentAuditSubtitle,
    ContentAuditEmpty,
    ContentAuditList,
    ContentAuditFooter,
    VideoPlayer,
  },
})
export default class InterviewContentAudit extends Vue {
  @ContentAuditModule.State('currentTab')
  currentTab!: IContentAuditModuleTabs;

  @ContentAuditModule.Getter('currentTabName')
  currentTabName!: TContentAuditTabName;

  @ContentAuditModule.State('currentListData')
  currentListData!: IContentAuditListDataWithPagination;

  @ContentAuditModule.State('searchParams')
  searchParams!: IGetContentAuditListOptions;

  @ContentAuditModule.Action('getCurrentListData')
  getCurrentListData!: () => Promise<IContentAuditListDataWithPagination>;

  @ContentAuditModule.Action('setSearchParams')
  setSearchParams!: (options: { searchParams: Partial<IGetContentAuditListOptions>, unRefresh?: boolean }) => Promise<void>;

  pageNoChange(pageNo: number) {
    this.setSearchParams({ searchParams: { pageNo } });
  }

  pageSizeChange(pageSize: number) {
    this.setSearchParams({ searchParams: { pageSize } });
  }

  get createdBy() {
    return this.searchParams.createdBy;
  }

  set createdBy(createdBy: number | string) {
    this.setSearchParams({ searchParams: { createdBy } });
  }

  loading = false;


  get showAuditor() {
    return this.currentTabName !== 'wait-audit';
  }

  get isEmpty() {
    return !this.currentListData || this.currentListData.totalCount === 0;
  }

  async created() {
    /**
     * 自动领取
     */
    // await this.autoGetTask();
    this.getCurrentListData();
  }

  hideList = false;

  onTapVideo() {
    if (this.$QJUtils.isMobile()) {
      this.hideList = true;
    }
  }

  toggleList() {
    this.hideList = !this.hideList;
  }

  async autoGetTask() {
    const { data: { totalCount } } = await getAuditListCount('RISK');

    if (!totalCount) {
      this.$notify.success('休息一会吧，任务池中暂无待审核的视频～');
      return;
    }

    const { success, data } = await getContentAuditTask('RISK', totalCount);
    if (success) {
      this.$notify.success(`已自动帮您领取${data.count}审核个任务!`);
    }
  }
}
