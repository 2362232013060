















import { IAuditList } from '@/model/content-audit';
import { Component, Prop, Vue } from 'vue-property-decorator';
import dangerous from '@/assets/images/dangerous.svg';
import ContentAuditPreviewDangerless from './content-audit-preview-dangerless.vue';
import ContentAuditPreviewDangerous from './content-audit-preview-dangerous.vue';
@Component({
  components: {
    ContentAuditPreviewDangerous,
    ContentAuditPreviewDangerless,
  },
})
export default class ContentAuditPreview extends Vue {
  @Prop() effectiveAudit?: IAuditList;
  @Prop({ type: Boolean, default: false }) dangerous?: boolean;
  dangerousIcon = dangerous;

  modify() {
    this.$emit('modified', true);
  }
}
