import Vue from 'vue';
import QJCommon from '@w-admin/common/src/common-plugin';
import { authFailCallBack } from '@/pages/home/router';
import { QJUtils } from '@w-admin/common';
import '@w-admin/common/src/plugin/ncform';
import '@w-admin/common/src/plugin/ali-video-player';
import '@w-admin/common/src/plugin/element';
import ASiteOnlyConfig from './config';

if (QJUtils.isMobile()) {
  document.body.style.cssText = 'widht:100vw;height:100vh;overflow: hidden;';
}

Vue.use(QJCommon, {
  config: {
    defaultTitle: '抢镜内部运营平台',
    authFailCallBack,
    $A: ASiteOnlyConfig,
  },
});
