import { getAccountList } from '@/common/service';
import { IPagination, ISearchAccount } from '@/common/types';
import { IAccountListData } from '@/model/account';
import { Module } from 'vuex';
import { IRootState } from '../root';
import { initAccountListData, initAccountSearchParams } from './init';

export interface IAccountManagementState {
  searchParams: ISearchAccount,
  listData: IPagination<IAccountListData>
}

const types = {
  UPDATE_SEARCH_PARAMS: 'updateSearchParams',
  UPDATE_LIST_DATA: 'updateListData',
  CLEAR_SEARCH: 'clearSearch',
  CLEAR_ACCOUNT_LIST: 'clearAccountList',
};

const accountManagementModule: Module<IAccountManagementState, IRootState> = {
  namespaced: true,
  state: {
    searchParams: initAccountSearchParams(),
    listData: initAccountListData(),
  },
  getters: {},
  mutations: {
    updateSearchParams(state, searchParams: Partial<ISearchAccount>) {
      state.searchParams = {
        ...state.searchParams,
        ...searchParams,
      };
    },
    updateListData(state, listData: IPagination<IAccountListData>) {
      state.listData = listData;
    },
    clearSearch(state) {
      state.searchParams = initAccountSearchParams();
    },
    clearAccountList(state) {
      state.listData = initAccountListData();
    },
  },
  actions: {
    async getList({ commit, dispatch, state }) {
      try {
        dispatch('wait/start', 'account.getList', { root: true });
        const { data } = await getAccountList(state.searchParams);
        commit(types.UPDATE_LIST_DATA, data);
        dispatch('wait/end', 'account.getList', { root: true });
        return data;
      } catch (error) {
        dispatch('wait/end', 'account.getList', { root: true });
        console.log(error);
        return state.listData;
      }
    },
    async setSearchParams(
      { commit, dispatch },
      options: {
        searchParams: Partial<ISearchAccount>,
        unRefresh?: boolean
      }) {
      commit(types.UPDATE_SEARCH_PARAMS, options.searchParams);
      options.unRefresh !== true && dispatch('getList');
    },
    async clearSearch({ commit, dispatch }) {
      commit(types.CLEAR_SEARCH);
      dispatch('getList');
    },
    async clearAccountList({ commit }) {
      commit(types.CLEAR_ACCOUNT_LIST);
    },
  },
};

export default accountManagementModule;
