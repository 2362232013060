








































































import { safeCallback } from '@/common/utils';
import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dangerous from '@/assets/images/dangerous.svg';
import ContentAuditPreview from './content-audit-preview.vue';
import ContentAuditPreviewExpand from './content-audit-preview-expand.vue';
import { IContentAuditModuleTabs } from '@/pages/home/store/interview-content-audit-module';

const ContentAuditModule = namespace('audit');

@Component({
  components: {
    ContentAuditPreview,
    ContentAuditPreviewExpand,
  },
})
export default class SubmitFooter extends Vue {
  @Prop() value?: boolean;
  @Prop() isEdit?: boolean;
  @Prop() isModified?: boolean;
  @Prop() expand?: boolean;
  @Prop() onSubmit?: () => void;

  dangerousIcon = dangerous;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  @ContentAuditModule.State('currentTab')
  currentTab!: IContentAuditModuleTabs;

  get effectiveAudit() {
    if (this.currentAuditInterview && this.currentAuditInterview.auditList) {
      const [effectiveAudit] = this.currentAuditInterview.auditList.filter(e => e.effective);
      return effectiveAudit ? effectiveAudit : undefined;
    }
    return undefined;
  }

  get previewDangerless() {
    return this.currentAuditInterview && !this.expand && this.currentAuditInterview.status === 1;
  }

  get previewDangerlessExpand() {
    return this.currentAuditInterview && this.expand && this.currentAuditInterview.status === 1;
  }

  get previewDangerous() {
    return this.currentAuditInterview && !this.expand && this.currentAuditInterview.status === 3;
  }

  get previewDangerousExpand() {
    return this.currentAuditInterview && this.expand && this.currentAuditInterview.status === 3;
  }

  submit() {
    safeCallback(this.onSubmit);
  }
}
