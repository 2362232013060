<template>
  <div>
    <common-upload
      v-if="!loading"
      v-show="!hidden"
      :disabled="disabled || readonly"
      :scene="mergeConfig.scene"
      :uploadOptions="options"
    ></common-upload>
    <div
      v-else
      v-loading="loading"
      element-loading-text="加载中"
      class="loading"
      :style="{
        width: mergeConfig.uploadOptions.coverWidth,
        height: mergeConfig.uploadOptions.coverHeight
      }"
    >
    </div>
  </div>
</template>

<script>
import ncformCommon from '@ncform/ncform-common';
import { Component, Mixins } from 'vue-property-decorator';
import { getVideoFileInfo, getVideoInfo } from '../service';

@Component
export default class NcCommonUpload extends Mixins(ncformCommon.mixins.vue.controlMixin) {
  coverLoading = true;
  infoLoading = true;
  fileList = []
  coverList = []
  defaultConfig = {
    scene: '',
    uploadOptions: {},
  }

  get loading() {
    return this.coverLoading || this.infoLoading;
  }

  handleSingleVuale(value) {
    /**
     * mediaId number
     * */
    if (typeof value === 'number' && !isNaN(value)) {
      return { mediaId: value };
    }

    /**
     * url string
     * */
    if (typeof value === 'string') {
      return { url: value };
    }

    /**
     * { url, mediaId }
     */
    if (value && typeof value === 'object' && value.url && value.mediaId) {
      return value;
    }

    /**
     * { url, mediaId: null }
     */
    if (value && typeof value === 'object' && value.url) {
      return { url: value.url };
    }

    /**
     * { url: null, mediaId }
     */
    if (value && typeof value === 'object' && value.mediaId) {
      return { mediaId: value.mediaId };
    }

    return undefined;
  }


  handleFileValue(value) {
    const res = this.handleSingleVuale(value);
    if (!res) {
      this.infoLoading = false;
      this.coverLoading = false;
      return;
    }
    this.fileList.push(res);
    this.infoLoading = false;
    this.coverLoading = false;
  }

  handleVideoVule(value) {
    const res = this.handleSingleVuale(value);
    if (!res || !res.mediaId) {
      this.coverLoading = false;
      this.infoLoading = false;
      return;
    }
    getVideoInfo(res.mediaId).then(rs => {
      this.coverLoading = false;
      if (rs?.data?.data?.coverUrl) {
        this.coverList.push({ url: rs.data.data.coverUrl });
      }
    }).catch(e => console.info('获取视频封面出错', e));
    getVideoFileInfo(res.mediaId).then(rs => {
      this.infoLoading = false;
      if (rs?.data?.data?.filename) {
        this.fileList.push({
          mediaId: res.mediaId,
          name: rs?.data?.data?.filename || '文件名称',
          size: rs?.data?.data?.size || 0,
        });
      }
    }).catch(e => console.info('获取视频信息出错', e));
  }

  commonHandle(value, isVideo) {
    if (!value) {
      this.infoLoading = false;
      this.coverLoading = false;
      return;
    }

    if (Array.isArray(value) && value.length) {
      value.forEach(isVideo ? this.handleVideoVule : this.handleFileValue);
      return;
    }

    isVideo ? this.handleVideoVule(value) : this.handleFileValue(value);
  }

  handleValue(value) {
    this.commonHandle(value, this.mergeConfig.uploadOptions.video);
  }

  initData() {
    this.handleValue(this.modelVal);
  }

  created() {
    this.initData();
  }

  get options() {
    return {
      ...this.mergeConfig.uploadOptions,
      fileList: this.fileList,
      coverList: this.coverList,
      onSuccess: res => {
        this.modelVal = res.data;
      },
    };
  }
}
</script>

<style lang="less" scoped>
.loading {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 10px;
}
</style>
