import { Module } from 'vuex';
import { IRootState } from './root';

export interface IInterviewQualityMarkModuleState {
}

const interviewQualityMarkModule: Module<IInterviewQualityMarkModuleState, IRootState> = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default interviewQualityMarkModule;
