

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import VideoCard from './components/video-card.vue';
import Anchor from '../anchor.vue';
import { IMedia, ISnippets } from './types';
import JdAbstract from './components/jd-abstract.vue';

type TSnippetMedia = IMedia & { snippetTitle: string };

@Component({
  components: {
    VideoCard,
    Anchor,
    JdAbstract,
  },
})
export default class JobDetails<T extends Record<string, any>> extends Vue {

  @Prop({ default: () => window })
  anchorContainer!: string | HTMLElement | (() => (HTMLElement | Window)) | Window;

  @Prop({ default: false, type: Boolean })
  widthoutAnchor!: string;

  @Prop({ default: () => ({ top: '185px', right: '180px' }) })
  anchorPos?: { left?: string, right?: string, top?: string, bottom?: string }

  @Prop({ required: true })
  job!: T;

  getPageSections = [
    {
      title: '基本信息',
      hash: '#基本信息',
    },
    // {
    //   title: '公司展示',
    //   hash: '#公司展示',
    // },
    {
      title: '职位描述',
      hash: '#职位描述',
    },
    {
      title: '公司介绍',
      hash: '#公司介绍',
    },
  ]

  get allJobSnippetsMedias() {
    return ((this.job.snippets || []) as ISnippets[]).reduce((total: TSnippetMedia[], current) => [
      ...total,
      ...current.medias.map(m => ({
        ...m,
        snippetTitle: current.title,
      })),
    ], [] as TSnippetMedia[]);
  }
}
