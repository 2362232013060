import { IMedia, IVideoBaseInfo } from '../../model/common';
import VideoPlayerModal from './video-player.vue';

type TVideoPreviewData = {
  media: Partial<IMedia<string | Partial<IVideoBaseInfo>>>;
  show :boolean;
  title?: string;
}

const previewVideo = (media: Partial<IMedia<string | Partial<IVideoBaseInfo>>>, title?: string) => {
  const player = new VideoPlayerModal() as VideoPlayerModal & TVideoPreviewData;
  player.media = media;
  player.title = title;
  player.show = true;
  player.$mount();
};

export default previewVideo;
