












































































































/* eslint-disable no-nested-ternary */
import { Component, Prop, Vue } from 'vue-property-decorator';
import JobPaperPreview from '../../job-create/components/job-paper-preview.vue';
import { IJob, IMyPaper, IPaperList, IPlayerValueType, IQuestionDetail } from '../../../model/job-create';
import PhoneExamPreview from '../../job-create/components/mobile-modal/phone-exam-preview.vue';
import JobPaper from '../../job-create/components/job-paper.vue';
import TimelineSection from './timeline-section.vue';
import TimelineSectionDot from './timeline-section-dot.vue';
// import { IVideoBaseInfo } from '../types';
import { getQuestionDetail } from '../../../api/service';

const imgPathConfig = {
  default: 'https://video.reta-inc.com/image/default/61FAE5CD50194B6691A74F8AB41CDC5E-6-2.png',
  noVideo: 'https://video.reta-inc.com/image/default/039E7CF475A9466CA9FCE5E6EBCFBCD1-6-2.png',
  encodeVideo: 'https://video.reta-inc.com/image/default/B0EDF1752A3A4F4FBD2CB840A4BAEB5C-6-2.png',
};

const defaultMyPaper = {
  duration: 0,
  levelPanel: [],
  tagPanel: [],
  list: [],
};

@Component({
  components: {
    JobPaper,
    JobPaperPreview,
    PhoneExamPreview,
    TimelineSection,
    TimelineSectionDot,
  },
})
export default class ExamPreview extends Vue {
  @Prop({ default: () => ({}) }) job!: Partial<IJob>;
  @Prop({ default: () => defaultMyPaper }) myPaper!: IMyPaper;
  @Prop({ type: Boolean, default: false }) usePreview!: boolean

  playerValue: IPlayerValueType<IQuestionDetail | Function> | null = null;
  currentPlayItem: string | number | null = null;

  defaultColor = '#F2F3F5;'
  linkColor = '#375EC5';
  activeColor = '#FF8730'

  $refs!: {
    phoneExamPreview: PhoneExamPreview
  }

  get exam() {
    return this.job?.exams?.[0] || { answerRequirement: { allowMultiAnswer: true } };
  }

  get answerTypeText() {
    return this.exam?.answerRequirement?.allowMultiAnswer
      ? '开卷面试，候选人可以多次答题'
      : '闭卷面试，候选人不可重复答题';
  }

  get showMyPaper() {
    return this.myPaper && Array.isArray(this.myPaper.list) && this.myPaper.list.length > 0;
  }

  toSettingQuestions() {
    this.$router.push({
      name: 'jd-question-bank',
      query: {
        from: this.$route.name,
      },
    });
  }

  async getDetail(id: number) {
    try {
      const { data, success } = await getQuestionDetail(id);
      if (success) {
        this.playItem({ type: 'QUESTION', data }, id);
      }
    } catch (error) {
      console.warn('[http] error', error);
    }
  }

  playQuestion(question: IPaperList) {
    this.getDetail(question.id);
  }

  playItem(item: IPlayerValueType<IQuestionDetail | Function> | null, type: number | string | null) {
    this.playerValue = null;
    this.$nextTick(() => {
      this.playerValue = item;
      this.currentPlayItem = type;
    });
  }

  goSetting() {
    this.$router.push({
      name: 'jd-edit',
      query: {
        from: this.$route.name,
        jobId: `${this.job.id}`,
        step: '1',
      },
    });
  }

  playDefault() {
    this.playItem({
      type: 'IMAGE_PLACEHOLDER',
      data: imgPathConfig.default,
    }, null);
  }

  initplayerValue() {
    this.playDefault();
  }

  mounted() {
    this.initplayerValue();
  }
}
