import moment from 'moment';
import { Notification } from 'element-ui';
import { IRootSchema } from '../model/form-schema';
import SparkMD5 from 'spark-md5';
import CryptoJS from 'crypto-js';
import { preUploadVideo, preUpload, postUpload } from './../api/service';
import { createOSSClient } from './ali-oss';
import globalConfig from '../config';
import { CustomAxiosRequestConfig } from '../api/ajax';
import { IPostUplaodData } from '../components/common-upload';

export { default as previewVideo } from '../components/video-preview';

export const throttle = (func: Function, wait: number = 1000, immediately = false) => {
  const state = {
    previous: 0,
    flag: true,
    timer: null as (number | null),
  };
  return immediately
    ? function () {
      if (state.flag) {
        // @ts-ignore
        func.apply(this, arguments);
        state.flag = false;
        state.timer = window.setTimeout(() => {
          state.flag = true;
        }, wait);
      }
    }
    : function () {
      const now = +new Date();
      if (now - state.previous > wait) {
        // @ts-ignore
        func.apply(this, arguments);
        state.previous = now;
      }
    };
};

export function bigNumberTransform(value: number) {
  const newValue = ['', '', ''];
  let fr = 1000;
  let num = 3;
  let text1 = '';
  let fm = 1;
  while (value / fr >= 1) {
    fr *= 10;
    num += 1;
  }
  if (num <= 4) { // 千
    newValue[0] = parseInt(`${value / 1000}`, 10) + '';
    newValue[1] = '千';
  } else if (num <= 8) { // 万
    text1 = parseInt(`${num - 4}`, 10) / 3 > 1 ? '千万' : '万';
    fm = text1 === '万' ? 10000 : 10000000;
    if (value % fm === 0) {
      newValue[0] = parseInt(`${value / fm}`, 10) + '';
    } else {
      newValue[0] = parseFloat(`${value / fm}`).toFixed(2) + '';
    }
    newValue[1] = text1;
  } else if (num <= 16) { // 亿
    text1 = (num - 8) / 3 > 1 ? '千亿' : '亿';
    text1 = (num - 8) / 4 > 1 ? '万亿' : text1;
    text1 = (num - 8) / 7 > 1 ? '千万亿' : text1;
    fm = 1;
    if (text1 === '亿') {
      fm = 100000000;
    } else if (text1 === '千亿') {
      fm = 100000000000;
    } else if (text1 === '万亿') {
      fm = 1000000000000;
    } else if (text1 === '千万亿') {
      fm = 1000000000000000;
    }
    if (value % fm === 0) {
      newValue[0] = parseInt(`${value / fm}`, 10) + '';
    } else {
      newValue[0] = parseFloat(`${value / fm}`).toFixed(2) + '';
    }
    newValue[1] = text1;
  }
  if (value < 1000) {
    newValue[0] = value + '';
    newValue[1] = '';
  }
  return newValue.join('');
}

/**
 * 返回 Max(today, fromDate) 的下一个 星期 weekday 的日期的 format 格式
 *
 * 如果当下日期和结果相同，则后延一周
 *
 * @param weekday 星期
 * @param fromDate 开始日期，若小于当下日期。则为当下日期
 * @param format 格式化规则， 默认 YYYY-MM-DD HH:mm:ss
 * @returns
 */
export const getNextWeekDayDate = (
  weekday: number,
  fromDate?: string | number | Date,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  const todayTime = new Date().getTime();
  const fromDateTime = fromDate ? new Date(fromDate).getTime() : todayTime;
  const realFromDate = new Date(Math.max(todayTime, fromDateTime));
  const realFromDateDay = moment(realFromDate).format(format);
  const nextWeekDay = moment(realFromDate).day(weekday).format(format);
  const nextNextWeekDay = moment(realFromDate).day(weekday + 7).format(format);
  return realFromDateDay === nextWeekDay ? nextNextWeekDay : nextWeekDay;
};

/**
 * 获得字符串实际长度，中文2，英文1
 * @param str 要获得长度的字符串
 * @returns
 */
export const getStringLength = function (str: string) {
  if (!str) {
    return 0;
  }
  let realLength = 0;
  let charCode = -1;
  const len = str.length;
  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    realLength += charCode >= 0 && charCode <= 128 ? 1 : 2;
  }
  return realLength;
};


/**
 * js截取字符串，中英文都能用
 * 如果给定的字符串大于指定长度，截取指定长度并追加后缀返回，否者返回源字符串
 * @param str：需要截取的字符串
 * @param len: 需要截取的长度
 * @param suffix 后缀 默认 ...
 * @returns
 */
export function subString(str: string, len: number, suffix = '...') {
  if (getStringLength(str) <= len) {
    return str;
  }
  const strArr = str.split('');
  let realLength = 0;
  let charCode = -1;
  let realStr = '';
  for (let i = 0; realLength <= len; i++) {
    if (i >= strArr.length) {
      break;
    }
    charCode = str.charCodeAt(i);
    realStr += strArr[i];
    realLength += charCode >= 0 && charCode <= 128 ? 1 : 2;
  }
  return `${realStr}${suffix}`;
}

/**
 * 拼接全名
 * @param user { name }
 * @returns
 */
export function fullname(user?: {
  name?: string,
  username?: string,
  firstname?: string,
  lastname?: string,
  nickname?: string,
}) {
  return user ? user.name || user.username || `${user.firstname || ''} ${user.lastname || ''}`.trim() || user.nickname || '' : '';
}

export const fileSize = (size: number) => {
  if (size >= 1024 ** 4) {
    return `${Math.ceil(size / 1024 ** 4)}TB`;
  }
  if (size >= 1024 ** 3) {
    return `${Math.ceil(size / 1024 ** 3)}GB`;
  }
  if (size >= 1024 ** 2) {
    return `${Math.ceil(size / 1024 ** 2)}MB`;
  }
  if (size >= 1024) {
    return `${Math.ceil(size / 1024)}KB`;
  }
  return `${size}B`;
};

export class QJStorage {

  /**
   * localStorage
   */
  public static getStorage<T = Record<string, any>>(key: string, storage: Storage = localStorage) {
    const ls = storage;
    if (!ls) {
      return undefined;
    }

    let v = ls.getItem(key);
    if (!v) {
      return undefined;
    }

    if (v.indexOf('obj-') === 0) {
      v = v.slice(4);
      return JSON.parse(v) as T;
    }

    if (v.indexOf('str-') === 0) {
      return v.slice(4);
    }

    return undefined;
  }

  public static setStorage(key: string, value: any, storage: Storage = localStorage) {
    if ([2, 3].includes(arguments.length)) {
      let v = value;
      if (typeof v === 'object') {
        v = JSON.stringify(v);
        v = 'obj-' + v;
      } else {
        v = 'str-' + v;
      }
      const ls = storage;
      if (ls) {
        ls.setItem(key, v);
      }
    }
  }

  public static removeStorage(key: string, storage: Storage = localStorage) {
    const ls = storage;
    if (ls && key) {
      ls.removeItem(key);
    }
  }

  public static clearStorage(storage: Storage = localStorage) {
    const ls = storage;
    if (ls) {
      ls.clear();
    }
  }

  /**
   * sessionStorage
   */
  public static getSession<T = Record<string, any>>(key: string) {
    return this.getStorage<T>(key, sessionStorage);
  }

  public static setSession(key: string, value: any) {
    this.setStorage(key, value, sessionStorage);
  }

  public static removeSession(key: string) {
    this.removeStorage(key, sessionStorage);
  }

  public static clearSession() {
    this.clearStorage(sessionStorage);
  }
}

export function injectGlobalConst<C, T>(schema: IRootSchema<C>, constants: T): IRootSchema<(C & T) | T> {
  return {
    ...schema,
    globalConfig: {
      ...schema.globalConfig,
      constants: {
        ...schema.globalConfig?.constants,
        ...constants,
      },
    },
  };
}

/**
 * 移除对象上的空字段，默认只移除顶层空字段，不支持数组
 * @param data 要移除空字段的对象
 * @param deep 移除嵌套对象的空字段，默认 false
 * @param emptyArray 【空】的定义数组 默认 ['', undefined, null]
 * @returns 移除空字段后的对象
 */
export function removeEmptyField(data?: Record<string, any>, deep = false, emptyArray = ['', undefined, null]) {
  if (!data) {
    return {};
  }
  return Object.keys(data).reduce((res, key) => {
    if (data[key] && typeof data[key] === 'object' && !Array.isArray(data[key]) && deep) {
      res[key] = removeEmptyField(data[key]);
    } else if (!emptyArray.includes(data[key])) {
      res[key] = data[key];
    }
    return res;
  }, {} as Record<string, any>);
}

export const isJSONString = (text: string) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    const obj = JSON.parse(text);
    return !!(typeof obj === 'object' && obj);
  } catch {
    return false;
  }
};


/**
 * 解析 JSON 字符串
 * @param text 要解析 json 的字段串
 * @param reviver
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export const JSONParse = <T = any>(text?: string, reviver?: (key: string, value: any) => any): T | null => {
  if (!text) {
    return null;
  }
  try {
    let res = JSON.parse(text, reviver);
    while (isJSONString(res)) {
      res = JSON.parse(res, reviver);
    }
    return res;
  } catch (error) {
    Notification.error({ title: 'json 解析出错', message: `原始 json: ${text}` });
    return null;
  }
};


export const safeCallback = <ArgsType extends unknown[], ReturnsTyps = void>(
  // eslint-disable-next-line no-unused-vars
  callback?: (...args: ArgsType) => ReturnsTyps | void,
  args?: ArgsType,
): ReturnsTyps | void => {
  return typeof callback === 'function'
    ? callback.apply(null, args!)
    : undefined;
};

export const isObject = (val: any) => typeof val === 'object' && val !== null;
export const isFunction = (val: any) => typeof val === 'function';
export const isPromise = (val: any) => {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch);
};

/**
 * 格式化日期时间
 * @param fromDate 日期时间
 * @param format 格式化规则， 默认 YYYY-MM-DD HH:mm:ss
 */
export const DateTimeFormat = (
  dateTime: string | number | Date,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  return moment(new Date(dateTime)).format(format);
};


/**
 * 格式化秒
 * @param int  value 总秒数
 * @return string result 格式化后的字符串
 */
export function formatSeconds(value?: number | string) {
  if (!value) {
    return '0秒';
  }

  let theTime = parseInt(String(value), 10);
  let theTime1 = 0; // 分
  let theTime2 = 0; // 小时
  let theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(String(theTime / 60), 10);
    theTime = parseInt(String(theTime % 60), 10);
    if (theTime1 > 60) {
      theTime2 = parseInt(String(theTime1 / 60), 10);
      theTime1 = parseInt(String(theTime1 % 60), 10);
      if (theTime2 > 24) {
        // 大于24小时
        theTime3 = parseInt(String(theTime2 / 24), 10);
        theTime2 = parseInt(String(theTime2 % 24), 10);
      }
    }
  }
  let result = '';
  if (theTime > 0) {
    result = `${parseInt(String(theTime), 10)}秒`;
  }
  if (theTime1 > 0) {
    result = `${parseInt(String(theTime1), 10)}分${result}`;
  }
  if (theTime2 > 0) {
    result = `${parseInt(String(theTime2), 10)}小时${result}`;
  }
  if (theTime3 > 0) {
    result = `${parseInt(String(theTime3), 10)}天${result}`;
  }
  return result;
}

export const getQueryString = (name: string) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substring(1).match(reg);
  if (r !== null) {
    return decodeURI(r[2]);
  }
  return null;
};

export const qsParse = (str: string, name: string) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = (str.startsWith('?') ? str.substring(1) : str).match(reg);
  if (r !== null) {
    return decodeURI(r[2]);
  }
  return null;
};

/**
 * 是否是直辖市 municipality directly under the Central Government
 */
export const isMDUCG = (jobInfo: {
  officeProvince?: string;
  officeCity?: string;
  [k: string]: any;
}) => {
  return ['北京', '天津', '上海', '重庆'].some(e =>
    jobInfo.officeProvince?.includes(e) || jobInfo.officeCity?.includes(e),
  );
};

export const fullAddress = (jobInfo: {
  officeProvince?: string;
  officeCity?: string;
  officeDistrict?: string;
  officeStreet?: string;
  officeAddress?: string;
  [k: string]: any;
}) => {
  const {
    officeProvince = '',
    officeCity = '',
    officeDistrict = '',
    officeStreet = '',
    officeAddress = '',
  } = jobInfo;
  return isMDUCG(jobInfo)
    ? `${officeCity}${officeDistrict}${officeStreet}${officeAddress}`
    : `${officeProvince}${officeCity}${officeDistrict}${officeStreet}${officeAddress}`;
};


export function isMobile() {
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /(Android)/i.test(navigator.userAgent);
}

export function isIOS() {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
}

export function isDingTalk() {
  return /(AliApp|DingTalk|com.alibaba.android.rimet|com.laiwang.DingTalk)/i.test(navigator.userAgent);
}

export function isWeChat() {
  return /(MicroMessenger|XWEB|MMWEBSDK|MMWEBID|WeChat|WeiXin)/i.test(navigator.userAgent);
}

export function getSite() {
  const isBServer = location.hostname.includes('b.reta-inc.com');
  if (isBServer) {
    return 'B';
  }
  return 'A';
}

export function getPlatform() {

  if (isDingTalk()) {
    return 'DINGTALK';
  }

  if (isWeChat()) {
    return 'WECHAT';
  }

  if (isAndroid()) {
    return 'ANDROID';
  }

  if (isIOS()) {
    return 'IOS';
  }

  return 'PC';
}

export function getPlatformAndSite() {
  if (isDingTalk()) {
    return 'SSO_DINGTALK';
  }
  return `${getSite()}_WEB_${getPlatform()}`;
}

export function getUA() {
  const clientName = 'JK-Client';
  const clientVersion = '1.0';
  const originalUA = navigator.userAgent;
  return originalUA.includes(clientName)
    ? originalUA
    : `${navigator.userAgent} ${clientName}/${clientVersion}`;
}

export function calcFileMD5(
  file: File,
  success?: (fileMD5: string) => void,
  error?: (...error: any[]) => void,
) {
  // @ts-ignore
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

  const chunkSize = 2097152;
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const spark = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  function loadNext() {
    const start = currentChunk * chunkSize;
    const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  }

  fileReader.onload = function (e: ProgressEvent<FileReader>) {
    // console.log('read chunk nr', currentChunk + 1, 'of', chunks);
    spark.append(e.target!.result as ArrayBuffer);
    currentChunk++;
    if (currentChunk < chunks) {
      loadNext();
    } else {
      const md5 = spark.end();
      // console.log('finished loading');
      typeof success === 'function' && success(md5);
      // console.info('computed hash', md5);
    }
  };

  fileReader.onerror = function () {
    console.warn('oops, something went wrong.');
    typeof error === 'function' && error(...arguments);
  };

  loadNext();
}

export function calcFileMD5Promise(file: File) {
  return new Promise<string>((resolve, reject) => {
    calcFileMD5(file, resolve, reject);
  });
}


export interface IUploadClientOptions {
  scene: string,
  filename: string,
  file: File,
  multipartUpload?: boolean,
  isVideo?: boolean;
  ajaxConfig?: CustomAxiosRequestConfig;
}
export class UploadClient {
  options: IUploadClientOptions
  uploadResult: any | null = null;
  postUploadResult: IPostUplaodData | null = null;
  abortCheckpoint: any = 0;
  client: any = null;
  objectName: string = '';
  mediaId: number | null = null;
  need: boolean = true;
  constructor(options: IUploadClientOptions) {
    this.options = options;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onProgress(_p: number, _cpt?: Record<string, any>, _res?: Record<string, any>) { }

  onProgressWrap(p: number, cpt: Record<string, any>, res: Record<string, any>) {
    this.abortCheckpoint = cpt;
    safeCallback(this.onProgress, [p, cpt, res]);
  }

  async preUpload() {
    const { scene, file, filename, isVideo, ajaxConfig } = this.options;
    const md5 = await calcFileMD5Promise(file);
    const { data } = await (isVideo ? preUploadVideo : preUpload)(scene, filename, md5, ajaxConfig);
    this.need = data.need;
    this.objectName = data.objectName;
    this.mediaId = data.mediaId;

    if (!this.need) {
      // 之前已经上传过同样的文件，无需上传文件
      if (!isVideo && this.mediaId) {
        const { data } = await postUpload(this.mediaId, ajaxConfig);
        this.postUploadResult = data;
      }
      return {
        client: this.client,
        mediaId: this.mediaId,
        uploadResult: this.uploadResult,
        postUploadResult: this.postUploadResult,
      };
    }

    this.client = await createOSSClient(data);

    return {
      client: this.client,
    };
  }

  async upload() {
    const { file, isVideo, ajaxConfig } = this.options;
    const preUploadData = await this.preUpload();

    if (!this.need) {
      return preUploadData;
    }

    if (!this.client) {
      return {};
    }

    if (this.options.multipartUpload) {
      this.uploadResult = await this.client.multipartUpload(
        this.objectName,
        file,
        {
          progress: this.onProgress,
          checkpoint: this.abortCheckpoint,
        },
      );
    } else {
      this.uploadResult = await this.client.put(this.objectName, file);
    }

    if (!isVideo && this.mediaId) {
      const { data } = await postUpload(this.mediaId, ajaxConfig);
      this.postUploadResult = data;
    }

    return {
      client: this.client,
      mediaId: this.mediaId,
      uploadResult: this.uploadResult,
      postUploadResult: this.postUploadResult,
    };
  }

  pause() {
    // eslint-disable-next-line no-unused-expressions
    this.client?.cancel();
  }

  async resumeUpload() {
    const { file } = this.options;
    this.uploadResult = await this.client.multipartUpload(this.objectName, file, {
      checkpoint: this.abortCheckpoint,
      progress: this.onProgress,
    });
    return {
      client: this.client,
      mediaId: this.mediaId,
      uploadResult: this.uploadResult,
    };
  }
}

export function filterBy<T extends Record<string, any>>(array: (T | null)[], filterByField: keyof T) {
  return [...new Set(array.map(e => e?.[filterByField]))].reduce((total: T[], fieldValue, index) => {
    total[index] = array.find(e => e?.[filterByField] === fieldValue) as T;
    return total;
  }, [] as T[]);
}

export const tuple = <T extends string[]>(...args: T) => args;
export const tupleNum = <T extends number[]>(...args: T) => args;

export function copyText(str: string) {
  return new Promise(resolve => {
    const input = document.createElement('input');
    input.type = 'text';
    input.style.position = 'absolute';
    input.style.top = '-1000px';
    document.body.appendChild(input);
    input.value = str;
    input.select();
    document.execCommand('copy');
    input.remove();
    resolve('复制成功！');
  });
}

export function fromShare() {
  return ['share', 'runas-share'].includes(globalConfig.current.runAsInfo.type || '');
}

export function Encrypt(str: string, secretkey = 'reta-inc') {
  return CryptoJS.AES.encrypt(str, secretkey).toString();
}

export function Decrypt(str: string, secretkey = 'reta-inc') {
  const bytes = CryptoJS.AES.decrypt(str, secretkey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function getInfoFromAllStorage<T>(key: string) {
  return QJStorage.getSession<T>(key) || QJStorage.getStorage<T>(key);
}

export function removeAllStorageBykey(key: string) {
  QJStorage.removeSession(key);
  QJStorage.removeStorage(key);
}

export function resetRunAsInfo() {
  globalConfig.current.runAsInfo = {
    targetUid: null as (string | number | null),
    targetName: null as (string | number | null),
    token: null as (string | number | null),
    type: null as 'runas' | 'share' | 'runas-share' | null,
  };
}

export function EncryptWithSeed(str: string) {
  const { cryptoOptions: { iv, key } } = globalConfig.current;
  const options = {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
  const encrypted = CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(key), options).ciphertext;
  return CryptoJS.enc.Base64.stringify(encrypted);
}

// export function DecryptWithSeed(str: string) {
//   const { cryptoOptions: { iv, key } } = globalConfig.current;
//   const options = {
//     iv: CryptoJS.enc.Utf8.parse(iv),
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   };
//   const realStr = CryptoJS.enc.Base64.parse(str).toString(CryptoJS.enc.Utf8);
//   return CryptoJS.AES.decrypt(realStr, CryptoJS.enc.Utf8.parse(key), options).toString(CryptoJS.enc.Utf8);
// }

export class FEPagination<T = any> {
  total: number = 0;
  lastPage: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  hasNextPage: boolean = true;
  hasPrevPage: boolean = false;
  loadAll: boolean = false;
  isFirstPage: boolean = true;
  isLastPage: boolean = false;
  totalData: T[] = [];
  currentPageData: T[] = [];
  currentAllPageList: T[] = [];
  innerIndex: number = 0;

  constructor(totalData: T[], pageNum = 1, pageSize = 10) {
    this.totalData = totalData;
    this.total = totalData.length;
    this.getList(pageNum, pageSize);
  }

  getCurrentPage(pageNum = 1, pageSize = 10) {
    const startIndex = (pageNum - 1) * pageSize;
    const lastPage = Math.ceil(this.total / pageSize);
    return {
      list: this.totalData.slice(startIndex, startIndex + pageSize),
      lastPage,
      isFirstPage: pageNum === 1,
      isLastPage: pageNum === lastPage,
      hasPrevPage: pageNum > 1,
      hasNextPage: pageNum < lastPage,
      loadAll: pageNum === lastPage,
    };
  }

  getList(pageNum = 1, pageSize = 10) {
    if (this.total > 0) {
      const {
        list,
        lastPage,
        isFirstPage,
        isLastPage,
        hasPrevPage,
        hasNextPage,
        loadAll,
      } = this.getCurrentPage(pageNum, pageSize);
      this.currentPageData = list;
      this.lastPage = lastPage;
      this.isFirstPage = isFirstPage;
      this.isLastPage = isLastPage;
      this.hasPrevPage = hasPrevPage;
      this.hasNextPage = hasNextPage;
      this.loadAll = loadAll;
    } else {
      this.currentPageData = [];
      this.isFirstPage = true;
      this.isLastPage = true;
      this.hasPrevPage = false;
      this.hasNextPage = false;
      this.loadAll = true;
    }
    return this.currentPageData;
  }

  loadMore(pageSize = 10) {
    let pageInfo = {
      singlePageList: [] as T[],
      currentAllPageList: [] as T[],
      isFirstPage: true,
      isLastPage: true,
      hasPrevPage: false,
      hasNextPage: false,
      loadAll: true,
    };
    if (this.total > 0) {
      this.innerIndex++;
      const { list, ...others } = this.getCurrentPage(this.innerIndex, pageSize);
      this.currentAllPageList = [
        ...this.currentAllPageList,
        ...list,
      ];
      pageInfo = {
        ...others,
        singlePageList: list,
        currentAllPageList: this.currentAllPageList,
      };
    }
    return pageInfo;
  }

  loadMoreReset(pageSize = 10) {
    this.innerIndex = 0;
    this.currentAllPageList = [];
    return this.loadMore(pageSize);
  }

  static create<T = any>(totalData: T[], pageNum = 1, pageSize = 10) {
    return new FEPagination<T>(totalData, pageNum, pageSize);
  }
}
