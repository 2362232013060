import Vue from 'vue';
import { Module } from 'vuex';
import { IRootState } from '../root';
import {
  getEnterpriseQuestionsList,
  saveEnterpriseQuestion,
  deleteEnterpriseQuestion,
} from '@/common/service';
import { ISearchEnterpriseQuestion } from '@/common/types';
import { IEnterpriseQuestion, IEnterpriseQuestionDeleteData } from '@/model/enterprise-question';


export interface IEnterpriseQuestionBankModule {
  enterpriseQuestionsList: {
    commonSkillsList: any,
    professionalSkillsList: any,
    othersList: any,
  },
}

export interface IEnterpriseQuestionData {
  questionType: keyof IEnterpriseQuestionBankModule['enterpriseQuestionsList'],
  enterpriseQuestionsData: any,
}

const types = {
  FETCH_ENTERPRISE_QUESTIONS: 'getEnterpriseQuestionsList',
};

const getQuestionTypeMapping = (questionType: string) => {
  if (questionType === 'common') {
    return 'commonSkillsList';
  }
  if (questionType === 'professional') {
    return 'professionalSkillsList';
  }
  return 'othersList';
};

const enterpriseQuestionBankModule: Module<IEnterpriseQuestionBankModule, IRootState> = {
  namespaced: true,
  state: {
    enterpriseQuestionsList: {
      commonSkillsList: {},
      professionalSkillsList: {},
      othersList: {},
    },
  },
  getters: {},
  mutations: {
    getEnterpriseQuestionsList(state, data:IEnterpriseQuestionData) {
      Vue.set(state.enterpriseQuestionsList, getQuestionTypeMapping(data.questionType), data.enterpriseQuestionsData);
    },
  },
  actions: {
    async getEnterpriseQuestionsList({ commit, dispatch }, options: ISearchEnterpriseQuestion) {
      dispatch('wait/start', `enterpriseQuestions.getList.${options.questionType}`, { root: true });
      const res = await getEnterpriseQuestionsList(options);
      dispatch('wait/end', `enterpriseQuestions.getList.${options.questionType}`, { root: true });
      const questionType = options.questionType;
      if (res.success) {
        const { data } = res;
        commit(types.FETCH_ENTERPRISE_QUESTIONS, { questionType, enterpriseQuestionsData: data });
      }
      return res;
    },
    async saveEnterpriseQuestion(_, options: IEnterpriseQuestion) {
      const res = await saveEnterpriseQuestion(options);
      return res;
    },
    async deleteEnterpriseQuestion(_, options: IEnterpriseQuestionDeleteData) {
      const res = await deleteEnterpriseQuestion(options);
      return res;
    },
  },
};

export default enterpriseQuestionBankModule;
