




























































import { IEnterpriseQuestionCategory, IEnterpriseQuestionListData } from '@/model/enterprise-question';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import enterpriseVideoQuestionSchema from '@/common/schema/enterprise-video-question.json';
import enterpriseTextVideoQuestionSchema from '@/common/schema/enterprise-text-video-question.json';
import enterpriseWhiteBoardQuestionSchema from '@/common/schema/enterprise-white-board-question.json';
import enterpriseCodingQuestionSchema from '@/common/schema/enterprise-coding-question.json';
import { IMedia } from '@/model/resource';
const EnterpriseQuestionBankModule = namespace('enterprise/enterpriseQuestionBank');

const initFormData = (initData?: Partial<IEnterpriseQuestionListData>) => {
  const formData = {
    title: '',
    questionStyle: 2,
    answerStyle: 2,
    categories: [],
    questionType: null,
    tags: [],
    languageTag: [],
    mediaId: null,
    minDuration: 0,
    duration: 300,
    content: '',
    tips: '',
    isPublish: true,
    ...initData,
  };
  if (initData && initData.questionType) {
    formData.questionType = (initData.questionType as IEnterpriseQuestionCategory).code;
  }
  if (initData && initData.medias && initData.medias.length) {
    formData.mediaId = (initData.medias as IMedia<string>[])[0].mediaId;
  }
  return formData;
};

@Component
export default class EditQuestion extends Vue {
  formName = 'enterpriseQuestionFrom';
  formSchema = {};
  enterpriseVideoQuestionSchema = enterpriseVideoQuestionSchema;
  enterpriseTextVideoQuestionSchema = enterpriseTextVideoQuestionSchema;
  enterpriseWhiteBoardQuestionSchema = enterpriseWhiteBoardQuestionSchema;
  enterpriseCodingQuestionSchema = enterpriseCodingQuestionSchema;

  @Prop({ type: String, default: '' }) buttonType!: string;
  @Prop({ type: String, default: '' }) buttonText!: string;
  @Prop({ type: Number, default: 1 }) libraryType!: number;
  @Prop({ type: Number, default: 1 }) currentCompanyId!: number;
  @Prop({ type: Object }) question!: IEnterpriseQuestionListData;


  @EnterpriseQuestionBankModule.Action('saveEnterpriseQuestion')
  saveEnterpriseQuestionAction!: any;

  @EnterpriseQuestionBankModule.Action('deleteEnterpriseQuestion')
  deleteEnterpriseQuestionAction!: any;

  showDrawer = false;
  questionForm = initFormData({});
  $ncformReset: any;
  $ncformValidate: any;

  getEnterpriseQuestionSchema(questionStyle: number) {
    if (questionStyle === 2) {
      return enterpriseVideoQuestionSchema;
    } if (questionStyle === 4) {
      return enterpriseTextVideoQuestionSchema;
    } if (questionStyle === 3) {
      return enterpriseWhiteBoardQuestionSchema;
    } if (questionStyle === 8) {
      return enterpriseCodingQuestionSchema;
    }
    return enterpriseVideoQuestionSchema;
  }

  @Watch('question')
  onQuestionChange(question: Partial<IEnterpriseQuestionListData>|undefined) {
    this.questionForm = initFormData({ ...question });
  }

  onClickEditButton() {
    this.formSchema = this.getEnterpriseQuestionSchema(this.questionForm.questionStyle);
    this.showDrawer = true;
  }

  onClickVideoButton() {
    this.formSchema = this.enterpriseVideoQuestionSchema;
    this.showDrawer = true;
    this.questionForm.questionStyle = 2;
    this.questionForm.answerStyle = 2;
  }

  onClickTextVideoButton() {
    this.formSchema = this.enterpriseTextVideoQuestionSchema;
    this.showDrawer = true;
    this.questionForm.questionStyle = 4;
    this.questionForm.answerStyle = 2;
  }

  onClickWhiteBoardButton() {
    this.formSchema = this.enterpriseWhiteBoardQuestionSchema;
    this.showDrawer = true;
    this.questionForm.questionStyle = 3;
    this.questionForm.answerStyle = 3;
  }

  onClickCodingQuestionButton() {
    this.formSchema = this.enterpriseCodingQuestionSchema;
    this.showDrawer = true;
    this.questionForm.questionStyle = 8;
    this.questionForm.answerStyle = 4;
    this.questionForm.duration = 600;
  }

  async submit() {
    this.$ncformValidate(this.formName).then(async (data: { result: any; }) => {
      if (data.result) {
        const saveQuestionPayload = {
          ...this.question,
          ...this.questionForm,
          ...{
            companyId: this.currentCompanyId,
            description: '',
            optional: false,
            libraryType: this.libraryType,
          },
        };
        if (!saveQuestionPayload.questionId) {
          saveQuestionPayload.minDuration = 0;
        }
        await this.saveEnterpriseQuestionAction(saveQuestionPayload);
        this.questionForm = initFormData({});
        this.showDrawer = false;
        this.$emit('close', saveQuestionPayload.questionType);
      }
    });
  }

  async deleteQuestion() {
    this.$confirm('确认删除？')
      .then(async () => {
        const res = await this.deleteEnterpriseQuestionAction({
          questionIds: [this.question.questionId],
          companyId: this.currentCompanyId,
        });
        if (res.success) {
          this.questionForm = initFormData({});
          this.showDrawer = false;
          this.$emit('close', (this.question.questionType as IEnterpriseQuestionCategory).code);
        } else {
          this.$notify.error('删除企业试题失败！');
        }
      })
      .catch(() => {});
  }

  get inFunc() {
    // @ts-ignore
    const func = e => {
      const res = Object.assign({}, e);
      if (e.medias && e.medias.length > 0 && e.medias[0] && e.medias[0].data) {
        const data = JSON.parse(e.medias[0].data);
        res.mediaId = data.url ? { url: data.url, mediaId: e.medias[0].mediaId } : e.medias[0].mediaId;
        const languageTag = Array.isArray(e.languageTag) ? e.languageTag[0] : null;
        res.languageTag = [ languageTag ];
      }
      return res;
    };
    return func.toString();
  }

  get outFunc() {
    // @ts-ignore
    const func = e => {
      // @ts-ignore
      const categories = (e.categories || []).map(item => item.code || item);
      const mediaId = e.mediaId && e.mediaId.mediaId || e.mediaId;
      const res = Object.assign({}, e, {
        mediaId: mediaId,
        medias: mediaId ? [mediaId] : [],
        languageTag: Array.isArray(e.languageTag) && e.languageTag.length > 0 ? e.languageTag[0] : null,
        categories: categories,
        isPublish: true,
      });
      return res;
    };
    return func.toString();
  }

  handleClose(done: any) {
    this.$confirm('确认关闭？')
      .then(() => {
        done();
      })
      .catch(() => {});
  }

  created() {
    if (this.question) {
      this.questionForm = initFormData({ ...this.question });
    }
  }
}
