import Vue from 'vue';
import checkLogin from '@/common/auth';
import Router, { RawLocation, RouteConfig } from 'vue-router';
import { globalConfig } from '@w-admin/common';
import appMenu, { enterpriseDetails, auditDetail } from './menus';

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

Object.defineProperty(Router.prototype, 'push', {
  value: function (location: RawLocation) {
    // @ts-ignore
    return originalPush.call(this, location).catch(err => err);
  },
});

Object.defineProperty(Router.prototype, 'replace', {
  value: function (location: RawLocation) {
    // @ts-ignore
    return originalReplace.call(this, location).catch(err => err);
  },
});

Vue.use(Router);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页 | 抢镜内部运营平台',
      requiresAuth: true,
    },
    redirect: {
      name: 'enterprise-management',
    },
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout/index.vue'),
    children: [...appMenu, ...enterpriseDetails, ...auditDetail],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录 | 抢镜内部运营平台',
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta?.title || globalConfig.current.defaultTitle;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!checkLogin()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.name === 'login' && checkLogin()) {
    next({ path: from.fullPath });
  } else {
    next();
  }
});

export const authFailCallBack = () => {
  router.replace({ name: 'login' });
};

export default router;
