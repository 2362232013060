import { render, staticRenderFns } from "./userinfo-drawer.vue?vue&type=template&id=0b6a2fa9&scoped=true&"
import script from "./userinfo-drawer.vue?vue&type=script&lang=ts&"
export * from "./userinfo-drawer.vue?vue&type=script&lang=ts&"
import style0 from "./userinfo-drawer.vue?vue&type=style&index=0&id=0b6a2fa9&scoped=true&lang=less&"
import style1 from "./userinfo-drawer.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6a2fa9",
  null
  
)

export default component.exports