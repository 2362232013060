import { ajax } from '@w-admin/common';
import {
  AjaxResponse,
  OSSAuth,
  IPageParams,
  IPagination,
  ISearchEnterprise,
  ISearchAccount,
  ISearchEnterpriseQuestion,
  IAccountExist,
  ISearchEnterpriseVideo,
  ISearchCategoryTree,
  IRegisterOptions,
  TAuditType,
  IGetContentAuditListOptions,
  IContentAuditListDataWithPagination,
  IContentAuditSubmitOption,
  ILoginRes,
  IBatchImportResponse,
  IReadResumeResult,
} from '@/common/types';

import { IAccount, IAccountForBind, IAccountForSave, IAccountListData, IInterviewersForSave, IPublisherForSave } from '@/model/account';
import { ICategoryNode } from '@/model/category';

import { IEnterprise, IEnterpriseListData } from '@/model/enterprise';

import { IEnterpriseQuestion, IEnterpriseQuestionDeleteData, IEnterpriseQuestionListData } from '@/model/enterprise-question';
import { IEnterpriseVideo, IEnterpriseVideoDeleteData, IEnterpriseVideoListData } from '@/model/enterprise-video';
import { IJob, IJobForSave } from '@/model/job';
import { IAuditList, IAuditDetail } from '@/model/audit';
import { IBaseUserInfo } from '@/model/user';
import { IContentAudit } from '@/model/content-audit';
import store from '@/pages/home/store';
import { IListRequest, IListResponse } from './apis/list/type';

export async function readResume(params: { platform?: boolean; name: string; rawMediaId: number; format: string; parse: boolean }) {
  return await ajax.post<IReadResumeResult>('/wt/v1/cv/upload', { ...params });
}

export async function fetchFileAuth(resourceType: string): Promise<AjaxResponse<OSSAuth>> {
  return await ajax.post<OSSAuth>('/qj/v1/file/auth', { resourceType });
}

export async function register(options: IRegisterOptions) {
  return await ajax.post('/qj/v1/user/register/email', options);
}

export async function login(options: { email: string; password: string }) {
  return await ajax.post<ILoginRes>('/qj/v1/auth/token/login/email', options);
}

export async function getEnterpriseList(options: IPageParams) {
  return await ajax.get<IPagination<IEnterpriseListData>>('/admin/v1/company/list', options);
}

export async function enterpriseDetails(companyId: number) {
  return await ajax.get<IEnterprise>(`/admin/v1/company/detail?companyId=${companyId}`);
}

export async function searchEnterprise(options: ISearchEnterprise) {
  const data = {
    ...options,
    searchConditionName: options.argsName,
    searchText: options.argsValue,
  };
  return await ajax.get<IPagination<IEnterpriseListData>>('/admin/v1/company/search', data);
}

export const bacthImportEnterpriseUrl = '/admin/v1/company/batch';
export const jobImportUrl = '/admin/v1/job/import';

export async function bacthImportEnterprise(mediaId: number) {
  return await ajax.post<IBatchImportResponse>('/admin/v1/company/batch', { mediaId });
}

export async function jobImport(mediaId: number, companyId: number) {
  return await ajax.post('/admin/v1/job/import', { mediaId, companyId });
}

export async function addEnterpriseImg(companyId: number, imgType: 'LOGO' | 'LICENSE', mediaId: string) {
  return await ajax.post('/admin/v1/company/add-img', {
    companyId,
    imgType,
    imgId: mediaId,
  });
}

export async function getAccountList(options: ISearchAccount) {
  return await ajax.get<IPagination<IAccountListData>>('/admin/v1/user/list', options);
}

export async function searchAccountByMobile(mobile: string, companyId: number) {
  return await ajax.get<IAccountExist>('/admin/v1/user/exist', { mobile, companyId });
}

export async function bindAccount(account: IAccountForBind) {
  account.avatar = `${account.avatar}`;
  return await ajax.post('/admin/v1/user/bind', account);
}

export async function saveAccount(account: IAccountForSave) {
  account.avatar = `${account.avatar}`;
  return await ajax.post('/admin/v1/user/save', account);
}

export async function getInterviewerList(jobId: number, pageSize: number = 20, pageNo: number = 1) {
  return await ajax.get<IPagination<IBaseUserInfo>>('/admin/v1/job/addible-evaluators', {
    jobId,
    pageSize,
    pageNo,
  });
}

export async function addInterviewers(interviewers: IInterviewersForSave) {
  return await ajax.post('/admin/v1/job/add-evaluators', interviewers);
}

export async function setPublisher(publisher: IPublisherForSave) {
  return await ajax.post('/admin/v1/job/set-publisher', publisher);
}

export async function getAccountDetails(bid: number, companyId: number) {
  return await ajax.get<IAccount>('/admin/v1/user/detail', { bid, companyId });
}

export async function saveEnterpriseQuestion(options: IEnterpriseQuestion) {
  return await ajax.post('/admin/v1/question/save', options);
}

export async function deleteEnterpriseQuestion(options: IEnterpriseQuestionDeleteData) {
  return await ajax.post('/admin/v1/question/delete', options);
}

export async function getEnterpriseQuestionsList(options: ISearchEnterpriseQuestion) {
  return await ajax.get<IPagination<IEnterpriseQuestionListData>>('/admin/v1/question/list', options);
}

export async function modifyContact(bids: (number | null | undefined)[], deleteBids: (number | null | undefined)[], companyId: number) {
  return await ajax.post('/admin/v1/user/contact', {
    bids,
    deleteBids,
    companyId,
  });
}

export async function saveEnterpriseVideo(options: IEnterpriseVideo) {
  return await ajax.post('/admin/v1/bvideo/save', options);
}

export async function deleteEnterpriseVideo(options: IEnterpriseVideoDeleteData) {
  return await ajax.post('/admin/v1/bvideo/delete', options);
}

export async function getEnterpriseVideosList(options: ISearchEnterpriseVideo) {
  return await ajax.get<IPagination<IEnterpriseVideoListData<string>>>('/admin/v1/bvideo/list', options);
}

export async function getCategoryTree(options: ISearchCategoryTree) {
  return await ajax.get<ICategoryNode>('/admin/v1/category/tree', options);
}

// export async function getCategoryList(options: ISearchCategoryTree) {
//   return await ajax.get<ICategory[]>('/admin/v1/category/list', options);
// }

// export async function getTagList(options: ISearchCategoryTree) {
//   return await ajax.get<ICategory[]>('/admin/v1/tag/list', options);
// }

export async function getJobList(params: IListRequest) {
  return await ajax.get<IListResponse>('/admin/v1/job/list', params);
}

export async function getJobDetails(jobId: number) {
  return await ajax.get<IJob>('/admin/v1/job/detail', { jobId });
}

export async function saveJob(options: Partial<IJobForSave>) {
  return await ajax.post<{ jobId: number }>('/admin/v1/job/save', options);
}

export async function removeJob(jobId: number) {
  return await ajax.post('/admin/v1/job/delete', { jobId });
}

export async function getSnapshotIds(jobId: number) {
  return await ajax.get<IJob>('/admin/v1/question/snapshotIds', { jobId });
}

export async function getContentAuditTask(type: TAuditType, count: number) {
  return await ajax.post<{ count: number }>('/admin/v1/audit/interview/fetch', { type, count });
}

export async function returnContentAuditTask(type: TAuditType, interviewIds: number[]) {
  return await ajax.post<{ count: number }>('/admin/v1/audit/interview/return', { type, interviewIds });
}

export async function getAuditListCount(type: TAuditType) {
  return await ajax.get<{ totalCount: number }>('/admin/v1/audit/interview/waiting-count', { type });
}

export async function getRiskAuditList(options: IGetContentAuditListOptions) {
  return await ajax.get<IContentAuditListDataWithPagination>('/admin/v1/audit/interview/risk/list', options);
}

export async function getRiskAuditDetail(interviewId: number) {
  return await ajax.get<IContentAudit>('/admin/v1/audit/interview/risk/detail', { interviewId });
}

export async function submitRiskInterview(option: IContentAuditSubmitOption) {
  return await ajax.post<{ count: number }>('/admin/v1/audit/interview/risk/submit', option);
}

export async function interviewReject(interviewId: number, mediaIds: number[]) {
  return await ajax.post('/admin/v1/audit/interview/reshot', {
    interviewId,
    mediaIds,
  });
}

export async function interviewRejectByBusiness(interviewId: number, reason: string, answerIdList: number[]) {
  return await ajax.post('/admin/v1/operation/breshot', {
    interviewId,
    reason,
    answerIdList,
  });
}

export async function su(targetUid: number, companyId: number) {
  return await ajax.post<{ id: number }>('/qj/v1/user/su', {
    targetUid,
    companyId,
  });
}

// 给后端传递mediaId
export const setAdvertisingPic = (companyId: number, AdvertisingPic: number) =>
  ajax.post('/admin/v1/company/set-advertising-pic', { companyId, AdvertisingPic });

// 更新企业面邀类型
export const updateInterviewType = (companyId: number, interviewType: number) =>
  ajax.post('/admin/v1/company/set-interview-type', { companyId, interviewType });

export async function exitSu() {
  return await ajax.post('/qj/v1/user/exit-su');
}

export async function refreshTokan() {
  try {
    const { data } = await ajax.post<{ authToken: string }>(
      '/qj/v1/auth/token/refresh',
      {},
      {
        customErrorMsg: 'Token 刷新失败',
      },
    );
    store.dispatch('login.refreshTokan', data.authToken);
  } catch (error) {
    console.log('Token 刷新失败', error);
  }
}

export async function getAuditList(status: number) {
  return await ajax.get<IAuditList[]>('/admin/v1/audit-company-user/list', { status });
}

export async function getAuditDetail(id: number) {
  return await ajax.get<IAuditDetail>('/admin/v1/audit-company-user/detail', { id });
}

export async function saveAuditResult(params: { id: number; auditStatus: number; reason?: string }) {
  return await ajax.post('/admin/v1/audit-company-user/audit', params);
}

export async function addWhiteList(mobile: string) {
  return await ajax.post('/qj/v1/user/add/whitelist', { mobile });
}

export async function addUserRole(mobile: string, roles: string[]) {
  return await ajax.post('/qj/v1/user/add/roles', { mobile, roles });
}

export async function getTagList(typeCode: string) {
  return await ajax.get<{ id: number; name: string }[]>('/wt/v1/tag/list', { typeCode });
}

export async function saveRelation(params: { type: string; parent: string; children: string[] }) {
  return await ajax.post('/wt/v1/tag/add-relation', params);
}
