














































// import { postSubmitResume } from '@/common/apis/resume/resume';
import { readResume } from '@/common/service';
import CvList from '@/pages/home/views/enterprise-management/job-management/components/cv-list.vue';
import type { CvListItem } from '@/pages/home/views/enterprise-management/job-management/types';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CvList,
  },
})
export default class BulkUploadDrawer extends Vue {
  showUploadResumeDrawer = false
  activeName = 'resume-to-submitted';
  tabLoading = false
  tableData: CvListItem[] = [];
  count = 0
  multipleSelection:{
    cvSnapshotId: number;
    name: string;
    phone: string;
    email: string;
    status: number;
  }[] = [];

  $refs!:{
    file: HTMLInputElement;
  }

  created() {
    this.tableData = [];
  }

  get submitBtnDisabled() {
    return this.multipleSelection.length === 0
    || this.multipleSelection.some(el => el.status === 0 || el.status === 2)
    || this.multipleSelection.every(el => el.name === '-' || el.phone === '-' || !/^1[3456789]\d{9}$/.test(el.phone));
  }

  open() {
    this.showUploadResumeDrawer = true;
  }

  // 批量选择简历
  uploadCv() {
    const input = this.$refs.file;
    input.click();
  }

  // 批量解析简历
  async uploadResume(e: Event) {
    const target = e.target as EventTarget & { files: FileList };
    const files = Object.values(target?.files);
    let tableList = files.map(file => {
      return {
        id: String(++this.count),
        name: '-',
        phone: '-',
        email: '-',
        cv: '-',
        file: file,
        status: 0,
      };
    });
    // 过滤重复文件
    this.isRepeatUploadFile(files);
    tableList = tableList.filter(el => !this.tableData.some(item => item.cv === el.file.name));
    this.tableData.push(...tableList);
    // 请求解析文件
    for await (const item of tableList) {
      await this.onUploaded(item).catch(() => {});
    }
    this.$refs.file.value = '';
  }
  // 是否重复上传
  isRepeatUploadFile(files, id = '-1') {
    const { tableData } = this;
    const isRepeat = tableData.some(el => files.some(item => {
      if (id !== '-1') {
        return item.name === el.cv && el.id !== id;
      }
      return item.name === el.cv;
    }));
    if (isRepeat) {
      this.$notify({
        title: '注意',
        message: '存在相同文件，不能重复上传！',
        type: 'warning',
      });
    }
    return isRepeat;
  }
  // 批量上传解析
  onUploaded(item) {
    const { name } = item.file;
    const uploadClient = new this.$QJUtils.UploadClient({
      scene: 'CV',
      filename: name,
      file: item.file,
    });
    return new Promise((resolve, reject) => {
      uploadClient
        .upload()
        .then(res => {
          const { mediaId: rawMediaId, postUploadResult } = res;
          return readResume({
            name: name,
            rawMediaId: rawMediaId as number,
            format: postUploadResult?.format!,
            parse: true,
            platform: true,
          });
        })
        .then(res => {
          const { parserResult } = res.data;
          item.phone = parserResult.mobile || '-';
          item.email = parserResult.email || '-';
          item.name = parserResult.name || '-';
          item.url = res.data.downloadUrl;
          item.cv = item.file.name;
          item.cvSnapshotId = res.data.id;
          item.status = 1;
          resolve(res);
        })
        .catch(e => {
          console.error('【解析失败】', item.file.name, e);
          item.name = '-';
          item.phone = '-';
          item.email = '-';
          item.cv = item.file.name;
          item.status = 2;
          reject(e);
        });
    });
  }
  // 重新上传
  handlerReUpload({ row, file }) {
    if (this.isRepeatUploadFile([file], row.id)) {
      return;
    }
    row.status = 0;
    row.file = file;
    this.onUploaded(row);
  }
  // 删除
  handlerDeleteCv(row:any) {
    this.$confirm('<p style="font-size: 18px;font-weight: 600;">确认删除该条信息?</p>'
      + '<p style="font-size: 14px;font-weight: 400;margin-top:10px;">删除后不可恢复</p>', '', {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning',
      dangerouslyUseHTMLString: true,
    }).then(() => {
      const { tableData } = this;
      const index = tableData.findIndex(el => el.id === row.id);
      tableData.splice(index, 1);
      this.$message({
        type: 'success',
        message: '删除成功!',
      });
    }).catch(() => {
    });
  }
}
