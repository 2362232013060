


















































































































import { IEnterprise } from '@/model/enterprise';
import { IJob, IJobExamsForSave, IJobExamsForSaveItem } from '@/model/job';
import { IMedia, IVideoBaseInfo } from '@/model/resource';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EnterpriseInterviewerListForm from './enterprise-interviewer-list-form.vue';
import ExamFrame from './exam-frame.vue';
import JobFrame from './job-frame.vue';

@Component({
  components: {
    JobFrame,
    ExamFrame,
    EnterpriseInterviewerListForm,
  },
})
export default class JobFormStepQuestion extends Vue {
  @Prop() job?: IJob;
  @Prop({ required: true }) enterprise!: IEnterprise;

  jobExamsList: IJobExamsForSaveItem[] = [];
  intervieweListShow = false;
  currentIndex = 0;

  change(
    item: 'interviewerIntroItem' | 'interludeItem' | 'conclusionItem',
    {
      value,
      index,
    }: {
      value: {
        mediaIds: (number | null | undefined)[];
        medias: (IMedia<IVideoBaseInfo> | undefined)[];
      };
      index: number;
    },
  ) {
    this.$set(this.jobExamsList[index], item, {
      ...this.jobExamsList[index][item],
      ...value,
    });
  }

  parseMediaData(media?: IMedia<string>[]): IMedia<IVideoBaseInfo>[] {
    return (media || []).map(e => ({
      ...e,
      data: this.$JSONParse(e.data) as IVideoBaseInfo,
    }));
  }

  showAddInterviewerDrawer(index: number) {
    this.currentIndex = index;
    this.intervieweListShow = true;
  }

  closeAddInterviewerDrawer() {
    this.currentIndex = -1;
    this.intervieweListShow = false;
  }

  onInterviewerIdChange(id: number, user: any) {
    if (this.currentIndex >= 0) {
      this.$set(this.jobExamsList[this.currentIndex], 'interviewerInfoItem', [user]);
      this.$set(this.jobExamsList[this.currentIndex].jobExams.interviewer, 'interviewerId', id);
    }
    this.closeAddInterviewerDrawer();
  }

  genListByJob(job?: IJob): IJobExamsForSaveItem[] {
    return job && Array.isArray(job.exams) && job.exams.length
      ? job.exams.map(exam => {
        return {
          jobExams: {
            answerRequirement: {
              allowSkip: exam?.answerRequirement?.allowSkip || false,
              allowUseLocalVideo: exam?.answerRequirement?.allowUseLocalVideo || false,
              allowMultiAnswer: exam?.answerRequirement?.allowMultiAnswer || false,
            },
            round: exam?.round || 1,
            questionSnapshots: exam?.questionSnapshots?.map(e => ({
              ...e,
              minDuration: e?.minDuration,
              maxDuration: e?.maxDuration,
              snapshotId: e?.snapshotId,
              questionId: e?.questionId,
              attachmentRequirement:
                  typeof e?.attachmentRequirement === 'object' && e?.attachmentRequirement?.code
                    ? e?.attachmentRequirement?.code
                    : (e.attachmentRequirement as string),
              medias: this.parseMediaData(e.medias),
            })),
            interviewer: {
              interviewerId: exam?.interviewer?.userInfo?.id,
              selfIntroMediaId: exam?.interviewer?.selfIntroMedia?.mediaId,
              conclusionMediaId: exam?.interviewer?.conclusionMedia?.mediaId,
            },
            interludeMediaId: exam?.interludeMedia?.mediaId,
          },
          interviewerIntroItem: {
            type: this.$QJDict.jobExamType.InterviewerIntro.code,
            title: this.$QJDict.jobExamType.InterviewerIntro.title,
            medias: exam?.interviewer?.selfIntroMedia
              ? ([
                {
                  ...exam?.interviewer?.selfIntroMedia,
                  title: '面试官自我介绍',
                  data: this.$JSONParse(exam?.interviewer?.selfIntroMedia?.data),
                },
              ] as IMedia<IVideoBaseInfo>[])
              : [],
            mediaIds: [exam?.interviewer?.selfIntroMedia?.mediaId],
          },
          interludeItem: {
            type: this.$QJDict.jobExamType.Interlude.code,
            title: this.$QJDict.jobExamType.Interlude.title,
            medias: exam.interludeMedia
              ? ([
                {
                  ...exam.interludeMedia,
                  title: '过场视频',
                  data: this.$JSONParse(exam?.interludeMedia?.data),
                },
              ] as IMedia<IVideoBaseInfo>[])
              : [],
            mediaIds: exam?.interludeMedia?.mediaId ? [exam?.interludeMedia?.mediaId] : [],
          },
          conclusionItem: {
            type: this.$QJDict.jobExamType.Conclusion.code,
            title: this.$QJDict.jobExamType.Conclusion.title,
            medias: [
              {
                ...exam.interviewer?.conclusionMedia,
                title: '面试官结束语',
                data: this.$JSONParse(exam.interviewer?.conclusionMedia?.data),
              },
            ] as IMedia<IVideoBaseInfo>[],
            mediaIds: [exam?.interviewer?.conclusionMedia?.mediaId],
          },
          interviewerInfoItem: exam.interviewer.userInfo ? [exam.interviewer.userInfo] : [],
        };
      })
      : [
        {
          jobExams: {
            answerRequirement: {
              allowSkip: false,
              allowUseLocalVideo: false,
              allowMultiAnswer: false,
            },
            round: 1,
            questionSnapshots: [],
            interviewer: {
              interviewerId: 0,
              selfIntroMediaId: 0,
              conclusionMediaId: 0,
            },
            interludeMediaId: 0,
          },
          interviewerIntroItem: {
            type: this.$QJDict.jobExamType.InterviewerIntro.code,
            title: this.$QJDict.jobExamType.InterviewerIntro.title,
            medias: [] as (IMedia<IVideoBaseInfo> & { title: string })[],
            mediaIds: [],
          },
          interludeItem: {
            type: this.$QJDict.jobExamType.Interlude.code,
            title: this.$QJDict.jobExamType.Interlude.title,
            medias: [] as (IMedia<IVideoBaseInfo> & { title: string })[],
            mediaIds: [],
          },
          conclusionItem: {
            type: this.$QJDict.jobExamType.Conclusion.code,
            title: this.$QJDict.jobExamType.Conclusion.title,
            medias: [] as (IMedia<IVideoBaseInfo> & { title: string })[],
            mediaIds: [],
          },
          interviewerInfoItem: [],
        },
      ];
  }

  examsResult(jobExamsList: IJobExamsForSaveItem[]): IJobExamsForSave[] {
    return jobExamsList.map(exam => ({
      questionSnapshots: exam.jobExams.questionSnapshots,
      interviewer: {
        interviewerId: exam.jobExams.interviewer.interviewerId,
        selfIntroMediaId: exam.interviewerIntroItem.medias[0]?.mediaId,
        conclusionMediaId: exam.conclusionItem.medias[0]?.mediaId,
      },
      answerRequirement: exam.jobExams.answerRequirement,
      interludeMediaId: exam.interludeItem.medias[0]?.mediaId,
      round: exam.jobExams.round || 1,
    }));
  }

  @Watch('jobExamsList', { deep: true, immediate: true })
  onListChange(jobExamsList: IJobExamsForSaveItem[]) {
    const jobExamsForSave = this.examsResult(jobExamsList);
    this.$emit('examsChange', jobExamsForSave);
  }

  init() {
    this.jobExamsList = this.genListByJob(this.job);
  }

  created() {
    this.init();
  }
}
