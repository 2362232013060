




import { Vue, Component } from 'vue-property-decorator';
// import { namespace } from 'vuex-class';
// const InterviewQualityMarkModule = namespace('mark');

@Component
export default class InterviewQualityMark extends Vue {
}
