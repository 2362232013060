



















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { IForm, TResume } from '@/model/resume';
import { postEditCv, postResumeSuccess, getSchoolSuggest, getResumeAuditList2 } from '@/common/apis/resume/resume';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import showJobCategory from '@w-admin/common/src/components/job-category';
import { IAddress } from '@/common/apis/list/type';
import { provinceAndCityData, CodeToText, TextToCode } from 'element-china-area-data';
import { IEdus, IWorks } from '@/common/apis/resume/type';
import { Bind, Throttle } from 'lodash-decorators';

const eduoptions = [
  {
    label: '不限',
    value: '不限',
  },
  {
    label: '初中及以下',
    value: '初中及以下',
  },
  {
    label: '高中',
    value: '高中',
  },
  {
    label: '中专/中技',
    value: '中专/中技',
  },
  {
    label: '大专',
    value: '大专',
  },
  {
    label: '本科',
    value: '本科',
  },
  {
    label: '硕士',
    value: '硕士',
  },
  {
    label: '博士',
    value: '博士',
  },
];

@Component({
  components: {
    Resume,
  },
})
export default class AllEdit extends Vue {
  showDrawer = false;
  imageUrl = '';
  previewResumeUrl = '';
  resumeId = 0;
  schoolLoading = false;
  eduoptions = eduoptions;
  loading = false;
  editStatus = 1;
  autoNextPage = true;
  pageSize = 1;
  pageNo = 1;

  sourceData!: TResume;
  form: IForm = {
    name: '',
    mobile: '',
    email: '',
    title: '',
    titleCode: null,
    sex: '',
    jobRole: '',
    location: '',
    workStart: '',
    lastEdu: {
      start: '',
      end: '',
      education: '',
      school: '',
      speciality: '',
    },
    lastWork: {
      toDate: false,
      start: '',
      end: '',
      title: '',
      titleCode: null,
      company: '',
    },
  };
  jobRoleOptions = [
    {
      label: '职场人',
      value: '职场人',
    },
    {
      label: '学生',
      value: '学生',
    },
  ];
  cityList: IAddress[] = [
    {
      label: '无',
      value: null,
      children: [
        {
          label: '无',
          value: null,
        },
      ],
    },
  ];
  schoolOptions: Record<string, string>[] = [];
  city: string[] | null | string = [];
  $refs!: {
    form: ElForm;
  };
  rules = {
    name: [
      { required: true, message: '名字不能为空', trigger: 'blur' },
      { max: 10, message: '最多支持10个字符', trigger: 'blur' },
    ],
    mobile: [
      {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入正确的手机号',
      },
    ],
    // email: [
    // {
    //   pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
    //   message: '请输入正确的邮箱',
    // },
    // ], 
  };
  isDirtyData = false;

  created() {
    this.init();
  }

  init() {
    this.getAddresses();
  }

  async getResumeAduitList() {
    this.loading = true;
    const params = {
      pageSize: this.pageSize,
      pageNo: this.pageNo,
      editStatus: '0',
    };
    try {
      const { data } = await getResumeAuditList2(params);
      if (data.list.length > 0) {
        const item = data.list[0];
        this.open(item.pdfUrl, item, item.resumeId);
      } else {
        this.$message({
          type: 'warning',
          message: '已经没有数据了!',
        });
      }
    } catch (error) {
      console.error('🙅 请求简历列表', error);
    } finally {
      this.loading = false;
    }
  }

  @Throttle(2000)
  @Bind
  nextResume() {
    if (this.editStatus === 0) {
      this.$message({
        type: 'warning',
        message: '请先批注当前简历!',
      });
    } else {
      this.getResumeAduitList();
    }
  }

  // eslint-disable-next-line complexity
  open(previewResumeUrl: string, data: TResume, resumeId: number) {
    this.resumeId = resumeId;
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    const { forwards } = data;
    const isExit = forwards?.[0]?.location?.indexOf('-');
    const location = isExit && isExit !== -1 ? forwards?.[0]?.location!.split('-') : [];
    this.city
    = location.length === 2
        ? [
          TextToCode[location[0]] && TextToCode[location[0]]?.code ? TextToCode[location[0]]?.code : '',
          TextToCode[location[0]] && TextToCode[location[0]][location[1]]?.code ? TextToCode[location[0]][location[1]]?.code : '',
        ]
        : [];
    this.sourceData = data;
    this.editStatus = data.editStatus;
    this.form = {
      name: data?.name || '', // 姓名
      mobile: data?.mobile || '', // 手机号
      email: data?.email || '', // 邮箱
      title: data?.forwards?.[0]?.titleCode && data?.forwards?.[0]?.titleCode !== '' ? data?.forwards?.[0]?.title : '', // 期望职位
      titleCode: data?.forwards?.[0]?.titleCode || '', // 职位 code
      sex: data?.sex || '', // 性别
      jobRole: data?.jobRole || '', // 职场身份
      location: forwards?.[0]?.location || '', // 期望地点
      workStart: data?.workStart || '', // 参加工作年月
      lastEdu: {
        start: data?.latestEdu?.start || '', // 在校开始时间
        end: data?.latestEdu?.end === '至今' ? '' : data?.latestEdu?.end || '', // 在校结束时间
        education: data?.latestEdu?.education || '', // 学历
        school: data?.latestEdu?.school || '', // 学校
        speciality: data?.latestEdu?.speciality || '', // 专业
      },
      lastWork: {
        toDate: data?.latestWork?.end === '至今' ? true : false,
        start: data?.latestWork?.start || '', // 在职开始时间
        end: data?.latestWork?.end === '至今' ? '' : data?.latestWork?.end || '', // 在职结束时间
        title: data?.latestWork?.titleCode && data.latestWork?.titleCode !== '' ? data.latestWork?.title || '' : '', // 职位
        titleCode: data?.latestWork?.titleCode || '',
        company: data?.latestWork?.company || '', // 公司
      },
    };
  }
  // 清空期望职位
  handlerClearForwardJobTitle() {
    this.form.title = '';
    this.form.titleCode = '';
  }
  // 清空工作职位
  handlerClearJobTitle() {
    this.form.lastWork.title = '';
    this.form.lastWork.titleCode = '';
  }

  // 完善完成
  @Throttle(2000)
  @Bind
  async handlerSuccess() {
    this.loading = true;
    try {
      await this.handlerSave();
      const { success } = await postResumeSuccess({ resumeId: this.resumeId, editStatus: 1 });
      if (success) {
        this.$message({
          type: 'success',
          message: '提交成功!',
        });
        this.$emit('success');
        this.editStatus = 1;
        if (this.autoNextPage) {
          this.nextResume();
        }
      }
    } catch (error) {
      console.error('🙅 完善简历', error);
    } finally {
      this.loading = false;
    }
  }

  // 标记为脏数据
  @Throttle(2000)
  @Bind
  async handlerDirtyData() {
    this.loading = true;
    try {
      const { success } = await postResumeSuccess({
        resumeId: Number(this.resumeId),
        editStatus: this.sourceData.editStatus === -1 ? 0 : -1,
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '设置成功!',
        });
        this.$emit('success');
        this.editStatus = -1;
        if (this.autoNextPage) {
          this.nextResume();
        }
      }
    } catch (error) {
      console.error('🙅 标记简历', error);
    } finally {
      this.loading = false;
    }
  }

  async remoteMethod(value: string) {
    this.schoolLoading = true;
    try {
      const { data } = await getSchoolSuggest({
        name: value,
      });
      this.schoolOptions = data.map(el => ({
        label: el.chName,
        value: el.chName,
      }));
    } catch (error) {
      console.error('🙅  学校联想搜索', error);
    } finally {
      this.schoolLoading = false;
    }
  }

  // 获取省市列表
  async getAddresses() {
    try {
      this.cityList.push(...provinceAndCityData);
    } catch (error) {
      console.error('🙅 获取省市:', error);
    }
  }

  showJobCategory(type: string) {
    const { form, sourceData } = this;
    const titleCode = sourceData?.latestWork?.titleCode || '';
    if (type === 'forward') {
      showJobCategory(titleCode).then(res => {
        if (Array.isArray(res)) {
          this.form.title = res?.[0]?.name;
          this.form.titleCode = res?.[0]?.code;
        } else {
          this.form.title = res?.name;
          this.form.titleCode = res?.code;
        }
      });
    } else {
      showJobCategory(form.titleCode || '').then(res => {
        if (Array.isArray(res)) {
          this.form.lastWork.title = res?.[0]?.name;
          this.form.lastWork.titleCode = res?.[0]?.code;
        } else {
          this.form.lastWork.title = res?.name;
          this.form.lastWork.titleCode = res?.code;
        }
      });
    }
  }

  // 保存
  async handlerSave() {
    const { form } = this;
    const { name, mobile, email, sex, jobRole, title, workStart, titleCode } = form;
    const { city, sourceData } = this;
    this.$refs.form.validate(async valid => {
      if (valid) {
        try {
          const lastWorkIndex = sourceData?.works?.findIndex(el => el.workId === sourceData?.latestWork?.workId);
          const lastEduIndex = sourceData?.edus?.findIndex(el => el.eduId === sourceData?.latestEdu?.eduId);
          const edus: Partial<IEdus>[] = sourceData?.edus || [];
          const works: Partial<IWorks>[] = sourceData?.works || [];
          edus[lastEduIndex] = {
            eduId: sourceData?.latestEdu?.eduId || null,
            education: form.lastEdu.education || '',
            end: form.lastEdu.end || '',
            school: form.lastEdu.school || '',
            speciality: form.lastEdu.speciality || '',
            start: form.lastEdu.start || '',
          };
          works[lastWorkIndex] = {
            workId: sourceData?.latestWork?.workId || null,
            start: form.lastWork.end || '',
            end: form.lastWork.toDate ? '至今' : form.lastWork.end,
            title: form.lastWork.title || '', // 职位
            titleCode: form.lastWork.titleCode,
            company: form.lastWork.company || '', // 公司
          };
          await postEditCv({
            resumeId: this.resumeId,
            form: {
              basic: {
                name: name!,
                mobile: mobile!,
                email: email!,
                sex: sex,
                jobRole: jobRole!,
                workStart: workStart ? moment(workStart).format('YYYY-MM-DD') : '',
              },
              forwards: [
                {
                  forwardId: sourceData?.forwards?.[0]?.forwardId || null,
                  title: title!,
                  titleCode: titleCode,
                  location: Array.isArray(city) ? city.map(el => CodeToText[el]).join('-') : city,
                },
              ],
              edus: edus,
              works: works,
            },
          });
        } catch (error) {
          console.error('🙅 保存基本信息', error);
        }
      }
    });
  }

  handlerCancel() {
    this.showDrawer = false;
  }
  handleAvatarSuccess(res: any, file: { raw: Blob | MediaSource }) {
    this.imageUrl = URL.createObjectURL(file.raw);
  }
  beforeAvatarUpload(file: { type: string; size: number }) {
    const isJPG = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 15;

    if (!isJPG) {
      this.$message.error('上传头像图片只能是 jpeg/png/jpg 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传头像图片大小不能超过 15MB!');
    }
    return isJPG && isLt2M;
  }
}
