import Vue from 'vue';
import axios from 'axios';
import vueNcform from '@ncform/ncform';
import ncformStdComps from '@ncform/ncform-theme-elementui';
import NcformRender from './ncform-render/index.vue';
import SelectBackCategory from '../components/select-back/select-back-category.vue';
import SelectBackTag from '../components/select-back/select-back-tag.vue';
import { NcCommonUpload } from '../components/common-upload';

const extComponents = {
  ...ncformStdComps,
  NcCommonUpload,
  SelectBackCategory,
  SelectBackTag,
};

Vue.use(vueNcform, { extComponents });
Vue.component('ncform-render', NcformRender);

Object.defineProperty(window, '$http', {
  value: axios,
});

Object.defineProperty(Vue.prototype, '$http', {
  value: axios,
});

