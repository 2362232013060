var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"cv-list",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.isShowSubmit)?_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"50"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"name","label":"姓名","width":"170","align":"center","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.name === '-')?_c('span',{staticClass:"column",staticStyle:{"color":"#eb524a"},on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.name))]):_c('span',{staticClass:"column",on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"手机号","width":"150","align":"center","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.phone && !/^1[3456789]\d{9}$/.test(row.phone))?_c('span',{staticClass:"column",staticStyle:{"color":"#eb524a"},on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.phone))]):_c('span',{staticClass:"column",on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.phone))])]}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"邮箱","width":"200","align":"center","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.email && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(row.email))?_c('span',{staticClass:"column",staticStyle:{"color":"#eb524a"},on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.email))]):_c('span',{staticClass:"column",on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.email))])]}}])}),_c('el-table-column',{attrs:{"prop":"cv","label":"简历","align":"center","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('div',{staticClass:"uploade-container"},[_c('svg-icon',{staticClass:"upload-loading",attrs:{"name":"upload-loading"}}),_c('span',[_vm._v("上传中...")])],1):_vm._e(),(row.status === 2)?_c('div',{staticClass:"uploade-faild-container"},[_c('svg-icon',{staticClass:"upload-faild",attrs:{"name":"upload-faild"}}),_c('span',{staticClass:"text"},[_vm._v("上传失败")]),_c('span',{staticClass:"column",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.cv))])],1):_vm._e(),(row.status === 1)?_c('div',[_c('span',{staticClass:"column-cv",on:{"click":function($event){return _vm.handlerUserEditor(row)}}},[_vm._v(_vm._s(row.cv))])]):_vm._e()]}}])}),(_vm.isShowSubmit)?_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status !== 0 && _vm.isShowSubmit && _vm.isShowDelete)?_c('el-link',{staticClass:"delete-cv",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handlerDeleteCv(row)}}},[_vm._v(" 删除 ")]):_vm._e(),(row.status !== 0 && _vm.isShowSubmit)?_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handlerReupload(row.id)}}},[_vm._v("重新上传")]):_vm._e(),_c('input',{ref:("singlefile" + (row.id)),staticClass:"hidden-input",attrs:{"type":"file","accept":".doc, .docx, .pdf"},on:{"change":function($event){return _vm.singleUploadResume(row, $event)}}})]}}],null,false,1164202168)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }