import User from '@/model/user';
import { Module } from 'vuex';
import { IRootState } from './root';
import { globalConfig } from '@w-admin/common';
import {
  login,
  // refreshTokan
} from '@/common/service';
import { initLoginUser } from './init';
import router from '@/pages/home/router';
import { QJStorage } from '@/common/utils';
import { logout } from '@w-admin/common/src/api/service';
import Cookies from 'js-cookie';
export interface ILoginState {
  token: string;
  loginUser: User;
  refreshTokenTimer: number | null;
}

// const REFRESH_TOKEN_TIME = 20 * 60 * 1000;

const types = {
  SET_TOKEN: 'setToken',
  UPDATE_LOGIN_USER: 'updateUserInfo',
  SET_TOKEN_TIMER: 'setTokenTimer',
};

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state: {
    token: '',
    loginUser: initLoginUser(),
    refreshTokenTimer: null,
  },
  getters: {
    userInfo(state) {
      return state.loginUser;
    },
  },
  mutations: {
    setTokenTimer(state, timer: number | null) {
      state.refreshTokenTimer = timer;
    },
    setToken(state, token: string) {
      state.token = token;
    },
    updateUserInfo(state, loginUser: User) {
      state.loginUser = {
        ...state.loginUser,
        ...loginUser,
      };
    },
  },
  actions: {
    async logout({ commit, state }) {
      await logout();
      QJStorage.removeStorage(globalConfig.current.tokenKey);
      QJStorage.removeStorage(globalConfig.current.userKey);
      clearInterval(state.refreshTokenTimer!);
      commit(types.SET_TOKEN_TIMER, null);
      commit(types.SET_TOKEN, '');
      commit(types.UPDATE_LOGIN_USER, initLoginUser());
      router.replace({ name: 'login' });
    },
    async login({ commit }, options: { email: string; password: string }) {
      const res = await login(options);
      if (res.success) {
        const { authToken, nickname, userInfo, suId } = res.data;
        const user = initLoginUser({ ...userInfo, suId, email: options.email, authToken, nickname });
        QJStorage.setStorage(globalConfig.current.tokenKey, authToken);
        QJStorage.setStorage(globalConfig.current.userKey, user);
        !suId && QJStorage.removeStorage(globalConfig.current.targetKey);
        commit(types.SET_TOKEN, authToken);
        commit(types.UPDATE_LOGIN_USER, user);
        Cookies.set('token', authToken, {
          domain: '.reta-inc.com',
          expires: new Date(Date.now() + 30 * 60 * 1000),
        });
        // const timer = setInterval(() => refreshTokan(), REFRESH_TOKEN_TIME);
        // commit(types.SET_TOKEN_TIMER, timer);
      }
      return res;
    },
    async getUserInfobyStorage({ commit, state }) {
      const user = (QJStorage.getStorage<User>(globalConfig.current.userKey) || {}) as User;
      commit(types.UPDATE_LOGIN_USER, user);
      if (!state.refreshTokenTimer) {
        // const timer = setInterval(() => refreshTokan(), REFRESH_TOKEN_TIME);
        // commit(types.SET_TOKEN_TIMER, timer);
      }
      return user;
    },
    async clearSU({ commit }) {
      const user = (QJStorage.getStorage<User>(globalConfig.current.userKey) || {}) as User;
      user.suId = null;
      QJStorage.setStorage(globalConfig.current.userKey, user);
      QJStorage.removeStorage(globalConfig.current.targetKey);
      commit(types.UPDATE_LOGIN_USER, user);
    },
    async refreshTokan({ commit }, authToken: string) {
      const user = (QJStorage.getStorage<User>(globalConfig.current.userKey) || {}) as User;
      user.authToken = authToken;
      QJStorage.setStorage(globalConfig.current.tokenKey, authToken);
      QJStorage.setStorage(globalConfig.current.userKey, user);
      commit(types.SET_TOKEN, authToken);
      commit(types.UPDATE_LOGIN_USER, user);
    },
  },
};

export default loginModule;
