






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { saveRecruitmentChannelList } from '@/common/apis/recruitment/index';
import { TSaveJobFollowerParams } from '@/common/apis/recruitment/type';

interface IOptions {
  label: string;
  value: string;
}

@Component
export default class EditRecruitementDialog extends Vue {
  @Prop({ default: '新增待招职位' })
  title!: string;
  @Prop(Array)
  hiringManagerOptions!: IOptions[];
  @Prop(Array)
  recruitmentChannelOptions!: IOptions[];
  @Prop(Array)
  accountoptions!: IOptions[];
  @Prop(Array)
  accountTypeOptions!: IOptions[];

  dialogFormVisible = false;

  rules = {
    title: [{ required: true, message: '请输入在线职位', trigger: 'blur' }],
    followerId: [{ required: true, message: '请输入招聘经理', trigger: 'blur' }],
    channel: [{ required: true, message: '请输入招聘渠道', trigger: 'blur' }],
    account: [{ required: true, message: '请输入招聘账号', trigger: 'blur' }],
    email: [{ required: true, message: '请输入招聘邮箱', trigger: 'blur' }],
  };

  form: TSaveJobFollowerParams = {
    accountType: 0, // 账号属性
    jobCode: '', // 职位编码
    channel: '', // 渠道
    account: '', // 账号
    followerName: '', // 招聘经理
    followerId: 0, // 招聘经理 id
    title: '', // 在线职位
    email: '', // 邮箱
  };

  $refs!: {
    form: ElForm;
  };

  // 保存
  handlerSave() {
    const { form } = this;
    this.$refs.form.validate(async valid => {
      if (valid) {
        const index = this.hiringManagerOptions.findIndex(el => el.value === form.followerId);
        const { success } = await saveRecruitmentChannelList({
          account: form.account,
          channel: form.channel,
          accountType: form.accountType,
          jobTitle: form.title,
          followerId: form.followerId,
          followerName: this.hiringManagerOptions[index].label,
          email: form.email,
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '保存成功!',
          });
        }
        this.$emit('submit');
        this.dialogFormVisible = false;
      }
    });
  }
  // 取消
  handlerCancle() {
    this.dialogFormVisible = false;
  }

  open(data) {
    if (data) {
      this.form = {
        id: data.id,
        jobCode: data.jobCode,
        followerId: data.followerId,
        followerName: data.followerName,
        channel: data.channel,
        account: data.account,
        email: data.email,
        accountType: data.accountType,
        title: data.title,
      };
    } else {
      this.form = {
        jobCode: '',
        followerName: '',
        followerId: '',
        channel: '',
        account: '',
        email: 'cv_receive@reta-inc.com',
        accountType: 0,
        title: '',
      };
    }
    this.dialogFormVisible = true;
  }
}
