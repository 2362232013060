







































































import { getCategoryTree } from '../../api/service';
import { TTypeCode } from '../../api/types';
import { ICategoryNode } from '../../model/common';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FirstCategoryItem from './first-category-item.vue';
import SecondCategoryItem from './second-category-item.vue';

@Component({
  components: {
    FirstCategoryItem,
    SecondCategoryItem,
  },
})
export default class JobCategory extends Vue {
  show = false;
  loadingDict = false;
  searchLoading = false;
  searchContent = '';
  searchOptions: ICategoryNode[] = [];
  jobCategory: ICategoryNode[] = [];
  jobCategoryList: ICategoryNode[] = [];
  jobThirdCategoryList: ICategoryNode[] = [];
  activeFirstNode: ICategoryNode | null = null;
  activeSecondNode: ICategoryNode | null = null;
  selectedCode: string | null = null;
  defaultSelectCode?: string = '';
  defaultSelectCategorys: {
    code: string;
    name: string;}[] = [];
  multipleCategory = false;

  $refs!: {
    search: Vue & HTMLElement & { blur(): void };
  };

  get selectCategorysCode() {
    return this.defaultSelectCategorys.map(c => c.code);
  }

  @Prop({ type: String, default: '确定' })
  okBtnText!: string;

  remoteMethod(query?: string) {
    if (!query) {
      this.searchOptions = [];
      return;
    }
    this.searchLoading = true;
    this.searchOptions = this.jobThirdCategoryList.filter(n => n.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
    this.searchLoading = false;
  }

  onCancel() {
    this.$emit('cancel');
    this.show = false;
  }

  onConfirm(tag: ICategoryNode) {
    this.$emit('confirm', tag);
    this.show = false;
  }

  async abstractGetDict<T = ICategoryNode[]>(typeCode: TTypeCode | string) {
    const isBServer = location.hostname.includes('b.reta-inc.com');
    const client = isBServer ? 'b' : 'a';
    const { data } = await getCategoryTree<T>({ typeCode: typeCode as TTypeCode }, client);
    return data;
  }

  async initDict() {
    const { JOB } = this.$QJDict.categoryType;
    this.loadingDict = true;
    return Promise.all([this.abstractGetDict(JOB.code)])
      .then(rs => {
        const [jobCategory] = rs;
        this.jobCategory = jobCategory;
        this.activeFirstNode = jobCategory[0];
        this.flattenJobCategory();
        this.loadingDict = false;
      })
      .catch((error: unknown) => {
        this.loadingDict = false;
        console.log('[获取职位类型失败]', error);
      });
  }

  initDefault(selectCode?: string) {
    if (!selectCode) {
      return;
    }
    const [node, second, first] = this.findPreviousNode('code', selectCode);
    if (first) {
      this.activeFirstNode = first;
    }
    if (second) {
      this.activeSecondNode = second;
    }
    if (node) {
      this.selectedCode = node.code;
    }
  }

  onSearchEnter() {
    const [firstNode] = this.searchOptions;
    if (!firstNode) {
      return;
    }
    const hoverItem = document.querySelector('.el-select-dropdown__item.hover');
    if (!hoverItem) {
      this.searchContent = firstNode.name;
      this.onSearchChange(firstNode.code);
      this.$refs.search && this.$refs.search.blur();
    }
  }

  onSearchClear() {
    this.searchOptions = [];
  }

  onSearchChange(selectCode: string) {
    if (!selectCode) {
      this.searchOptions = [];
      return;
    }
    const res = this.findPreviousNode('code', selectCode).filter(e => !!e);

    if (res.length === 3) {
      const [node, second, first] = res;
      if (first) {
        this.activeFirstNode = first;
      }
      if (second) {
        this.activeSecondNode = second;
      }
      if (node) {
        this.selectedCode = node.code;
        if (!this.multipleCategory) {
          this.onConfirm(node);
        }
      }
      return;
    }

    if (res.length === 2) {
      const [second, first] = res;
      if (first) {
        this.activeFirstNode = first;
      }
      if (second) {
        this.activeSecondNode = second;
      }
      return;
    }

    if (res.length === 1) {
      const [first] = res;
      if (first) {
        this.activeFirstNode = first;
      }
      return;
    }
  }

  flattenJobCategory() {
    const first = this.jobCategory;
    const second = first
      .map(e => e.children)
      .flat()
      .filter(e => !!e) as ICategoryNode[];
    const third = second
      .map(e => e.children)
      .flat()
      .filter(e => !!e) as ICategoryNode[];
    this.jobCategoryList = [...first, ...second, ...third];
    this.jobThirdCategoryList = [...third];
  }

  findPreviousNode(key: 'code' | 'name' | 'id', value: string) {
    const node = this.jobCategoryList.find(e => e[key] === value);
    if (!node) {
      return [];
    }
    const second = this.jobCategoryList.find(e => e.id === node.parentId);
    if (!second) {
      return [node];
    }
    const first = this.jobCategoryList.find(e => e.id === second.parentId);
    return [node, second, first];
  }

  onFirstSelect(node: ICategoryNode) {
    this.activeFirstNode = node;
  }

  onSecondSelect(node: ICategoryNode | null) {
    this.activeSecondNode = node;
  }

  onSelectNode(tag: ICategoryNode) {
    if (this.multipleCategory) {
      const selectIndex = this.defaultSelectCategorys.findIndex(c => c.code === tag.code);
      if (selectIndex > 0) {
        this.defaultSelectCategorys.splice(selectIndex, 1);
        return;
      }
      if (this.defaultSelectCategorys.length >= 5) {
        this.$message.warning('最多选择5个职类');
        return;
      }
      this.defaultSelectCategorys.push({
        name: tag.name,
        code: tag.code,
      });
    } else {
      this.selectedCode = tag.code;
      this.onConfirm(tag);
    }
  }

  handleClose(code) {
    this.defaultSelectCategorys.splice(this.defaultSelectCategorys.findIndex(t => t.code === code), 1);
  }

  handleSave() {
    this.$emit('confirm', this.defaultSelectCategorys);
    this.show = false;
  }

  async created() {
    await this.initDict();
    this.initDefault(this.multipleCategory ? this.defaultSelectCategorys?.[0]?.code : this.defaultSelectCode);
  }
}
