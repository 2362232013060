import App from '@/pages/home/App.vue';
import '@/assets/style/index.less';
import SvgIcon from '@/components/svg-icon.vue';
import router from '@/pages/home/router';
import store, { wait } from '@/pages/home/store';
import Vue from 'vue';
import './qj-import';

Vue.config.productionTip = false;

Vue.component('svg-icon', SvgIcon);
new Vue({
  router,
  store,
  wait,
  render: h => h(App),
}).$mount('#app');
