
import OSS from 'ali-oss';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { getInfoFromAllStorage } from '../../utils/global';
import { uploadUrl, uploadConfig } from './config';
import {
  AjaxResponse,
  IFile,
  IPreUploadObjRes,
  IUploadVideoRes,
  TPreuploadScene,
  IPostUplaodData,
} from './types';
import globalConfig from '../../config';

const getOptions = () => {
  const token = getInfoFromAllStorage<string>(globalConfig.current.tokenKey);
  return {
    headers: {
      ...token ? { token } : {},
      platform: 'PC',
      trace_id: nanoid(),
    },
  };
};

const preHandle = (file: IFile, scene: TPreuploadScene, isVideo = false) => {
  const url = uploadUrl(isVideo);
  const options = getOptions();
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('contentType', 'multipart/form-data');
  formData.append('scene', scene);
  formData.append('sourceType', 'LOCAL');
  const data: Record<string, any> = {
    filename: file.name,
    contentType: 'multipart/form-data',
    scene: scene,
    sourceType: 'LOCAL',
  };
  return {
    url,
    options,
    formData,
    data,
  };
};

export async function postUpload(mediaId: number) {
  const options = getOptions();
  return await axios.post<AjaxResponse<IPostUplaodData>>(uploadConfig.postPath, { mediaId }, options);
}

export async function upload(file: IFile, scene: TPreuploadScene) {
  const { url, options, data } = preHandle(file, scene);
  const res = await axios.post<AjaxResponse<IPreUploadObjRes>>(url, data, options);
  const {
    mediaId,
    objectName,
    accessKeyId,
    accessKeySecret,
    securityToken,
    bucketName,
    endpoint,
  } = res.data.data;

  const client = new OSS({
    accessKeyId: accessKeyId,
    accessKeySecret: accessKeySecret,
    stsToken: securityToken,
    bucket: bucketName,
    endpoint: endpoint,
  });

  try {
    await client.put(objectName, file);
    const { data: uploadResData } = await postUpload(mediaId);
    return {
      success: true,
      code: 200,
      message: '',
      data: uploadResData.data,
    };
  } catch (error) {
    console.info('[上传文件出错]', error);
    return {
      success: false,
      code: 1,
      message: '上传文件出错',
      data: {
        mediaId,
        url: '',
      },
    };
  }

}

export async function createUploadVideoAuth(file: IFile, scene: TPreuploadScene, coverMediaId?: number | null) {
  const { url, options, data } = preHandle(file, scene, true);
  return await axios.post<AjaxResponse<IUploadVideoRes>>(
    url,
    {
      ...data,
      ...coverMediaId ? { coverMediaId } : {},
    },
    options,
  );
}

export async function refreshUploadVideoAuth(videoId: string) {
  return await axios.post<AjaxResponse<IUploadVideoRes>>(uploadUrl(true), { videoId }, getOptions());
}

export async function getVideoInfo(mediaId: number) {
  return await axios.get<AjaxResponse<{ duration: number, coverUrl: string }>>(
    uploadConfig.getVideoInfoPath,
    {
      ...getOptions(),
      params: { mediaId },
    },
  );
}

export async function getVideoFileInfo(mediaId: number) {
  return await axios.get<AjaxResponse<{
    filename: string,
    width: number,
    height: number,
    duration: string,
    size: number
  }>>(
    uploadConfig.getMezzanineInfoPath,
    {
      ...getOptions(),
      params: { mediaId },
    },
  );
}

export async function updateCover(mediaId: number, coverMediaId: number) {
  return await axios.post<AjaxResponse<any>>(
    uploadConfig.updateCoverPath,
    { mediaId, coverMediaId },
    getOptions(),
  );
}

