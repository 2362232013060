










































































































import { getResumeAuditList2, getResumeDetail } from '@/common/apis/resume/resume';
import { Component, Vue } from 'vue-property-decorator';
import { TResume } from '@/model/resume';
import AllEdit from './components/all-edit.vue';

const educationOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '研究生',
    value: '1',
  },
  {
    label: '本科',
    value: '2',
  },
  {
    label: '大专',
    value: '3',
  },
  {
    label: '中专',
    value: '4',
  },
  {
    label: '高中',
    value: '5',
  },
  {
    label: '初中及以下',
    value: '6',
  },
];
@Component({
  components: {
    AllEdit,
  },
})
export default class ResumeReview extends Vue {
  pageSize = 10;
  pageNo = 1;
  total = 0
  query = {
    editStatus: '0',
  }
  loading = false
  statusOptions = [
    {
      label: '全部',
      value: null,
    },
    {
      label: '已完善',
      value: '1',
    },
    {
      label: '未完善',
      value: '0',
    },
  ]
  $refs!:{
    AllEdit: AllEdit
  }
  educationOptions = educationOptions
  cvList: TResume[] = [];

  created() {
    this.getResumeAduitList();
  }

  // 打开简历详情
  async handlerCvDetail(item: Partial<TResume>) {
    const { data } = await getResumeDetail({
      resumeId: Number(item.resumeId),
    });
    this.$refs.AllEdit.open(data.pdfUrl, data, Number(item.resumeId));

    // this.$router.push({
    //   name: 'resume-detail',
    //   query: {
    //     cvId: String(item.resumeId),
    //     edit: '0',
    //   },
    // });
  }

  // 数组截取
  sliceArr< T >(long: number, arr: T[]) : T[] {
    if (!Array.isArray(arr)) {
      return [];
    }
    return arr.length < long ? arr : arr.slice(0, long);
  }

  handlerSuccess() {
    this.getResumeAduitList();
  }

  // 获取简历审核列表
  async getResumeAduitList() {
    this.loading = true;
    const { query } = this;
    const params = {
      pageSize: this.pageSize,
      pageNo: this.pageNo,
    };
    Object.keys(query).forEach(el => {
      if (query[el] !== '') {
        params[el] = query[el];
      }
    });
    this.cvList.length = 0;
    try {
      const { data } = await getResumeAuditList2(params);
      this.cvList = data.list;
      this.total = data.totalCount;
    } catch (error) {
      console.error('🙅 请求简历列表', error);
    } finally {
      this.loading = false;
    }
  }

  // 搜索简历
  handlerSearch() {
    this.pageNo = 1;
    this.getResumeAduitList();
  }

  handleCurrentChange(value: number) {
    this.pageNo = value;
    this.getResumeAduitList();
  }

  handleSizeChange(value: number) {
    this.pageSize = value;
    this.getResumeAduitList();
  }
}
