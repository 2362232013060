



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import EditQuestion from './edit-question.vue';
import { namespace, Getter } from 'vuex-class';
import { IEnterprise } from '@/model/enterprise';
import moment from 'moment';
import { IPagination } from '@/common/types';
import { IEnterpriseQuestionListData } from '@/model/enterprise-question';
import { IVideoBaseInfo } from '@/model/resource';
const EnterpriseQuestionBankModule = namespace('enterprise/enterpriseQuestionBank');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@Component({
  components: {
    EditQuestion,
  },
})
export default class QuestionListItem extends Vue {
  @Prop({ type: Object }) questionListItem!: IPagination<IEnterpriseQuestionListData>;
  @Prop({ type: Object }) questionType!: any;
  @Prop({ type: Number, default: 1 }) currentCompanyId!: number;
  @Prop({ type: Number, default: 1 }) libraryType!: number;

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  pageNo = 1;
  pageSize = 20;
  get totalCount() {
    return this.questionListItem ? this.questionListItem.totalCount : 0;
  }

  sizeChange(val: number) {
    this.handleSizeChange(this.questionType?.code, val);
  }
  currentChange(val: number) {
    this.handleCurrentChange(this.questionType?.code, val);
  }

  getFormattedCreatedAt(createdAt: any) {
    return moment(createdAt).format(dateFormat);
  }

  coverUrl(question: IEnterpriseQuestionListData) {
    if (question.medias && question.medias.length) {
      const jsonData = this.$JSONParse(question.medias[0].data);
      return jsonData?.coverUrl || jsonData.url || '';
    }
    return '';
  }

  questionTypes = this.$QJConfig.enterpriseQuestionCategoriesTypes;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseQuestionBankModule.State('enterpriseQuestionsList')
  enterpriseQuestionsList!: any;

  @EnterpriseQuestionBankModule.Action('getEnterpriseQuestionsList')
  getEnterpriseQuestionsListAction!: any;

  @EnterpriseQuestionBankModule.Action('saveEnterpriseQuestion')
  saveEnterpriseQuestionAction!: any;

  @EnterpriseQuestionBankModule.Action('deleteEnterpriseQuestion')
  deleteEnterpriseQuestionAction!: any;

  preview(question: IEnterpriseQuestionListData<string>) {
    const data = question && question.medias && this.$JSONParse<IVideoBaseInfo>(question.medias[0]?.data) || {} as IVideoBaseInfo;
    if (data && data.playAddressList && data.playAddressList[0]) {
      this.$alert(`
      <video
        controls
        height="400"
        width="auto"
        style="max-width: 500px;"
        src="${data.playAddressList[0].playUrl}"
      ></video>`, `视频预览: ${question.title}`, {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'video-preview-dialog',
      }).catch(() => {});
    } else if (data && data.url ) {
      this.$alert(`
      <img
        style="max-height: 700px; width: auto;"
        src="${data.url}"
      ></img>`, `图片预览: ${question.title}`, {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'video-preview-dialog',
      }).catch(() => {});
    }

  }

  reloadQuestionList(questionType: string) {
    this.reloadQuestionListByQuestionType(questionType);
    if (this.questionType.code !== questionType) {
      this.reloadQuestionListByQuestionType(this.questionType.code);
    }
  }

  reloadQuestionListByQuestionType(questionType: string) {
    const options = {
      companyId: this.currentCompanyId,
      libraryType: this.libraryType,
      questionType: questionType,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

  handleSizeChange(questionType: string, val: number) {
    this.pageSize = val;
    const options = {
      companyId: this.currentCompanyId,
      libraryType: this.libraryType,
      questionType: questionType,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

  handleCurrentChange(questionType: string, val: number) {
    this.pageNo = val;
    const options = {
      companyId: this.currentCompanyId,
      libraryType: this.libraryType,
      questionType: questionType,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseQuestionsListAction(options);
  }

}
