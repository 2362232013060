

























































































































import { ITimelineSection } from '../types';
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component
export default class TimelineSection extends Vue {
  @Prop({ required: true }) section!: ITimelineSection
  @Prop() isPresent!: boolean;
  @Prop() selfIntroMediaStyle?: string | Record<string, string>;
  @Prop({ default: () => ['answerMedia', 'interludeMedia'] }) ignoreType!: ITimelineSection['type'][];

  isHover = false;

  defaultColor = '#303133';
  linkColor = '#375EC5'
}
