import { QJStorage } from '@/common/utils';
import { Module } from 'vuex';
import { IRootState } from './root';

export interface ILayoutModule {
  asideIsCollapse: boolean
  openedsMenus: string[]
}

const types = {
  UPDATE_ASIDE_IS_COLLAPSE: 'updateAsideIsCollapse',
  UPDATE_OPENED_MENUS: 'updateOpendMenus',
};

const layoutModule: Module<ILayoutModule, IRootState> = {
  namespaced: true,
  state: {
    asideIsCollapse: QJStorage.getStorage<string>('asideIsCollapse') === 'true',
    openedsMenus: [],
  },
  getters: {},
  mutations: {
    updateAsideIsCollapse(state, collapse: boolean) {
      state.asideIsCollapse = collapse;
      QJStorage.setStorage('asideIsCollapse', collapse);
    },
    updateOpendMenus(state, option: { type: 'add' | 'remove'; value: string }) {
      const operationMap = {
        add(key: string) {
          if (!state.openedsMenus.includes(key)) {
            state.openedsMenus.push(key);
          }
        },
        remove(key: string) {
          if (state.openedsMenus.includes(key)) {
            const index = state.openedsMenus.findIndex(k => k === key);
            state.openedsMenus.splice(index, 1);
          }
        },
      };
      operationMap[option.type](option.value);
    },
  },
  actions: {
    setAsideCollapse({ commit }, collapse: boolean) {
      commit(types.UPDATE_ASIDE_IS_COLLAPSE, collapse);
    },
    setOpenedMenus({ commit }, option: { type: 'add' | 'remove'; value: string }) {
      commit(types.UPDATE_OPENED_MENUS, option);
    },
  },
};

export default layoutModule;
