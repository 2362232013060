/* eslint-disable max-params */
import axios from 'axios';
import ajax, { CustomAxiosRequestConfig } from './ajax';
import { ICategory, ICategoryNode } from '@w-admin/common/src/model/common';
import { TAPIClent } from '@w-admin/common/src/config';
import { IPostUplaodData } from '@w-admin/common/src/components/common-upload';
import {
  IAddCategoryOptions,
  IAddTagOptions,
  IInterviewer,
  IPagination,
  ISearchCategoryTree,
  IPreUploadRes,
  IGetRandomExamParpeResponse,
  IQuestionsQuery,
} from './types';
import { IExamQuestionForSave, IJudge0Params } from '../model/exam';
import { IQuestionDetail, ISkill } from '../model/job-create';
import { $Config } from '..';


// 获取随机题目
export async function getRandomExamPaper(options: { jobId: number }) {
  return await ajax.get<IGetRandomExamParpeResponse>('/wt/v1/job/randomExamPaper', options);
}

// 获取列表
export async function getQuestionsList(query: Partial<IQuestionsQuery>) {
  return await ajax.get<any>('/wt/v1/job/questions', query);
}

// 获取试题详情
export async function getQuestionsDetail(query: { questionIds: string }) {
  return await ajax.get<any>('/admin/v1/question/detail', query);
}

export async function getCategoryTree<T = ICategoryNode | ICategoryNode[]>(
  options: ISearchCategoryTree,
  client: keyof typeof $Config.apiPrefix = 'a',
) {
  return await ajax.get<T>(`${$Config.apiPrefix[client]}/v1/category/tree`, options);
}

export async function getTagList(
  options: ISearchCategoryTree,
  client: keyof typeof $Config.apiPrefix = 'a',
) {
  return await ajax.get<ICategory[]>(`${$Config.apiPrefix[client]}/v1/tag/list`, options);
}

export async function addNewTag(
  options: IAddTagOptions,
  client: keyof typeof $Config.apiPrefix = 'a',
) {
  return await ajax.post<string>(`${$Config.apiPrefix[client]}/v1/tag/add`, options);
}

export async function addNewCategory(options: IAddCategoryOptions) {
  return await ajax.post('/admin/v1/category/add', options);
}

export async function getInterviewerList(
  client: TAPIClent,
  options: {
    jobId: number,
    pageNo: number,
    pageSize: number
  },
) {
  return await ajax.get<IPagination<IInterviewer>>(`${$Config.apiPrefix[client]}/v1/job/addible-evaluators`, options);
}

export async function getAddedInterviewerList(client: TAPIClent, jobId: number) {
  return await ajax.get<number[]>(`${$Config.apiPrefix[client]}/v1/job/added-evaluators`, { jobId });
}

export async function addInterviewer(client: TAPIClent, jobId: number, bids: number[]) {
  return await ajax.post(`${$Config.apiPrefix[client]}/v1/job/add-evaluators`, { jobId, bids });
}

export async function preUpload(
  scene: string,
  filename: string,
  md5?: string,
  config?: CustomAxiosRequestConfig,
  force = false,
) {
  return await ajax.post<IPreUploadRes>(
    '/api/obj/v3/preUpload',
    {
      scene,
      filename,
      md5,
      force,
      sourceType: 'LOCAL',
    },
    config,
  );
}

export async function preUploadVideo(
  scene: string,
  filename: string,
  md5?: string,
  config?: CustomAxiosRequestConfig,
  force = false,
) {
  return await ajax.post<IPreUploadRes>(
    '/api/video/v3/preUpload',
    {
      scene,
      filename,
      md5,
      sourceType: 'LOCAL',
      force,
    },
    config,
  );
}

export async function postUpload(mediaId: number, config?: CustomAxiosRequestConfig) {
  return await ajax.post<IPostUplaodData>(
    '/api/obj/v2/postUpload',
    { mediaId },
    config,
  );
}

export async function logout() {
  return await ajax.post('/qj/v1/auth/logout/platform');
}

export async function getQuestionDetail(questionId: number) {
  return await ajax.get<IQuestionDetail>('/wt/v1/job/question', { questionId });
}

export async function saveQuestion(data: IExamQuestionForSave) {
  return await ajax.post('/wt/v1/question/save', data);
}

export async function getPresetSkillsByCategory(categoryCode: string) {
  return await ajax.get<ISkill[]>('/wt/v1/tag/find-skill-by-category', { categoryCode });
}

export async function runCodeSubmission(body: IJudge0Params): Promise<any> {
  return await axios.post('/submissions?base64_encoded=true&wait=true&fields=*', body, {
    headers: {
      'content-type': 'application/json',
    },
  });
}
