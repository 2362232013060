

























import { Vue, Component } from 'vue-property-decorator';
import menus, { enterpriseDetails } from '@/pages/home/router/menus';
import { namespace } from 'vuex-class';
import User from '@/model/user';
import { IMenu } from '@/common/types';

const LoginModule = namespace('login');

@Component
export default class HeaderBar extends Vue {
  activeMenuPath = '/enterprise-management';
  menus: IMenu[] = [...menus, ...enterpriseDetails];

  @LoginModule.Getter('userInfo')
  userInfo!: User;

  @LoginModule.Action('logout')
  logout!: () => Promise<void>;

  @LoginModule.Action('clearSU')
  clearSU!: () => Promise<void>;

  get command() {
    return {
      logout: () => {
        this.logout();
      },
      toCancelAttachment: () => {
        const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
        const { attachmentTokenKey, attachmentReleaseValue } = this.$QJConfig;
        const sign = `${Date.now()}`;
        this.clearSU().then(() => {
          window.open(`${this.$QJConfig.BEndPath}?${attachmentTokenKey}=${encodeURIComponent(token!)}&sign=${sign}&type=${attachmentReleaseValue}`);
        });
      },
    };
  }

  onMenuClick(command: 'logout' | 'toCancelAttachment') {
    typeof this.command[command] === 'function' && this.command[command]();
  }

  matchPath(matchedpath: string, path?: string) {
    return path && path.includes(':') ? new RegExp(path.replace(/(:\w+)/g, '\\w+')).test(matchedpath) : path === matchedpath;
  }

  get hasSlots() {
    return Object.keys(this.$slots).length > 0 || Object.keys(this.$scopedSlots).length > 0;
  }

  get currentMenu() {
    const currentPath: IMenu[] = [];
    const menuLen = this.menus.length;
    for (let i = 0; i < menuLen; i++) {
      const menu = this.menus[i];
      const inFirstMenu = this.matchPath(this.activeMenuPath, menu.path);
      if (inFirstMenu) {
        currentPath.push(menu);
      }
      if (menu.children && menu.children.length) {
        const submenu = menu.children.find(submenu => this.matchPath(this.activeMenuPath, submenu.path));
        if (submenu) {
          currentPath.push(submenu);
          break;
        }
      }
    }
    return currentPath.reverse();
  }

  get currentMenuTitle() {
    const [currentMenu] = this.currentMenu;
    return currentMenu && currentMenu.meta ? currentMenu.meta.menuTitle : '';
  }

  getActiveMenuFromPath() {
    this.activeMenuPath = this.$route.path;
  }

  init() {
    this.getActiveMenuFromPath();
  }

  created() {
    this.init();
  }
}
