import { Module } from 'vuex';
import { IPagination, ISearchEnterprise } from '@/common/types';
import { IEnterpriseListData } from '@/model/enterprise';
import { getEnterpriseList, searchEnterprise } from '@/common/service';
import { IRootState } from '../root';
import accountManagementModule from './account-management-module';
import enterpriseInformationModule from './enterprise-information-module';
import enterpriseQuestionBankModule from './enterprise-question-bank-module';
import enterpriseVideoModule from './enterprise-video-module';
import interviewRecordsModule from './interview-records-module';
import jobManagementModule from './job-management-module';
import { initEnterpristListData, initSearchParams } from './init';


export interface IEnterpriseManagementState {
  searchParams: ISearchEnterprise,
  listData: IPagination<IEnterpriseListData>
}

const types = {
  UPDATE_SEARCH_PARAMS: 'updateSearchParams',
  UPDATE_LIST_DATA: 'updateListData',
  CLEAR_SEARCH: 'clearSearch',
};

const enterpriseManagementModule: Module<IEnterpriseManagementState, IRootState> = {
  namespaced: true,
  modules: {
    /**
     * 企业信息
     */
    enterpriseInfo: enterpriseInformationModule,
    /**
     * 账号管理
     */
    account: accountManagementModule,
    /**
     * 职位管理
     */
    job: jobManagementModule,
    /**
     * 企业题库
     */
    enterpriseQuestionBank: enterpriseQuestionBankModule,
    /**
     * 企业视频
     */
    enterpriseVideo: enterpriseVideoModule,
    /**
     * 面试记录
     */
    interviewRecords: interviewRecordsModule,
  },
  state: {
    searchParams: initSearchParams(),
    listData: initEnterpristListData(),
  },
  getters: {},
  mutations: {
    updateSearchParams(state, searchParams: Partial<ISearchEnterprise>) {
      state.searchParams = {
        ...state.searchParams,
        ...searchParams,
      };
    },
    updateListData(state, listData: IPagination<IEnterpriseListData>) {
      state.listData = {
        ...listData,
        pageSize: state.searchParams.pageSize,
      };
    },
    clearSearch(state) {
      state.searchParams = initSearchParams();
    },
  },
  actions: {
    async getList({ commit, state }) {
      try {
        const isSearch = state.searchParams.argsName || state.searchParams.argsValue || state.searchParams.followerName;
        const { data } = isSearch
          ? await searchEnterprise(state.searchParams)
          : await getEnterpriseList({
            pageNo: state.searchParams.pageNo,
            pageSize: state.searchParams.pageSize,
          });
        commit(types.UPDATE_LIST_DATA, {
          ...state.searchParams,
          ...data,
        });
        return data;
      } catch (error) {
        console.log(error);
        return state.listData;
      }
    },
    async setSearchParams(
      { commit, dispatch },
      options: {
        searchParams: Partial<ISearchEnterprise>,
        unRefresh?: boolean
    }) {
      commit(types.UPDATE_SEARCH_PARAMS, options.searchParams);
      options.unRefresh !== true && dispatch('getList');
    },
    async clearSearch({ commit, dispatch }) {
      commit(types.CLEAR_SEARCH);
      dispatch('getList');
    },
  },
};

export default enterpriseManagementModule;
