/* eslint-disable max-len */
import logo from '../assets/image/logo.png';
import Dict from './dict';
import {
  aside,
  enterpriseQuestionCategoriesTypes,
  enterpriseVideoParentCategoriesTypes,
  enterpriseVideoParentCategoriesTree,
  enterpriseJobManagerVideoDisplayPart,
  enterpriseJobManagerQuestionsPart,
  eventName,
  LDKey,
} from './business';
import layoutConfig from './layout';

const CRYPTO_KEY_OFFSET = 38;

const handleKey = (nums: number[]) => nums.map(e => String.fromCharCode(e - CRYPTO_KEY_OFFSET)).join('');

const apiPrefix = {
  uc: '/qj',
  a: '/admin',
  b: '/wt',
  c: '/zm',
};

const clientNotifyMethods = {
  uc: 'message',
  a: 'notify',
  b: 'message',
  c: 'message',
};

const Config = {
  Dict,
  apiPrefix,
  clientNotifyMethods,
  baseUrl: '/',
  defaultTitle: '抢镜',
  logo,
  aside,
  tokenKey: '_token_',
  tempTokenKey: '_temp_token_',
  userKey: '_user_',
  targetKey: '_targetInfo_',
  attachmentTokenKey: 'attachmentToken',
  attachmentFlagKey: '_you_are_not_you_',
  attachmentFlagValue: '_trust_me_, it_is_real_',
  attachmentReleaseValue: 'release',
  attachmentRunAsValue: 'runas',
  runAsInfo: {
    targetUid: null as string | number | null,
    targetName: null as string | number | null,
    token: null as string | number | null,
    type: null as 'runas' | 'share' | 'runas-share' | null,
  },
  cryptoOptions: {
    key: handleKey([128, 128, 105, 95, 113, 90, 86, 151, 148, 87, 121, 92, 92, 92, 81, 99]),
    iv: handleKey([87, 88, 89, 90, 91, 92, 93, 94, 95, 135, 136, 137, 138, 139, 140, 141]),
  },
  eventName,
  STORAGEEVENT: 'onStorageChage',
  LDKey,
  layout: layoutConfig,
  BEndPath: process.env.VUE_APP_BEND_URL,
  CWebPath: process.env.VUE_APP_C_WEB_URL,
  CWebCodeSnapPath: process.env.VUE_APP_C_WEB_CODE_SNAP_URL,
  CWebEnterpriseHomepagePath: `${process.env.VUE_APP_C_WEB_URL}/enterprise/homepage`,
  BEndJobDetailPath: process.env.VUE_APP_BEND_JOB_DETAIL_PATH,
  defaultAvatar: 'https://video.reta-inc.com/image/default/A3B94BCBBE624737B99D59E0605939CD-6-2.png',
  enterpriseQuestionCategoriesTypes,
  enterpriseVideoParentCategoriesTypes,
  enterpriseVideoParentCategoriesTree,
  enterpriseJobManagerVideoDisplayPart,
  enterpriseJobManagerQuestionsPart,
  emailSuffix: '@reta-inc.com',
  agentOperationServiceAgreement: 'https://c.reta-inc.com/copy-right/agent-operation-service-agreement',
  userServiceAgreement: 'https://c.reta-inc.com/copy-right/user-service-agreement',
  privacyPolicy: 'https://c.reta-inc.com/copy-right/privacy-policy',
  businessLicense: 'https://c.reta-inc.com/business-license/business-license',
  hrLicense: 'https://c.reta-inc.com/business-license/hr-license',
  jobTemplate:
    'https://qiangjing.yuque.com/attachments/yuque/0/2022/xlsx/23177526/1649939162542-9448368d-7ab2-4117-84b3-edb7756e79d6.xlsx?from=https%3A%2F%2Fqiangjing.yuque.com%2Fstaff-pd00kk%2Fstg89k%2Fgop9xh',
  enterpriseTemplate:
    'https://qiangjing.yuque.com/attachments/yuque/0/2022/xlsx/179558/1641287018680-e6d46e34-50c1-4c43-9e65-167a88b65702.xlsx?from=https%3A%2F%2Fqiangjing.yuque.com%2Fstaff-pd00kk%2Fstg89k%2Fapguld',
  authFailCallBack: () => {},
  dingCorpKey: '_ding_corp_id_',
};

export const globalConfig = {
  original: Config as typeof Config,
  current: Config as typeof Config,
};

export type TQJCommonConfig = typeof Config;

export interface IQJBaseConfig extends TQJCommonConfig {
  [k: string]: any;
}

export interface IQJConfig<AConfig = any, BConfig = any> extends IQJBaseConfig {
  $A: AConfig;
  $B: BConfig;
}

export interface IQJAConfig<AConfig = any> extends IQJBaseConfig {
  $A: AConfig;
}

export interface IQJBConfig<BConfig = any> extends IQJBaseConfig {
  $B: BConfig;
}

export type TAPIClent = keyof typeof apiPrefix;

export default globalConfig;
export { default as QJConfig } from './plugin';
