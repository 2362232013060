




















import { IAuditList } from '@/model/content-audit';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContentAuditPreviewDangerless from './content-audit-preview-dangerless.vue';
import ContentAuditPreviewDangerous from './content-audit-preview-dangerous.vue';

@Component({
  components: {
    ContentAuditPreviewDangerous,
    ContentAuditPreviewDangerless,
  },
})
export default class ContentAuditPreviewExpand extends Vue {
  @Prop({ required: true }) auditList!: IAuditList[];

  get sortedList() {
    return this.auditList.sort((a, b) => {
      if (a.effective && !b.effective) {
        return -1;
      }
      if (!a.effective && b.effective) {
        return 1;
      }
      return 0;
    });
  }

  modify() {
    this.$emit('modified', true);
  }
}
