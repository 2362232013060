import { ICategoryNode } from '../../model/common';
import JobCategory from './index.vue';
import cloneDeep from 'lodash/cloneDeep';

interface ICategory {
  name: string
  code: string
}

export default function showJobCategory(defaultSelectCode?: string | ICategory[], multipleCategory?: boolean) {
  const instance: JobCategory & {
    show: boolean,
    defaultSelectCode?: string,
    defaultSelectCategorys: ICategory[],
    multipleCategory: boolean
  } = new JobCategory();
  instance.$mount();
  instance.show = true;
  if (multipleCategory) {
    instance.multipleCategory = multipleCategory;
    instance.defaultSelectCategorys = cloneDeep(defaultSelectCode as ICategory[]);
  } else {
    instance.defaultSelectCode = defaultSelectCode as string;
  }
  return new Promise<ICategoryNode | ICategory[]>((resolve, reject) => {
    instance.$on('cancel', reject);
    instance.$on('confirm', resolve);
  });
}
