import { ajax } from '@w-admin/common';
import {
  IResumeInfo,
  IResumeListResponse,
  ISubmitResumeRequest,
  IResumeAuditListRequest,
  IReviewPassRequest,
  ICategoryNode,
  IEditCvParams,
} from './type';
import { TResume } from '@/model/resume';

// 一键投递
export async function postSubmitResume(params: ISubmitResumeRequest) {
  return await ajax.post<{ failedNames: string[]; failedCount: number }>('/admin/v1/cv/batchDeliver', params);
}
// 查询投递简历
export async function getResumeList(params: { jobId: number }) {
  return await ajax.get<IResumeListResponse[]>('/admin/v1/cv/getDeliver', params);
}
// 修改基础信息
export async function updateResumeInfo(params: IResumeInfo) {
  return await ajax.post('/admin/v1/cv/update', params);
}

// 获取简历审核列表
export async function getResumeAuditList(params: Partial<IResumeAuditListRequest>) {
  return await ajax.get<{ totalCount: number; list: TResume[] }>('/admin/v1/resume/search', params);
}

// (众包)获取简历审核列表 
export async function getResumeAuditList2(params: { editStatus?: string | null, pageSize: number, pageNo:number }) {
  return await ajax.get<{ totalCount: number; list: TResume[] }>('/outsourcing/v1/resume/getToEditResumes', params);
}

// 简历库获取简历详情(老数据结构的接口) => 确定是否删除
export async function getResumeDetail1(params: { resumeId: number }) {
  return await ajax.get<TResume>('/admin/v1/resume/getDetail', params);
}
// 获取简历详情
// export async function getResumeDetail(params: { cvId: number }) {
//   return await ajax.get<any>('/admin/v1/auditCv/getDetail', params);
// }

// 审核通过/不通过
export async function reviewPass(params: IReviewPassRequest) {
  return await ajax.post<any>('/admin/v1/auditCv/update', params);
}
// 获取薪资列表
export async function getCategoryTree(params: { typeCode: string }) {
  return await ajax.get<ICategoryNode[]>('/wt/v1/category/tree', params);
}

// 编辑简历 
export async function postEditCv(params: {resumeId: number;form : Partial<IEditCvParams>} ) {
  return await ajax.post('/outsourcing/v1/resume/edit', params);
}

// 获取简历详情 
export async function getResumeDetail(params:{resumeId: number}) {
  return await ajax.get<TResume>('/outsourcing/v1/resume/getDetail', params);
}

// 简历标注完成 
export async function postResumeSuccess(params:{resumeId: number, editStatus: number}) {
  return await ajax.post('/outsourcing/v1/resume/editFinished', params);
}

// 联想公司 
export async function getCompanySuggest(params:{searchText: string, searchConditionName: string, pageNo: number, pageSize: number}) {
  return await ajax.get<{list:{id: number; name: string}[]}>('/admin/v1/company/search', params);
}

// 联想学校 
export async function getSchoolSuggest(params:{name: string}) {
  return await ajax.get<{chName: string}[]>('/zm/v1/school/search', params);
}
// 职位管理导入简历
export async function postExportCv(params:{jobId: number;cvCenterIdList:number[]}) {
  return await ajax.post<
  {
    succeedCount: number;
    failedCount: number;
    failedData: {name: string; reason: string}[]
  }>('/admin/v1/cv/batchDeliverFromCvCenter', params);
}

// 获取简历待投递次数 
export async function getResumeDeliveryCount(params:{resumeId: number, pageSize: number}) {
  return await ajax.get<{companyName: string; deliverTime: string}[]>('/admin/v1/resume/latest-rpo-list', params);
}

// 匹配职位简历列表 
export async function getJobToResumes(params:{jobId:number}) {
  return await ajax.get<TResume[]>('/admin/v1/resume/matched-for-job', params);
}