

















































































































import { getEnterpriseVideosList } from '@/common/service';
import { IPagination, ISearchEnterpriseVideo } from '@/common/types';
import { IEnterprise } from '@/model/enterprise';
import { IEnterpriseVideoListData } from '@/model/enterprise-video';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VideoItem from '@/components/video-item.vue';
import SelectBackBase from './select-back-base.vue';
import { ISnippetsForSave } from '@/model/job';
import { IVideoBaseInfo } from '@/model/resource';

@Component({
  components: {
    SelectBackBase,
    VideoItem,
  },
})
export default class SelectBackVideo extends Vue {
  @Prop({ default: false, type: Boolean }) placeholder!: boolean;
  @Prop({ default: '', type: String }) dialogTitle!: string;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly!: boolean;
  @Prop({ default: false, type: Boolean }) hidden!: boolean;
  @Prop({ default: false, type: Boolean }) remove!: boolean;
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() type?: string;
  @Prop() value?: number[];
  @Prop() item?: ISnippetsForSave;
  @Prop() defaultParentCategory?: string;
  @Prop() defaultSecondCategory?: string;

  loading = false;
  parentCategoryObj = this.$QJConfig.enterpriseVideoParentCategoriesTree[0];
  secondCategoryObj = this.$QJConfig.enterpriseVideoParentCategoriesTree[0].secondCategory[0];
  parentCategory = this.$QJConfig.enterpriseVideoParentCategoriesTree[0].code;
  secondCategory = this.$QJConfig.enterpriseVideoParentCategoriesTree[0].secondCategory[0].code;
  jobCategories = '';

  parentCategoryChange(e: string) {
    this.parentCategoryObj = this.$QJConfig.enterpriseVideoParentCategoriesTree.find(item => item.code === e)!;
    this.secondCategoryObj = this.parentCategoryObj.secondCategory[0];
    this.secondCategory = this.secondCategoryObj.code;
    this.getList(1, this.pageSize);
  }

  secondCategoryChange({ index }: { index: string }) {
    this.secondCategoryObj = this.parentCategoryObj.secondCategory[Number(index)];
    this.secondCategory = this.secondCategoryObj.code;
    this.getList(1, this.pageSize);
  }

  get searchParams() {
    return {
      companyId: this.enterprise.id,
      parentCategory: this.parentCategory,
      secondCategory: this.secondCategory,
      jobCategories: this.jobCategories,
    };
  }

  innerValue: string[] = [];

  getNextValue() {
    const videos = this.innerValue.map(e => this.$JSONParse<IEnterpriseVideoListData<IVideoBaseInfo>>(e));
    return {
      mediaIds: videos ? videos.map(e => e?.mediaId) : [],
      medias: videos ? videos.map(e => ({ ...e?.media, title: e?.bvideoName })) : [],
    };
  }

  handleChange() {
    this.$emit('update:value', this.getNextValue());
  }

  listData: IPagination<IEnterpriseVideoListData<IVideoBaseInfo> & { data: IVideoBaseInfo }> = {
    totalCount: 0,
    pageSize: 20,
    pageNo: 1,
    list: [],
  };

  get pageSize() {
    return this.listData.pageSize;
  }
  get pageNo() {
    return this.listData.pageNo;
  }

  get totalCount() {
    return this.listData.totalCount;
  }

  set pageSize(pageSize: number) {
    this.getList(this.pageNo, pageSize);
  }

  set pageNo(pageNo: number) {
    this.getList(pageNo, this.pageSize);
  }

  handleSizeChange(val: number) {
    this.pageSize = val;
  }

  handleCurrentChange(val: number) {
    this.pageNo = val;
  }

  hoverMap: Record<string | number, boolean> = {
    defaultState: false,
  };

  preview(video: IEnterpriseVideoListData<IVideoBaseInfo> & { data: IVideoBaseInfo, title?: string }, e: Event) {
    e.preventDefault();
    if (
      (video.media?.data?.playAddressList && video.media?.data?.playAddressList[0]?.playUrl)
      || (video?.data?.playAddressList && video?.data?.playAddressList[0]?.playUrl)
    ) {
      this.$alert(`
      <video
        controls
        height="400"
        width="auto"
        style="max-width: 500px;"
        src="${video?.media?.data.playAddressList[0].playUrl || video.data.playAddressList[0].playUrl}"
      ></video>`, `视频预览: ${video.bvideoName || video.media?.title || video.title || ''}`, {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'video-preview-dialog',
        // eslint-disable-next-line no-sequences
        beforeClose: (action, instance, done) => (instance.$el.remove(), done()),
      }).catch(() => {});
    }
  }

  previewShow(video: IEnterpriseVideoListData<IVideoBaseInfo>) {
    if (!video.id) {
      return;
    }
    this.$set(this.hoverMap, video.id, true);
  }

  previewHide(video: IEnterpriseVideoListData<IVideoBaseInfo>) {
    if (!video.id) {
      return;
    }
    this.$set(this.hoverMap, video.id, false);
  }

  onRemove(item: IEnterpriseVideoListData) {
    const index = this.innerValue.findIndex(e => e === JSON.stringify(item));
    if (index >= 0) {
      this.innerValue.splice(index, 1);
      this.$emit('update:value', this.getNextValue());
    }
  }

  submit() {
    this.$emit('submit', this.innerValue, this.getNextValue());
    return true;
  }

  init() {
    this.innerValue = (this.item?.medias || []).map(e => JSON.stringify(e));
  }

  async getList(pageNo: number, pageSize: number) {
    this.loading = true;
    const options = {
      ...this.searchParams,
      pageNo,
      pageSize,
    } as unknown as ISearchEnterpriseVideo;
    try {
      const { data } = await getEnterpriseVideosList(options);
      this.listData = {
        ...data,
        list: (data.list || []).map((e: IEnterpriseVideoListData<string> ) => ({
          ...e,
          media: {
            ...e.media,
            data: this.$JSONParse(e.media?.data),
          },
        })) as (IEnterpriseVideoListData<IVideoBaseInfo> & { data: IVideoBaseInfo })[],
      };
      this.loading = false;
    } catch (error) {
      this.listData = {
        totalCount: 0,
        pageSize: 20,
        pageNo: 1,
        list: [],
      };
      console.info('【企业视频获取失败】', options, error);
      this.loading = false;
    }
  }

  mounted() {
    this.init();
  }

  onShow() {
    if (this.defaultParentCategory && this.$QJConfig.enterpriseVideoParentCategoriesTree.some(e => e.code === this.defaultParentCategory)) {
      this.parentCategoryObj = this.$QJConfig.enterpriseVideoParentCategoriesTree.find(e => e.code === this.defaultParentCategory)!;
      this.parentCategory = this.parentCategoryObj.code;
    }
    if (this.defaultSecondCategory && this.parentCategoryObj.secondCategory.some(e => e.code === this.defaultSecondCategory)) {
      this.secondCategoryObj = this.parentCategoryObj.secondCategory.find(e => e.code === this.defaultSecondCategory)!;
      this.secondCategory = this.secondCategoryObj.code;
    }
    this.getList(1, 20);
  }
}
