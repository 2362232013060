









































import { Component, Vue } from 'vue-property-decorator';

import { Getter, namespace } from 'vuex-class';
import { IContentAudit } from '@/model/content-audit';
import { ISubtitleSentences, IVideoBaseInfo } from '@/model/resource';
import { ICurrentAuditVideo } from '@/common/types';

type TVideoRef = {
  getCurrentTime(): number;
  getStatus(): string;
}

const ContentAuditModule = namespace('audit');


@Component
export default class ContentAuditSubtitle extends Vue {

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  @ContentAuditModule.State('currentVideo')
  currentVideo?: ICurrentAuditVideo

  @ContentAuditModule.State('currentVideoIndex')
  currentVideoIndex?: number;

  currentTime = 0;

  get hasSubtitle() {
    return this.currentVideo?.subtitle?.sentences
    && Array.isArray(this.currentVideo?.subtitle?.sentences)
    && this.currentVideo?.subtitle?.sentences.length > 0;
  }

  get subtitle() {
    return this.currentVideo?.subtitle?.sentences || [];
  }

  isCurrentSubtitle(subtitle: ISubtitleSentences) {
    const currentSecond = this.currentTime * 1000;
    return subtitle.from <= currentSecond && subtitle.to >= currentSecond;
  }

  getCurrentTime(videoRef: TVideoRef) {
    if (videoRef.getStatus() !== 'playing') {
      this.endrAF();
    }
    this.currentTime = videoRef.getCurrentTime();
    const needScrollTop = this.getNeedOffsetTop();
    this.autoScroll(needScrollTop);
    this.rAFId = requestAnimationFrame(() => {
      this.getCurrentTime(videoRef);
    });
  }

  rAFId = 0;

  getStandardOffsetTop() {
    const headerHeight = 60;
    const footerHeight = 80;
    const clientHeight = document.documentElement.clientHeight;
    const parentheight = clientHeight - headerHeight - footerHeight;
    const standardOffset = Math.floor(parentheight / 2);
    return standardOffset;
  }

  getCurrentOffsetTop() {
    const currentSubtitle = document.querySelector('.current-text') as (HTMLElement | null);
    return currentSubtitle?.offsetTop;
  }

  getNeedOffsetTop() {
    const standardOffsetTop = this.getStandardOffsetTop();
    const currentOffsetTop = this.getCurrentOffsetTop();
    if (!currentOffsetTop) {
      return 0;
    }
    return currentOffsetTop - standardOffsetTop;
  }

  autoScroll(offset: number) {
    if (this.$refs.subtitlePlayer && offset) {
      (this.$refs.subtitlePlayer as HTMLElement).scrollTo({
        behavior: 'auto',
        top: offset,
      });
    }
  }

  replaceBlackWord(text?: string) {
    let res = text || '';
    const blackWords = this.currentVideo?.blackWords;
    if (Array.isArray(blackWords) && blackWords.length > 0) {
      res = blackWords.reduce((total, cv) => {
        total = total.replace(new RegExp(`(${cv})`, 'g'), '<em class="bw">$1</em>');
        return total;
      }, res);
    }
    return res;
  }

  endrAF() {
    cancelAnimationFrame(this.rAFId);
  }

  startrAF(videoRef: TVideoRef) {
    this.getCurrentTime(videoRef);
  }

  onVideoPlaying(videoRef: TVideoRef) {
    this.startrAF(videoRef);
  }

  onVideoPause() {
    this.endrAF();
  }

  addPlayerListener() {
    this.$QJEventBus.$on(this.$QJConfig.eventName.ONVIDEOPLAYING, this.onVideoPlaying);
    this.$QJEventBus.$on(this.$QJConfig.eventName.ONVIDEOPUASE, this.onVideoPause);
  }

  removrPlayerListener() {
    this.$QJEventBus.$off(this.$QJConfig.eventName.ONVIDEOPLAYING, this.onVideoPlaying);
    this.$QJEventBus.$off(this.$QJConfig.eventName.ONVIDEOPUASE, this.onVideoPause);
  }

  created() {
    this.addPlayerListener();
  }

  destroyed() {
    this.removrPlayerListener();
  }
}
