











import { Component, Vue } from 'vue-property-decorator';
import RecruitmentManagement from './recruitment-management.vue';
import ClueManagement from './clue-management.vue';

@Component({
  components: {
    RecruitmentManagement,
    ClueManagement,
  },
})
export default class extends Vue {
  activeName = 'clue';

  created() {
    this.activeName = this.$route?.query?.tabName ? String(this.$route?.query?.tabName) : 'clue';
  }
}
