























































import { IPagination } from '@/common/types';
import { IEnterprise } from '@/model/enterprise';
import { IBaseUserInfo } from '@/model/user';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAccountList } from '@/common/service';

@Component
export default class EnterprisePublisherListForm extends Vue {
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop({ required: true }) jobId!: number;

  interviewId: number | null = null;

  listData: IPagination<IBaseUserInfo> = {
    pageSize: 20,
    pageNo: 1,
    totalCount: 0,
    list: [],
  };

  async getList(pageSize: number, pageNo: number) {
    const { data } = await getAccountList({
      pageNo,
      pageSize,
      companyId: this.enterprise.id,
    });
    this.listData = data;
  }

  get pageSize() {
    return this.listData.pageSize;
  }
  get pageNo() {
    return this.listData.pageNo;
  }

  set pageSize(pageSize: number) {
    this.interviewId = null;
    this.getList(pageSize, this.pageNo);
  }

  set pageNo(pageNo: number) {
    this.interviewId = null;
    this.getList(this.pageSize, pageNo);
  }

  handleSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  handleCurrentChange(pageNo: number) {
    this.pageNo = pageNo;
  }

  async submit() {
    const user = this.listData.list.find(u => u.id === this.interviewId);
    this.$emit('submit', this.interviewId, user);
  }

  created() {
    this.getList(20, 1);
  }
}
