import { getRiskAuditDetail, getRiskAuditList } from '@/common/service';
import {
  IContentAuditListDataWithPagination,
  ICurrentAuditVideo,
  IGetContentAuditListOptions,
  TContentAuditTabName,
} from '@/common/types';
import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { globalConfig } from '@w-admin/common';
import { JSONParse, QJStorage } from '@w-admin/common/src/utils/global';
import { Module } from 'vuex';
import {
  initContentAuditSearchParams,
  initContentAuditTabs,
  initCurrentContentAuditListData,
} from './init';
import { IRootState } from './root';


export interface IContentAuditModuleTabs {
  name: TContentAuditTabName
  title: string
  status: 0 | 1 | 2 | 3
}


export interface IInterviewContentAuditModuleState {
  tabs: IContentAuditModuleTabs[];
  currentTab?: IContentAuditModuleTabs;
  currentTabIndex: number;
  currentListData: IContentAuditListDataWithPagination;
  searchParams: IGetContentAuditListOptions;
  currentAuditInterview?: IContentAudit<IVideoBaseInfo>;
  currentVideo?: ICurrentAuditVideo;
  currentVideoIndex?: number;
}

const types = {
  UPDATE_CURRENT_TAB_BY_NAME: 'updateCurrentTabByName',
  UPDATE_SEARCH_PARAMS: 'updateSearchParams',
  UPDATE_CURRENT_LIST_DATA: 'updateCurrentListData',
  UPDATE_CURRENT_AUDIT_INTERVIEW: 'updateCurrentAuditInterview',
  UPDATE_CURRENT_VIDEO: 'updateCurrentVideo',
};

const initTabs = initContentAuditTabs();

const interviewContentAuditModule: Module<IInterviewContentAuditModuleState, IRootState> = {
  namespaced: true,
  state: {
    tabs: initTabs,
    currentTab: initTabs[0],
    currentTabIndex: 0,
    currentAuditInterview: undefined,
    currentVideo: undefined,
    currentVideoIndex: 0,
    currentListData: initCurrentContentAuditListData(),
    searchParams: initContentAuditSearchParams(),
  },
  getters: {
    currentTabName(state) {
      return state.currentTab?.name;
    },
    tabs(state) {
      return state.tabs;
    },
  },
  mutations: {
    updateSearchParams(state, searchParams: Partial<IGetContentAuditListOptions>) {
      state.searchParams = {
        ...state.searchParams,
        ...searchParams,
      };
    },
    updateCurrentTabByName(state, tabName: TContentAuditTabName) {
      const tab = state.tabs.find(tab => tab.name === tabName);
      const index = state.tabs.findIndex(tab => tab.name === tabName);
      state.currentTab = tab;
      state.currentTabIndex = index;
    },
    updateCurrentListData(state, listData: IContentAuditListDataWithPagination) {
      state.currentListData = listData;
    },
    updateCurrentAuditInterview(state, auditInterview?: IContentAudit<IVideoBaseInfo>) {
      state.currentAuditInterview = auditInterview;
    },
    updateCurrentVideo(state, { video, index }: { video?: ICurrentAuditVideo, index?: number }) {
      state.currentVideo = video;
      state.currentVideoIndex = index;
    },
  },
  actions: {
    async getCurrentListData({ state, commit }) {
      try {
        const { data } = await getRiskAuditList(state.searchParams);
        commit(types.UPDATE_CURRENT_LIST_DATA, data);
        return data;
      } catch (error) {
        console.info('【视频风险合规列表获取出错】', state.searchParams, error);
        return state.currentListData;
      }
    },
    async setSearchParams(
      { commit, dispatch },
      options: {
        searchParams: Partial<IGetContentAuditListOptions>,
        unRefresh?: boolean
      }) {
      commit(types.UPDATE_SEARCH_PARAMS, options.searchParams);
      return options.unRefresh !== true && await dispatch('getCurrentListData');
    },
    setCurrentTabByName({ commit, dispatch, state }, tabName: TContentAuditTabName) {
      commit(types.UPDATE_CURRENT_TAB_BY_NAME, tabName);
      commit(types.UPDATE_CURRENT_AUDIT_INTERVIEW, undefined);
      const tab = state.tabs.find(tab => tab.name === tabName);
      dispatch('setSearchParams', {
        searchParams: {
          status: tab?.status,
          pageNo: 1,
          createdBy: '',
        },
      });
    },
    async setCurrentAuditInterview({ dispatch, commit, state }, interviewId: number) {
      try {
        dispatch('wait/start', 'audit.setCurrentAuditInterview', { root: true });
        const item = state.currentListData.list.find(e => e.interviewId === interviewId)!;
        const { data } = await getRiskAuditDetail(interviewId);
        const parseData: IContentAudit<IVideoBaseInfo> = {
          ...data,
          vsn: item.vsn,
          mediaList: data?.mediaList.map(m => ({
            ...m,
            read: !!QJStorage.getStorage(globalConfig.current.LDKey.STORAGE_READ_VIDEO_NAME)?.includes(m.mediaId),
            data: JSONParse<IVideoBaseInfo>(m.data)!,
          })),
        };
        commit(types.UPDATE_CURRENT_AUDIT_INTERVIEW, parseData);
        dispatch('wait/end', 'audit.setCurrentAuditInterview', { root: true });
        return parseData;
      } catch (error) {
        dispatch('wait/end', 'audit.setCurrentAuditInterview', { root: true });
        console.info('【获取审核面试详情出错】', interviewId, error);
        return state.currentAuditInterview;
      }
    },
    async nextAudit({ dispatch }) {
      /**
       * 刷新当前页面列表
       */
      const data = await dispatch('getCurrentListData') as IContentAuditListDataWithPagination;
      /**
       * 如果当前页面还存在数据
       */
      if (data && data.totalCount > 0 && data.list && data.list[0] && data.list[0].interviewId) {
        /**
         * 将当前页面列表第一个面试激活
         */
        dispatch('setCurrentAuditInterview', data.list[0].interviewId);
      } else if (data && data.totalCount > 0) {
        /**
         * 当前页面没有数据，但是其他页面仍存在数据, 返回第一页
         */
        const firstPageData = await dispatch('setSearchParams', {
          searchParams: { pageNo: 1 },
        }) as boolean | IContentAuditListDataWithPagination;
        /**
         *
         * 将第一页的第一个面试激活
         */
        if (firstPageData && typeof firstPageData !== 'boolean' && firstPageData.list && firstPageData.list[0] && firstPageData.list[0].interviewId) {
          dispatch('setCurrentAuditInterview', firstPageData.list[0].interviewId);
        }
      } else {
        dispatch('setCurrentVideo', { video: undefined, index: 0 });
      }
    },
    setCurrentVideo({ commit }, options: { video?: ICurrentAuditVideo, index?: number }) {
      commit(types.UPDATE_CURRENT_VIDEO, options);
    },
  },
};

export default interviewContentAuditModule;
