

















































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { ICertificates } from '@/common/apis/resume/type';
import { ElForm } from 'element-ui/types/form';
import { postEditCv } from '@/common/apis/resume/resume';

@Component({
  components: {
    Resume,
  },
})
export default class QualificationCertificate extends Vue {
  showDrawer = false
  previewResumeUrl = ''
  resumeId = 0

  list: ICertificates[] = [{
    certificate: '',
    date: '',
  }]
  $refs!:{
    form: ElForm[]
  }
  rules = {
    certificate: { max: 50, message: '最多支持50个字符', trigger: 'blur' },
  }

  open(previewResumeUrl: string, data: ICertificates[], resumeId: number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.list = data;
    this.resumeId = resumeId;
  }
  remove(index: number) {
    this.list.splice(index, 1);
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  // 添加资质证书
  handlerEduProject() {
    this.list.push({
      certificate: '',
      date: '',
    });
  }
  async handlerSave() {
    let valid = true;
    for (const item of this.$refs.form) {
      // eslint-disable-next-line no-loop-func
      item.validate(async value => {
        if (!value) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = false;
        }
      });
    }
    if (valid) {
      try {
        const { success } = await postEditCv({
          resumeId: this.resumeId,
          form: {
            certificates: this.list,
          },
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '提交成功!',
          });
          this.showDrawer = false;
          this.$emit('submit');
        }
      } catch (error) {
        console.error('🙅 保存资质证书', error);
      }
    }
  }
}
