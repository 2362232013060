import Vue from 'vue';
import Vuex from 'vuex';
import root from './root';
import VueWait from 'vue-wait';

Vue.use(Vuex);
Vue.use(VueWait);

export const store = new Vuex.Store(root);
export const wait = new VueWait({
  useVuex: true,
  vuexModuleName: 'wait',
});

export default store;
