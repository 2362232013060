import { PluginObject, VueConstructor } from 'vue';
import BrowserCompatible from './browser-compatible.vue';

export function isIE() {
  return navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident') > -1;
}

export function isSafari() {
  return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
}

export function unsupported() {
  return isIE() || isSafari();
}

const BrowserCompatiblePlugin: PluginObject<undefined> = {
  isInstalled: false,
  install(Vue: VueConstructor) {
    if (this.isInstalled) {
      return;
    }
    this.isInstalled = true;
    Object.defineProperty(Vue.prototype, '$isUnSupportedBrowser', {
      value: unsupported,
    });
    Vue.component('browser-compatible', BrowserCompatible);
  },
};

declare module 'vue/types/vue' {
  export interface Vue {
    $isUnSupportedBrowser: typeof unsupported
  }
}


export default BrowserCompatiblePlugin;
