

















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { IWorks } from '@/common/apis/resume/type';
import { ElForm } from 'element-ui/types/form';
import { getCompanySuggest, postEditCv } from '@/common/apis/resume/resume';
import showJobCategory from '@w-admin/common/src/components/job-category';

@Component({
  components: {
    Resume,
  },
})
export default class WorkEdit extends Vue {
  showDrawer = false
  previewResumeUrl = ''
  resumeId = 0
  works: Partial<IWorks & {toDate : boolean}>[] = [{
    company: '',
    end: '',
    start: '',
    summary: '',
    title: '',
  }];
  toDate = false
  endDisabled = false
  // companyLoading = false
  companyOptions:Record<string, string>[] = []

  rules = {
    start: { required: true, message: '在职起始时间不能为空', trigger: 'blur' },
    end: { required: true, message: '在职结束时间不能为空', trigger: 'blur' },
    // title: { required: true, message: '职位不能为空', trigger: 'blur' },
    company: { required: true, message: '公司不能为空', trigger: 'blur' },
  }

  $refs!:{
    form: ElForm[]
  }

  @Watch('toDate')
  onToDate(value: boolean) {
    this.endDisabled = value;
  }

  open(previewResumeUrl: string, data:(IWorks & {toDate?: boolean})[], resumeId:number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.works = data.map(el => {
      this.endDisabled = el.end === '至今';
      return {
        ...el,
        title: '',
        end: el.end !== '至今' ? el.end : '',
        toDate: el.end === '至今',
      };
    });
    this.resumeId = resumeId;
  }

  async handlerSave() {
    let valid = true;
    for (const item of this.$refs.form) {
      // eslint-disable-next-line no-loop-func
      item.validate(async value => {
        if (!value) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = false;
        }
      });
    }
    if (valid) {
      try {
        const { success } = await postEditCv({
          resumeId: this.resumeId,
          form: {
            works: this.works.map(el => ({
              ...el,
              end: el.toDate ? '至今' : el.end,
            })),
          },
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '提交成功!',
          });
          this.showDrawer = false;
          this.$emit('submit');
        }
      } catch (error) {
        console.error('🙅 保存工作经历', error);
      }
    } else {
      this.$message({
        type: 'warning',
        message: '请检查表单，必填字段不能为空！',
      });
    }
  }

  // 公司联想搜索
  async remoteMethod(value: string, cb: any) {
    if (value.trim() === '') {
      cb([]);
      return;
    }
    try {
      const { data } = await getCompanySuggest({
        searchConditionName: 'COMPANY_NAME',
        searchText: value,
        pageNo: 1,
        pageSize: 30,
      });
      const companyOptions = data.list.map(el => ({
        label: el.name,
        value: el.name,
      }));
      cb(companyOptions);
    } catch (error) {
      console.error('🙅  公司联想搜索', error);
    }
  }
  showJobCategory(item:Partial<IWorks>) {
    showJobCategory().then(res => {
      if (Array.isArray(res)) {
        item.title = res?.[0]?.name;
        item.titleCode = res?.[0]?.code;
      } else {
        item.title = res?.name;
        item.titleCode = res?.code;
      }
    });
  }

  remove(index: number) {
    this.works.splice(index, 1);
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  handlerAddWork() {
    this.works.push({
      company: '',
      end: '',
      start: '',
      summary: '',
      title: '',
    });
  }
}
