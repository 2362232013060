

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import phone from '../../assets/image/phone.png';
import TimelineSection from './components/timeline-section.vue';
import TimelineSectionDot from './components/timeline-section-dot.vue';
import { IVideoBaseInfo, ITimelineSection, IMedia, IJobQuestionSnapshots } from './types';

type TVideoRef = {
  play(): void;
  pause(): void;
  getStatus(): string;
}

const PLAY_OPTIONS = {
  width: '96%',
  height: '99%',
  autoplay: false,
  videoSrc: '',
  controlBarVisibility: 'always',
  skinLayout: [
    { name: 'bigPlayButton', align: 'cc' },
    { name: 'H5Loading', align: 'cc' },
    { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
    { name: 'infoDisplay' },
    { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
    { name: 'thumbnail' },
    {
      name: 'controlBar',
      align: 'blabs',
      x: 0,
      y: 16,
      children: [
        { name: 'progress', align: 'blabs', x: 0, y: 44 },
        { name: 'playButton', align: 'tl', x: 15, y: 12 },
        { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
        { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
        { name: 'volume', align: 'tr', x: 5, y: 10 },
      ],
    },
  ],
};

@Component({
  components: {
    TimelineSection,
    TimelineSectionDot,
  },
})
export default class JobQusetionDetailsPreview<T extends Record<string, any>> extends Vue {
  isShowVideo = true;
  isShowText = false;
  isShowImage = false;

  timeLineIgnoreType: ITimelineSection['type'][] = ['answerMedia', 'interludeMedia']

  $refs!: {
    player: Vue & TVideoRef & HTMLElement
  }

  phoneImg = phone;

  @Prop({ required: true })
  job!: T;

  get exam() {
    return this.job?.exams && this.job?.exams[0];
  }

  get questionSnapshots() {
    if (!this.exam || !this.exam.questionSnapshots) {
      return [];
    }
    return (this.exam.questionSnapshots as IJobQuestionSnapshots[]).map((e: { medias: IMedia<string>[]; }) => ({
      ...e,
      medias: e.medias.map(m => ({
        ...m,
        data: this.$JSONParse<IVideoBaseInfo>(m.data),
      } as IMedia<IVideoBaseInfo>)),
    } as IJobQuestionSnapshots<IVideoBaseInfo>));
  }

  onPlayReady() {
    if (this.$refs.player) {
      const prefix = '.process-content .video-player .prism-player';
      const videoEl = document.querySelector(`${prefix} video`) as HTMLVideoElement;
      const togglePlayBtn = document.querySelector(`${prefix} .prism-play-btn`) as HTMLDivElement;
      videoEl.addEventListener('click', () => {
        togglePlayBtn && togglePlayBtn.click();
      });
    }
  }

  playerOptions = {
    ...PLAY_OPTIONS,
  };

  currentVideoPlayUrl?:string | null = '';
  currentImageUrl = '';
  currentText = '';

  tags = {
    allowSkip: ['题目全必答', '题目可跳过'],
    allowUseLocalVideo: ['只能现场录制', '可用本地视频'],
    allowMultiAnswer: ['不可重录', '可重录'],
  };

  defaultColor = '#F2F3F5;'
  linkColor = '#375EC5';
  activeColor = '#FF8730';

  /**
   * 面试官自我介绍
   **/
  get selfIntroMediaActity() {
    const video = this.$JSONParse<IVideoBaseInfo>(this.exam?.interviewer?.selfIntroMedia?.data);
    return {
      size: 'normal',
      icon: 'el-icon-location-outline',
      type: 'selfIntroMedia',
      content: '面试官自我介绍',
      playUrl: video?.playAddressList && video?.playAddressList[0] ? video.playAddressList[0].playUrl : null,
    };
  }

  /**
   * 过场视频
   **/
  getInterludeMediaActity(hash?: string | number) {
    const video = this.$JSONParse<IVideoBaseInfo>(this.exam?.interludeMedia?.data);
    const playUrl = video?.playAddressList ? video?.playAddressList[0].playUrl : null;
    return {
      size: 'normal',
      icon: 'el-icon-location-outline',
      type: 'interludeMedia',
      content: '过场视频',
      playUrl: `${playUrl}${hash ? `#${hash}` : ''}`,
    };
  }

  /**
   * 回答视频
   **/
  getAnswerMediaActity(hash?: string | number) {
    // eslint-disable-next-line max-len
    const playUrl = 'https://video.reta-inc.com/fbbc53d7357b4ddba93fa97972e088a4/a894d529ade54502a42388a43f0e35a1-53c5de5a0aa1f3f4dbb1a9434ae33e63-sd.mp4';
    return {
      size: 'normal',
      icon: 'el-icon-location-outline',
      type: 'answerMedia',
      content: '候选人回答视频',
      playUrl: `${playUrl}${hash ? `#${hash}` : ''}`,
    };
  }

  /**
   * 试题视频
   **/
  get questionActivities() {
    return (
      this.questionSnapshots.map((question, index) => ({
        size: 'normal',
        icon: 'el-icon-location-outline',
        type: 'questionSnapshots',
        questionContent: question.content,
        questionStyle: question.questionStyle,
        // eslint-disable-next-line max-len
        content: `第${index + 1}题: ${question.content}`,
        maxDuration: this.$QJUtils.formatSeconds(question.maxDuration),
        minDuration: this.$QJUtils.formatSeconds(question.minDuration),
        tips: question.tips || '无',
        playUrl: question.medias && question.medias[0]?.data?.playAddressList ? question.medias[0]?.data?.playAddressList[0].playUrl : null,
        imageUrl: question.medias && question.medias[0]?.data?.url,
      })) as ITimelineSection[]
    );
    // .reduce((total, cv, ci) => {
    //   const res = [
    //     ...total,
    //     // 之前的
    //     ...(ci === 0 ? [] : [this.getInterludeMediaActity(ci) as ITimelineSection]),
    //     // 过程视频  第一题之前没有过场视频
    //     cv,
    //     // 当前题目
    //     this.getAnswerMediaActity(ci) as ITimelineSection,
    //     // 回答视频
    //   ];
    //   return res;
    // }, [] as ITimelineSection[]);
  }

  /**
   * 面试官结束语
   **/
  get conclusionMediaActity() {
    const video = this.$JSONParse<IVideoBaseInfo>(this.exam?.interviewer?.conclusionMedia?.data);
    return {
      size: 'normal',
      icon: 'el-icon-location-outline',
      type: 'conclusionMedia',
      content: '面试官结束语',
      playUrl: video?.playAddressList && video?.playAddressList[0] ? video.playAddressList[0].playUrl : null,
    };
  }


  get activities() {
    return [
      {
        type: 'section',
        content: '开场',
        size: 'large',
        icon: 'el-icon-location',
      },
      this.selfIntroMediaActity,
      {
        type: 'section',
        content: '提问',
        size: 'large',
        icon: 'el-icon-location',
      },
      ...this.questionActivities,
      {
        type: 'section',
        content: '结束',
        size: 'large',
        icon: 'el-icon-location',
      },
      this.conclusionMediaActity,
    ];
  }

  get playerList() {
    return (this.activities as ITimelineSection[]).filter(e => !!e.playUrl);
  }

  isIgnore(type: string) {
    return this.timeLineIgnoreType.includes(type as ITimelineSection['type']);
  }

  isCurrent(activity: any) {
    const currentIndex = this.playerList.findIndex(e => e.playUrl === this.currentVideoPlayUrl);
    const current = this.playerList.find(e => e.playUrl === this.currentVideoPlayUrl);
    const activityIndex = this.playerList.findIndex(e => e.playUrl === activity.playUrl);
    if (
      current && currentIndex >= 0
      && ['answerMedia'].includes(current.type)
      && activityIndex > 0
    ) {
      // 如果当前播放到是回答视频， 则高亮上一道题
      return currentIndex - 1 === activityIndex;
    }

    if (
      current && currentIndex >= 0
      && ['interludeMedia'].includes(current.type)
      && activityIndex > 0
    ) {
      // 如果当前播放到是过场视频， 则高亮上一道题 (中间还有一个回答视频) 所以 -2
      return currentIndex - 2 === activityIndex;
    }
    return activity.playUrl && this.currentVideoPlayUrl === activity.playUrl;
  }

  onItemClick(activity: any) {
    if (activity && activity.playUrl) {
      this.playItem(activity);
    } else if (activity && activity.imageUrl) {
      this.showImage(activity);
    } else {
      this.showText(activity);
    }
  }

  showImage(activity: {imageUrl: string}) {
    this.isShowVideo = false;
    this.isShowText = false;
    this.isShowImage = true;
    this.currentImageUrl = activity.imageUrl;
  }

  showText(activity: {questionContent: string}) {
    this.isShowVideo = false;
    this.isShowText = true;
    this.isShowImage = false;
    this.currentText = activity.questionContent;
  }

  playItem(activity: ITimelineSection) {
    this.playerOptions.autoplay = false;
    this.currentVideoPlayUrl = activity.playUrl;
    this.isShowVideo = true;
    this.isShowText = false;
    this.isShowImage = false;
  }

  created() {
    this.currentVideoPlayUrl = this.playerList[0]?.playUrl || this.selfIntroMediaActity.playUrl || '';
  }
}
