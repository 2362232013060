

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICategoryNode } from '../../model/common';
import PlusIcon from './plus-icon.svg';
import MinusIcon from './minus-icon.svg';

@Component
export default class SecondCategoryItem extends Vue {
  @Prop({ type: Boolean, default: false }) opened!: boolean;
  @Prop({ required: true }) node!: ICategoryNode
  @Prop() selectedCode!: string
  @Prop() index!: number
  @Prop() selectCategorysCode!: string[]
  @Prop() multipleCategory!: boolean

  plusIcon = PlusIcon
  minusIcon = MinusIcon

  handleClick() {
    this.$emit('select', this.opened ? null : this.node);
  }

  selectNode(tag: ICategoryNode) {
    this.$emit('selectNode', tag);
  }
}
