import { AxiosResponse } from 'axios';
import { AjaxResponse } from '@w-admin/common/src/api/types';
import dd from 'dingtalk-jsapi';
import { removeAllStorageBykey } from '@w-admin/common/src/utils/global';
import { globalConfig } from '@w-admin/common/src/config';

export const isDing = !!dd.env.version;

const LOGIN_STATUS_ERROR_CODE = [4002, 4006];

export function getDingLoginResponseIntercepter(canceAllTasks) {
  return async function dingLoginResponseIntercepter<T>(res: AxiosResponse<AjaxResponse<T>>) {
    if (LOGIN_STATUS_ERROR_CODE.includes(res.data.code)) {
      canceAllTasks();
      removeAllStorageBykey(globalConfig.current.tokenKey);
      window.location.reload();
      return Promise.reject({
        message: 'token过期',
        config: res.config,
        code: String(res.data.code),
        response: res,
        isKnownError: true,
      });
    }
    return res;
  };
}
