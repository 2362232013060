















































































































/* eslint-disable no-unused-vars */
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AjaxResponse } from '@/common/types';
import RegisterForm from './register-form.vue';

const LoginModule = namespace('login');

@Component({
  components: {
    RegisterForm,
  },
})
export default class Login extends Vue {

  registerShow = false

  form = {
    email: '',
    password: '',
  };

  get isMobile() {
    return this.$QJUtils.isMobile();
  }

  isAggreed = this.isMobile ? true : false;

  rules = {
    email: [
      {
        required: true,
        message: '请输入企业邮箱登录',
      },
      {
        pattern: /^[a-zA-Z][a-zA-Z0-9_-]*$/,
        message: '邮箱格式不正确',
      },
    ],
    password: [{ required: true, message: '请输入密码' }],
  };

  @LoginModule.Action('login')
  loginAction!: (options: { email: string; password: string }) => Promise<AjaxResponse<{ authToken: string; nickname: string }>>;

  submitForm() {
    // eslint-disable-next-line
    (this.$refs['form'] as any).validate(async (valid: boolean) => {
      if (valid) {
        try {
          const { success } = await this.loginAction({
            email: `${this.form.email}${this.$QJConfig.emailSuffix}`,
            password: this.form.password,
          });
          if (!success) {
            return;
          }
          this.$router.push(this.getRedirect());
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  goToRegister() {
    this.registerShow = true;
  }

  getRedirect() {
    const { redirect = '/' } = this.$route.query;
    return redirect as string;
  }

  created() {
    const { reg } = this.$route.query;
    if (reg && Number(reg) === 1) {
      this.goToRegister();
    }
  }
}
