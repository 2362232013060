import { IPagination, ISearchAccount, ISearchEnterprise } from '@/common/types';
import { IAccountListData } from '@/model/account';
import { IEnterpriseListData } from '@/model/enterprise';
import { IJobListData } from '@/model/job';
import { enterpriseMenu } from '@/pages/home/router/menus';

export const initSearchParams = (searchParams?: ISearchEnterprise): ISearchEnterprise => ({
  pageSize: 20,
  pageNo: 1,
  argsName: 'COMPANY_NAME',
  ...searchParams,
});

export const initEnterpristListData = (listData?: IPagination<IEnterpriseListData>): IPagination<IEnterpriseListData> => ({
  totalCount: 0,
  pageSize: 20,
  pageNo: 1,
  list: [],
  ...listData,
});

export const initActiveMenu = (opt?: { name: string; index: number }) => ({
  name: enterpriseMenu[0].name,
  index: 0,
  ...opt,
});

export const initAccountSearchParams = (searchParams?: ISearchAccount): ISearchAccount => ({
  companyId: -1,
  pageSize: 20,
  pageNo: 1,
  ...searchParams,
});

export const initAccountListData = (listData?: IPagination<IAccountListData>): IPagination<IAccountListData> => ({
  totalCount: 0,
  pageSize: 20,
  pageNo: 1,
  list: [],
  ...listData,
});

export const initJobListData = (listData?: IJobListData[]): IJobListData[] => [...(listData ? listData : [])];
