

























import { Component, Prop, Vue } from 'vue-property-decorator';
import ExamPreview from './components/exam-preview.vue';
import { IJob, IMyPaper } from '../../model/job-create';


@Component({
  components: {
    ExamPreview,
  },
})
export default class JobExamPreview extends Vue {
  @Prop({ type: Boolean, default: true }) useDialog!: boolean;
  show = false;
  job: Partial<IJob> = {};
  myPaper: IMyPaper = {
    duration: 0,
    levelPanel: [],
    tagPanel: [],
    list: [],
  }
}
