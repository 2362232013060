


















































import { IContentAuditListDataWithPagination } from '@/common/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ContentAuditListCard from './content-audit-list-card.vue';

@Component({
  components: {
    ContentAuditListCard,
  },
})
export default class ContentAuditList extends Vue {
  @Prop({ required: true }) listData!: IContentAuditListDataWithPagination
  @Prop() auditorId?: number
  @Prop() showAuditor?: boolean
}
