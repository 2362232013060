
















































































































/* eslint-disable no-unused-vars */
import { IBatchImportResponse, IPagination, ISearchEnterprise } from '@/common/types';
import { Vue, Component } from 'vue-property-decorator';
import { IEnterpriseListData, getAuthLevelDesc, E_AuthLevel } from '@/model/enterprise';
import { namespace } from 'vuex-class';
import { bacthImportEnterprise } from '@/common/service';
import { IUploadFileOptions } from '@w-admin/common/src/components/common-upload';
import enterpriseColumnsConfig from './config';

const EnterpriseModule = namespace('enterprise');
const AccountModule = namespace('enterprise/account');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component
export default class EnterpriseManagement extends Vue {
  importLoading = false;
  getAuthLevelDesc = getAuthLevelDesc
  E_AuthLevel = E_AuthLevel

  get batchImportOption(): IUploadFileOptions<IBatchImportResponse> {
    return {
      url: '#',
      name: 'excelFile',
      limit: 1,
      accept: [
        '.xls',
        '.xlsx',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
      ].join(','),
      headers: {
        token: this.$QJStorage.getStorage(this.$QJConfig.tokenKey),
      },
      beforeUpload: () => {
        this.importLoading = true;
        return true;
      },
      onSuccess: res => {
        this.importLoading = false;
        if (!res) {
          return;
        }
        if (res.code === 200) {
          this.$notify.success('批量导入成功');
          this.pageNo = 1;
          return;
        }
        this.$notify.info({
          duration: 0,
          title: res.message || '批量导入失败',
          message: `<a
            target="_blank"
            href="${res.data?.failedUrl}"
            class="el-link el-link--primary is-underline"
          >
            <span class="el-link--inner">点击下载</span>
          <a>`,
          dangerouslyUseHTMLString: true,
        });
        this.pageNo = 1;
      },
      onError: () => {
        this.$notify.error('批量导入失败');
        this.importLoading = false;
      },
    };
  }

  async upload(options: IUploadFileOptions & { file: File }) {
    const { file, onError, onSuccess } = options;
    if (!file) {
      return;
    }
    const uploadClient = new this.$QJUtils.UploadClient({
      scene: 'COMPANY_PUBLIC',
      filename: file.name,
      file,
    });
    try {
      const { mediaId } = await uploadClient.upload();
      const rs = await bacthImportEnterprise(mediaId!);
      this.$QJUtils.safeCallback(onSuccess, [rs, file, [file]]);
    } catch (error) {
      this.$QJUtils.safeCallback(onError, [error, file, [file]]);
    }
  }

  downloadTemplateFile() {
    window.open(this.$QJConfig.enterpriseTemplate);
  }

  get tableMaxHeight() {
    return document.documentElement.clientHeight * 2;
  }

  enterpriseColumnsConfig = enterpriseColumnsConfig;

  @EnterpriseModule.State('searchParams')
  searchParams!: ISearchEnterprise;

  @EnterpriseModule.State('listData')
  listData!: IPagination<IEnterpriseListData>;

  @EnterpriseModule.Action('setSearchParams')
  setSearchParams!: (options: { searchParams: Partial<ISearchEnterprise>; unRefresh?: boolean }) => Promise<void>;

  @EnterpriseModule.Action('getList')
  getList!: () => Promise<IPagination<IEnterpriseListData>>;

  @EnterpriseModule.Action('clearSearch')
  clearSearch!: () => Promise<void>;

  @EnterpriseInfoModule.Action('setCurrentEnterprise')
  setCurrentEnterprise!: (enterpriseId: number) => Promise<void>;

  @AccountModule.Action('clearAccountList')
  clearAccountList!: () => Promise<void>;

  async gotoDetails(enterprise: IEnterpriseListData) {
    const loading = this.$loading({ text: '正在加载企业信息...', fullscreen: true });
    await this.clearAccountList();
    await this.setCurrentEnterprise(Number(enterprise.id));
    loading.close();
    this.$router.replace({
      name: 'account-management',
      params: { id: String(enterprise.id) },
    });
  }

  get searchValue() {
    return this.searchParams.argsValue || '';
  }

  set searchValue(argsValue: string) {
    this.setSearchParams({
      searchParams: { argsValue },
      unRefresh: true,
    });
  }

  get accountManager() {
    return this.searchParams.followerName || '';
  }

  set accountManager(followerName: string) {
    this.setSearchParams({
      searchParams: { followerName },
      unRefresh: true,
    });
  }

  get searchOption() {
    return this.searchParams.argsName;
  }

  set searchOption(argsName: ISearchEnterprise['argsName']) {
    this.setSearchParams({
      searchParams: { argsName },
      unRefresh: true,
    });
  }

  get pageSize() {
    return this.searchParams.pageSize;
  }

  set pageSize(pageSize: number) {
    this.setSearchParams({ searchParams: { pageSize } });
  }

  get pageNo() {
    return this.searchParams.pageNo;
  }

  set pageNo(pageNo: number) {
    this.setSearchParams({ searchParams: { pageNo } });
  }

  handleSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  handleCurrentChange(pageNo: number) {
    this.pageNo = pageNo;
  }

  submitSearch() {
    if ((!this.searchParams.argsName || !this.searchParams.argsValue) && this.searchParams.argsValue === '') {
      return;
    }
    // const searchOption = this.$QJDict.enterpriseSearchOption[this.searchParams.argsName];
    // if (!searchOption.check.test(this.searchParams.argsValue)) {
    //   this.$notify.error(`${searchOption.title}格式不正确`);
    //   return;
    // }
    this.getList();
  }

  created() {
    this.getList();
  }

  isDingCorp(row: IEnterpriseListData) {
    const { authLevel } = row;
    return authLevel !== null;
  }
}
