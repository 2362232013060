






import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICategoryNode } from '../../model/common';

@Component
export default class FirstCategoryItem extends Vue {
  @Prop({ type: Boolean, default: false }) active!: boolean;
  @Prop({ required: true }) node!: ICategoryNode

  handleClick() {
    this.$emit('select', this.node);
  }
}
