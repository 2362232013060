












import { IAuditList } from '@/model/content-audit';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContentAuditPreviewDangerless extends Vue {
  @Prop() audit?: IAuditList;

  modify() {
    this.$emit('modified', true);
  }
}
