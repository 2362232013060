import { getEnterpriseVideosList } from '@/common/service';
import { ISearchEnterpriseVideo } from '@/common/types';
import Vue from 'vue';
import { Module } from 'vuex';
import { IRootState } from '../root';

export interface IEenterpriseVideoModule {
  enterpriseVideosList: {

  }
}

export interface IEnterpriseVideoData {
  secondCategory: keyof IEenterpriseVideoModule['enterpriseVideosList'],
  enterpriseVideosData: any,
}

const types = {
  FETCH_ENTERPRISE_VIDEO_LIST: 'getEnterpriseVideosList',
};

const enterpriseVideoModule: Module<IEenterpriseVideoModule, IRootState> = {
  namespaced: true,
  state: {
    enterpriseVideosList: {

    },
  },
  getters: {},
  mutations: {
    getEnterpriseVideosList(state, data:IEnterpriseVideoData) {
      Vue.set(state.enterpriseVideosList, data.secondCategory, data.enterpriseVideosData);
    },
  },
  actions: {
    async getEnterpriseVideosList({ commit, dispatch }, options: ISearchEnterpriseVideo) {
      dispatch('wait/start', `enterpriseVideos.getList.${options.secondCategory}`, { root: true });
      const res = await getEnterpriseVideosList(options);
      dispatch('wait/end', `enterpriseVideos.getList.${options.secondCategory}`, { root: true });
      const secondCategory = options.secondCategory;
      if (res.success) {
        const { data } = res;
        commit(types.FETCH_ENTERPRISE_VIDEO_LIST, { secondCategory, enterpriseVideosData: data });
      }
      return res;
    },
  },
};

export default enterpriseVideoModule;
