

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IMedia } from '../types';
import playVideoIcon from '../../../assets/image/play-video-icon.png';
import previewVideo from '../../video-preview';

@Component
export default class VideoCard extends Vue {
  @Prop({ required: false, default: '' }) title!: string;
  @Prop({ required: true }) media!: IMedia<string>;
  @Prop({ required: true }) width!: string;
  @Prop({ required: true }) height!: string;
  @Prop({ required: false }) cardStyle!: string;

  isShowDialog = false;
  playVideoIcon = playVideoIcon;

  $refs!: {
    currentVideoPlayer: Vue & {
      pause: () => Promise<void>;
      isShowVideoPlayer: boolean;
    };
  };

  @Watch('isShowDialog')
  isShowDialogChanged(newVal: boolean) {
    if (!newVal) {
      this.$refs.currentVideoPlayer.pause();
    }
  }

  get getVideoInfo() {
    try {
      return JSON.parse(this.media.data);
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  async openVideoPlayer() {
    previewVideo(this.media, this.title);
  }
}
