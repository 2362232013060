import { Module } from 'vuex';
import { IRootState } from './root';

export interface IPlatformQuestionBankModuleState {
}

const platformQuestionBankModule: Module<IPlatformQuestionBankModuleState, IRootState> = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default platformQuestionBankModule;
