import { AxiosInstance } from 'axios';
import { VueConstructor } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { commonRequest } from './common';

export function setBgImg(el: HTMLElement, binding: DirectiveBinding, request: AxiosInstance) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const url = binding.value;
    commonRequest(url, request).then(base64Url => {
      el.style.backgroundImage = `url(${base64Url})`;
    }).catch(() => {
      el.style.backgroundImage = `url(${url})`;
    });
  }
}

const installAuthBgImgDirective = (Vue: VueConstructor, request: AxiosInstance) => {
  Vue.directive('auth-bgImg', {
    bind(el: HTMLElement, binding: DirectiveBinding) {
      setBgImg(el, binding, request);
    },
    componentUpdated(el, binding) {
      setBgImg(el, binding, request);
    },
  });
};

export default installAuthBgImgDirective;
