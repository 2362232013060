import globalConfig from './config';
export { globalConfig } from './config';
export { default as BrowserCompatiblePlugin } from './components/browser-compatible';
export { default as QJCommon } from './common-plugin';
export { default as ajax } from './api/ajax';
export { default as Dict } from './config/dict';
export { default as EventBus } from './event-bus';
export * as QJUtils from './utils/global';
export * as QJCommonModel from './model/common';
export * as QJFormSchema from './model/form-schema';
export const $Config = window.$QJConfig || globalConfig.current;
