












































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class JdAbstract<T extends Record<string, any>> extends Vue {
  @Prop({ required: true })
  job!: T;
}
