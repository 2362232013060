
import AddInterviewerDrawer from './index.vue';
import { IShowAddInterviewerDraweroptions, IAddInterviewerDrawerData, TAPIClent } from './types';

export default function showAddInterviewerDrawer(client: TAPIClent, options: IShowAddInterviewerDraweroptions) {
  const instance = new AddInterviewerDrawer() as AddInterviewerDrawer & IAddInterviewerDrawerData;
  instance.client = client;
  instance.jobId = options.jobId;
  instance.jobTitle = options.jobTitle;
  if (options.subtitle) {
    instance.subtitle = options.subtitle;
  }
  if (options.extraButton) {
    instance.extraButton = options.extraButton;
  }
  if (options.footerStyle) {
    instance.footerStyle = options.footerStyle;
  }
  if (typeof options.success === 'function') {
    instance.success = options.success;
  }
  if (options.subjectText) {
    instance.subjectText = options.subjectText;
  }
  instance.$mount();
  instance.show = true;
}
