





























import { getFilterList, getList } from '@/common/apis/list/list';
import { Component, Vue } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import Search from '@/pages/home/views/list-of-operations/components/search.vue';
import { candidateColumns, roleSelectOptions } from '@/pages/home/views/list-of-operations/contants';

@Component({
  components: {
    List,
    Search,
  },
})
export default class ListOfOperations extends Vue {
  activeName = 'candidate-list';
  pageNo = 1;
  pageSize = 10;
  totalCount = 0;
  loading = false;
  columns = candidateColumns;
  roleSelectOptions = roleSelectOptions;
  tableData: any = [];
  query: {
    cv: string;
    name: string;
    mobile: string;
    email: string;
  } = {
    cv: '',
    name: '',
    mobile: '',
    email: '',
  };
  // search
  roleName = '';
  companySearch = '';
  jobSearch = '';
  jobPublisher = '';
  topicValue = '';
  topicType = '';

  async created() {
    this.getTableList('CANDIDATE');
  }

  handlerCvSearch() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getTableList('CANDIDATE');
  }

  // 附身
  handlerLook(item) {
    const id = item.id;
    const name = item.name;
    const path = '';
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: id,
      targetName: name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(
      `${this.$QJConfig.BEndPath}${path}?${Object.entries(query)
        .map(e => e.join('='))
        .join('&')}`,
    );
  }

  // 获取所有列表（职位、公司等
  async getFilterList(type: string, companyIds: string = '') {
    const params = {
      type: type,
      companyIds: companyIds,
    };
    const { data } = await getFilterList(params);
    return data;
  }

  // 获取职位列表
  async getTableList(type: string) {
    this.loading = true;
    Object.keys(this.query).forEach(key => {
      if (!this.query[key] || this.query[key] === '') {
        delete this.query[key];
      }
    });
    const params = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      type: type,
      ...this.query,
    };
    try {
      const { data } = await getList(params);
      this.totalCount = data.totalCount!;
      this.tableData = data.list;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  clearQuery() {
    this.query = {
      cv: '',
      name: '',
      mobile: '',
      email: '',
    };
  }

  handlerClear(type: string) {
    this.query[type] = '';
    this.getTableList('CANDIDATE');
  }

  handlerSubmit(item: { type: string; value: string; isShowSearchIcon: boolean }) {
    this.query.name = '';
    this.query.mobile = '';
    this.query.email = '';
    if (item.isShowSearchIcon) {
      this.query[item.type] = item.value;
      this.getTableList('CANDIDATE');
    } else {
      this.query[item.type] = item.value;
    }
  }

  handleClick() {
    this.clearQuery();
    const { activeName } = this;
    this.pageNo = 1;
    this.pageSize = 10;
    switch (activeName) {
      case 'role-list':
        this.columns = candidateColumns;
        this.getTableList('CANDIDATE');
        break;
    }
  }

  handlerSizeChange(value: number) {
    this.pageSize = value;
    this.getTableList('CANDIDATE');
  }

  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getTableList('CANDIDATE');
  }
}
