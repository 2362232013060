















import { Vue, Component, Prop } from 'vue-property-decorator';

const ref = {
  innerChange: false,
};

@Component
export default class Anchor extends Vue {
  @Prop({ required: true }) anchorData!: { title: string, hash: string }[]
  @Prop() pos?: { left?: string, right?: string, top?: string, bottom?: string }
  @Prop() offset?: number

  currentHash = this.$route.hash;

  scrollToHash(hash: string) {
    const title = document.querySelector(hash) as HTMLElement | null;
    if (!title) { return; }
    ref.innerChange = true;
    window.scrollTo({
      top: title.offsetTop + (this.offset || 0),
      behavior: 'smooth',
    });
    setTimeout(() => {
      history.replaceState(null, '', document.URL.replace(/#([\w\W]+)/, hash));
      this.currentHash = hash;
      ref.innerChange = false;
    }, 300);
  }

  listenScroll() {
    window.addEventListener('scroll', () => {
      if (ref.innerChange) {
        return;
      }
      const currentNav = this.anchorData.find(nav => {
        const title = document.querySelector(nav.hash) as HTMLElement | null;
        if (!title) {
          return false;
        }
        return Math.abs(document.documentElement.scrollTop - title.offsetTop) <= 300;
      });
      if (!currentNav) {
        return;
      }
      history.replaceState(null, '', document.URL.replace(/#([\w\W]+)/, currentNav.hash));
      this.currentHash = currentNav.hash;
    });
  }

  created() {
    if (!this.$route.hash && this.anchorData[0]?.hash) {
      this.currentHash = this.anchorData[0].hash;
      history.replaceState(null, '', `${document.URL}${this.anchorData[0].hash}`);
    }
    this.listenScroll();
  }

}
