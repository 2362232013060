







































































































































import moment from 'moment';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import phone from '@w-admin/common/src/assets/image/phone.png';
import { IPlayerValueType, IPlayQuestionItem, TPlayType, IQuestionDetail } from '@w-admin/common/src/model/job-create';
import { IVideoBaseInfo } from '@w-admin/common/src/model/resource';

interface IQustionToTypeOptions {
  item: {
    type: 'QUESTION',
    data: IQuestionDetail
  },
  questionMediasList: IPlayQuestionItem[]
}

type TPlayValue = IPlayerValueType<IQuestionDetail | Function> | null

type TVideoRef = {
  play(): void;
  pause(): void;
  getStatus(): string;
}

const PLAY_OPTIONS = {
  width: '96%',
  height: '98%',
  autoplay: true,
  videoSrc: '',
  controlBarVisibility: 'always',
  skinLayout: [
    { name: 'bigPlayButton', align: 'cc' },
    { name: 'H5Loading', align: 'cc' },
    { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
    { name: 'infoDisplay' },
    { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
    { name: 'thumbnail' },
    {
      name: 'controlBar',
      align: 'blabs',
      x: 0,
      y: 16,
      children: [
        { name: 'progress', align: 'blabs', x: 0, y: 44 },
        { name: 'playButton', align: 'tl', x: 15, y: 12 },
        { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
        { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
        { name: 'volume', align: 'tr', x: 5, y: 10 },
      ],
    },
  ],
};

@Component
export default class PhoneExamPreview extends Vue {
  phoneImg = phone;

  title = ''

  @Prop() value!: TPlayValue
  @Prop({ type: Boolean, default: false }) withoutBackground?: boolean;
  @Prop({ type: Boolean, default: false }) customWidthHeader?: boolean;

  type: TPlayType = 'EMPTY'

  playContent: any | null = null

  videoUrl?: string | null = null;

  $refs!: {
    player: Vue & TVideoRef & HTMLElement
  }

  playerOptions = {
    ...PLAY_OPTIONS,
  };

  now = moment().format('HH:mm');
  timer: number | null = null


  qusetionToType(question: IQustionToTypeOptions) {

    const res = {
      type: '' as TPlayType,
      value: [] as string | string[] | Record<string, any> | Record<string, any>[] | undefined,
    };

    const { item: { data }, questionMediasList } = question;

    const {
      VIDEO,
      TEXT,
      PICTURE,
      CODE,
    } = this.$QJDict.questionStyle;

    switch (data.questionStyle) {
      case VIDEO.value:
        if (questionMediasList.map(e => e?.data?.playAddressList?.[0]?.playUrl)?.[0]) {
          // 有提问视频
          res.type = 'VIDEO';
          res.value = questionMediasList.map(e => e?.data?.playAddressList?.[0]?.playUrl)?.[0];
        } else {
          // 无提问视频 按 文本题 处理
          res.type = 'TEXT';
          res.value = data.content;
          this.title = VIDEO.title;
        }
        break;
      case TEXT.value:
        res.type = 'TEXT';
        res.value = data.content;
        this.title = TEXT.title;
        break;
      case PICTURE.value:
        res.type = 'PICTURE';
        res.value = {
          content: data.content,
          imgs: questionMediasList.map(e => e?.data?.url),
        };
        this.title = PICTURE.title;
        break;
      case CODE.value:
        res.type = 'RICH';
        res.value = {
          content: data.content,
          imgs: questionMediasList.map(e => e?.data?.url),
        };
        this.title = CODE.title;
        break;
      default:
        res.type = 'EMPTY';
        res.value = undefined;
        this.title = '';
        break;
    }
    return res;
  }

  playItem(item: TPlayValue) {
    if (!item) {
      this.type = 'EMPTY';
      this.playContent = undefined;
      return;
    }
    if (item.type === 'VIDEO') {
      this.type = 'VIDEO';
      this.playContent = item.data;
    } else if (item.type === 'QUESTION' && typeof item?.data === 'object') {
      const questionMediasList = (Array.isArray(item.data?.medias) ? item.data?.medias : []).map(e => {
        return {
          ...(item.data as IQuestionDetail),
          ...e,
          data: this.$JSONParse<IVideoBaseInfo>(e.data),
        };
      }) as IPlayQuestionItem[];
      const question = {
        item,
        questionMediasList,
      } as IQustionToTypeOptions;
      const playItem = this.qusetionToType(question);
      this.type = playItem.type;
      this.playContent = playItem.value;
    } else if (item.type === 'IMAGE_PLACEHOLDER' && typeof item?.data === 'string') {
      this.type = 'IMAGE_PLACEHOLDER';
      this.playContent = item.data;
    } else if (item.type === 'CUSTOM') {
      this.type = 'CUSTOM';
      this.playContent = item.data;
      if (this.customWidthHeader && this.playContent?.title) {
        this.title = this.playContent?.title;
      }
    } else {
      this.type = 'EMPTY';
      this.playContent = undefined;
    }
    this.$emit('playItem', {
      type: this.type,
      data: item.data,
    });
  }

  get isNotVideo() {
    return !['VIDEO', 'EMPTY'].includes(this.type || '');
  }

  get isVideo() {
    return this.type === 'VIDEO';
  }

  get isRich() {
    return ['PICTURE', 'RICH', 'CODE'].includes(this.type || '');
  }

  get isText() {
    return ['TEXT', 'PICTURE', 'RICH', 'CODE'].includes(this.type || '');
  }

  get isImagePlacehoder() {
    return this.type === 'IMAGE_PLACEHOLDER' && this.playContent && typeof this.playContent === 'string';
  }

  get isCustom() {
    return this.type === 'CUSTOM' && this.playContent;
  }

  onPlayReady() {
    if (this.$refs.player) {
      const prefix = '.exam-preview .prism-player';
      const videoEl = document.querySelector(`${prefix} video`) as HTMLVideoElement;
      const togglePlayBtn = document.querySelector(`${prefix} .prism-play-btn`) as HTMLDivElement;
      videoEl.addEventListener('click', () => {
        togglePlayBtn && togglePlayBtn.click();
      });
    }
  }

  updateNow() {
    this.timer = window.setInterval(() => {
      this.now = moment().format('HH:mm');
    }, 1000 * 60);
  }

  beforeDestroy() {
    clearInterval(this.timer!);
  }

  @Watch('value', { immediate: true })
  handleValueChange(value: TPlayValue) {
    this.playItem(value);
  }

  mobileWidth = '342px';
  mobileWidthNum = 342;
  mobileHeight = '700px';
  mobileHeightNum = 700;
  mobileHeaderHeight = '74px';
  mobileHeaderHeightNum = 74;

  created() {
    this.updateNow();
  }
}
