























































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class BrowserCompatible extends Vue {
  edgeURL = 'https://www.microsoft.com/zh-cn/edge';
  chromeURL = 'https://google.cn/intl/zh-CN/chrome/';

  gotoDownload(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.style.cssText = 'display: none;position: absolute;top: -9999;left: -9999px;';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
