






































import { getFilterList, getQuestionList } from '@/common/apis/list/list';
import { Component, Vue } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import Search from '@/pages/home/views/list-of-operations/components/search.vue';
import EditQuestion from '@/pages/home/views/enterprise-management/enterprise-question-bank/components/edit-question.vue';
import { interviewSearchOptions, roleSelectOptions, questionsColumns, topicTypeOptions } from '@/pages/home/views/list-of-operations/contants';

@Component({
  components: {
    List,
    Search,
    EditQuestion,
  },
})
export default class ListOfOperations extends Vue {
  activeName = 'questions-list';
  pageNo = 1;
  pageSize = 10;
  totalCount = 0;
  searchValue = '';
  loading = false;
  searchOption = '';
  columns = questionsColumns;

  interviewSearchOptions = interviewSearchOptions;
  roleSelectOptions = roleSelectOptions;
  topicTypeOptions = topicTypeOptions;
  tableData: any = [];
  companyOptions: any = [];
  jobOptions: any = [];

  // search
  roleName = '';
  companySearch = '';
  jobSearch = '';
  jobPublisher = '';
  topicValue = '';
  topicType = '';

  get currentCompanyId() {
    return 0;
  }

  async created() {
    this.getQuestionList();
  }

  reloadQuestionList() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getQuestionList();
  }

  handlerQuestionSizeChange(value: number) {
    this.pageSize = value;
    this.handlerTopicSearch();
  }

  handlerQuestionCurrentChange(value: number) {
    this.pageNo = value;
    this.handlerTopicSearch();
  }

  // 试题搜索
  handlerTopicSearch() {
    const { topicType } = this;
    let type = '';
    if (Array.isArray(topicType)) {
      type = topicType.join(',');
    }
    this.getQuestionList(type, this.topicValue, '');
  }

  // 附身
  handlerLook(item) {
    let id = null;
    let path = '';
    switch (this.activeName) {
      case 'questions-list':
        id = item.id;
        path = `/question-bank-manage/exam/edit/${id}`;
        break;
    }
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: item.uploader.id,
      targetName: item.uploader.name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(
      `${this.$QJConfig.BEndPath}${path}?${Object.entries(query)
        .map(e => e.join('='))
        .join('&')}`,
    );
  }

  // 获取所有列表（职位、公司等
  async getFilterList(type: string, companyIds: string = '') {
    const params = {
      type: type,
      companyIds: companyIds,
    };
    const { data } = await getFilterList(params);
    return data;
  }

  // 获取试题列表
  async getQuestionList(questionStyles: string = '', titleKeyword: string = '', categories: string = '') {
    this.loading = true;
    const params = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      questionStyles: questionStyles,
      titleKeyword: titleKeyword,
      categories: categories,
    };
    Object.keys(params).forEach(key => {
      if (!params[key] || params[key] === '') {
        delete params[key];
      }
    });
    const { data } = await getQuestionList(params);
    this.totalCount = data.total!;
    this.tableData = data.list.map(el => ({
      ...el,
      categoriesName: el.categories.map(el => el.name).join('、'),
      tagsName: el.tags.map(el => el.name).join('、'),
      duration: this.formatSecToStr(el.duration),
    }));
    this.loading = false;
  }

  formatSecToStr(seconds) {
    const daySec = 24 * 60 * 60;
    const hourSec = 60 * 60;
    const minuteSec = 60;
    const dd = Math.floor(seconds / daySec);
    const hh = Math.floor((seconds % daySec) / hourSec);
    const mm = Math.floor((seconds % hourSec) / minuteSec);
    const ss = seconds % minuteSec;
    if (dd > 0) {
      return dd + '天' + hh + '小时' + mm + '分钟' + ss + '秒';
    }
    if (hh > 0) {
      return hh + '小时' + mm + '分钟' + ss + '秒';
    }
    if (mm > 0) {
      return mm + '分钟' + ss + '秒';
    }
    return ss + '秒';
  }

  handleClick() {}
}
