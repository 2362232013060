export const enterpriseMenu = [
  {
    path: '/enterprise-management/:id/enterprise-info',
    name: 'enterprise-info',
    meta: {
      title: '企业信息 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '企业信息',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "enterprise-info" */ '../views/enterprise-management/enterprise-information/index.vue'),
  },
  {
    path: '/enterprise-management/:id/account-management',
    name: 'account-management',
    meta: {
      title: '账号管理 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '账号管理',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "account-management" */ '../views/enterprise-management/account-management/index.vue'),
  },
  {
    path: '/enterprise-management/:id/job-management',
    name: 'job-management',
    meta: {
      title: '职位管理 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '职位管理',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "enterprise-job-management" */ '../views/enterprise-management/job-management/index.vue'),
  },
  {
    path: '/enterprise-management/:id/enterprise-question-bank',
    name: 'enterprise-question-bank',
    meta: {
      title: '企业题库 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '企业题库',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "enterprise-question-bank" */ '../views/enterprise-management/enterprise-question-bank/index.vue'),
  },
  {
    path: '/enterprise-management/:id/enterprise-video',
    name: 'enterprise-video',
    meta: {
      title: '企业视频 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '企业视频',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "enterprise-video" */ '../views/enterprise-management/enterprise-video/index.vue'),
  },
  {
    path: '/enterprise-management/:id/interview-records',
    name: 'interview-records',
    meta: {
      title: '面试记录 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '面试记录',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "interview-records" */ '../views/enterprise-management/interview-records/index.vue'),
  },
];

export const enterpriseDetails = [
  {
    path: '/enterprise-management/:id',
    name: 'enterprise-details',
    meta: {
      title: '企业详情 - 企业管理 | 抢镜内部运营平台',
      menuTitle: '企业详情',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "enterprise-details" */ '../views/enterprise-management/details.vue'),
    children: [...enterpriseMenu],
  },
];

export const auditSubMenu = [
  {
    path: '/interview-audit/account-audit',
    name: 'enterprise-management-account-audit',
    meta: {
      title: '企业账号审核 | 抢镜内部运营平台',
      menuTitle: '企业账号审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () =>
      import(/* webpackChunkName: "enterprise-management-account-audit" */ '../views/enterprise-management/enterprise-account-audit/index.vue'),
  },
  {
    path: '/interview-audit/interview-content-audit',
    name: 'interview-content-audit',
    meta: {
      title: '面试内容审核 | 抢镜内部运营平台',
      menuTitle: '视频风险合规',
      requiresAuth: true,
      icon: 'el-icon-finished',
      nocontainer: true,
      type: 'RISK',
    },
    component: () => import(/* webpackChunkName: "interview-content-audit" */ '../views/interview-audit/interview-content-audit/index.vue'),
  },
  {
    path: '/interview-audit/video-quality-audit',
    name: 'video-quality-audit',
    meta: {
      title: '拍摄质量审核 | 抢镜内部运营平台',
      menuTitle: '拍摄质量审核',
      requiresAuth: true,
      icon: 'el-icon-finished',
      type: 'QUALITY',
    },
    component: () => import(/* webpackChunkName: "video-quality-audit" */ '../views/interview-audit/video-quality-audit/index.vue'),
  },
  {
    path: '/interview-audit/interview-quality-mark',
    name: 'interview-quality-mark',
    meta: {
      title: '面试质量标注 | 抢镜内部运营平台',
      menuTitle: '面试质量标注',
      requiresAuth: true,
      icon: 'el-icon-edit-outline',
      type: 'CAPBILITY',
    },
    component: () => import(/* webpackChunkName: "interview-quality-mark" */ '../views/interview-audit/interview-quality-mark/index.vue'),
  },
];

export const workplaceAuditMenu = [
  {
    path: '/workplace-audit/user-information-review',
    name: 'user-information-review',
    meta: {
      title: '用户审核 | 抢镜内部运营平台',
      menuTitle: '用户审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "user-information-review" */ '../views/workplace-audit/user-information-review/index.vue'),
  },
  {
    path: '/workplace-audit/user-dynamic-audit',
    name: 'user-dynamic-audit',
    meta: {
      title: '内容审核 | 抢镜内部运营平台',
      menuTitle: '内容审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "user-dynamic-audit" */ '../views/workplace-audit/user-dynamic-audit/index.vue'),
  },
  {
    path: '/workplace-audit/report-review',
    name: 'report-review',
    meta: {
      title: '举报审核 | 抢镜内部运营平台',
      menuTitle: '举报审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "report-review" */ '../views/workplace-audit/report-review/index.vue'),
  },
  {
    path: '/workplace-audit/review-content-review',
    name: 'review-content-review',
    meta: {
      title: '评论审核 | 抢镜内部运营平台',
      menuTitle: '评论审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "review-content-reviewt" */ '../views/workplace-audit/review-content-review/index.vue'),
  },
  {
    path: '/workplace-audit/achievement-review',
    name: 'achievement-review',
    meta: {
      title: '成果审核 | 抢镜内部运营平台',
      menuTitle: '成果审核',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "achievement-review" */ '../views/workplace-audit/achievement-review/index.vue'),
  },
];

// 面试管理子菜单
export const interviewManagerMenu = [
  {
    path: '/interview-management/interview-list',
    name: 'interview-list',
    meta: {
      title: '面试列表 | 抢镜内部运营平台',
      menuTitle: '面试列表',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "interview-list" */ '../views/interview-management/interview-list/index.vue'),
  },
  {
    path: '/interview-management/interview-monitor',
    name: 'interview-monitor',
    meta: {
      title: '面试数据监控 | 抢镜内部运营平台',
      menuTitle: '面试数据监控',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "interview-monitor" */ '../views/interview-management/interview-monitor/index.vue'),
  },
];

// 候选人库
export const candidatePollMenu = [
  {
    path: '/candidate-list',
    name: 'candidate-list',
    meta: {
      title: '候选人列表 | 抢镜内部运营平台',
      menuTitle: '候选人列表',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    component: () => import(/* webpackChunkName: "resume-review" */ '../views/candidate-pool/candidate-list/index.vue'),
  },
  {
    path: '/resume-review',
    name: 'resume-review',
    meta: {
      title: '简历库 | 抢镜内部运营平台',
      menuTitle: '简历库',
      requiresAuth: true,
      icon: 'el-icon-user',
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "resume-review" */ '../views/candidate-pool/resume-review/index.vue'),
  },
  {
    path: '/resume-annotation',
    name: 'resume-annotation',
    meta: {
      title: '简历批注 | 抢镜内部运营平台',
      menuTitle: '简历批注',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    component: () => import(/* webpackChunkName: "resume-annotation" */ '../views/candidate-pool/resume-annotation/index.vue'),
  },
  {
    path: '/resume-detail',
    name: 'resume-detail',
    meta: {
      title: '简历详情 | 抢镜内部运营平台',
      menuTitle: '简历详情',
      requiresAuth: true,
      icon: 'el-icon-user',
      hide: true,
    },
    component: () => import(/* webpackChunkName: "resume-detail" */ '../views/candidate-pool/resume-review/components/cv-detail.vue'),
  },
];

export const auditDetail = [
  {
    path: '/enterprise-management/account-audit/detail/:id',
    name: 'enterprise-management-account-audit-detail',
    meta: {
      title: '账号审核详情 | 抢镜内部运营平台',
      menuTitle: '账号审核详情',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "interview-monitor" */ '../views/enterprise-management/enterprise-account-audit/detail.vue'),
  },
];

// 系统设置
export const settingMenu = [
  {
    path: '/setting/black-thesaurus',
    name: 'black-thesaurus',
    meta: {
      title: '黑词库 | 抢镜内部运营平台',
      menuTitle: '黑词库',
      requiresAuth: true,
      icon: 'el-icon-setting',
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/setting/black-thesaurus/index.vue'),
  },
  {
    path: '/setting/setting-page',
    name: 'SettingPage',
    meta: {
      title: '标签管理 | 抢镜内部运营平台',
      menuTitle: '标签管理',
      requiresAuth: true,
      icon: 'el-icon-setting',
    },
    component: () => import(/* webpackChunkName: "setting-page" */ '../views/setting/index.vue'),
  },
];

export const appMenu = [
  {
    path: '/enterprise-management',
    name: 'enterprise-management',
    meta: {
      title: '企业管理 | 抢镜内部运营平台',
      menuTitle: '企业管理',
      requiresAuth: true,
      icon: 'el-icon-office-building',
    },
    component: () => import(/* webpackChunkName: "enterprise-management" */ '../views/enterprise-management/index.vue'),
  },
  {
    path: '/all-job-management',
    name: 'all-job-management',
    meta: {
      title: '职位管理 | 抢镜内部运营平台',
      menuTitle: '职位管理',
      requiresAuth: true,
      keepAlive: true,
      icon: 'el-icon-office-building',
    },
    component: () => import(/* webpackChunkName: "job-management" */ '../views/job-management/index.vue'),
  },
  {
    path: '/interview-management',
    name: 'interview-management',
    meta: {
      title: '面试管理 | 抢镜内部运营平台',
      menuTitle: '面试管理',
      requiresAuth: true,
      icon: 'el-icon-date',
    },
    component: () => import(/* webpackChunkName: "interview-list" */ '../views/interview-management/interview-list/index.vue'),
  },
  {
    path: '/platform-question-bank',
    name: 'platform-question-bank',
    meta: {
      title: '面试题库 | 抢镜内部运营平台',
      menuTitle: '面试题库',
      requiresAuth: true,
      icon: 'el-icon-notebook-2',
    },
    component: () => import(/* webpackChunkName: "platform-question-bank" */ '../views/platform-question-bank/interview-question-bank/index.vue'),
  },
  {
    path: '/candidate-pool',
    name: 'candidate-pool',
    meta: {
      title: '候选人库 | 抢镜内部运营平台',
      menuTitle: '候选人库',
      requiresAuth: true,
      icon: 'el-icon-user',
    },
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "candidate-pool" */ '../views/candidate-pool/index.vue'),
    children: [...candidatePollMenu],
  },
  {
    path: '/workplace-audit',
    name: 'workplace-audit',
    meta: {
      title: '社区审核 | 抢镜内部运营平台',
      menuTitle: '社区审核',
      requiresAuth: true,
      icon: 'el-icon-date',
    },
    component: () => import(/* webpackChunkName: "workplace-audit" */ '../views/workplace-audit/index.vue'),
    children: [...workplaceAuditMenu],
  },
  {
    path: '/interview-audit',
    name: 'interview-audit',
    meta: {
      title: '招聘审核 | 抢镜内部运营平台',
      menuTitle: '招聘审核',
      requiresAuth: true,
      icon: 'el-icon-finished',
    },
    component: () => import(/* webpackChunkName: "interview-audit" */ '../views/interview-audit/index.vue'),
    children: [...auditSubMenu],
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: '系统配置 | 抢镜内部运营平台',
      menuTitle: '系统配置',
      requiresAuth: true,
      icon: 'el-icon-setting',
      // hide: true,
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/setting/index1.vue'),
    children: [...settingMenu],
  },
  {
    path: '/white-list',
    name: 'white-list',
    meta: {
      title: '白名单设置 | 抢镜内部运营平台',
      menuTitle: '白名单设置',
      requiresAuth: true,
      icon: 'el-icon-setting',
      hide: true,
    },
    component: () => import(/* webpackChunkName: "white-list" */ '../views/setting/white-list.vue'),
  },
  {
    path: '/clue',
    name: 'clue',
    meta: {
      title: '代招管理 | 抢镜内部运营平台',
      menuTitle: '代招管理',
      requiresAuth: true,
      icon: 'el-icon-monitor',
    },
    component: () => import(/* webpackChunkName: "recruitment-clue" */ '../views/recruitment-management/tabs.vue'),
  },
  {
    path: '/channel',
    name: 'channel',
    meta: {
      title: '代招渠道 | 抢镜内部运营平台',
      menuTitle: '代招渠道',
      requiresAuth: true,
      icon: 'el-icon-monitor',
      hide: true,
    },
    component: () => import(/* webpackChunkName: "recruitment-channel" */ '../views/recruitment-management/recruitment-channel.vue'),
  },
  {
    path: '/import-cv',
    name: 'import-cv',
    meta: {
      title: '管理代招职位 | 抢镜内部运营平台',
      menuTitle: '管理代招职位',
      requiresAuth: true,
      icon: 'el-icon-monitor',
      hide: true,
    },
    component: () => import(/* webpackChunkName: "recruitment-channel" */ '../views/recruitment-management/import-cv.vue'),
  },
];

export default appMenu;
