
































import { Component, Vue } from 'vue-property-decorator';
import { importClue } from '@/common/apis/recruitment/index';
import { IUploadFileOptions } from '@w-admin/common/src/components/common-upload';
import { IJobImportResponse } from '@/common/types';

@Component
export default class AddClue extends Vue {
  dialogVisible = false;
  importLoading = false;
  mediaId: number | null | undefined = null;

  get jobImportOption(): IUploadFileOptions<IJobImportResponse> {
    return {
      url: '#',
      name: 'excelFile',
      limit: 1,
      accept: [
        '.xls',
        '.xlsx',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
      ].join(','),
      // data: {
      //   companyId: this.currentEnterprise.id,
      // },
      headers: {
        token: this.$QJStorage.getStorage(this.$QJConfig.tokenKey),
      },
      beforeUpload: () => {
        this.importLoading = true;
        return true;
      },
      onSuccess: res => {
        this.importLoading = false;
        if (!res) {
          return;
        }
        if (!res?.success) {
          this.$notify.error('服务器内部错误');
          return;
        }
      },
      onError: () => {
        this.$notify.error('线索导入失败');
        this.importLoading = false;
      },
      onExceed: () => {
        this.$message.warning('只能上传一个文件～');
      },
    };
  }

  async upload(options: IUploadFileOptions & { file: File }) {
    const { file, onError } = options;
    if (!file) {
      return;
    }
    const uploadClient = new this.$QJUtils.UploadClient({
      scene: 'COMPANY_PUBLIC',
      filename: file.name,
      file,
    });
    try {
      const { mediaId } = await uploadClient.upload();
      console.log('mediaId', mediaId);
      this.mediaId = mediaId;
    } catch (error) {
      this.$QJUtils.safeCallback(onError, [error, file, [file]]);
    }
  }
  // 下载模版
  handlerDownLoad() {
    window.open(
      // eslint-disable-next-line max-len
      'https://qiangjing.yuque.com/attachments/yuque/0/2022/xlsx/23185177/1662715432710-4518cd35-ea35-472f-8d6f-d76ee86b2c70.xlsx?from=https%3A%2F%2Fqiangjing.yuque.com%2Fstaff-pd00kk%2Fstg89k%2Fakg229',
    );
  }

  handlerCancle() {
    this.dialogVisible = false;
  }

  open() {
    this.dialogVisible = true;
  }

  async handlerSave() {
    try {
      const { success } = await importClue({ mediaId: this.mediaId! });
      if (success) {
        this.$message({
          type: 'success',
          message: '导入成功！',
        });
        this.$emit('success');
        this.dialogVisible = false;
      }
    } catch (error) {
      console.error('🙅 导入线索', error);
    }
  }
}
