
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IMyPaper } from '../../../model/job-create';

interface ItxtNum {
  text: string
  num: number
}
interface ILevelMap {
  easy: ItxtNum,
  medium: ItxtNum,
  hard: ItxtNum,
}

@Component
export default class JobPaperPreview extends Vue {
  @Prop(Boolean)
  showTypeAndCount!: boolean

  @Prop(String)
  answerTypeText!: string

  @Prop(Object)
  myPaper!: IMyPaper

  // 计算试卷时间
  get paperTime() {
    const duration: number = this.myPaper.list.reduce((t, v) => t + v.duration, 0);
    return Math.ceil(duration / 60);
  }

  // 难度分布
  get levelDistr() {
    const EASY = (this.myPaper.list || []).filter(lev => lev.level === 'EASY').length;
    const MEDIUM = (this.myPaper.list || []).filter(lev => lev.level === 'MEDIUM').length;
    const HARD = (this.myPaper.list || []).filter(lev => lev.level === 'HARD').length;
    const levelMap: ILevelMap | any = {
      easy: {
        text: '简单',
        num: EASY,
      },
      medium: {
        text: '适中',
        num: MEDIUM,
      },
      hard: {
        text: '困难',
        num: HARD,
      },
    };
    const textArr: string[] = [];
    Object.keys(levelMap).forEach((l: string) => {
      if (levelMap[l].num) {
        textArr.push(levelMap[l].text + levelMap[l].num);
      }
    });
    return textArr.join(' | ');
  }
  // 考察点分布
  get tagsDistr() {
    const tags = this.myPaper.list.filter(t => t.tags).map(tag => {
      return tag.tags?.map(t => t.name);
    });
    let tagList: string[] = [];
    tags.forEach(t => {
      tagList = [...tagList, ...t];
    });
    const removeRepeatTag = Array.from(new Set(tagList));
    return removeRepeatTag.join(' | ');
  }
}
