




























































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';

@Component({
  components: {
    Resume,
  },
})
export default class LanguageSkills extends Vue {
  showDrawer = false
  previewResumeUrl = ''

  list: {
    language: string;
    speaking: string;
    write: string;
  }[] = [{
    language: '',
    speaking: '',
    write: '',
  }]
  open(previewResumeUrl: string) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  // 添加项目经历
  handlerEduProject() {
    this.list.push({
      language: '',
      speaking: '',
      write: '',
    });
  }
}
