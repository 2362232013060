import {
  ICurrentAuditVideo,
  IGetVideoQualityAuditListOptions,
  IVideoQualityAuditListDataWithPagination,
  TVideoQualityAuditTabName,
} from '@/common/types';
import { TVideoQualityAudit } from '@/model/video-quality-audit';
import { Module } from 'vuex';
import {
  initCurrentVideoQualityAuditListData,
  initVideoQualityAuditSearchParams,
  initVideoQualityAuditTabs,
} from './init';
import { IRootState } from './root';

export interface IVideoQualityAuditModuleTabs {
  name: TVideoQualityAuditTabName
  title: string
  status: 0 | 1 | 2 | 3
}

export interface IVideoQualityAuditModuleState {
  tabs: IVideoQualityAuditModuleTabs[];
  currentTab?: IVideoQualityAuditModuleTabs;
  currentTabIndex: number;
  currentListData: IVideoQualityAuditListDataWithPagination;
  searchParams: IGetVideoQualityAuditListOptions;
  currentAuditInterview?: TVideoQualityAudit;
  currentVideo?: ICurrentAuditVideo;
  currentVideoIndex?: number;
}

const types = {
  UPDATE_CURRENT_TAB_BY_NAME: 'updateCurrentTabByName',
  UPDATE_SEARCH_PARAMS: 'updateSearchParams',
  UPDATE_CURRENT_LIST_DATA: 'updateCurrentListData',
  UPDATE_CURRENT_AUDIT_INTERVIEW: 'updateCurrentAuditInterview',
  UPDATE_CURRENT_VIDEO: 'updateCurrentVideo',
};


const initTabs = initVideoQualityAuditTabs();

const videoQualityAuditModule: Module<IVideoQualityAuditModuleState, IRootState> = {
  namespaced: true,
  state: {
    tabs: initTabs,
    currentTab: initTabs[0],
    currentTabIndex: 0,
    currentAuditInterview: undefined,
    currentVideo: undefined,
    currentVideoIndex: 0,
    currentListData: initCurrentVideoQualityAuditListData(),
    searchParams: initVideoQualityAuditSearchParams(),
  },
  getters: {
    currentTabName(state) {
      return state.currentTab?.name;
    },
    tabs(state) {
      return state.tabs;
    },
  },
  mutations: {
    updateSearchParams(state, searchParams: Partial<IGetVideoQualityAuditListOptions>) {
      state.searchParams = {
        ...state.searchParams,
        ...searchParams,
      };
    },
    updateCurrentTabByName(state, tabName: TVideoQualityAuditTabName) {
      const tab = state.tabs.find(tab => tab.name === tabName);
      const index = state.tabs.findIndex(tab => tab.name === tabName);
      state.currentTab = tab;
      state.currentTabIndex = index;
    },
    updateCurrentListData(state, listData: IVideoQualityAuditListDataWithPagination) {
      state.currentListData = listData;
    },
    updateCurrentAuditInterview(state, auditInterview?: TVideoQualityAudit) {
      state.currentAuditInterview = auditInterview;
    },
    updateCurrentVideo(state, { video, index }: { video?: ICurrentAuditVideo, index?: number }) {
      state.currentVideo = video;
      state.currentVideoIndex = index;
    },
  },
  actions: {
    setCurrentTabByName({ commit /** , dispatch, state **/ }, tabName: TVideoQualityAuditTabName) {
      commit(types.UPDATE_CURRENT_TAB_BY_NAME, tabName);
      commit(types.UPDATE_CURRENT_AUDIT_INTERVIEW, undefined);
      // const tab = state.tabs.find(tab => tab.name === tabName);
      // dispatch('setSearchParams', {
      //   searchParams: {
      //     status: tab?.status,
      //     pageNo: 1,
      //     createdBy: '',
      //   },
      // });
    },
  },
};

export default videoQualityAuditModule;
