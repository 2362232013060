









/* eslint-disable no-unused-vars */
import { TVideoQualityAuditTabName } from '@/common/types';
import showGetAuditTaskModal from '@/components/get-audit-task-modal';
import { IVideoQualityAuditModuleTabs } from '@/pages/home/store/video-quality-audit-module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const videoQualityAuditModule = namespace('videoQuality');

@Component
export default class VideoQualityAuditNavHeader extends Vue {
  @Prop() currentMenuTitle!: string;

  @videoQualityAuditModule.Action('setCurrentTabByName')
  setCurrentTabByNameAction!: (name: TVideoQualityAuditTabName) => Promise<void>;

  @videoQualityAuditModule.Getter('tabs')
  tabs!: IVideoQualityAuditModuleTabs[];

  @videoQualityAuditModule.Getter('currentTabName')
  currentTabName!: TVideoQualityAuditTabName;

  @videoQualityAuditModule.State('currentTabIndex')
  currentTabIndex!: number;

  onTabChange(name: TVideoQualityAuditTabName) {
    if (name !== this.currentTabName) {
      this.setCurrentTabByNameAction(name);
    }
  }

  iWantToGetTask() {
    showGetAuditTaskModal('QUALITY');
  }
}
