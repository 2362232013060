




import { Vue, Component } from 'vue-property-decorator';
// import { namespace } from 'vuex-class';
// const InterviewRecordsModule = namespace('enterprise/interviewRecords');

@Component
export default class InterviewRecords extends Vue {
}
