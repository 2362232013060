/* eslint-disable max-len */
export const uploadConfig = {
  baseUrl: '',
  uploadObjPath: '/api/obj/v2/preUpload',
  uploadVideoPath: '/api/video/v1/preUpload',
  postPath: '/api/obj/v2/postUpload',
  singleSizeLimit: 20 * 1024 ** 2,
  videoSizeLimit: 100 * 1024 ** 2,
  aliyunConfig: {
    userId: '1365542894676266',
    region: 'cn-shanghai',
  },
  getVideoInfoPath: '/api/video/v1/getVideoInfo',
  updateCoverPath: '/api/video/v1/updateCover',
  getMezzanineInfoPath: '/api/video/v1/getMezzanineInfo',
};

export const preUploadSceneMap = {
  /**
   * 用户视频库, 个人简介等
   */
  USER_VIDEO: {
    code: 'USER_VIDEO',
    scene: '用户视频',
    desc: '用户视频库, 个人简介等',
    video: true,
  },
  /**
   * 用户头像等
   */
  USER_PUBLIC: {
    code: 'USER_PUBLIC',
    scene: '用户公开附件',
    desc: '用户头像等',
  },
  /**
   * 用户私有附件
   */
  USER_PRIVATE: {
    code: 'USER_PRIVATE',
    scene: '用户私有附件',
    desc: '用户私有附件',
  },
  /**
   * 公司视频库中的媒体。公司简介、职位详情、面试欢迎等
   */
  COMPANY_VIDEO: {
    code: 'COMPANY_VIDEO',
    scene: '公司视频',
    desc: '公司视频库中的媒体。公司简介、职位详情、面试欢迎等',
  },
  /**
   * 公司Logo等
   */
  COMPANY_PUBLIC: {
    code: 'COMPANY_PUBLIC',
    scene: '公司公开附件',
    desc: '公司Logo等',
  },
  /**
   * 公司营业执照等
   */
  COMPANY_PRIVATE: {
    code: 'COMPANY_PRIVATE',
    scene: '公司私有附件',
    desc: '公司营业执照等',
  },
  /**
   * 视频封面
   */
  VIDEO_COVER: {
    code: 'VIDEO_COVER',
    scene: '视频封面',
    desc: '视频封面',
  },
  /**
   * 简历附件pdf/doc'
   */
  CV: {
    code: 'CV',
    scene: '简历',
    desc: '简历附件pdf/doc',
  },
  /**
   * 面试问题中的媒体
   */
  QUESTION: {
    code: 'QUESTION',
    scene: '问题',
    desc: '面试问题中的媒体',
  },
  /**
   * 面试回答中的媒体
   */
  ANSWER: {
    code: 'ANSWER',
    scene: '答题',
    desc: '面试回答中的媒体',
  },
  /**
   * 头像
   */
  AVATAR: {
    code: 'AVATAR',
    scene: '头像',
    desc: '头像',
  },
};

export const uploadUrl = (isVideo: boolean = false) => `${uploadConfig.baseUrl}${isVideo ? uploadConfig.uploadVideoPath : uploadConfig.uploadObjPath}`;

export const ImageAccept = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  // '.webp',
  // '.svg',
  // 'image/*',
  'image/jpg',
  'image/png',
  'image/jpeg',
  'image/gif',
  // 'image/webp',
  // 'image/svg+xml',
];

export const defaultImg = 'data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMA///////////////////////////////////////////////////////////////////////////////////////bAEMB///////////////////////////////////////////////////////////////////////////////////////AABEIAMgAyAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAgED/8QAHRABAQADAQEBAQEAAAAAAAAAAAECETESQWFRIf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDoAAAAG4zcBozcbsAAAAANs3P6DQAAAAAAAAAAAZanbckgCvNPIJFef1l/wGNnWLkBoADLdFukgwbJtvkEivP6eQYbv9b5rNUDdN01TV/gN3T0xgK9fh6SA6b2JxAMviVZJB0CcgDLdIbbtgNkWT/AAGXgJt2wVAbJpoAAAAAAAAAObpXMFY/QxAbeIXeIBc41k40EcY6dRZoFStc1S/0FAAmxLoizQNlU5rl2DQAC3Qi9Bu6bqQHQTFAy8Qu8QC8eBOAF4hd5UAucanFQAAJsS6Js/gMl0tzbvQLGS7aCLNCrNpBUu2o4sBF6tlmwQN1W6oEUAMvELy4gFzgTgDXN0cwVj9UnFQAAAAMs2h0rmCp1ScVAIv8ApbtgNWyTTQAAAAAATklWXxIOkAAQtF7Qbj1SJ1YAAAAMvEKySC5xlvxvIgBUn1km1gAAAAAAAAnJKr1kBYACL1aL0CdWidiwAAAARegwG9GLk0DQAAAAAAAAARek6XpOgsABN6pOQMW5r3AaJ9Hr8BTLwl2XgIBsmwbJ9UJtBQndPQKE+m+oDRm4bgNAAABF6To3HoKAATl8UnIEguSAgXqNBEirxoDmrFl6ToLTYoBDHQBzHTTNQEC9Q8wECvP6eQZs3TVNUGKxSrH6CgAGZcay8BC5xCpQUJ9fh6BQndbLsGZJXeIBca5gOgjdN0Fid09AoT6/G+gaM3DcBrLxu4y8BCsUrnAaAAy8aA5i9RuoDmOgCNVUjQBmmgJ8mqoBGqaqwEMdAHMdGagIF6hqAgV5PIJXOM8qAAAAAAAAAAAAAAAAAAAAAAAAAAAAB//Z';