








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VideoItemCheckBox extends Vue {
  @Prop({ type: Number, required: true }) index!: number;
  @Prop({ type: Boolean }) checked?: boolean;
  @Prop({ type: String, required: true }) coverUrl!: string;

  get innerChecked() {
    return !!this.checked;
  }

  set innerChecked(checked: boolean) {
    this.$emit('update:checked', checked);
  }

  checkboxClick() {
    this.$emit('update:checked', !this.checked);
  }
}
