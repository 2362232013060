

































import { Vue, Component } from 'vue-property-decorator';
import EditVideo from './components/edit-video.vue';
import VideoListItem from './components/video-list-item.vue';
import { namespace } from 'vuex-class';
import Anchor from '@/components/anchor.vue';
import { IEnterprise } from '@/model/enterprise';
const EnterpriseVideoModule = namespace('enterprise/enterpriseVideo');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component({
  components: {
    EditVideo,
    Anchor,
    VideoListItem,
  },
})
export default class EnterpriseVideo extends Vue {
  videoTypes = this.$QJConfig.enterpriseVideoParentCategoriesTypes;

  currentParentCategory = '0';

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseVideoModule.State('enterpriseVideosList')
  enterpriseVideosList!: any;

  @EnterpriseVideoModule.Action('getEnterpriseVideosList')
  getEnterpriseVideosListAction!: any;

  get getCurrentSecondCategoryList(): { name: string; code: string }[] {
    const parentCategoryMap = new Map<string, { name: string; code: string }[]>([
      [
        '0',
        [
          // 职位类
          { name: '团队介绍', code: '0' },
          { name: '工作内容', code: '1' },
          { name: '职位类业务介绍', code: '2' },
          { name: '员工培训', code: '3' },
          { name: '其它', code: '9999' },
        ],
      ],
      [
        '1',
        [
          // 公司类
          { name: '公司环境', code: '100' },
          { name: '公司发展', code: '101' },
          { name: '公司类业务介绍', code: '102' },
          { name: '公司其他类', code: '99999' },
        ],
      ],
      [
        '2',
        [
          // 面试官类
          { name: '开场自我介绍', code: '1000' },
          { name: '面试结束语', code: '1001' },
          { name: '转场视频', code: '1002' },
        ],
      ],
      [
        '9999',
        [
          // 其它类
          { name: '其它', code: '' },
        ],
      ],
    ]);

    if (parentCategoryMap.has(this.currentParentCategory)) {
      return parentCategoryMap.get(this.currentParentCategory)!;
    }

    return [{ name: '其它', code: '' }];
  }

  getVideosListByVideoType(videoType: string) {
    return this.enterpriseVideosList[this.getCurrentSecondCategory(videoType)];
  }

  reloadVideoList(secondCategory: string) {
    const options = {
      companyId: this.currentEnterprise.id,
      parentCategory: this.currentParentCategory,
      secondCategory: secondCategory,
      jobCategories: '',
      pageNo: 1,
      pageSize: 20,
    };
    this.getEnterpriseVideosListAction(options);
  }

  changeParentCategory() {
    this.getCurrentSecondCategoryList.forEach(videoType => {
      const options = {
        companyId: this.currentEnterprise.id,
        parentCategory: this.currentParentCategory,
        secondCategory: this.getCurrentSecondCategory(videoType.name),
        jobCategories: '',
        pageNo: 1,
        pageSize: 20,
      };
      this.getEnterpriseVideosListAction(options);
    });
  }
  getCurrentSecondCategory(videoType: string) {
    const matchMap = {
      0: {
        // 职位类
        团队介绍: '0',
        工作内容: '1',
        职位类业务介绍: '2',
        员工培训: '3',
        其它: '9999',
      },
      1: {
        // 公司类
        公司环境: '100',
        公司发展: '101',
        公司类业务介绍: '102',
        公司其他类: '99999',
      },
      2: {
        // 面试官类
        开场自我介绍: '1000',
        面试结束语: '1001',
        转场视频: '1002',
      },
      9999: {
        // 其它类
        其它: '',
      },
    };

    const matchItem = matchMap[Number(this.currentParentCategory) as keyof typeof matchMap];

    if (matchItem) {
      return matchItem[videoType as keyof typeof matchItem] || '';
    }

    return ''; // 其它类
  }

  get getAnchorData() {
    const anchorData: { title: any; hash: string }[] = [];
    this.getCurrentSecondCategoryList.forEach((element: any) => {
      anchorData.push({
        title: element.name,
        hash: `#${element.name}`,
      });
    });
    return anchorData;
  }

  mounted() {
    this.getCurrentSecondCategoryList.forEach(videoType => {
      const options = {
        companyId: this.currentEnterprise.id,
        parentCategory: '0',
        secondCategory: this.getCurrentSecondCategory(videoType.name),
        jobCategories: '',
        pageNo: 1,
        pageSize: 20,
      };
      this.getEnterpriseVideosListAction(options);
    });
  }
}
