



































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { postEditCv } from '@/common/apis/resume/resume';

@Component({
  components: {
    Resume,
  },
})
export default class PersonalAdvantage extends Vue {
  showDrawer = false
  summary = ''
  previewResumeUrl = ''
  resumeId = 0

  open(previewResumeUrl: string, summary: string, resumeId: number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.summary = summary;
    this.resumeId = resumeId;
  }

  async handlerSave() {
    try {
      const { success } = await postEditCv({
        resumeId: this.resumeId,
        form: {
          advantage: {
            summary: this.summary,
          },
        },
      });
      if (success) {
        this.$message({
          type: 'success',
          message: '提交成功!',
        });
        this.showDrawer = false;
        this.$emit('submit');
      }
    } catch (error) {
      console.error('🙅 保存个人优势', error);
    }
  }

  handlerCancel() {
    this.showDrawer = false;
  }
}
