














import SelectBackExame from '@/components/select-back/select-back-exam.vue';
import { IEnterprise } from '@/model/enterprise';
import { IJob, IJobQuestionSnapshotsForSave } from '@/model/job';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    SelectBackExame,
  },
})
export default class ExamFrame extends Vue {
  @Prop() job?: IJob;
  @Prop({ type: Boolean }) required?: boolean;
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() title?: string;
  @Prop() index?: number;
  @Prop({ required: true }) value!: IJobQuestionSnapshotsForSave[];

  get innervalue() {
    return this.value;
  }

  set innervalue(value: IJobQuestionSnapshotsForSave[]) {
    this.$emit('update:value', value);
  }

  remove() {
    this.$emit('remove', this.index);
  }

  change(e: number[]) {
    this.$emit('update:item', e);
  }
}
