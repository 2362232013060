




















import { IEnterprise } from '@/model/enterprise';
import { IJob, ISnippetsForSave } from '@/model/job';
import { IMedia, IVideoBaseInfo } from '@/model/resource';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import JobFrame from './job-frame.vue';

@Component({
  components: {
    JobFrame,
  },
})
export default class JobFormStepVideoShow extends Vue {
  @Prop() job?: IJob;
  @Prop({ required: true }) enterprise!: IEnterprise;

  list: ISnippetsForSave[] = [];

  change({ value, index }: {
    value: {
      mediaIds: (number | null | undefined)[];
      medias: (IMedia<IVideoBaseInfo> | undefined)[];
    },
    index: number
  }) {
    this.$set(this.list, index, {
      ...this.list[index],
      ...value,
    });
  }

  addModule() {
    this.$prompt('请输入模块名称', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /[\w\W]+/,
      inputErrorMessage: '模块名称不能为空',
    }).then(res => {
      this.list.push({
        title: (res as MessageBoxInputData).value,
        type: this.$QJDict.videoDisplayType.Custom.code,
        medias: [],
      });
    }).catch(() => {
      console.info({
        type: '职位管理-视频展示-新建模块',
        message: '取消输入',
      });
    });
  }

  parseMediaData(media?: IMedia<string>[]): IMedia<IVideoBaseInfo>[] {
    return (media || []).map(e => ({
      ...e,
      data: this.$JSONParse(e.data) as IVideoBaseInfo,
    }));
  }

  genListByJob(job?: IJob) {
    const customCode = this.$QJDict.videoDisplayType.Custom.code;
    const jobMedias = job?.snippets?.reduce((total, cv) => {
      if (cv.type === customCode) {
        total[cv.type] = [
          ...(total[cv.type] ? total[cv.type] : []),
          {
            title: cv.title,
            type: cv.type,
            mediaIds: cv?.medias?.map(e => e.mediaId) || [],
            medias: this.parseMediaData(cv?.medias),
          },
        ];
      } else {
        total[cv.type] = {
          title: cv.title,
          type: cv.type,
          mediaIds: cv?.medias?.map(e => e.mediaId) || [],
          medias: this.parseMediaData(cv?.medias),
        };
      }
      return total;
    }, {} as any) || {};
    const localList = this.$QJConfig.enterpriseJobManagerVideoDisplayPart.map(type => ({
      title: this.$QJDict.videoDisplayType[type].title,
      type: this.$QJDict.videoDisplayType[type].code,
      mediaIds: jobMedias && jobMedias[type] ? jobMedias[type].mediaIds : [],
      medias: jobMedias && jobMedias[type] ? jobMedias[type].medias : [],
    }));

    const hasCustom = !!(jobMedias && jobMedias[customCode] && Array.isArray(jobMedias[customCode]) && jobMedias[customCode].length);

    if (hasCustom) {
      localList.push(...jobMedias[customCode]);
    }
    return localList;
  }

  remove(index: number) {
    this.list.splice(index, 1);
  }

  editName(item: { index: number, name: string }) {
    this.$set(this.list[item.index], 'title', item.name);
  }

  @Watch('list', { deep: true, immediate: true })
  onListChange(list: ISnippetsForSave[]) {
    this.$emit('snippetsChange', list);
  }

  created() {
    this.list = this.genListByJob(this.job);
  }
}
