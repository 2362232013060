import { render, staticRenderFns } from "./select-back-tag.vue?vue&type=template&id=6a56a140&scoped=true&"
import script from "./select-back-tag.vue?vue&type=script&lang=js&"
export * from "./select-back-tag.vue?vue&type=script&lang=js&"
import style0 from "./select-back-tag.vue?vue&type=style&index=0&id=6a56a140&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a56a140",
  null
  
)

export default component.exports