


























































import { safeCallback, isPromise } from './../../utils/global';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SelectBackBase<V = any> extends Vue {
  @Prop() value?: V | V[];
  @Prop({ default: '选择' }) btnText!: string;
  @Prop({ default: '请选择内容' }) placeholder!: string;
  @Prop({ default: '请选择一项' }) dialogTitle!: string;
  @Prop({ default: '80%' }) dialogWidth!: string | number;
  @Prop({ default: 'primary' }) btnType!: string;
  @Prop() multiple?: boolean;
  @Prop() disabled?: boolean;
  @Prop() readonly?: boolean;
  @Prop() hidden?: boolean;
  @Prop() remove?: boolean;
  @Prop() labelValue?: string | string[];
  @Prop({ type: Boolean, default: false }) hideCancelBtn?: boolean;
  @Prop() submit?: () => Promise<any> | boolean;
  @Prop({ type: Boolean, default: false }) loading?: boolean;
  @Prop() referenceSize?: string;

  selectItems?: V | V[];

  show = false;

  onRemove(name: string) {
    this.$emit('onRemove', name);
  }

  inputFocus() {
    if (this.readonly || this.disabled) {
      return;
    }
    this.openDialog();
  }

  openDialog() {
    this.show = true;
    this.$emit('onShow');
  }

  selectAndClosd() {
    const res = safeCallback(this.submit, []);
    if (isPromise(res)) {
      (res as Promise<any>).then(() => {
        this.show = false;
      });
      return;
    }
    if (res === true || res === undefined) {
      this.show = false;
    }
  }
}
