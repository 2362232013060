

























































































import { submitRiskInterview } from '@/common/service';
import { IContentAuditEvidence, IContentAuditSubmitOption } from '@/common/types';
import { IContentAudit } from '@/model/content-audit';
import { IVideoBaseInfo } from '@/model/resource';
import { IContentAuditModuleTabs } from '@/pages/home/store/interview-content-audit-module';
import { SelectBackTag } from '@w-admin/common/src/components/select-back';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SubmitFooter from './submit-footer.vue';
import VideoItemCheckBox from './video-item-check-box.vue';

const ContentAuditModule = namespace('audit');

type TVideoRef = {
  player: any;
  getCurrentTime(): number;
  getStatus(): string;
  pause(): void;
  play(): void;
  seek(time: number): void;
  setVolume(vol: number): void;
  setSpeed(speed: string): void;
  setProgressMarkers(points: { offset?: number; text?: string; isCustomized?: boolean }[]): void;
};

type TPoint = {
  videoRef: TVideoRef;
  index: number;
  mediaId: number;
  point: number;
  checked: boolean;
};

@Component({
  components: {
    SelectBackTag,
    VideoItemCheckBox,
    SubmitFooter,
  },
})
export default class ContentAuditFooter extends Vue {
  @ContentAuditModule.State('currentVideoIndex')
  currentVideoIndex?: number;

  @ContentAuditModule.State('currentAuditInterview')
  currentAuditInterview!: IContentAudit<IVideoBaseInfo>;

  @ContentAuditModule.State('currentTab')
  currentTab!: IContentAuditModuleTabs;

  @ContentAuditModule.Action('nextAudit')
  nextAudit!: () => Promise<void>;

  tagsConfig = {
    addable: true,
    maxlength: 5,
    addTypeCode: this.$QJDict.tagsType.AUDIT_RISK_CUSTOMIZE.code,
    typeCodeList: [this.$QJDict.tagsType.AUDIT_RISK.code, this.$QJDict.tagsType.AUDIT_RISK_CUSTOMIZE.code],
  };

  maxTags = 5;
  dangerous = false;
  remarks = '';
  tags: number[] = [];
  evidences: IContentAuditEvidence[] = [];

  videoRef: (Vue & HTMLElement & TVideoRef) | null = null;

  get evidencePlaceholder() {
    return this.$QJUtils.isMobile() ? '请双击视频添加风险标记' : '请使用【Alt + 空格】添加风险标记';
  }

  get progressMarkers() {
    return this.evidences.map(e => {
      return e.points.map(p => {
        return {
          offset: Math.floor(p / 1000),
          text: `关键点: ${p < 1000 ? `${p}毫秒` : this.$QJUtils.formatSeconds(p / 1000)}`,
          isCustomized: true,
        };
      });
    });
  }

  expand = false;
  isModified = false;

  modified(isModified: boolean) {
    this.isModified = isModified;
  }

  get isEdit() {
    return this.currentTab.status === 0 || this.isModified;
  }

  get isPreviewList() {
    return this.expand && !this.isEdit && this.currentTab.status !== 0;
  }

  get toggleBtnVisible() {
    /**
     * 无风险、有风险 tab 非编辑态下
     * 只有审核列表多余 1 个才展示 expand 按钮
     */
    return this.currentTab.status !== 0 && !this.isEdit ? !!(this.currentAuditInterview?.auditList?.length > 1) : true;
  }

  pointClick(index: number, point: number, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.$QJEventBus.$emit(this.$QJConfig.eventName.SEEK_VIDEO_TIME, {
      index,
      point,
    });
  }

  onChange(dangerous: boolean) {
    if (dangerous) {
      this.$confirm('合规视频需要谨慎操作，确认该视频发现法律风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.dangerous = true;
          this.expand = true;
        })
        .catch(() => {
          this.dangerous = false;
          this.expand = false;
        });
    } else {
      this.dangerous = false;
      this.expand = false;
    }
  }

  onEvidencesChange(index: number) {
    if (this.videoRef) {
      this.progressMarkers[index].length === 0
        ? this.videoRef.setProgressMarkers([{}])
        : this.videoRef.setProgressMarkers(this.progressMarkers[index]);
    }
  }

  get effectiveAudit() {
    if (this.currentAuditInterview && this.currentAuditInterview.auditList) {
      const [effectiveAudit] = this.currentAuditInterview.auditList.filter(e => e.effective);
      return effectiveAudit ? effectiveAudit : undefined;
    }
    return undefined;
  }

  async submit() {
    const option: IContentAuditSubmitOption = {
      interviewId: this.currentAuditInterview.interviewId,
      tags: this.tags || [],
      status: this.dangerous ? 3 : 1,
      remarks: this.remarks,
      evidences: this.evidences || [],
      vsn: this.currentAuditInterview.vsn,
    };

    if (this.dangerous) {
      if (option.tags!.length === 0) {
        this.$notify.warning('至少选择一个风险内容标签');
        return;
      }
      if (option.evidences!.every(e => e.points.length === 0)) {
        this.$notify.warning('至少标记一个关键证据');
        return;
      }
    }

    const hasReaded = this.$QJStorage.getStorage(this.$QJConfig.LDKey.STORAGE_READ_VIDEO_NAME) || [];

    if (this.currentAuditInterview.mediaList.some(e => !hasReaded.includes(e.mediaId))) {
      try {
        await this.$confirm('当前面试还有视频未浏览，建议全部预览后再做出判定?', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: this.$QJUtils.isMobile() || this.$QJUtils.isDingTalk() ? 'dingTalk-global-video-contant-audit-confirm' : undefined,
        });
      } catch (error) {
        return;
      }
    }

    try {
      const subOptions = this.dangerous ? option : { interviewId: option.interviewId, status: option.status, vsn: this.currentAuditInterview.vsn };
      const { success } = await submitRiskInterview(subOptions);
      if (success) {
        this.$notify.success('审核成功');
        this.nextAudit();
        this.isModified = false;
        this.expand = false;
      }
    } catch (error) {
      console.info('[风险合规审核失败]', option, error);
    }
  }

  init(auditInterview: IContentAudit<IVideoBaseInfo>) {
    this.dangerous = auditInterview.status === 3;
    this.evidences = auditInterview.mediaList.map((e, index) => ({
      index,
      mediaId: e.mediaId,
      points: (this.effectiveAudit?.evidences && this.effectiveAudit?.evidences[index]?.points) || [],
      checked: (this.effectiveAudit?.evidences && this.effectiveAudit?.evidences[index]?.points.length > 0) || false,
    }));
    this.tags = (this.effectiveAudit?.tags && this.effectiveAudit?.tags.map(e => e.id)) || [];
    this.remarks = this.effectiveAudit?.remarks || '';
    if (!this.isEdit && this.expand) {
      this.expand = auditInterview?.auditList?.length > 1;
    }
  }

  get expandFooterHeight() {
    return this.$QJUtils.isMobile() ? '100vh' : '300px';
  }

  @Watch('currentAuditInterview', { immediate: true })
  currentAuditInterviewChange(auditInterview: IContentAudit<IVideoBaseInfo>) {
    auditInterview && this.init(auditInterview);
  }

  @Watch('expand', { immediate: true })
  expandChange(expand: boolean) {
    document.documentElement.style.setProperty('--content-audit-footer-height', expand ? this.expandFooterHeight : '80px');
  }

  onVideoPlayerReady(videoRef: Vue & HTMLElement & TVideoRef) {
    this.videoRef = videoRef;
  }

  onMarkedDangerousPoint(item: TPoint) {
    if (this.isEdit) {
      this.evidences[item.index].checked = item.checked;
      this.evidences[item.index].points.push(item.point);
      item.videoRef.setProgressMarkers(this.progressMarkers[item.index]);
      this.$notify.success({
        title: '关键点标记成功',
        message: `关键点位置: ${item.point < 1000 ? `${item.point}毫秒` : this.$QJUtils.formatSeconds(item.point / 1000)}`,
      });
      if (!this.expand && !this.$QJUtils.isMobile()) {
        this.expand = true;
      }
    }
  }

  addEventListener() {
    this.$QJEventBus.$on(this.$QJConfig.eventName.ONVIDEOPLAYERREADY, this.onVideoPlayerReady);
    this.$QJEventBus.$on(this.$QJConfig.eventName.MARKED_DANGEROUS_POINT, this.onMarkedDangerousPoint);
  }

  removeEventListener() {
    this.$QJEventBus.$off(this.$QJConfig.eventName.ONVIDEOPLAYERREADY, this.onVideoPlayerReady);
    this.$QJEventBus.$off(this.$QJConfig.eventName.MARKED_DANGEROUS_POINT, this.onMarkedDangerousPoint);
  }

  created() {
    this.addEventListener();
  }

  destroyed() {
    this.removeEventListener();
  }
}
