













import { Component, Vue } from 'vue-property-decorator';
import { IAuditList } from '@/model/audit';
import { getAuditList } from '@/common/service';
import List from './list.vue';

@Component({
  components: {
    List,
  },
})
export default class EnterpriseAccountAudit extends Vue {
  loading = false;
  activeTab = 'wait-audit';
  dataList: IAuditList[] = [];

  async loadAuditList(status) {
    try {
      this.loading = true;
      const { data } = await getAuditList(status);
      this.dataList = data;
    } catch (error) {
      console.error('[获取审核列表]', error);
    } finally {
      this.loading = false;
    }
  }

  handleClick() {
    switch (this.activeTab) {
      case 'wait-audit':
        this.loadAuditList(2);
        break;
      case 'success':
        this.loadAuditList(3);
        break;
      case 'fail':
        this.loadAuditList(4);
        break;
      default:
        break;
    }
  }

  mounted() {
    this.activeTab = (this.$route.query.activeTab as string) || 'wait-audit';
    this.handleClick();
  }
}
