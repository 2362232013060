

































import { Vue, Component } from 'vue-property-decorator';
import { IEnterprise } from '@/model/enterprise';
import { AjaxResponse } from '@/common/types';
import { IUploadFileOptions, IUploadObjRes } from '@w-admin/common/src/components/common-upload';
import { setAdvertisingPic, updateInterviewType } from '@/common/service';
import { namespace } from 'vuex-class';
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');
@Component
export default class ESetting extends Vue {
  drawer = false;
  interviewType: number = 0;
  adLoading = false;
  url = null;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseInfoModule.Action('setCurrentEnterprise')
  setCurrentEnterprise!: (enterpriseId: number) => Promise<AjaxResponse<IEnterprise>>;

  get currentEnterpriseId() {
    return this.$route.params.id;
  }
  get uploadOptions(): IUploadFileOptions<IUploadObjRes> {
    return {
      accept: this.$QJUploader.imgTypes.join(','),
      limit: 1,
      multiple: false,
      onlyImage: true,
      showFileList: false,
      singleFileSizeLimit: 20 * 1024 ** 2,
      beforeUpload: () => {
        this.adLoading = true;
        return true;
      },
      onSuccess: async res => {
        try {
          const { id } = this.currentEnterprise;
          await setAdvertisingPic(id, res.data.mediaId);
          /**
           * 刷新当前企业信息
           */
          const { success } = await this.setCurrentEnterprise(Number(this.currentEnterpriseId));
          this.init();
          success && this.$notify.success('企业宣传图上传成功');
          this.adLoading = false;
        } catch (error) {
          this.adLoading = false;
        }
      },
      onError: () => {
        this.adLoading = false;
      },
    };
  }
  previewImage(url: string, e: Event) {
    e.stopImmediatePropagation();
    this.$alert(
      `
      <div style="
      width: auto;
      max-width:710px;
      height:auto;
      min-height: 240px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center center;
      background-image: url(${url});
      margin: 0 auto;
      "></div>`,
      '企业宣传图预览',
      {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        customClass: 'license-preview-dialog',
      },
    ).catch(() => {});
  }
  changeRadio(value: number) {
    const { id } = this.currentEnterprise;
    updateInterviewType(id, value).then(() => {
      this.$message.success('设置成功');
    });
  }
  init() {
    const { ext, advertisingPic } = this.currentEnterprise;
    this.interviewType = ext.interviewType;
    if (advertisingPic) {
      const { url } = JSON.parse(advertisingPic.data);
      this.url = url;
    }
  }
  created() {
    this.init();
  }
}
