













































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { TResume } from '@/model/resume';
import { IForwards, IBasic } from '@/common/apis/resume/type';
import { getCategoryTree, postEditCv } from '@/common/apis/resume/resume';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import showJobCategory from '@w-admin/common/src/components/job-category';
import { IAddress } from '@/common/apis/list/type';
import { provinceAndCityData, CodeToText } from 'element-china-area-data';

type TForm = Partial<IForwards> & Partial<IBasic>& {forwardLocation?:string}

@Component({
  components: {
    Resume,
  },
})
export default class UserInfoDrawer extends Vue {
  showDrawer = false
  imageUrl = ''
  previewResumeUrl = ''
  resumeId = 0
  salarys:{label: string; value: string}[] = []
  form:TForm = {};
  jobStatus:{label: string; value: string}[] = [
    {
      label: '离职-随时到岗',
      value: '离职-随时到岗',
    },
    {
      label: '在职-月内到岗',
      value: '在职-月内到岗',
    },
    {
      label: '在职-考虑机会',
      value: '在职-考虑机会',
    },
    {
      label: '在职-暂不考虑',
      value: '在职-暂不考虑',
    },
  ]
  politicalStatus:{label: string; value: string}[] = [
    {
      label: '共产党员',
      value: '共产党员',
    },
    {
      label: '预备党员',
      value: '预备党员',
    },
    {
      label: '团员',
      value: '团员',
    },
    {
      label: '其他党派',
      value: '其他党派',
    },
    {
      label: '无党派',
      value: '无党派',
    },
    {
      label: '群众',
      value: '群众',
    },
  ]
  jobRoleOptions = [
    {
      label: '职场人',
      value: '职场人',
    },
    {
      label: '学生',
      value: '学生',
    },
  ]
  cityList:IAddress[] = [{
    label: '无',
    value: null,
    children: [{
      label: '无',
      value: null,
    }],
  }]

  city: string[] | null | string = []

  $refs!:{
    form: ElForm
  }

  rules = {
    name: [
      { required: true, message: '名字不能为空', trigger: 'blur' },
      { max: 10, message: '最多支持10个字符', trigger: 'blur' },
    ],
    mobile: [
      {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入正确的手机号',
      },
    ],
    email: [
      // {
      //   pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
      //   message: '请输入正确的邮箱',
      // },
    ],
  }

  created() {
    this.init();
  }

  init() {
    this.getCategoryTree();
    this.getAddresses();
  }

  // 获取省市列表
  async getAddresses() {
    try {
      this.cityList.push(...provinceAndCityData);
    } catch (error) {
      console.error('🙅 获取省市:', error);
    }
  }

  showJobCategory(form:TForm) {
    showJobCategory().then(res => {
      if (Array.isArray(res)) {
        form.title = res?.[0]?.name;
        form.titleCode = res?.[0]?.code;
      } else {
        form.title = res?.name;
        form.titleCode = res?.code;
      }
    });
  }

  // 获取薪资列表
  async getCategoryTree() {
    const { data } = await getCategoryTree({ typeCode: 'SALARY' });
    this.salarys = data.map(el => ({
      label: el.name,
      value: el.name,
    }));
  }
  // 保存
  async handlerSave() {
    const { form } = this;
    const {
      name,
      mobile,
      email,
      sex,
      jobRole,
      title,
      workStart,
      titleCode,
    } = form;
    const { city } = this;
    this.$refs.form.validate(async valid => {
      if (valid) {
        try {
          const { success } = await postEditCv({
            resumeId: this.resumeId,
            form: {
              basic: {
                name: name!,
                mobile: mobile!,
                email: email!,
                sex: sex,
                jobRole: jobRole!,
                workStart: workStart ? moment(workStart).format('YYYY-MM-DD') : '',
              },
              forwards: [{
                title: title!,
                titleCode: titleCode,
                location: Array.isArray(city) ? city.map(el => CodeToText[el]).join('-') : city,
              }],
            },
          });
          if (success) {
            this.$message({
              type: 'success',
              message: '提交成功!',
            });
            this.showDrawer = false;
            this.$emit('submit');
          }
        } catch (error) {
          console.error('🙅 保存基本信息', error);
        }
      }
    });
  }
  open(previewResumeUrl: string, data:TResume, resumeId: number) {
    this.resumeId = resumeId;
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    const { forwards } = data;
    this.form = {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      titleCode: null,
      sex: data.sex,
      jobRole: data.jobRole,
      title: '',
      forwardLocation: forwards?.[0]?.location || '',
      workStart: data.workStart,
    };
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  handleAvatarSuccess(res: any, file: { raw: Blob | MediaSource; }) {
    this.imageUrl = URL.createObjectURL(file.raw);
  }
  beforeAvatarUpload(file: { type: string; size: number; }) {
    const isJPG = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 15;

    if (!isJPG) {
      this.$message.error('上传头像图片只能是 jpeg/png/jpg 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传头像图片大小不能超过 15MB!');
    }
    return isJPG && isLt2M;
  }
}
