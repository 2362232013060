
































import { Component, Vue, Prop } from 'vue-property-decorator';
import cvIcon from '@/assets/images/cv-icon.png';
import interviewVideoIcon from '@/assets/images/interview-video.png';
import companyIcon from '@/assets/images/company.png';
import schoolIcon from '@/assets/images/school.png';

interface ICandidate {
  isSelect: boolean;
  name: string;
  mobile: string;
  company: string;
  school: string;
  synced: boolean;
  cvUrl: string;
  deliveryTime: string;
}

@Component
export default class CandidateCard extends Vue {
  @Prop()
  data!: ICandidate;
  @Prop({ default: false })
  isA!: boolean;

  cvIcon = cvIcon;
  companyIcon = companyIcon;
  schoolIcon = schoolIcon;
  interviewVideoIcon = interviewVideoIcon;

  // 面试视频
  handlerInterviewVideo() {
    // const type = this.$QJConfig.runAsInfo.type;
    // const shareContext = encodeURIComponent(
    //   this.$QJUtils.Encrypt(
    //     JSON.stringify({
    //       interviewId,
    //       targetName: this.$QJConfig.runAsInfo.targetName || targetName,
    //       targetUid: this.$QJConfig.runAsInfo.targetUid || targetUid,
    //       type,
    //     }),
    //   ),
    // );
  }

  handlerOpenCv() {
    const { cvUrl } = this.data;
    if (!cvUrl || cvUrl === '') {
      this.$message({
        type: 'error',
        message: '简历不存在！',
      });
      return;
    }
    window.open(cvUrl);
  }
}
