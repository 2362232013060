import GetAuditTaskModal from './index.vue';
import { TAuditType } from '@/common/types';
import router from '@/pages/home/router';

export interface IGetAuditTaskModalData {
  show: boolean;
  type: TAuditType;
}

export default function showGetAuditTaskModal(type: TAuditType) {
  const instance = new GetAuditTaskModal({ router }) as GetAuditTaskModal & IGetAuditTaskModalData;
  instance.type = type;
  instance.$mount();
  instance.show = true;
}
