import { render, staticRenderFns } from "./second-category-item.vue?vue&type=template&id=7503933c&scoped=true&"
import script from "./second-category-item.vue?vue&type=script&lang=ts&"
export * from "./second-category-item.vue?vue&type=script&lang=ts&"
import style0 from "./second-category-item.vue?vue&type=style&index=0&id=7503933c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7503933c",
  null
  
)

export default component.exports