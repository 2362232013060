



































































import { getContentAudit, postManualReview } from '@/common/apis/review/review';
import { IContentAuditResponse, IManualReviewRequest } from '@/common/apis/review/type';
import ViewOriginalImage from '@/components/view-original-image.vue';
import { Component, Vue } from 'vue-property-decorator';
import ReviewFaildDialog from '../user-information-review/components/review-faild-dialog.vue';
import { tabToStatusMap } from '../user-information-review/contants';

@Component({
  components: {
    ReviewFaildDialog,
    ViewOriginalImage,
  },
})
export default class UserDynamicAudit extends Vue {
  activeName = 'pending-manual-review';
  total = 100;
  pageSize = 9999;
  curPage = 1;
  $refs!: {
    reviewFaildDialog: ReviewFaildDialog;
    viewOriginalImage: ViewOriginalImage
  };
  images: string[] = [];
  reviewList: any = [];

  get isShowPassText() {
    return ['pending-manual-review', 'machine-review-rejected', 'manual-review-rejected'].includes(this.activeName);
  }

  get isShowFaildPassText() {
    return ['pending-manual-review', 'approved-by-the-machine', 'manual-review-passed'].includes(this.activeName);
  }

  get isShowReviewer() {
    return ['manual-review-passed', 'manual-review-rejected'].includes(this.activeName);
  }

  created() {
    this.getReviewContentList();
  }

  isIndividualVideo(item) {
    return item.textAudits?.length === 0 && (item.videoAudits.length > 0 || item?.webpageAudits?.length > 0);
  }

  viewImg(url: string, i:number, imageIndex:number) {
    this.images = this.reviewList[i].images.map(el => el.url);
    this.$refs.viewOriginalImage.open(url, imageIndex);
  }

  // 切换页签
  handlerTabChange() {
    this.curPage = 1;
    this.pageSize = 9999;
    this.getReviewContentList();
  }
  // 当前页数
  handleCurrentChange(value: number) {
    this.curPage = value;
    this.getReviewContentList();
  }
  // 获取审核列表
  async getReviewContentList() {
    const { activeName } = this;
    const params = {
      entityType: 'HIGHLIGHT',
      status: tabToStatusMap[activeName],
      pageNo: this.curPage,
      pageSize: this.pageSize,
    };
    let result: IContentAuditResponse | null = null;
    try {
      const { data } = await getContentAudit(params);
      result = data;
    } catch (error) {
      console.error('[http]审核列表:', error);
    }
    result && this.renderList(result);
  }
  // 渲染列表
  renderList(result: IContentAuditResponse) {
    const { activeName } = this;
    switch (tabToStatusMap[activeName]) {
      case 1: // 待审核
      case 2: // 机器审核驳回
      case 3: // 机器审核通过
        this.renderPendingReviewList(result);
        break;
      case 4:
      case 5:
        this.renderPendingReviewList(result);
        break;
    }
  }
  // 待人工审核
  renderPendingReviewList(result: IContentAuditResponse) {
    this.total = result.totalCount;
    const data = result.list.filter(el => el.inform);
    this.reviewList = data.map(el => {
      const reason = el.pictureAudits.map(item => {
        return item.reason;
      });
      reason.push(...el.textAudits.map(item => item.reason));
      // 举报图片
      const images:string[] = [];
      if (el.inform?.medias.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        el.inform?.medias.forEach(el => {
          const url = JSON.parse(el.data).url;
          images.push(url);
        });
      }
      return {
        id: el.id,
        whistleblower: el.inform.informer.name,
        informType: el.inform.informType,
        describe: el.inform.reason,
        name: el.owner?.nickName || '',
        avatar: el.owner?.avatar || '',
        publishTime: el.submitAt,
        reason: el.reason,
        images: images,
        vsn: el.vsn,
        auditByName: el.auditor?.name || '',
        auditResult: reason.filter(item => item).join('、'),
      };
    });
  }
  // 人工审核通过/驳回
  // renderManualReviewNoPassedList(result: IContentAuditResponse) {
  //   this.total = result.totalCount;
  //   const data = result.list.filter(el => el.inform);
  //   this.reviewList = data.map(el => {
  //     const reason = el.pictureAudits.map(item => {
  //       return item.reason;
  //     });
  //     reason.push(...el.textAudits.map(item => item.reason));
  //     // 举报图片
  //     const images:string[] = [];
  //     if (el.inform?.medias.length > 0) {
  //       // eslint-disable-next-line no-unused-expressions
  //       el.inform?.medias.forEach(el => {
  //         const url = JSON.parse(el.data).url;
  //         images.push(url);
  //       });
  //     }
  //     return {
  //       id: el.id,
  //       name: el.owner?.nickName || '',
  //       avatar: el.owner?.avatar || '',
  //       publishTime: el.submitAt || '',
  //       reason: el.reason,
  //       images: images,
  //       auditResult: reason.filter(item => item).join('、'),
  //       auditByName: el.auditor?.name || '',
  //       reviewTime: el.auditAt || '',
  //       vsn: el.vsn,
  //     };
  //   });
  // }

  // 敏感词高亮替换
  brightenKeyword(contents: string, keywords: string[]) {
    keywords.forEach(function (item) {
      if (contents.indexOf(item) !== -1) {
        const reg = new RegExp(item, 'gi');
        contents = contents.replace(reg, `<span style="color:red;font-weight:bold">${item}</span>`);
      }
    });
    return contents;
  }

  async handlerPassed(item: any) {
    this.$confirm('是否展示该条高光时刻？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const params: IManualReviewRequest = {
        id: item.id,
        status: 4,
        vsn: item.vsn,
      };
      try {
        const result = await postManualReview(params);
        if (result.success) {
          this.$message({
            type: 'success',
            message: '通过成功!',
          });
          this.getReviewContentList();
        }
      } catch (error) {
        console.error('通过【http:】', error);
      }
    });

  }

  handlerNoPassed(item: any) {
    if (this.isShowFaildPassText) {
      this.$refs.reviewFaildDialog.open(item);
    }
  }

  async handlerConfirm(item: { row: any; value: string }) {
    const params: IManualReviewRequest = {
      id: item.row.id,
      status: 5,
      reason: item.value,
      vsn: item.row.vsn,
      auditor: item.row.revirewer,
    };
    try {
      const result = await postManualReview(params);
      if (result.success) {
        this.$message({
          type: 'success',
          message: '驳回成功!',
        });
        this.getReviewContentList();
      }
    } catch (error) {
      console.error('驳回【http:】', error);
    }
  }
}
