export const channelOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '抢镜',
    value: 'QIANGJING',
  },
  {
    label: 'BOSS直聘',
    value: 'BOSS',
  },
  {
    label: '拉勾',
    value: 'LAGOU',
  },
  {
    label: '牛客',
    value: 'NIUKE',
  },
  {
    label: '前程无忧',
    value: 'JOB51',
  },
  {
    label: '智联招聘',
    value: 'ZHILIAN',
  },
  {
    label: '实习僧',
    value: 'SHIXISENG',
  },
  {
    label: '合作客户',
    value: 'PARTNER',
  },
  {
    label: '58同城',
    value: 'C_58',
  },
  {
    label: '猎聘',
    value: 'LIEPIN',
  },
  {
    label: '其他',
    value: 'OTHER',
  },
];

export const channelMap = {
  QIANGJING: '抢镜',
  BOSS: 'BOSS直聘',
  LAGOU: '拉勾',
  NIUKE: '牛客',
  JOB51: '前程无忧',
  ZHILIAN: '智联招聘',
  SHIXISENG: '实习僧',
  PARTNER: '合作客户',
  OTHER: '其他',
};

export const educationOptions = [
  {
    label: '不限',
    value: '',
  },
  {
    label: '初中及以下',
    value: '初中及以下',
  },
  {
    label: '中专中技',
    value: '中专中技',
  },
  {
    label: '高中',
    value: '高中',
  },
  {
    label: '大专',
    value: '大专',
  },
  {
    label: '本科',
    value: '本科',
  },
  {
    label: '硕士',
    value: '硕士',
  },
  {
    label: '博士',
    value: '博士',
  },
];

export const sourceOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '用户',
    value: 'USER',
  },
  {
    label: '公司',
    value: 'COMPANY',
  },
  {
    label: '管理员',
    value: 'ADMIN',
  },
  {
    label: '邮箱',
    value: 'EMAIL',
  },

];