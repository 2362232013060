<template>
  <!-- eslint-disable max-len -->
  <select-back-base
    btnText="选择"
    :labelValue="labelValue"
    :placeholder="placeholder"
    :dialogTitle="mergeConfig.dialogTitle"
    :disabled="disabled"
    :readonly="readonly"
    :hidden="hidden"
    :remove="mergeConfig.remove"
    :multiple="mergeConfig.multiple"
    :referenceSize="mergeConfig.referenceSize"
    :submit="submit"
    @onRemove="onRemove"
    @onShow="onShow"
  >
    <div class="add-new-category-wrapper" v-if="addVisible">
      <div class="content">
        <h2>添加新类型</h2>
        <el-input
          placeholder="类型种类名称"
          :value="$QJDict.categoryType[innerTypeCode].title"
          disabled
          readonly
        >
          <template slot="prepend">类型种类名称</template>
        </el-input>
        <el-input placeholder="请输入要添加类型的父级ID" v-model="addParentId" type="number">
          <template slot="prepend">类型父级ID</template>
        </el-input>
        <el-input placeholder="请输入要添加类型的名称" v-model="addNewName">
          <template slot="prepend">类型名称</template>
        </el-input>
        <el-input placeholder="请输入要添加类型的 Code" v-model="addNewCode">
          <template slot="prepend">类型Code</template>
        </el-input>
        <el-input placeholder="请输入要添加类型的排序序号" v-model="addNewOrder" type="number">
          <template slot="prepend">排序序号</template>
        </el-input>
        <div class="footer">
          <el-button @click="onAddClose">取消</el-button>
          <el-button type="primary" @click="addNewCategory">确定添加</el-button>
        </div>
      </div>
    </div>
    <template v-if="$scopedSlots.reference" v-slot:reference="{ showDialog }">
      <slot name="reference" :showDialog="showDialog"></slot>
    </template>
    <el-row
      :gutter="24"
      type="flex"
      align="center"
      class="header"
    >
      <el-col class="label">
        <span>类别类型</span>
      </el-col>
      <el-col class="flex-auto">
        <el-select
          v-model="innerTypeCode"
          placeholder="请选择类别类型"
          @change="getCategoryTree"
          :disabled="!!mergeConfig.typeCode"
          style="width: 100%"
        >
          <el-option
            v-for="item in $QJDict.categoryType"
            :key="item.code"
            :label="item.title"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col class="add-root-category" v-if="mergeConfig.editable && mergeConfig.typeEditable">
        <el-tooltip content="添加类别">
          <el-button @click="addRootType" icon="el-icon-plus"></el-button>
        </el-tooltip>
      </el-col>
      <el-col class="refresh">
        <el-tooltip content="刷新数据">
          <i @click="refreshData" :class="loading ? 'el-icon-loading' : 'el-icon-refresh'"></i>
        </el-tooltip>
      </el-col>
      <el-col class="label">
        <span>关键字过滤</span>
      </el-col>
      <el-col class="flex-auto">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          @keydown.native.enter="search"
          clearable
          @clear="clear"
        >
          <template slot="append">
            <el-button type="default" :disabled="searching" @click="search"> 检索 </el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>
    <div
      v-loading="loading || searching"
      :element-loading-text="loading ? '稍等一下，数据马上加载完成哦ヽ(✿ﾟ▽ﾟ)ノ...' : '数据检索中...'"
      class="dataTree"
    >
      <el-tree
        v-if="treeData && treeData.length"
        v-show="!loading"
        :data="treeData"
        :props="defaultProps"
        :show-checkbox="mergeConfig.multiple"
        node-key="code"
        :filter-node-method="filterNode"
        ref="tree"
        empty-text="这里啥都没有唉 (ノ﹏ヽ) ～"
      >
        <template v-if="mergeConfig.multiple && mergeConfig.editable" v-slot="{ node, data }">
          <span class="custom-tree-node">
            <span>{{ node.label }}</span>
            <span>
              <el-button type="text" size="mini" @click.stop="() => appendItem(node, data)"> 添加 </el-button>
              <!-- <el-button
                type="text"
                size="mini"
                @click.stop="() => removeItem(node, data)"
              >
                删除
              </el-button> -->
            </span>
          </span>
        </template>
        <template v-else-if="!mergeConfig.multiple" v-slot="{ node, data }">
          <span class="custom-tree-node">
            <span class="custom-tree-node-single" @click="toggleSingleCode(data)">
              <svg
                v-if="singleChecked && singleChecked.code === data.code"
                viewBox="0 0 1040 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="10945"
                width="16"
                height="16"
              >
                <path
                  d="M509.92 176C325.504 176 176 325.504 176 509.92c0 184.416 149.504 333.92 333.92 333.92 184.416 0 333.92-149.504 333.92-333.92C843.84 325.504 694.32 176 509.92 176z m0 48c157.904 0 285.92 128 285.92 285.92 0 157.904-128.016 285.92-285.92 285.92C352 795.84 224 667.808 224 509.92 224 352 352 224 509.92 224z m0 96C405.024 320 320 405.024 320 509.92c0 104.88 85.024 189.92 189.92 189.92 104.88 0 189.92-85.04 189.92-189.92 0-104.896-85.04-189.92-189.92-189.92z"
                  p-id="10946"
                  fill="#409EFF"
                ></path>
              </svg>
              <svg
                v-else-if="data.disabled"
                viewBox="0 0 1040 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="11383"
                width="16"
                height="16"
              >
                <path
                  d="M509.92 843.84C325.504 843.84 176 694.32 176 509.92 176 325.504 325.504 176 509.92 176c184.416 0 333.92 149.504 333.92 333.92 0 184.416-149.504 333.92-333.92 333.92z m0-48c157.904 0 285.92-128.016 285.92-285.92C795.84 352 667.808 224 509.92 224 352 224 224 352 224 509.92c0 157.904 128 285.92 285.92 285.92z"
                  p-id="11384"
                  fill="#dbdbdb"
                ></path>
              </svg>
              <svg
                v-else
                viewBox="0 0 1040 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="10699"
                width="16"
                height="16"
              >
                <path
                  d="M509.92 843.84C325.504 843.84 176 694.32 176 509.92 176 325.504 325.504 176 509.92 176c184.416 0 333.92 149.504 333.92 333.92 0 184.416-149.504 333.92-333.92 333.92z m0-48c157.904 0 285.92-128.016 285.92-285.92C795.84 352 667.808 224 509.92 224 352 224 224 352 224 509.92c0 157.904 128 285.92 285.92 285.92z"
                  p-id="10700"
                ></path>
              </svg>
              <span :class="{ checked: singleChecked && singleChecked.code === data.code }">
                {{ node.label }}
              </span>
            </span>
            <span v-if="mergeConfig.editable">
              <el-button type="text" size="mini" @click="() => appendItem(node, data)"> 添加 </el-button>
              <!-- <el-button
                type="text"
                size="mini"
                @click="() => removeItem(node, data)"
              >
                删除
              </el-button> -->
            </span>
          </span>
        </template>
      </el-tree>
      <el-empty v-else-if="!loading && !searching" style="height: 500px" description="这里啥都没有唉 (ノ﹏ヽ) ～"></el-empty>
    </div>
    <template v-slot:footerBefore>
      <div class="action">
        <el-checkbox class="needParent" v-model="needParent">保留父级</el-checkbox>
        <el-button
          v-if="mergeConfig.editable && mergeConfig.rootEditable"
          icon="el-icon-plus"
          size="mini"
          @click="addRootCategory"
        >
          添加根节点类别
        </el-button>
      </div>
    </template>
  </select-back-base>
</template>

<script>
import ncformCommon from '@ncform/ncform-common';
import { Component, Mixins } from 'vue-property-decorator';
import SelectBackBase from './select-back-base.vue';
import { getCategoryTree, addNewCategory } from '../../api/service';

const localBaseKey = '_category_';

@Component({
  components: {
    SelectBackBase,
  },
})
export default class SelectBackCategory extends Mixins(ncformCommon.mixins.vue.controlMixin) {
  defaultProps = {
    children: 'children',
    label: 'name',
  };

  addVisible = false;
  addParentId = 0;
  addNewName = '';
  addNewCode = '';
  addNewOrder = 0;

  innerTypeCode = 'JOB';

  loading = false;

  pageLoading = false;

  labelValue = [];

  treeData = [];

  filterText = '';

  needParent = false;

  defaultConfig = {
    dialogTitle: '请选择职位类别',
    remove: false,
    multiple: true,
  };

  searching = false;

  get localTreekey() {
    return `${localBaseKey}_tree_${this.innerTypeCode}`;
  }

  singleChecked = null;

  toggleSingleCode(data) {
    if (data?.disabled) {
      return;
    }
    if (this.singleChecked?.code === data.code) {
      this.singleChecked = null;
    } else {
      this.singleChecked = { ...data };
    }
  }

  search() {
    if (this.searching) {
      return;
    }
    this.searching = true;
    this.$refs.tree.filter(this.filterText);
    this.searching = false;
  }

  clear() {
    this.$refs.tree.filter();
  }

  filterNode(value, data) {
    if (!value) {
      return true;
    }
    return data.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1;
  }

  getCategoryInfoFormTree(data, code) {
    let res = null;
    const dataArray = data || [];
    for (let i = 0; i < dataArray.length; i++) {
      const item = dataArray[i];
      if (item.code === code) {
        res = item;
        break;
      } else if (item.children && Array.isArray(item.children) && item.children.length > 0) {
        res = this.getCategoryInfoFormTree(item.children, code);
        if (res) {
          break;
        }
      }
    }
    return res;
  }

  setLabelValue(value) {
    if (!value) {
      return;
    }
    if (this.treeData.length === 0) {
      this.getData();
    }
    const items = (Array.isArray(value) ? value : [value])
      .filter(e => e !== false)
      .map(e => {
        if (typeof e === 'object' && e.code && e.name) {
          return `${e.code}-${e.name}`;
        }

        if (typeof e === 'string') {
          const current = this.getCategoryInfoFormTree(this.treeData, e);
          if (current) {
            return `${current.code}-${current.name}`;
          }
        }

        return `${e}-${e}`;
      });
    this.labelValue = this.mergeConfig.multiple ? items : items[0];
  }

  addRootType() {
    console.log('addRootType');
  }

  alertMessage(parentId = 0) {
    this.addParentId = parentId;
    this.addVisible = true;
  }

  addRootCategory() {
    this.alertMessage(0);
  }

  appendItem(node, data) {
    this.alertMessage(data?.id || 0);
  }

  onAddClose() {
    this.addParentId = 0;
    this.addNewName = '';
    this.addNewCode = '';
    this.addNewOrder = 0;
    this.addVisible = false;
  }

  onAddSuccess() {
    this.refreshData();
    this.onAddClose();
  }

  addNewCategory() {
    if (this.innerTypeCode && this.addNewCode && this.addNewName) {
      addNewCategory({
        typeId: this.$QJDict.categoryType[this.innerTypeCode].id,
        parentId: this.addParentId,
        code: this.addNewCode,
        name: this.addNewName,
        order: this.addNewOrder,
      }).then(rs => {
        if (rs && rs.success) {
          this.$notify.success('类型添加成功');
          this.onAddSuccess();
        } else {
          this.$notify.error('类型添加失败');
        }
      }).catch(error => {
        this.$notify.error('类型添加失败');
        console.log('【添加新分类失败】', {
          error,
          typeId: this.typeId,
          parentId: this.innerParentId,
          code: this.code,
          name: this.name,
          order: this.order,
        });
      });
    } else {
      this.$notify.warning('信息填写不完整');
    }
  }

  removeItem(node, data) {
    // const parent = node.parent;
    // const children = parent.data.children || parent.data;
    // const index = children.findIndex(d => d.id === data.id);
    // children.splice(index, 1);
    console.log('remove', node, data);
  }

  onRemove(text) {
    const [code] = text.split('-');
    const index = this.modelVal.findIndex(e => e.code === code);
    if (index < 0) {
      return;
    }
    this.modelVal.splice(index, 1);
    this.setLabelValue(this.modelVal);
  }

  setCheckedTree(initData) {
    this.$nextTick(() => {
      if (this.mergeConfig.multiple && Array.isArray(initData)) {
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.tree?.setCheckedKeys(
          initData.map(e => e.code || e),
          true,
        );
      } else if (Array.isArray(initData) && initData[0]) {
        const data = typeof initData[0] === 'string' ? { code: initData[0] } : initData[0];
        this.singleChecked = data;
      }
      this.pageLoading = false;
    });
  }

  getCheckedNodes() {
    if (!this.mergeConfig.multiple) {
      return [this.singleChecked];
    }
    return this.$refs?.tree?.getCheckedNodes(!this.needParent, this.needParent);
  }

  submit() {
    const checkedNodes = this.getCheckedNodes();

    if (!this.mergeConfig.multiple && Array.isArray(checkedNodes) && checkedNodes.length > 1) {
      this.$notify.warning('只能选择一个哦 (っ´▽`)っ');
      return false;
    }

    if (typeof this.mergeConfig.maxlength === 'number' && Array.isArray(checkedNodes) && checkedNodes.length > this.mergeConfig.maxlength) {
      this.$notify.warning(`最多只能选择${this.mergeConfig.maxlength}哦 (っ´▽\`)っ`);
      return false;
    }

    this.modelVal = checkedNodes;
    this.setLabelValue(checkedNodes);
    return true;
  }

  addDisableToData(data, handle) {
    return data.map(item => {
      if (item.children && item.children.length) {
        return {
          ...item,
          disabled: true,
          children: this.addDisableToData(item.children, handle),
        };
      }
      return {
        ...item,
        disabled: typeof handle === 'function' ? handle(item) : false,
      };
    });
  }

  async getCategoryTree(typeCode) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const { data } = await getCategoryTree({ typeCode }).catch(() => {
      this.loading = false;
      return { data: [] };
    });
    this.treeData = this.addDisableToData(data);
    if (Array.isArray(data) && data.length > 0) {
      this.$QJStorage.setStorage(this.localTreekey, this.treeData);
    }
    this.loading = false;
  }

  init() {
    if (this.mergeConfig.typeCode) {
      this.innerTypeCode = this.mergeConfig.typeCode;
    }
    this.setLabelValue(this.modelVal);
  }

  getData() {
    const localTreeData = this.$QJStorage.getStorage(this.localTreekey);
    if (localTreeData) {
      this.treeData = localTreeData;
    } else {
      this.refreshData();
    }
  }

  refreshData() {
    if (this.loading) {
      return;
    }
    Promise.all([this.getCategoryTree(this.innerTypeCode)]);
  }

  created() {
    this.init();
  }

  onShow() {
    this.pageLoading = true;
    this.getData();
    const initData = Array.isArray(this.modelVal) ? this.modelVal : [this.modelVal];
    this.setCheckedTree(initData);
  }
}
</script>

<style lang="less" scoped>
.add-root-category {
  flex: 1;
}
.refresh {
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
}

.header {
  position: sticky;
  top: -30px;
  z-index: 10;
  background: #fff;
  padding-bottom: 20px;
}

.action {
  display: flex;
  align-items: center;
  gap: 20px;
}

.needParent {
  display: flex;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.dataTree {
  min-height: 500px;
}
.label {
  width: max-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.flex-auto {
  flex: auto;
}

.add-new-category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);

  .content {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background: #fff;
    padding: 50px;
  }

  .footer {
    text-align: center;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  &-single {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    cursor: pointer;

    .checked {
      color: #409eff;
    }
  }
}
</style>
