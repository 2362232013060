import { IJob, IJobForSave, IJobQuestionSnapshotsForSave } from '@/model/job';
import { IVideoBaseInfo } from '@/model/resource';
import { JSONParse } from '@w-admin/common/src/utils/global';
/**
 * 职位详情转保存提交的数据
 * @param job 职位详情
 * @param value 要提交的 { snippets, exams }
 * @returns
 */
export const transformJobToSave = (
  job: IJob | undefined,
  value?: Partial<IJobForSave> | undefined,
): Partial<IJobForSave> => {
  return {
    ...job,
    category: job?.category?.code || value?.category,
    keywords: job?.keywords,
    publishedBy: job?.publishedBy?.id || value?.publishedBy,
    welfare: value?.welfare || job?.welfare,
    status: job?.status || value?.status,
    snippets: value?.snippets?.map(e => {
      return {
        medias: (e.medias || []).filter(m => m && m.mediaId),
        title: e.title,
        type: e.type,
      };
    }),
    exams: value ? value.exams?.map(e => ({
      questionSnapshots: e.questionSnapshots.map(q => ({
        ...q,
        attachmentRequirement: q.attachmentRequirement && typeof q.attachmentRequirement === 'object'
          ? q.attachmentRequirement.code
          : q.attachmentRequirement,
      })),
      interviewer: e.interviewer,
      answerRequirement: e.answerRequirement,
      interludeMediaId: e.interludeMediaId,
      round: e.round,
    })) : job?.exams.map(e => ({
      questionSnapshots: e.questionSnapshots.map(q => ({
        ...q,
        attachmentRequirement: q.attachmentRequirement && typeof q.attachmentRequirement === 'object'
          ? q.attachmentRequirement.code
          : q.attachmentRequirement,
        medias: q.medias.map(m => ({
          ...m,
          data: JSONParse<IVideoBaseInfo>(m?.data) as IVideoBaseInfo,
        })),
      })) as IJobQuestionSnapshotsForSave[],
      interviewer: {
        interviewerId: e?.interviewer?.userInfo?.id,
        selfIntroMediaId: e?.interviewer?.selfIntroMedia?.mediaId,
        conclusionMediaId: e?.interviewer?.conclusionMedia?.mediaId,
      },
      answerRequirement: e.answerRequirement,
      interludeMediaId: e.interludeMedia?.mediaId,
      round: e.round,
    })),
  };
};

/**
 * 兼容
 */
export {
  throttle,
  bigNumberTransform,
  getNextWeekDayDate,
  getStringLength,
  subString,
  fullname,
  fileSize,
  QJStorage,
  injectGlobalConst,
  removeEmptyField,
  isJSONString,
  JSONParse,
  safeCallback,
  isObject,
  isFunction,
  isPromise,
  DateTimeFormat,
} from '@w-admin/common/src/utils/global';
