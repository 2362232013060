import { PluginObject } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const ref = {
  innerChange: false,
  originInnerHTMLWeakMap: new WeakMap<HTMLElement, string>(),
};

const unbind = (el: HTMLElement) => {
  const isInserted = el.querySelectorAll('.el-badge').length > 0;
  if (isInserted) {
    ref.innerChange = true;
    if (ref.originInnerHTMLWeakMap.get(el)) {
      el.innerHTML = ref.originInnerHTMLWeakMap.get(el)!;
    }
    ref.innerChange = false;
  }
};

const updateValue = (el: HTMLElement, value: number | string) => {
  el.innerText = `${value}`;
};

const insertBadge = (el: HTMLElement, isNumber: boolean = false, value: number | boolean = 0) => {
  const isInserted = el.querySelectorAll('.el-badge').length > 0;
  if (isInserted) {
    const badgeContent = el.querySelector('.el-badge__content') as HTMLElement;
    ref.innerChange = true;
    if (value === false) {
      unbind(el);
    } else if (typeof value === 'number') {
      updateValue(badgeContent, value);
    }
    ref.innerChange = false;
  } else {
    const div = document.createElement('div');
    div.className = 'el-badge';
    const sup = document.createElement('sup');
    sup.className = `el-badge__content ${isNumber ? '' : ' is-dot'}`;
    if (isNumber && typeof value === 'number') {
      updateValue(sup, value);
    }
    if (value !== false) {
      ref.innerChange = true;
      ref.originInnerHTMLWeakMap.set(el, el.innerHTML);
      div.innerHTML = `${el.innerHTML}${sup.outerHTML}`;
      el.innerHTML = div.outerHTML;
      ref.innerChange = false;
    }
  }
};

const handleBadge = (el: HTMLElement, binding: DirectiveBinding, isUpdate: boolean = false) => {
  const oldValue = binding.oldValue;
  const value = binding.value;
  const arg = binding.arg;
  if (isUpdate && oldValue === value) {
    return;
  }
  if (arg && arg === 'tab' && Array.isArray(value)) {
    const tabsWrapper = el.querySelector('.el-tabs__nav');
    if (!tabsWrapper) {
      return;
    }
    tabsWrapper.addEventListener('DOMSubtreeModified', () => {
      if (ref.innerChange) {
        return;
      }
      const tabItems = [...tabsWrapper.children] as HTMLElement[];
      if (tabItems.length === value.length + 1) {
        value.forEach((v, i) => {
          insertBadge(tabItems[i + 1], typeof v === 'number', v);
        });
      }
    }, false);
  } else {
    insertBadge(el, typeof value === 'number', value);
  }
};

const VBadgePlugin: PluginObject<undefined> & { hasInstalled: boolean } = {
  hasInstalled: false,
  install(Vue) {
    if (this.hasInstalled) {
      return;
    }
    this.hasInstalled = true;
    Vue.directive('badge', {
      inserted: (el, binding) => {
        handleBadge(el, binding);
      },
      update: (el, binding) => {
        handleBadge(el, binding, true);
      },
      unbind,
    });
  },
};

export default VBadgePlugin;
