































import { getFilterList, getList } from '@/common/apis/list/list';
import { Component, Vue, Watch } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import Search from '@/pages/home/views/list-of-operations/components/search.vue';
import {
  interviewColumns,
  interviewSearchOptions,
  tabTypeMap,
  roleSelectOptions,
  topicTypeOptions,
} from '@/pages/home/views/list-of-operations/contants';

@Component({
  components: {
    List,
    Search,
  },
})
export default class ListOfOperations extends Vue {
  activeName = 'intervie-list';
  pageNo = 1;
  pageSize = 10;
  totalCount = 0;
  searchValue = '';
  loading = false;
  searchOption = '';
  columns = interviewColumns;
  interviewSearchOptions = interviewSearchOptions;
  roleSelectOptions = roleSelectOptions;
  topicTypeOptions = topicTypeOptions;
  tableData: any = [];
  companyOptions: any = [];
  jobOptions: any = [];
  query: any = {
    companyIds: '',
    jobIds: '',
    name: '',
    mobile: '',
    interviewId: '',
    email: '',
    invitationCode: '',
  };

  // search
  roleName = '';
  companySearch = '';
  jobSearch = '';
  jobPublisher = '';
  topicValue = '';
  topicType = '';

  async created() {
    // this.getTableList(tabTypeMap[this.activeName]);
    const data = await this.getFilterList('COMPANY');
    this.companyOptions = this.companyOptions.concat(
      data.map(el => ({
        label: el.name,
        value: el.id,
      })),
    );
    const item = await this.getFilterList('JOB');
    this.jobOptions = this.jobOptions.concat(
      item.map(el => ({
        label: el.name,
        value: el.id,
      })),
    );
  }

  @Watch('companySearch')
  async onCompanySearch(value) {
    this.pageNo = 1;
    this.pageSize = 10;
    this.query.companyIds = Array.isArray(value) ? value.join(',') : '';
    this.getTableList(tabTypeMap[this.activeName]);
  }

  @Watch('jobSearch')
  async onJobSearch(value) {
    this.pageNo = 1;
    this.pageSize = 10;
    this.query.jobIds = Array.isArray(value) ? value.join(',') : '';
    this.getTableList(tabTypeMap[this.activeName]);
  }

  // 附身
  handlerLook(item) {
    let id = null;
    let name = null;
    let path = '';
    switch (this.activeName) {
      case 'intervie-list':
        id = item.publishedBy;
        name = item.cname;
        path = `/workstation/room/${item.interviewId}`;
        break;
    }
    const token = this.$QJStorage.getStorage<string>(this.$QJConfig.tokenKey);
    const attachmentTokenKey = this.$QJConfig.attachmentTokenKey;
    const sign = `${Date.now()}`;
    const query = {
      [attachmentTokenKey]: encodeURIComponent(token!),
      sign,
      targetUid: id,
      targetName: name,
      type: this.$QJConfig.attachmentRunAsValue,
    };
    window.open(
      `${this.$QJConfig.BEndPath}${path}?${Object.entries(query)
        .map(e => e.join('='))
        .join('&')}`,
    );
  }

  // 获取所有列表（职位、公司等
  async getFilterList(type: string, companyIds: string = '') {
    const params = {
      type: type,
      companyIds: companyIds,
    };
    const { data } = await getFilterList(params);
    return data;
  }

  // 获取职位列表
  async getTableList(type: string) {
    this.loading = true;
    Object.keys(this.query).forEach(key => {
      if (!this.query[key] || this.query[key] === '') {
        delete this.query[key];
      }
    });
    const params = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      type: type,
      ...this.query,
    };
    try {
      const { data } = await getList(params);
      this.totalCount = data.totalCount!;
      this.tableData = data.list.map(el => ({
        ...el,
        opened: el.opened === undefined ? 'undefined' : el.opened,
        // name: el.publishedBy.name,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  clearQuery() {
    this.query.name = '';
    this.query.mobile = '';
    this.query.interviewId = '';
    this.query.email = '';
    this.query.invitationCode = '';
  }

  handlerSubmit(item: { type: string; value: string; isShowSearchIcon: boolean }) {
    this.pageNo = 1;
    this.pageSize = 10;
    this.clearQuery();
    if (item.isShowSearchIcon) {
      this.query[item.type] = item.value;
      this.getTableList(tabTypeMap[this.activeName]);
    } else {
      this.query[item.type] = item.value;
    }
  }

  handleClick() {
    const { activeName } = this;
    this.pageNo = 1;
    this.pageSize = 10;
    switch (activeName) {
      case 'intervie-list':
        this.companySearch = '';
        this.jobSearch = '';
        this.columns = interviewColumns;
        this.getTableList('INTERVIEW');
        break;
    }
  }

  handlerSizeChange(value: number) {
    this.pageSize = value;
    this.getTableList(tabTypeMap[this.activeName]);
  }

  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getTableList(tabTypeMap[this.activeName]);
  }
}
