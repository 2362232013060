






























































import { Component, Vue } from 'vue-property-decorator';
import List from '@/pages/home/views/list-of-operations/components/list.vue';
import { clueColumns } from './contants';
import EditClueDialog from './dialog/edit-clue-dialog.vue';
import AddClue from './dialog/add-clue.vue';
import { getClueList } from '@/common/apis/recruitment';
import { updateJob, sendRecruitmentEmail } from '@/common/apis/recruitment/index';
import { IClue } from '@/common/apis/recruitment/type';

interface IOptions {
  label: string;
  value: string | number;
}

@Component({
  components: {
    List,
    EditClueDialog,
    AddClue,
  },
})
export default class ClueManagement extends Vue {
  loading = false;
  pageNo = 1;
  pageSize = 50;
  totalCount = 0;
  columns = clueColumns;
  companyDisabled = true;
  followerNameDisabled = true;
  tableData: IClue[] = [];
  accountManagerOptions: IOptions[] = [];
  query = {
    companyName: '',
    contactsMobile: '',
    contactsEmail: '',
    followerName: '',
  };

  $refs!: {
    EditClueDialog: EditClueDialog;
    AddClue: AddClue;
  };
  created() {
    this.getClueList();
  }
  // 获取线索列表
  async getClueList() {
    const { query, pageNo, pageSize } = this;
    const params = {
      pageNo,
      pageSize,
    };
    Object.keys(query).forEach(el => {
      if (query[el] !== '') {
        params[el] = query[el];
      }
    });
    try {
      const { data } = await getClueList(params);
      this.tableData = data.list;
      this.totalCount = data.totalCount;
    } catch (error) {
      console.error('🙅 获取线索列表', error);
    }
  }
  // 邮箱编辑打开
  handlerEmailEditOpen(row: IClue) {
    this.$refs.EditClueDialog.open(row);
  }
  // 邮箱编辑
  handlerEmailEdit() {
    this.getClueList();
  }
  // 导入
  handlerImport() {
    this.$refs.AddClue.open();
  }
  // 搜索
  handlerSearch() {
    this.getClueList();
  }
  // 编辑
  handlerEdit(row: IClue) {
    this.$refs.EditClueDialog.open(row);
  }
  // 更新职位
  async handlerUpdateJob(row: IClue) {
    try {
      const { success } = await updateJob({ id: row.id });
      if (success) {
        this.$message({
          type: 'success',
          message: '更新成功!',
        });
        this.getClueList();
      }
    } catch (error) {
      console.error('🙅 更新职位', error);
    }
  }
  // 发送代招邮箱
  async handleSendEmail(row: IClue) {
    try {
      const { success } = await sendRecruitmentEmail({ id: row.id });
      if (success) {
        this.$message({
          type: 'success',
          message: '发送成功!',
        });
      }
    } catch (error) {
      console.error('🙅 ', error);
    }
  }
  handlerSizeChange(value: number) {
    this.pageSize = value;
    this.getClueList();
  }
  handlerCurrentChange(value: number) {
    this.pageNo = value;
    this.getClueList();
  }
}
