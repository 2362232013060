import { PluginFunction } from 'vue';
import Config from './config';
import QJConfig from './config/plugin';
import VBadgePlugin from './directive/v-badge';
import AuthMediaPlugin from './directive/auth-directive';
import { getWithAuthRequest } from './api/ajax';
import QJUtilsPlugin from './utils';
import { getUA } from './utils/global';
import BrowserCompatiblePlugin from './components/browser-compatible';

export interface IQJCommonPluginOptions {
  config?: Partial<typeof Config> | undefined
  withoutUploader?: boolean
}

class QJCommonPlugin {

  hasInstalled = false;

  install: PluginFunction<IQJCommonPluginOptions> = (Vue, options = {}) => {
    if (this.hasInstalled) {
      return;
    }
    /**
     * common_global_style
     */
    require('./assets/style/global.less');

    /**
     * utils
     *
     * $QJEventBus
     * $QJFullname
     * $QJStorage
     * $JSONParse
     * $QJUtils
     */
    Vue.use(QJUtilsPlugin);
    /**
     * config
     *
     * $QJConfig
     * $QJDict
     */
    Vue.use(QJConfig, options?.config);
    /**
     * v-badge
     */
    Vue.use(VBadgePlugin);
    /**
     * v-auth-src
     * v-auth-bgImg
     */
    Vue.use(AuthMediaPlugin, { request: getWithAuthRequest() });
    /**
     * common-upload
     */
    if (!options.withoutUploader) {
      import(/* webpackChunkName: "common-upload" */ './components/common-upload').then(QJUploader => {
        Vue.use(QJUploader.default);
      });
    }
    /**
     * browser-compatible
     */
    Vue.use(BrowserCompatiblePlugin);
    /**
     * customUA
     */
    Object.defineProperty(navigator, 'userAgent', {
      writable: true,
      configurable: true,
      value: getUA(),
    });
    /**
     * mark-installed
     */
    this.hasInstalled = true;
  }
}

const QJCommon = new QJCommonPlugin();

export default QJCommon;

