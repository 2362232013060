


























































import type { CvListItem } from '@/pages/home/views/enterprise-management/job-management/types';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CvList extends Vue {
  @Prop() tableData!: CvListItem[];
  @Prop({
    default: true,
  }) isShowSubmit!: boolean;
  @Prop({
    default: true,
  }) isShowDelete!: boolean;

  $refs!: {
    singlefile: HTMLInputElement;
  };

  @Emit('selectionChange')
  handleSelectionChange(value: any) {
    return value;
  }

  handlerUserEditor(row: any) {
    if (this.isShowSubmit) {
      this.emitUserEditor(row);
    } else {
      window.open(row.url);
    }
  }

  // 用户编辑
  @Emit('userEditor')
  emitUserEditor(row: any) {
    return row;
  }

  // 单个重新上传
  @Emit('reUpload')
  singleUploadResume(row, e: Event) {
    const target = e.target as EventTarget & { files: FileList };
    const file = target.files[0];
    this.$refs[`singlefile${row.id}`].value = '';
    return {
      row: row,
      file: file,
    };
  }
  // 删除
  @Emit('deleteCv')
  handlerDeleteCv(row: any) {
    return row;
  }
  // 重新上传
  handlerReupload(id: number) {
    this.uploadCv(`singlefile${id}`);
  }

  // 上传简历
  uploadCv(id) {
    const input = this.$refs[id];
    input.click();
  }
}
