



















import { Vue, Component } from 'vue-property-decorator';
import { IMedia, IVideoBaseInfo } from '../../model/common';

@Component
export default class VideoPlayerModal extends Vue {

  title?: string = '';
  media!: Partial<IMedia<string | Partial<IVideoBaseInfo>>>;
  show = false;

  $refs!: {
    QJVideoPlayer: Vue & {
      pause: () => Promise<void>;
    }
  }

  get options() {
    return {
      source: this.getVideoInfo.playAddressList[0].playUrl,
      autoplay: true,
      isLive: false,
      height: '80vh',
    };
  }

  get getVideoInfo() {
    try {
      return typeof this.media.data === 'string'
        ? JSON.parse(this.media.data)
        : this.media.data;
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  pause() {
    this.$refs.QJVideoPlayer && this.$refs.QJVideoPlayer.pause();
  }

  beforeDestroy() {
    this.$refs.QJVideoPlayer && this.$refs.QJVideoPlayer.$destroy();
  }
}
