





































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITimelineSection } from '../types';


@Component
export default class TimelineSectionDot extends Vue {
  @Prop({ required: true }) type!: ITimelineSection['type'];
  @Prop({ type: Boolean, default: false }) isPresent!: boolean;
  @Prop({ type: Boolean, default: false }) isCompleted!: boolean;
  @Prop({ default: () => ['answerMedia', 'interludeMedia'] }) ignoreType!: ITimelineSection['type'][];
}
