









































import { Component, Vue, Watch } from 'vue-property-decorator';
import JobDetails from './jd-details.vue';
import JobQusetionDetailsPreview from './question-details.vue';

@Component({
  components: {
    JobQusetionDetailsPreview,
    JobDetails,
  },
})
export default class JobPreview extends Vue {
  menus = [
    {
      title: '职位详情',
      name: 'jd-details-job',
    },
    {
      title: '面试配置',
      name: 'jd-details-exam',
    },
  ];
  activeMenuName = 'jd-details-job';
  show = false;
  title = '职位预览';
  job = {};

  onMenuChange(m: { title: string; name: string }) {
    this.activeMenuName = m.name;
  }

  @Watch('show')
  onShowChange(newVal: boolean) {
    if (!newVal) {
      this.$destroy();
    }
  }

}
