import Router from 'vue-router';
import JobPreview from './preview.vue';
import JobInfoPreview from './preview-job-info.vue';
import JobExamPreview from './preview-job-exam.vue';

import { IMyPaper } from '../../model/job-create';
export { default as JobDetails } from './jd-details.vue';
export { default as JobQusetionDetailsPreview } from './question-details.vue';

export { default as TimelineSection } from './components/timeline-section.vue';
export { default as TimelineSectionDot } from './components/timeline-section-dot.vue';
export { default as JdAbstract } from './components/jd-abstract.vue';
export { default as ExamPreview } from './components/exam-preview.vue';

interface JobPreviewData<T = Record<string, any>>{
  show: boolean;
  title: string;
  activeMenuName?: 'jd-details-exam' | 'jd-details-job';
  job: T;
  myPaper?: IMyPaper
}

const previewJob = <T extends Record<string, any>>(
  job: T,
  tab: JobPreviewData['activeMenuName'] = 'jd-details-job',
  router = new Router(),
) => {
  const preview = new JobPreview({ router }) as JobPreview & JobPreviewData<T>;
  preview.job = job;
  preview.title = `职位预览: ${job.title}`;
  preview.activeMenuName = tab;
  preview.show = true;
  preview.$mount();
};

export const previewJobInfo = <T extends Record<string, any>>(job: T) => {
  const preview = new JobInfoPreview() as JobInfoPreview & JobPreviewData<T>;
  preview.job = job;
  preview.show = true;
  preview.$mount();
};

export const previewJobExam = <T extends Record<string, any>>(job: T, myPaper: IMyPaper) => {
  const preview = new JobExamPreview() as JobExamPreview & JobPreviewData<T>;
  preview.job = job;
  preview.myPaper = myPaper;
  preview.show = true;
  preview.$mount();
};

export default previewJob;
