export const clueColumns = [
  {
    label: '序号',
    prop: 'id',
    width: 50,
  },
  {
    label: '公司名称',
    prop: 'companyName',
    width: 300,
  },
  {
    label: '联系人',
    prop: 'contactsName',
    width: 100,
  },
  {
    label: '联系电话',
    prop: 'contactsMobile',
    width: 100,
  },
  {
    label: '客户经理',
    prop: 'followerName',
    width: 150,
  },
  {
    label: 'boss职位同步状态',
    prop: 'scrapeJobStatus',
    width: 150,
  },
  {
    label: 'boss在招职位数',
    prop: 'scrapedJobCount',
    width: 150,
  },
  {
    label: '同步时间',
    prop: 'scrapeJobRequestAt',
    width: 150,
  },
  {
    label: '联系邮箱',
    prop: 'contactsEmail',
    width: 150,
  },
];

export const managementColumns = [
  {
    label: '序号',
    prop: 'id',
    width: 50,
  },
  {
    label: '公司名称',
    prop: 'companyName',
    width: 300,
  },
  {
    label: '职位名称',
    prop: 'title',
    width: 200,
  },
  {
    label: '职位编码',
    prop: 'code',
    width: 150,
  },
  {
    label: '地区',
    prop: 'officeLocation',
    width: 150,
  },
  {
    label: '客户经理',
    prop: 'followerName',
    width: 150,
  },
  {
    label: '代招费用',
    prop: 'fee',
    width: 150,
  },
  {
    label: '职位状态',
    prop: 'statusName',
    width: 150,
  },
  {
    label: '是否配置面试间',
    prop: 'openedDesc',
    width: 150,
  },
  {
    label: '职位在线天数',
    prop: 'onlineDays',
    width: 150,
  },
  {
    label: '累计投递人数',
    prop: 'totalCandidateCount',
    width: 150,
  },
  {
    label: '发布时间',
    prop: 'publishedAt',
    width: 150,
  },
  {
    label: '代招状态',
    prop: 'rpoStatus',
    width: 150,
  },
  {
    label: '招聘经理',
    prop: 'rpoFollowerName',
    width: 150,
  },
  {
    label: '招聘渠道',
    prop: 'rpoChannelCount',
    width: 150,
  },
];

export const columns = [
  {
    label: '招聘经理',
    prop: 'followerName',
    width: 150,
  },
  {
    label: '招聘渠道',
    prop: 'recruitmentChannel',
    width: 150,
  },
  {
    label: '招聘账号',
    prop: 'account',
    width: 200,
  },
  {
    label: '账号属性',
    prop: 'accountType',
    width: 200,
  },
  {
    label: '在线职位',
    prop: 'title',
    width: 200,
  },
  {
    label: '招聘邮箱',
    prop: 'email',
    width: 250,
  },
];
