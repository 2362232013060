import { AxiosInstance } from 'axios';

export function commonRequest(url: string, request: AxiosInstance) {
  return request({
    method: 'get',
    url,
    responseType: 'arraybuffer',
  })
    .then(rs => {
      const mimeType = rs.headers['content-type'].toLowerCase();
      const imgBase64 = btoa(
        new Uint8Array(rs.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
      );
      return 'data:' + mimeType + ';base64,' + imgBase64;
    });
}
