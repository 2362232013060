






































import Resume from '@/components/resume.vue';
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Resume,
  },
})
export default class UserMessageDialog extends Vue {
  centerDialogVisible = false
  form = {
    name: '',
    mobile: '',
    email: '',
    url: '',
    id: -1,
  }

  $refs!:{
    form: HTMLElement
  }

  rules = {
    name: [{ required: true, message: '请输入面试者姓名', trigger: 'blur' }],
    ignoreMobile: [],
    mobile: [
      {
        required: true,
        message: '请输入手机号',
        trigger: 'blur',
      },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: '手机号码格式不正确',
      },
    ],
    email: [
      {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        message: '邮箱格式不正确',
        trigger: 'blur',
      },
    ],
  };

  get saveDisabled() {
    const { form } = this;
    if (form.name && form.mobile && /^1[3456789]\d{9}$/.test(form.mobile)) {
      return false;
    }
    return true;
  }

  open(data) {
    this.centerDialogVisible = true;
    this.form.name = data.name;
    this.form.mobile = data.mobile;
    this.form.email = data.email;
    this.form.id = data.id;
    this.form.url = data.url;
  }

  @Emit()
  save() {
    this.centerDialogVisible = false;
    return this.form;
  }

  mobileChange() {
    this.form.mobile = (this.form.mobile || '')
      .trim()
      .replace(/-/g, '')
      .replace(/[\n\r\t]/g, '')
      .split('')
      .map(e => (e || '').trim())
      .join('');
  }
}
