import { StoreOptions } from 'vuex';
import loginModule from './login-module';
import enterpriseManagementModule from './enterprise-management-module';
import platformQuestionBankModule from './platform-question-bank-module';
import videoQualityAuditModule from './video-quality-audit-module';
import interviewContentAuditModule from './interview-content-audit-module';
import interviewQualityMarkModule from './interview-quality-mark-module';
import mediaLibraryModule from './media-library-module';
import layoutModule from './layout-module';

export interface IRootState {

}

const root: StoreOptions<IRootState> = {
  modules: {
    /**
     * 页面布局
     */
    layout: layoutModule,
    /**
     * 登录
     */
    login: loginModule,
    /**
     * 媒体库
     */
    mediaLibrary: mediaLibraryModule,
    /**
     * 企业管理
     */
    enterprise: enterpriseManagementModule,
    /**
     * 平台面试题库
     */
    platformQustionBank: platformQuestionBankModule,
    /**
     * 面试内容审核 - 视频风险合规
     */
    audit: interviewContentAuditModule,
    /**
     * 拍摄质量审核
     */
    videoQuality: videoQualityAuditModule,
    /**
     * 面试质量标注
     */
    mark: interviewQualityMarkModule,
  },
  state: {},
  getters: {
    // eslint-disable-next-line max-params
    loadings(state, getters, rootState, rootGetters) {
      return rootGetters['wait/is'];
    },
  },
  mutations: {},
  actions: {},
};

export default root;
