











































import { Vue, Component, Prop } from 'vue-property-decorator';
import EditVideo from './edit-video.vue';
import { Getter, namespace } from 'vuex-class';
import Anchor from '@/components/anchor.vue';
import { IEnterprise } from '@/model/enterprise';
import moment from 'moment';
import { IPagination } from '@/common/types';
import { IEnterpriseVideoListData } from '@/model/enterprise-video';
import { IVideoBaseInfo } from '@/model/resource';
const EnterpriseVideoModule = namespace('enterprise/enterpriseVideo');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@Component({
  components: {
    EditVideo,
    Anchor,
  },
})
export default class VideoListItem extends Vue {
  @Prop({ type: Object }) videoListItem!: IPagination<IEnterpriseVideoListData>;
  @Prop({ type: String, default: '' }) currentParentCategory!: string;
  @Prop({ type: Object }) currentSecondCategory!: any;

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  pageNo = 1;
  pageSize = 20;
  get totalCount() {
    return this.videoListItem ? this.videoListItem.totalCount : 0;
  }

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseVideoModule.Action('getEnterpriseVideosList')
  getEnterpriseVideosListAction!: any;

  reloadVideoList(secondCategory: string) {
    this.reloadVideoListBySecondCategory(secondCategory);
    if (this.currentSecondCategory.code !== secondCategory) {
      this.reloadVideoListBySecondCategory(this.currentSecondCategory.code);
    }
  }

  reloadVideoListBySecondCategory(secondCategory: string) {
    const options = {
      companyId: this.currentEnterprise.id,
      parentCategory: this.currentParentCategory,
      secondCategory: secondCategory,
      jobCategories: '',
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseVideosListAction(options);
  }

  getFormattedCreatedAt(createdAt: moment.MomentInput) {
    return moment(createdAt).format(dateFormat);
  }

  preview(video: IEnterpriseVideoListData<string>) {
    const data = this.$JSONParse<IVideoBaseInfo>(video?.media?.data);
    if (data && data.playAddressList && data.playAddressList[0]?.playUrl) {
      this.$alert(
        `
      <video
        controls
        height="400"
        width="auto"
        style="max-width: 500px;"
        src="${data.playAddressList[0].playUrl}"
      ></video>`,
        `视频预览: ${video.bvideoName}`,
        {
          confirmButtonText: '关闭预览',
          center: true,
          dangerouslyUseHTMLString: true,
          closeOnPressEscape: true,
          customClass: 'video-preview-dialog',
        },
      ).catch(() => {});
    }
  }

  getCurrentSecondCategory(videoType: string) {
    if (this.currentParentCategory === '0') {
      // 职位类
      if (videoType === '团队介绍') {
        return '0';
      }
      if (videoType === '工作内容') {
        return '1';
      }
      if (videoType === '职位类业务介绍') {
        return '2';
      }
      if (videoType === '员工培训') {
        return '3';
      }
      return '9999';
    }
    if (this.currentParentCategory === '1') {
      // 公司类
      if (videoType === '公司环境') {
        return '100';
      }
      if (videoType === '公司发展') {
        return '101';
      }
      if (videoType === '公司类业务介绍') {
        return '102';
      }
      return '99999';
    }
    if (this.currentParentCategory === '2') {
      // 面试官类
      if (videoType === '开场自我介绍') {
        return '1000';
      }
      if (videoType === '面试结束语') {
        return '1001';
      }
      if (videoType === '转场视频') {
        return '1002';
      }
    }
    return ''; // 其它类
  }

  coverUrl(video: IEnterpriseVideoListData<string>) {
    if (video.media) {
      return this.$JSONParse(video.media.data)?.coverUrl || '';
    }
    return '';
  }

  handleSizeChange(val: number) {
    this.pageSize = val;
    const options = {
      companyId: this.currentEnterprise.id,
      parentCategory: this.currentParentCategory,
      secondCategory: this.getCurrentSecondCategory(this.currentSecondCategory.name),
      jobCategories: '',
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseVideosListAction(options);
  }
  handleCurrentChange(val: number) {
    this.pageNo = val;
    const options = {
      companyId: this.currentEnterprise.id,
      parentCategory: this.currentParentCategory,
      secondCategory: this.getCurrentSecondCategory(this.currentSecondCategory.name),
      jobCategories: '',
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    };
    this.getEnterpriseVideosListAction(options);
  }
}
