

































































































import { Component, Vue } from 'vue-property-decorator';
import Resume from '@/components/resume.vue';
import { ElForm } from 'element-ui/types/form';
import { postEditCv } from '@/common/apis/resume/resume';
import { ITrainings } from '@/common/apis/resume/type';

@Component({
  components: {
    Resume,
  },
})
export default class TrainingExperience extends Vue {
  showDrawer = false
  previewResumeUrl = ''
  resumeId = 0

  trainings: ITrainings[] = [{
    start: '',
    end: '',
    trainingName: '',
    institution: '',
    course: '',
    certificate: '',
    summary: '',
  }]

  $refs!:{
    form: ElForm[]
  }

  rules = {
    start: { required: true, message: '培训起始时间不能为空', trigger: 'blur' },
    end: { required: true, message: '培训结束时间不能为空', trigger: 'blur' },
    trainingName: [
      { required: true, message: '机构名称不能为空', trigger: 'blur' },
      { max: 50, message: '最大支持输入50字' },
    ],
  }
  open(previewResumeUrl: string, data:ITrainings[], resumeId:number) {
    this.showDrawer = true;
    this.previewResumeUrl = previewResumeUrl;
    this.resumeId = resumeId;
    this.trainings = data;
  }
  handlerCancel() {
    this.showDrawer = false;
  }
  //  删除
  removeCourse(index: number) {
    this.trainings.splice(index, 1);
  }
  // 保存
  async handlerSave() {
    let valid = true;
    for (const item of this.$refs.form) {
      // eslint-disable-next-line no-loop-func
      item.validate(async value => {
        if (!value) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = false;
        }
      });
    }
    if (valid) {
      try {
        const { success } = await postEditCv({
          resumeId: this.resumeId,
          form: {
            trainings: this.trainings,
          },
        });
        if (success) {
          this.$message({
            type: 'success',
            message: '提交成功!',
          });
          this.showDrawer = false;
          this.$emit('submit');
        }
      } catch (error) {
        console.error('🙅 保存培训经历', error);
      }
    }
  }
  // 添加项目经历
  handlerEduProject() {
    this.trainings.push({
      start: '',
      end: '',
      trainingName: '',
      institution: '',
      course: '',
      certificate: '',
      summary: '',
    });
  }
}
