


































import { IEnterprise } from '@/model/enterprise';
import { IEnterpriseVideoListData, IEnterpriseVideoCategory } from '@/model/enterprise-video';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import enterpriseVideoSchema from '@/common/schema/enterprise-video.json';
import { deleteEnterpriseVideo, saveEnterpriseVideo } from '@/common/service';
const EnterpriseVideoModule = namespace('enterprise/enterpriseVideo');
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

const initFormData = (initData?: Partial<IEnterpriseVideoListData>) => {
  const formData = {
    bvideoName: '',
    parentCategory: '',
    secondCategory: '',
    jobCategories: [],
    mediaId: null,
    remarks: '',
    id: null,
    companyId: null,
    ...initData,
  };
  if (initData && initData.parentCategory) {
    formData.parentCategory = (initData.parentCategory as IEnterpriseVideoCategory).code;
  }
  if (initData && initData.secondCategory) {
    formData.secondCategory = (initData.secondCategory as IEnterpriseVideoCategory).code;
  }
  return formData;
};

const secondCategoryOptions = {
  0: [
    {
      value: '0',
      label: '团队介绍',
    },
    {
      value: '1',
      label: '工作内容',
    },
    {
      value: '2',
      label: '职位类业务介绍',
    },
    {
      value: '3',
      label: '员工培训',
    },
    {
      value: '9999',
      label: '其它',
    },
  ],
  1: [
    {
      value: '100',
      label: '公司环境',
    },
    {
      value: '101',
      label: '公司发展',
    },
    {
      value: '102',
      label: '公司类业务介绍',
    },
    {
      value: '99999',
      label: '公司其他类',
    },
  ],
  2: [
    {
      value: '1000',
      label: '开场自我介绍',
    },
    {
      value: '1001',
      label: '面试结束语',
    },
    {
      value: '1002',
      label: '转场视频',
    },
  ],
  9999: [
    {
      value: '',
      label: '其它',
    },
  ],
};

@Component
export default class EditVideo extends Vue {
  formName = 'enterpriseVideoFrom';
  $ncformValidate: any;
  get formSchema() {

    return {
      ...enterpriseVideoSchema,
      properties: {
        ...enterpriseVideoSchema.properties,
        secondCategory: {
          ...enterpriseVideoSchema.properties.secondCategory,
          ui: {
            ...enterpriseVideoSchema.properties.secondCategory.ui,
            widgetConfig: {
              ...enterpriseVideoSchema.properties.secondCategory.ui.widgetConfig,
              enumSource: secondCategoryOptions[Number(this.videoForm.parentCategory) as keyof typeof secondCategoryOptions],
            },
          },
        },
      },
    };
  }

  @Prop({ type: String, default: '' }) buttonType!: string;
  @Prop({ type: String, default: '' }) buttonText!: string;
  @Prop({ type: Object }) enterpriseVideo!: IEnterpriseVideoListData;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseVideoModule.State('enterpriseVideosList')
  enterpriseVideosList!: IEnterpriseVideoListData;

  showDrawer = false;

  videoForm = initFormData();
  $ncformReset: any;

  get outFunc() {
    // @ts-ignore
    const func = e => {
      const jobCategories = (e.jobCategories || []).map((item: { code: string; }) => item.code);
      const res = Object.assign({}, e, {
        mediaId: e.mediaId.mediaId || e.mediaId,
        jobCategories: jobCategories,
      });
      return res;
    };
    return func.toString();
  }

  @Watch('enterpriseVideo')
  onVideoChange(enterpriseVideo: Partial<IEnterpriseVideoListData>|undefined) {
    this.videoForm = initFormData({ ...enterpriseVideo });
  }

  onFormChange(options: { paths: any; itemValue: any; formValue: any; }) {
    const { paths, itemValue } = options;
    if (paths !== 'parentCategory' ) {
      return;
    }
    const secondCategoryList = secondCategoryOptions[Number(itemValue) as keyof typeof secondCategoryOptions];
    const cond = secondCategoryList.every(e => e.value !== this.videoForm.secondCategory);
    cond && ( this.videoForm.secondCategory = '');
  }

  async submit() {
    this.$ncformValidate(this.formName).then(async (data: { result: any; }) => {
      if (data.result) {
        const saveVideoPayload = {
          ...this.enterpriseVideo,
          ...this.videoForm,
          ...{ companyId: this.currentEnterprise.id },
        };
        await saveEnterpriseVideo(saveVideoPayload);
        this.videoForm = initFormData();
        this.showDrawer = false;
        this.$emit('close', saveVideoPayload.secondCategory);
      }
    });
  }

  async deleteVideo() {
    this.$confirm('确认删除？')
      .then(async () => {
        const res = await deleteEnterpriseVideo({
          id: this.enterpriseVideo.id,
          companyId: this.currentEnterprise.id,
        });
        if (res.success) {
          this.videoForm = initFormData();
          this.showDrawer = false;
          this.$emit('close', (this.enterpriseVideo.secondCategory as IEnterpriseVideoCategory).code);
        } else {
          this.$notify.error('删除企业视频失败！');
        }
      })
      .catch(() => {});
  }

  handleClose(done: any) {
    this.$confirm('确认关闭？')
      .then(() => {
        done();
      })
      .catch(() => {});
  }

  created() {
    if (this.enterpriseVideo) {
      this.videoForm = initFormData({ ...this.enterpriseVideo });
    }
  }
}
