
































































































































































import { getResumeList, postSubmitResume, updateResumeInfo } from '@/common/apis/resume/resume';
import { getAccountList, getInterviewerList, readResume, removeJob, setPublisher } from '@/common/service';
import { bigNumberTransform } from '@/common/utils';
import { IAccount } from '@/model/account';
import { IEnterprise } from '@/model/enterprise';
import { IJob, IJobListData } from '@/model/job';
import type { CvListItem } from '@/pages/home/views/enterprise-management/job-management/types';
import showAddInterviewerDrawer from '@w-admin/common/src/components/drawers/add-interviewer';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CvList from './cv-list.vue';
import EnterprisePublisherListForm from './enterprise-publisher-list-form.vue';
import UserEditorDialog from './user-dialog/user-editor-dialog.vue';

const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');

@Component({
  components: {
    EnterprisePublisherListForm,
    CvList,
    UserEditorDialog,
  },
})
export default class JobCard extends Vue {
  @Prop({ required: true }) job!: IJobListData | IJob;

  subjectText = '职位协作人';
  activeName = 'resume-to-submitted';
  tableData: CvListItem[] = [];
  tabLoading = false;
  multipleSelection: {
    cvSnapshotId: number;
    name: string;
    phone: string;
    email: string;
    status: number;
  }[] = [];
  count = 0;

  $refs!: {
    file: HTMLInputElement;
  };

  getAccountList = getAccountList;
  getInterviewerList = getInterviewerList;

  showEditPublisherDrawer = false;
  showUploadResumeDrawer = false;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @EnterpriseInfoModule.State('currentEnterpriseContacts')
  currentEnterpriseContacts?: IAccount;

  get submitBtnDisabled() {
    return (
      this.multipleSelection.length === 0
      || this.multipleSelection.some(el => el.status === 0 || el.status === 2)
      || this.multipleSelection.every(el => el.name === '-' || el.phone === '-' || !/^1[3456789]\d{9}$/.test(el.phone))
    );
  }

  get isShowSubmit() {
    return this.activeName !== 'cv-has-been-submitted';
  }

  // 一键投递
  async handlerSubmitResume() {
    const params = {
      jobId: this.job.id,
      cvList: this.multipleSelection
        .map(el => ({
          cvId: el.cvSnapshotId,
          name: el.name,
          mobile: el.phone,
          email: el.email,
        }))
        .filter(el => el.name !== '-' && el.mobile !== '-' && /^1[3456789]\d{9}$/.test(el.mobile)),
    };
    try {
      await postSubmitResume(params);
      params.cvList.forEach(el => {
        const index = this.tableData.findIndex(item => item.cvSnapshotId === el.cvId);
        this.tableData.splice(index, 1);
      });
      this.$message({
        type: 'success',
        message: '投递成功!',
      });
    } catch (error) {
      console.error('[一键投递 http]：', error);
    }
  }
  // 编辑简历信息
  async handlerSave(form) {
    if (this.activeName === 'cv-has-been-submitted') {
      const params = {
        id: form.id,
        name: form.name,
        mobile: form.mobile,
        email: form.email,
      };
      try {
        const { data } = await updateResumeInfo(params);
        if (data) {
          this.$message({
            type: 'success',
            message: '保存成功!',
          });
        }
      } catch (error) {
        console.error('[编辑保存]', error);
      }
    }
    const index = this.tableData.findIndex(el => el.id === form.id);
    this.tableData[index].name = form.name || '-';
    this.tableData[index].email = form.email || '-';
    this.tableData[index].phone = form.mobile || '-';
  }
  // 用户编辑
  handlerUserEditor(row: any) {
    this.$refs['user-editor'].open({
      id: row.id,
      name: row.name === '-' ? '' : row.name,
      mobile: row.phone === '-' ? '' : row.phone,
      email: row.email === '-' ? '' : row.email,
      url: row.url,
    });
  }
  // 简历多个复选
  handleSelectionChange(value: any) {
    this.multipleSelection = value;
  }
  // 批量导入简历
  handlerBulkImportResumes() {
    this.showUploadResumeDrawer = true;
  }
  // 上传简历
  uploadCv() {
    const input = this.$refs.file;
    input.click();
  }
  // 删除简历
  handlerDeleteCv(row: any) {
    this.$confirm(
      '<p style="font-size: 18px;font-weight: 600;">确认删除该条信息?</p>'
        + '<p style="font-size: 14px;font-weight: 400;margin-top:10px;">删除后不可恢复</p>',
      '',
      {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      },
    )
      .then(() => {
        const { tableData } = this;
        const index = tableData.findIndex(el => el.id === row.id);
        tableData.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      })
      .catch(() => {});
  }
  // 解析简历
  async uploadResume(e: Event) {
    const target = e.target as EventTarget & { files: FileList };
    const files = Object.values(target?.files);
    let tableList = files.map(file => {
      return {
        id: String(++this.count),
        name: '-',
        phone: '-',
        email: '-',
        cv: '-',
        file: file,
        status: 0,
      };
    });
    // 过滤重复文件
    this.isRepeatUploadFile(files);
    tableList = tableList.filter(el => !this.tableData.some(item => item.cv === el.file.name));
    this.tableData.push(...tableList);
    // 请求解析文件
    for await (const item of tableList) {
      await this.onUploaded(item).catch(() => {});
    }
    this.$refs.file.value = '';
  }
  // 重新上传
  handlerReUpload({ row, file }) {
    if (this.isRepeatUploadFile([file], row.id)) {
      return;
    }
    row.status = 0;
    row.file = file;
    this.onUploaded(row);
  }
  // 是否重复上传
  isRepeatUploadFile(files, id = '-1') {
    const { tableData } = this;
    const isRepeat = tableData.some(el =>
      files.some(item => {
        if (id !== '-1') {
          return item.name === el.cv && el.id !== id;
        }
        return item.name === el.cv;
      }),
    );
    if (isRepeat) {
      this.$notify({
        title: '注意',
        message: '存在相同文件，不能重复上传！',
        type: 'warning',
      });
    }
    return isRepeat;
  }
  // 批量上传解析
  onUploaded(item) {
    const { name } = item.file;
    const uploadClient = new this.$QJUtils.UploadClient({
      scene: 'CV',
      filename: name,
      file: item.file,
    });
    return new Promise((resolve, reject) => {
      uploadClient
        .upload()
        .then(res => {
          const { mediaId: rawMediaId, postUploadResult } = res;
          return readResume({
            name: name,
            rawMediaId: rawMediaId as number,
            format: postUploadResult?.format!,
            parse: true,
            platform: true,
          });
        })
        .then(res => {
          const { parserResult } = res.data;
          item.phone = parserResult.mobile || '-';
          item.email = parserResult.email || '-';
          item.name = parserResult.name || '-';
          item.url = res.data.downloadUrl;
          item.cv = item.file.name;
          item.cvSnapshotId = res.data.id;
          item.status = 1;
          resolve(res);
        })
        .catch(e => {
          console.error('【解析失败】', item.file.name, e);
          item.name = '-';
          item.phone = '-';
          item.email = '-';
          item.cv = item.file.name;
          item.status = 2;
          reject(e);
        });
    });
  }
  // 获取已投递简历列表
  async geDeliveredResume() {
    const { data } = await getResumeList({ jobId: this.job.id });
    if (data.length > 0) {
      this.tableData = data.map(el => ({
        id: el.cvId,
        cvSnapshotId: el.cvId,
        phone: el.mobile,
        name: el.name,
        cv: el.cvName,
        email: el.email,
        url: el.cvUrl,
        status: 1,
      }));
    }
  }
  async handleClick(value: string) {
    this.tabLoading = true;
    // 已投递简历
    if (value === 'cv-has-been-submitted') {
      this.tableData = [];
      await this.geDeliveredResume();
    } else {
      this.tableData = [];
    }
    this.tabLoading = false;
  }
  async deleteJob() {
    const { success } = await removeJob(this.job.id);
    if (success) {
      this.$emit('reload');
      this.$message({
        type: 'success',
        message: '职位删除成功!',
      });
    }
  }

  get moreAction() {
    return [
      {
        color: 'red',
        icon: 'el-icon-delete',
        text: '删除职位',
        action: () => {
          this.$confirm('此操作将永久删除该职位, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.deleteJob();
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              });
            });
        },
      },
    ];
  }

  onMenuClick(index: number) {
    this.$QJUtils.safeCallback(this.moreAction?.[index]?.action);
  }

  managerClick() {
    this.$notify.success(this.job.evaluatorCount + '人');
  }

  editPublisher() {
    this.showEditPublisherDrawer = true;
  }

  bigNumberTransform = bigNumberTransform;

  async onModifyPublisher(interviewerId: number) {
    try {
      const res = await setPublisher({
        jobId: this.job.id,
        publisherId: interviewerId,
      });
      if (res.success) {
        this.$notify.success('发布者修改成功');
        this.$emit('reload');
      }
      this.showEditPublisherDrawer = false;
    } catch (error) {
      console.log('修改发布者出错', error);
    }
  }

  openDetailsPage(jobId: string | number) {
    this.$emit('enterDetail', jobId);
  }

  showAddInterviewerDrawer() {
    showAddInterviewerDrawer('a', {
      jobId: this.job.id,
      jobTitle: this.job.title,
      subjectText: this.subjectText,
      success: () => {
        this.$emit('reload');
      },
    });
  }
}
