





import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const LoginModule = namespace('login');

@Component
export default class App extends Vue {

  @LoginModule.Action('getUserInfobyStorage')
  getUserInfobyStorage!: () => Promise<void>;

  init() {
    document.body.style.minWidth = this.$QJUtils.isMobile() || this.$QJUtils.isDingTalk() ? '300px' : '1366px';
  }

  created() {
    this.getUserInfobyStorage();
  }
}
