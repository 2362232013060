
























































import SelectBackVideo from '@/components/select-back/select-back-video.vue';
import { IEnterprise } from '@/model/enterprise';
import { IJob, ISnippetsForSave } from '@/model/job';
import { IMedia, IVideoBaseInfo } from '@/model/resource';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    SelectBackVideo,
  },
})
export default class JobFrame extends Vue {
  @Prop() job?: IJob;
  @Prop({ type: Boolean }) required?: boolean;
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() title?: string;
  @Prop() type?: string;
  @Prop() index?: number;
  @Prop() value?: (number | null | undefined)[];
  @Prop() item?: ISnippetsForSave;
  @Prop() defaultParentCategory?: string;
  @Prop() defaultSecondCategory?: string;

  editeNameVisible = false;
  editeName?: string = ''

  created() {
    this.editeName = this.title;
  }

  get innervalue() {
    return {
      mediaIds: this.value || [],
      medias: this.item?.medias || [],
    };
  }

  set innervalue(value: {
    mediaIds: (number | null | undefined)[];
    medias: (IMedia<IVideoBaseInfo> | undefined)[];
  }) {
    this.$emit('change', { value, index: this.index });
  }

  remove() {
    this.$emit('remove', this.index);
  }

  edit() {
    this.$emit('edit-name', { index: this.index, name: this.editeName });
    this.editeNameVisible = false;
  }
}
