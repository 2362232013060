



























import { interviewRejectByBusiness } from '@/common/service';
import { Vue, Component } from 'vue-property-decorator';
// import { namespace } from 'vuex-class';
// const videoQualityModule = namespace('videoQuality');

@Component
export default class VideoQualityAudit extends Vue {
  interviewId: string = '';
  answerIdsStr: string = '';
  reason: string = '';

  reset() {
    this.interviewId = '';
    this.answerIdsStr = '';
    this.reason = '';
  }

  rejectInterview(answerIdList: number[]) {
    interviewRejectByBusiness(
      Number(this.interviewId),
      this.reason,
      answerIdList,
    )
      .then(({ success }) => {
        if (success) {
          this.$notify.success(`成功驳回${answerIdList.length}个回答`);
          this.reset();
        } else {
          this.$notify.error('回答驳回失败');
        }
      });
  }

  submit() {
    if (!this.interviewId.trim()) {
      this.$notify.warning('请输入面试ID');
      return;
    }

    if (!/^\d+$/.test(this.interviewId.trim())) {
      this.$notify.warning('面试ID不合法');
      return;
    }

    if (!this.answerIdsStr.trim()) {
      this.$notify.warning('请至少输入一个回答ID');
      return;
    }

    if (!/^(\d+\s*[(\\n),，]?\s*)+$/.test(this.answerIdsStr.trim())) {
      this.$notify.warning('回答ID格式不合法');
      return;
    }

    if (!this.reason.trim()) {
      this.$notify.warning('请输入驳回原因');
      return;
    }

    const answerIdList = this.answerIdsStr
      .replace(/，/g, ',')
      .replace(/\n/g, ',')
      .split(',')
      .filter(e => !!e)
      .map(e => e.trim())
      .map(e => Number(e));

    this.$confirm(`确定要驳回面试ID为${this.interviewId}，回答ID为${answerIdList.join(',')}的面试吗？`, '温馨提示')
      .then(() => {
        this.rejectInterview(answerIdList);
      })
      .catch(() => {});
  }
}
