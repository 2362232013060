import { Module } from 'vuex';
import { IRootState } from './root';

export interface IMediaLibraryModuleState {
}

const mediaLibraryModule: Module<IMediaLibraryModuleState, IRootState> = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default mediaLibraryModule;
