













































/* eslint-disable no-unused-vars */
import { IUploadFileOptions } from '@w-admin/common/src/components/common-upload';
import { jobImport } from '@/common/service';
import { IJobImportResponse } from '@/common/types';
import { IEnterprise } from '@/model/enterprise';
import { IJob, IJobListData } from '@/model/job';
import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import JobCard from './components/job-card.vue';
import JobForm from './components/job-form.vue';
const EnterpriseInfoModule = namespace('enterprise/enterpriseInfo');
const JobModule = namespace('enterprise/job');

@Component({
  components: {
    JobCard,
    JobForm,
  },
})
export default class JobManagement extends Vue {
  @JobModule.State('listData')
  listData!: IJobListData[];

  @JobModule.Action('getList')
  getList!: (enterpriseId: number) => Promise<IJobListData[]>;

  @JobModule.Action('setCurrentJob')
  setCurrentJob!: (jobId?: string | number) => Promise<IJob | undefined>;

  @JobModule.State('currentJob')
  currentJob?: IJob;

  @EnterpriseInfoModule.State('currentEnterprise')
  currentEnterprise!: IEnterprise;

  @Getter('loadings')
  loadings!: (name: string) => boolean;

  importLoading = false;

  currentJobShow = false;

  jobFormTitle = '创建职位';

  get jobImportOption(): IUploadFileOptions<IJobImportResponse> {
    return {
      url: '#',
      name: 'excelFile',
      limit: 1,
      accept: [
        '.xls',
        '.xlsx',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
      ].join(','),
      data: {
        companyId: this.currentEnterprise.id,
      },
      headers: {
        token: this.$QJStorage.getStorage(this.$QJConfig.tokenKey),
      },
      beforeUpload: () => {
        this.importLoading = true;
        return true;
      },
      onSuccess: res => {
        this.importLoading = false;
        if (!res) {
          return;
        }
        if (!res?.success) {
          this.$notify.error('服务器内部错误');
          return;
        }
        if (res?.code === 200) {
          this.$notify.success('职位导入成功');
          this.getList(this.currentEnterprise.id);
          return;
        }
        this.$notify.info({
          duration: 0,
          title: res.message || '职位导入失败',
          message: `<a
            target="_blank"
            href="${res.data?.failedUrl}"
            class="el-link el-link--primary is-underline"
          >
            <span class="el-link--inner">点击下载</span>
          <a>`,
          dangerouslyUseHTMLString: true,
        });
        this.getList(this.currentEnterprise.id);
      },
      onError: () => {
        this.$notify.error('职位导入失败');
        this.importLoading = false;
      },
    };
  }

  async upload(options: IUploadFileOptions & { file: File }) {
    const { file, onError, onSuccess } = options;
    if (!file) {
      return;
    }
    const uploadClient = new this.$QJUtils.UploadClient({
      scene: 'COMPANY_PUBLIC',
      filename: file.name,
      file,
    });
    try {
      const { mediaId } = await uploadClient.upload();
      const rs = await jobImport(mediaId!, this.currentEnterprise.id);
      this.$QJUtils.safeCallback(onSuccess, [rs, file, [file]]);
    } catch (error) {
      this.$QJUtils.safeCallback(onError, [error, file, [file]]);
    }
  }

  reloadJob() {
    this.setCurrentJob(this.currentJob?.id);
  }

  downloadTemplateFile() {
    window.open(this.$QJConfig.jobTemplate);
  }

  enterDetail(jobId: string | number) {
    this.jobFormTitle = '编辑职位';
    const loading = this.$loading({
      lock: true,
      text: '正在加载职位信息...',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.7)',
    });
    this.setCurrentJob(jobId).then(() => {
      this.currentJobShow = true;
      loading.close();
    });
  }

  createJob() {
    this.jobFormTitle = '创建职位';
    this.setCurrentJob(undefined);
    this.currentJobShow = true;
  }

  created() {
    this.getList(this.currentEnterprise.id);
  }
}
