






import { Component, Prop, Vue } from 'vue-property-decorator';

// 自动引入src/assets/icons下所有svg素材作为symbol添加到body中
const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
const req = require.context('@/assets/icons', true, /\.svg$/);
requireAll(req);

@Component({
  name: 'svg-icon',
})
export default class SvgIcon extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  name!: string;

  @Prop({
    type: String,
    default: '',
  })
  color!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  useStroke!: boolean;

  @Prop(String)
  width!: string;

  @Prop(String)
  height!: string;

  get style() {
    const result: any = {};
    if (this.useStroke) {
      result.stroke = this.color;
    } else {
      result.fill = this.color;
    }

    if (this.width) {
      result.width = this.width;
    }
    if (this.height) {
      result.height = this.height;
    }
    return result;
  }
}
