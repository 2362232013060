import FailReason from './index.vue';

export default function failReason(id: number) {
  const instance: FailReason & { show: boolean, id: number } = new FailReason();
  instance.show = true;
  instance.id = id;
  instance.$mount();
  return new Promise(resolve => {
    instance.$on('loadDetailData', resolve);
  });
}