


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { saveClue } from '@/common/apis/recruitment';

@Component
export default class EditClueDialog extends Vue {
  @Prop({ default: '编辑线索' })
  title!: string;
  @Prop({ default: false })
  followerNameDisabled!: boolean;
  @Prop({ default: false })
  companyDisabled!: boolean;

  dialogFormVisible = false;

  rules = {
    companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
    contactsName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
    contactsMobile: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
    followerName: [{ required: true, message: '请输入客户经理', trigger: 'blur' }],
    contactsEmail: [{ required: true, message: '请输入联系邮箱', trigger: 'blur' }],
  };

  form = {
    id: null,
    contactsName: '',
    companyName: '',
    contactsMobile: '',
    followerName: '',
    contactsEmail: '',
  };

  $refs!: {
    form: ElForm;
  };

  // 保存
  handlerSave() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        const { success } = await saveClue(this.form);
        if (success) {
          this.$message({
            type: 'success',
            message: '保存成功!',
          });
        }
        this.$emit('submit');
        this.dialogFormVisible = false;
      }
    });
  }
  // 取消
  handlerCancle() {
    this.dialogFormVisible = false;
  }

  open(data) {
    if (data) {
      this.form = {
        id: data.id || null,
        contactsName: data.contactsName,
        companyName: data.companyName,
        followerName: data.followerName,
        contactsMobile: data.contactsMobile,
        contactsEmail: data.contactsEmail,
      };
    }
    this.dialogFormVisible = true;
  }
}
