/**
 * 钉钉组织认证等级
 */
export enum E_AuthLevel {
  NotAuthed = 0, // 未认证
  High, // 高级认证，有营业执照、法人
  Middle, // 中级认证，通过阿里巴巴认证
  Low, // 低级认证，很少
}

export function getAuthLevelDesc(authLevel: E_AuthLevel) {
  if (authLevel === null) {
    return '';
  }
  switch (authLevel) {
    case E_AuthLevel.High:
      return '高级认证';
    case E_AuthLevel.Middle:
      return '中级认证';
    case E_AuthLevel.Low:
      return '低级认证';
  }
  return '未认证';
}

export interface IEnterpriseListData {
  /**
   * 企业 id
   */
  id: number;
  /**
   * 编号
   */
  docNo: string;
  /**
   * 企业名称
   */
  name: string;
  /**
   * 企业简称
   */
  shortName: string;
  /**
   * 客户经理
   */
  followerName: string;
  /**
   * 入住阶段
   */
  enterStage: string;
  /**
   * 企业性质
   */
  kind: string;
  /**
   * 企业地址
   */
  registerLocation: string;
  /**
   * 行业编码
   */
  industry: string;
  /**
   * 行业名称
   */
  industryName: string;
  /**
   * 联系人 bid
   */
  contactsBid: number;
  /**
   * 联系人
   */
  contacts: string;
  /**
   * 公司规模1
   */
  scale: string;
  /**
   * 融资阶段
   */
  finStage: string;
  /**
   * 状态
   */
  status: string;
  /**
   * 招聘账号数
   */
  accountCount: number;
  /**
   * 职位数量
   */
  jobCount: number;
  /**
   * 注册时间
   */
  registerDate: string;
  /**
   * logo 地址
   */
  logoUrl: string;
  /**
   * 营业执照地址
   */
  licenseUrl: string;
  /**
   * AI机器人开关
   */
  enableAi: string;
  /**
   * 公司宣传图
   */
  advertisingPic: {
    data: string;
  } | null;
  /**
   * 面试类型
   */
  interViewType: 0 | 1;
  /**
   * 钉钉认证类型
   */
  authLevel: E_AuthLevel | null;
}

export interface IEnterprise extends IEnterpriseListData {
  ext: {
    enableAi: boolean;
    interviewType: 0 | 1;
  };
}
