const Dict = {
  educationFromMapping: {
    1: '博士后',
    2: '博士',
    3: '硕士',
    4: '研究生',
    5: '本科', // '大学本科，共有统招生、代培生、成人高考、大专升本科',
    6: '专科', // '（大专），分为统招生、代培生、成人高考',
    7: '高中/职中/中专',
    8: '初中',
    9: '小学',
  },
  enterpriseSearchOption: {
    COMPANY_NAME: {
      title: '企业名称/简称',
      code: 'COMPANY_NAME',
      check: /[\w\W]+/,
    },
    COMPANY_NUMBER: {
      title: '企业编号',
      code: 'COMPANY_NUMBER',
      check: /\d+/,
    },
    CONTACTS: {
      title: '企业联系人姓名',
      code: 'CONTACTS',
      check: /[\w\W]+/,
    },
  },
  accountStatus: {
    0: '禁用',
    1: '正常',
  },
  salaryType: {
    MONTHLY: {
      code: 'MONTHLY',
      value: 0,
      title: '月薪',
    },
    YEARLY: {
      code: 'YEARLY',
      value: 1,
      title: '年薪',
    },
    HOURLY: {
      code: 'HOURLY',
      value: 2,
      title: '时薪',
    },
    DAILY: {
      code: 'DAILY',
      value: 3,
      title: '日薪',
    },
  },
  categoryType: {
    JOB: {
      code: 'JOB',
      title: '职位分类',
      id: 71,
    },
    CITY: {
      code: 'CITY',
      title: '城市编码',
      id: 69,
    },
    INDUSTRY: {
      code: 'INDUSTRY',
      title: '行业编码',
      id: 70,
    },
    QUESTION_ATTACHMENT_REQUIREMENT: {
      code: 'QUESTION_ATTACHMENT_REQUIREMENT',
      title: '试题附件要求类别',
      id: 72,
    },
    RECRUITMENT_TYPE: {
      code: 'RECRUITMENT_TYPE',
      title: '招聘类型',
      id: 73,
    },
    JOB_TYPE: {
      code: 'JOB_TYPE',
      title: '岗位性质',
      id: 74,
    },
    EDUCATION: {
      code: 'EDUCATION',
      title: '学历',
      id: 75,
    },
    COMPANY_SCALE: {
      code: 'COMPANY_SCALE',
      title: '公司规模',
      id: 76,
    },
    FIN_STAGE: {
      code: 'FIN_STAGE',
      title: '融资阶段',
      id: 77,
    },
    WELFARE: {
      code: 'WELFARE',
      title: '福利待遇',
      id: 78,
    },
    COMPANY_KIND: {
      code: 'COMPANY_KIND',
      title: '公司性质',
      id: 79,
    },
    SALARY: {
      code: 'SALARY',
      title: '薪资',
      id: 80,
    },
    MONTH_SALARY_TIMES: {
      code: 'MONTH_SALARY_TIMES',
      title: '月薪数',
      id: 81,
    },
    EXPERIENCE: {
      code: 'EXPERIENCE',
      title: '经验要求',
      id: 82,
    },
    JOB_SEQUENCE: {
      code: 'JOB_SEQUENCE',
      title: '职能序列',
      id: 83,
    },
  },
  tagsType: {
    QUESTION: {
      code: 'QUESTION',
      title: '试题标签',
    },
    QUESTION_CUSTOMIZE: {
      code: 'QUESTION_CUSTOMIZE',
      title: '自定义试题标签',
    },
    JOB: {
      code: 'JOB',
      title: '职位标签',
    },
    JOB_BENEFITS: {
      code: 'JOB_BENEFITS',
      title: '职位福利标签',
    },
    AUDIT_RISK: {
      code: 'AUDIT_RISK',
      title: '风险审核标签',
    },
    AUDIT_RISK_CUSTOMIZE: {
      code: 'AUDIT_RISK_CUSTOMIZE',
      title: '风险审核自定义标签',
    },
    AUDIT_QUALITY_VOICE: {
      code: 'AUDIT_QUALITY_VOICE',
      title: '声音质量审核标签',
    },
    AUDIT_QUALITY_VOICE_CUSTOMIZE: {
      code: 'AUDIT_QUALITY_VOICE_CUSTOMIZE',
      title: '声音质量审核自定义标签',
    },
    AUDIT_QUALITY_FRAME: {
      code: 'AUDIT_QUALITY_FRAME',
      title: '画面质量审核标签',
    },
    AUDIT_QUALITY_FRAME_CUSTOMIZE: {
      code: 'AUDIT_QUALITY_FRAME_CUSTOMIZE',
      title: '画面质量审核自定义标签',
    },
    AUDIT_QUALITY_OTHER: {
      code: 'AUDIT_QUALITY_OTHER',
      title: '其他质量审核标签',
    },
    AUDIT_QUALITY_OTHER_CUSTOMIZE: {
      code: 'AUDIT_QUALITY_OTHER_CUSTOMIZE',
      title: '其他质量审核自定义标签',
    },
    CODING_LANGUAGE: {
      code: 'CODING_LANGUAGE',
      title: '编程语言',
    },
    SKILL: {
      code: 'SKILL',
      title: '技能要求',
    },
  },
  videoDisplayType: {
    WarmVideo: {
      code: 'WarmVideo',
      title: '暖场视频',
    },
    JobDetail: {
      code: 'JobDetail',
      title: '职位介绍',
    },
    TeamIntro: {
      code: 'TeamIntro',
      title: '团队介绍',
    },
    BizIntro: {
      code: 'BizIntro',
      title: '业务介绍',
    },
    CompanyIntro: {
      code: 'CompanyIntro',
      title: '企业介绍',
    },
    Custom: {
      code: 'Custom',
      title: '自定义',
    },
  },
  jobExamType: {
    InterviewerIntro: {
      code: 'InterviewerIntro',
      title: '面试官介绍',
    },
    QuestionSet: {
      code: 'QuestionSet',
      title: '试题集',
    },
    Question: {
      code: 'Question',
      title: '试题',
    },
    Interlude: {
      code: 'Interlude',
      title: '过场视频',
    },
    Conclusion: {
      code: 'Conclusion',
      title: '结束语',
    },
  },
  auditType: {
    RISK: {
      code: 'RISK',
      title: '风险合规',
    },
    QUALITY: {
      code: 'QUALITY',
      title: '拍摄质量',
    },
    CAPBILITY: {
      code: 'CAPBILITY',
      title: '面试水平',
    },
  },
  interviewStatus: {
    all: {
      text: '全部',
      code: ['ALL'],
      value: 0,
      countField: 'allInterviewCount',
    },
    // unaccepted: {
    //   text: '已邀请',
    //   code: ['UNACCEPTED'],
    //   value: 1,
    // },
    unanswered: {
      text: '未答题',
      code: ['UNANSWER'],
      value: 2,
      countField: 'unansweredInterviewCount',
    },
    answered: {
      text: '待评估',
      code: ['ANSWERED'],
      value: 4,
      countField: 'needEvaluateInterviewCount',
    },
    pass: {
      text: '已评估',
      code: ['PASSED'],
      value: 5,
      countField: 'evaluatedInterviewCount',
    },
    // fail: {
    //   text: '面试未通过',
    //   code: ['FAILED'],
    //   value: 6,
    // },
    // cancel: {
    //   text: '面试已取消',
    //   code: ['CANCEL'],
    //   value: 7,
    // },
    // auditfailed: {
    //   text: '审核失败',
    //   code: ['AUDIT_FAILED'],
    //   value: -1,
    // },
    // auditing: {
    //   text: '系统审核',
    //   code: ['AUDITING'],
    //   value: 3,
    // },
  },
  newTalent: {
    waitScreen: {
      text: '待筛选',
      code: ['WAITSCREEN'],
      value: 0,
    },
    inappropriate: {
      text: '不合适',
      code: ['INAPPROPRIATE'],
      value: 1,
    },
  },
  /**
   * 面试状态
   */
  interviewStatusDetails: {
    /**
     * 待接受
     */
    WAIT_ACCEPT: 1,
    /**
     * 待面试
     * WAITING_ACCEPT accept(): WAITING_INTERVIEW
     */
    WAIT_ANSWER: 2,
    /**
     * 审核中
     * WAIT_ANSWER commit(): AUDITING
     */
    AUDITING: 3,
    /**
     * 待反馈
     * AUDITING audit(): WAIT_FEEDBACK
     */
    WAIT_FEEDBACK: 4,
    /**
     * 面试通过
     * WAIT_FEEDBACK feedback(): PASSED/FAILED
     */
    PASSED: 5,
    /**
     * 面试失败
     */
    FAILED: 6,
    /**
     * 取消
     * cancel(): CANCEL
     */
    CANCEL: 7,
    /**
     * 待定
     * cancel(): CANCEL
     */
    HOLD: 8,
    /**
     * 审核失败
     * AUDITING audit(): AUDIT_FAILED
     */
    AUDIT_FAILED: -1,
  },
  /**
   * 面试状态文案
   */
  interviewStatusDesc: {
    1: '已邀请',
    2: '未答题',
    3: '待审核',
    4: '待面试反馈',
    5: '面试已通过',
    6: '面试未通过',
    7: '面试已取消',
    '-1': '审核拒绝',
  },
  interviewFeedbackRate: [
    {
      label: '不合格',
      value: 1,
      code: 'FAIL',
    },
    {
      label: '一般',
      value: 3,
      code: 'PASS',
    },
    {
      label: '良好',
      value: 4,
      code: 'GOOD',
    },
    {
      label: '优秀',
      value: 5,
      code: 'EXCELLENT',
    },
  ],
  questionStyle: {
    VIDEO: {
      code: 'VIDEO',
      title: '视频回答题',
      value: 2,
      answerType: 2,
      answerTypeName: '（候选人通过录制答题视频来回答问题）',
    },
    PICTURE: {
      code: 'PICTURE',
      title: '代码分析题', // 图片类
      value: 3,
      answerType: 3,
      answerTypeName: '（候选人圈出代码中的问题来完成考察）',
    },
    TEXT: {
      code: 'TEXT',
      title: '视频回答题', // 文本题
      value: 4,
      answerType: 2,
      answerTypeName: '（候选人通过录制答题视频来回答问题）',
    },
    // CHOICE: {
    //   code: 'CHOICE',
    //   title: '单选题',
    //   value: 6,
    //   answerType: 0,
    // },
    // MULTI_CHOICE: {
    //   code: 'MULTI_CHOICE',
    //   title: '多选题',
    //   value: 7,
    //   answerType: 0,
    // },
    CODE: {
      code: 'CODE',
      title: '编程题', // 代码题
      value: 8,
      answerType: 4,
      answerTypeName: '（候选人通过在线编写代码对方式来回答问题）',
    },
  },
  videoStatus: {
  /**
   * 正常
   */
    OK: {
      title: '正常',
      code: 'OK',
      value: 1,
    },
    /**
     * 删除
     */
    DELETED: {
      title: '视频已经删除',
      code: 'DELETED',
      value: -1,
    },
    /**
   * 没准备好
   * 正在上传/正在审核/视频正在转码 ...
   */
    NOT_READY: {
      title: '视频正在转码中...',
      code: 'NOT_READY',
      value: -2,
    },
    /**
   * 审核失败
   */
    AUDIT_FAIL: {
      title: '审核失败',
      code: 'AUDIT_FAIL',
      value: -3,
    },
  },
  levelList: [
    {
      label: '不限',
      value: 0,
    },
    {
      label: '简单',
      value: 1,
    },
    {
      label: '适中',
      value: 2,
    },
    {
      label: '困难',
      value: 3,
    },
  ],
  roleCode: {
    // ASSISTANT: {
    //   code: 'ASSISTANT',
    //   title: '招聘助手',
    //   value: 1,
    // },
    WHISTLEBLOWER: {
      code: 'WHISTLEBLOWER',
      title: '举报人',
      value: 2,
    },
  },
};

export default Dict;
