import { PluginObject } from 'vue';
import { globalConfig, IQJConfig, IQJAConfig, IQJBConfig } from '.';

const QJConfig: PluginObject<Partial<IQJConfig | IQJAConfig | IQJBConfig> | undefined> = {
  install: (Vue, options) => {
    const mergedConfig = {
      ...globalConfig.original,
      ...options ? options : {},
    };

    globalConfig.current = mergedConfig;

    Object.defineProperty(window, '$QJConfig', {
      value: mergedConfig,
      writable: false,
    });

    Object.defineProperty(window, '$QJDict', {
      value: mergedConfig.Dict,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$QJConfig', {
      value: mergedConfig,
      writable: false,
    });

    Object.defineProperty(Vue.prototype, '$QJDict', {
      value: mergedConfig.Dict,
      writable: false,
    });
  },
};

export default QJConfig;
