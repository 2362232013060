



























import { upload } from '@w-admin/common/src/components/common-upload/service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    //
  },
})
export default class MyComponent extends Vue {
  isResizing:{
    value:boolean
  } = { value: false }

  uploading = false;

  $refs!: {
    file: HTMLInputElement;
    iframe: HTMLIFrameElement;
  };

  @Prop({ type: String, default: '' })
  previewResumeUrl!: string;

  onUploadClick() {
    const input = this.$refs.file;
    input.click();
  }

  async uploadResume(e: Event) {
    this.uploading = true;
    const target = e.target as EventTarget & { files: FileList };
    const file = target?.files[0];
    const { name } = file;
    try {
      const { data } = await upload(file, 'CV');
      this.$emit('uploaded', { name, data: data });
    } catch (err) {
      console.warn('[upload] error', err);
    } finally {
      this.uploading = false;
    }
  }
  // mounted() {
  //   // this.$refs.iframe.height = this.$el.clientHeight + '';
  //   this.$refs.iframe.width = this.$el.clientWidth + '';
  //   this.$refs.iframe.style.maxWidth = this.$el.clientWidth + '';
  // }
}
