import { getJobDetails, getJobList } from '@/common/service';
import { IJob, IJobListData } from '@/model/job';
import { Module } from 'vuex';
import { IRootState } from '../root';
import { initJobListData } from './init';

export interface IJobManagementState {
  listData: IJobListData[];
  currentJob?: IJob;
}

const types = {
  UPDATE_LIST_DATA: 'updateListData',
  UPDATE_CURRENT_JOB: 'updateCurrentJob',
};

const jobManagementModule: Module<IJobManagementState, IRootState> = {
  namespaced: true,
  state: {
    listData: initJobListData(),
    currentJob: undefined,
  },
  getters: {},
  mutations: {
    updateCurrentJob(state, job: IJob) {
      state.currentJob = job;
    },
    updateListData(state, listData: IJobListData[]) {
      state.listData = listData;
    },
  },
  actions: {
    async setCurrentJob({ commit, state }, jobId?: number) {
      if (!jobId) {
        commit(types.UPDATE_CURRENT_JOB, undefined);
        return undefined;
      }
      try {
        const { data } = await getJobDetails(jobId);
        commit(types.UPDATE_CURRENT_JOB, data);
        return data;
      } catch (error) {
        console.info(`【获取职位详情失败】jobId: ${jobId}`, error);
        return state.currentJob;
      }
    },
    async getList({ commit, dispatch, state }, enterpriseId: number) {
      try {
        dispatch('wait/start', 'job.getList', { root: true });
        const { data } = await getJobList({
          companyId: enterpriseId,
          pageNo: 1,
          pageSize: 9999,
        });
        commit(types.UPDATE_LIST_DATA, data.list);
        dispatch('wait/end', 'job.getList', { root: true });
        return data;
      } catch (error) {
        dispatch('wait/end', 'job.getList', { root: true });
        console.log(error);
        return state.listData;
      }
    },
  },
};

export default jobManagementModule;
