



















































































































import { getEnterpriseQuestionsList } from '@/common/service';
import { IPagination, ISearchEnterpriseVideo } from '@/common/types';
import { IEnterprise } from '@/model/enterprise';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectBackBase from './select-back-base.vue';
import { IJobExamsForSave, IJobQuestionSnapshotsForSave } from '@/model/job';
import { IMedia, IVideoBaseInfo } from '@/model/resource';
import { IEnterpriseQuestionListData } from '@/model/enterprise-question';
import QuestionItem from '../question-item.vue';

@Component({
  components: {
    SelectBackBase,
    QuestionItem,
  },
})
export default class SelectBackExame extends Vue {
  @Prop({ default: false, type: Boolean }) placeholder!: boolean;
  @Prop({ default: '', type: String }) dialogTitle!: string;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly!: boolean;
  @Prop({ default: false, type: Boolean }) hidden!: boolean;
  @Prop({ default: false, type: Boolean }) remove!: boolean;
  @Prop({ required: true }) enterprise!: IEnterprise;
  @Prop() type?: string;
  @Prop() value?: IJobQuestionSnapshotsForSave[];
  @Prop() item?: IJobExamsForSave;

  loading = false;
  questionTypeObj = this.$QJConfig.enterpriseQuestionCategoriesTypes[0];
  questionType = this.$QJConfig.enterpriseQuestionCategoriesTypes[0].code;

  stringifyQuestion(question: IEnterpriseQuestionListData<IVideoBaseInfo>) {
    return JSON.stringify({
      questionId: question.questionId,
      title: question.title,
      content: question.content,
      medias: question.medias,
      minDuration: question.minDuration,
      maxDuration: question.maxDuration,
      score: question.score,
      libraryType: question.libraryType,
      tips: question.tips,
      snapshotId: question.snapshotId,
      attachmentRequirement: question.attachmentRequirement,
    });
  }

  parentCategoryChange(e: string) {
    this.questionTypeObj = this.$QJConfig.enterpriseQuestionCategoriesTypes.find(item => item.code === e)!;
    this.getList(1, this.pageSize);
  }

  get searchParams() {
    return {
      companyId: this.enterprise.id,
      libraryType: 1,
      questionType: this.questionType,
    };
  }

  questionMedias(question:IEnterpriseQuestionListData<IVideoBaseInfo> & { data: IVideoBaseInfo } ) {
    return question?.medias?.[0]?.data
    && (
      question.medias[0].data.url || question.medias[0].data.coverUrl
    );
  }

  innerValue: string[] = [];

  get nextValue() {
    return this.$QJUtils.filterBy(
      this.innerValue.map(e => this.$JSONParse<IEnterpriseQuestionListData<IVideoBaseInfo>>(e)),
      'questionId',
    );
  }

  handleChange() {
    this.$emit('update:value', this.nextValue);
  }

  listData: IPagination<IEnterpriseQuestionListData<IVideoBaseInfo> & { data: IVideoBaseInfo }> = {
    totalCount: 0,
    pageSize: 20,
    pageNo: 1,
    list: [],
  };

  get pageSize() {
    return this.listData.pageSize;
  }
  get pageNo() {
    return this.listData.pageNo;
  }

  get totalCount() {
    return this.listData.totalCount;
  }

  set pageSize(pageSize: number) {
    this.getList(this.pageNo, pageSize);
  }

  set pageNo(pageNo: number) {
    this.getList(pageNo, this.pageSize);
  }

  handleSizeChange(val: number) {
    this.pageSize = val;
  }

  handleCurrentChange(val: number) {
    this.pageNo = val;
  }

  onMinDurationChange(minDuration: number, index: number) {
    this.$set(this.innerValue, index, JSON.stringify({
      ...this.$JSONParse(this.innerValue[index]),
      minDuration,
    }));
    this.$emit('update:value', this.nextValue);
  }

  onMaxDurationChange(maxDuration: number, index: number) {
    this.$set(this.innerValue, index, JSON.stringify({
      ...this.$JSONParse(this.innerValue[index]),
      maxDuration,
    }));
    this.$emit('update:value', this.nextValue);
  }

  onAnnexRequirementsChange(attachmentRequirement: string, index: number) {
    this.$set(this.innerValue, index, JSON.stringify({
      ...this.$JSONParse(this.innerValue[index]),
      attachmentRequirement,
    }));
    this.$emit('update:value', this.nextValue);
  }

  hoverMap: Record<string | number, boolean> = {
    defaultState: false,
  };

  preview(
    {
      question,
      item,
    }: {
    question: IEnterpriseQuestionListData<IVideoBaseInfo> & { data: IVideoBaseInfo },
    item?: IMedia<IVideoBaseInfo>,
  },
    e: Event,
  ) {
    e.preventDefault();
    if (
      (
        question.medias && question.medias[0]?.data?.playAddressList && question.medias[0]?.data?.playAddressList[0]?.playUrl)
      || (question?.data?.playAddressList && question?.data?.playAddressList[0]?.playUrl
      || item?.data?.playAddressList && item?.data?.playAddressList[0] && item?.data?.playAddressList[0].playUrl
      )
    ) {
      this.$alert(`
      <video
        controls
        height="400"
        width="auto"
        style="max-width: 500px;"
        src="${question.medias ? question.medias[0]?.data?.playAddressList[0]?.playUrl : ''}"
      ></video>`, `视频预览: ${question.title || item?.title || ''}`, {
        confirmButtonText: '关闭预览',
        center: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'video-preview-dialog',
        // eslint-disable-next-line no-sequences
        beforeClose: (action, instance, done) => (instance.$el.remove(), done()),
      }).catch(() => {});
    }
  }

  previewShow(question: IEnterpriseQuestionListData<IVideoBaseInfo>) {
    if (!question.questionId) {
      return;
    }
    this.$set(this.hoverMap, question.questionId, true);
  }

  previewHide(question: IEnterpriseQuestionListData<IVideoBaseInfo>) {
    if (!question.questionId) {
      return;
    }
    this.$set(this.hoverMap, question.questionId, false);
  }

  onRemove(index: number) {
    if (index >= 0) {
      this.innerValue.splice(index, 1);
      this.$emit('update:value', this.nextValue);
    }
  }

  submit() {
    this.$emit('submit', this.innerValue, this.nextValue);
    return true;
  }

  init() {
    this.innerValue = (this.value || []).map(e => this.stringifyQuestion(e as unknown as IEnterpriseQuestionListData<IVideoBaseInfo>));
  }

  async getList(pageNo: number, pageSize: number) {
    this.loading = true;
    const options = {
      ...this.searchParams,
      pageNo,
      pageSize,
    } as unknown as ISearchEnterpriseVideo;
    try {
      const { data } = await getEnterpriseQuestionsList(options);
      const handledData = {
        ...data,
        list: data.list.map((e: IEnterpriseQuestionListData<string>) => ({
          ...e,
          maxDuration: e.maxDuration || e.duration || 180,
          medias: e.medias ? e.medias.map(m => ({
            ...m,
            data: this.$JSONParse<IVideoBaseInfo>(m.data),
          })) : [],
          data: e.medias ? this.$JSONParse<IVideoBaseInfo>(e.medias[0]?.data) : {},
        })) as (IEnterpriseQuestionListData<IVideoBaseInfo> & { data: IVideoBaseInfo })[],
      };

      /**
       * 过滤已经选择过的题目
       */
      this.listData = {
        ...handledData,
        list: handledData.list.filter(e => this.nextValue.every(selected => selected.questionId !== e.questionId )),
      };

      this.loading = false;
    } catch (error) {
      this.listData = {
        totalCount: 0,
        pageSize: 20,
        pageNo: 1,
        list: [],
      };
      console.info('【企业试题获取失败】', options, error);
      this.loading = false;
    }
  }

  mounted() {
    this.init();
  }

  onShow() {
    this.getList(1, 20);
  }
}
